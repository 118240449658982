import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./styles";

export const Footer = (props) => (
  <View fixed style={styles.footer}>
    <View style={styles.footerContent}>
      <Text style={{ marginTop: 20 }}>{props.footerText}</Text>
    </View>
  </View>
);
