import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "bukrabold",
  src: `${process.env.PUBLIC_URL}/fonts/bukrabold.ttf`,
  format: "truetype",
});
Font.register({
  family: "bukraregular",
  src: `${process.env.PUBLIC_URL}/fonts/bukraregular.ttf`,
  format: "truetype",
});

export const styles = StyleSheet.create({
  page: {
    fontFamily: "bukrabold",
    padding: "30 40 30 40",
  },
  bolder: {
    fontWeight: "bold",
    fontFamily: "bukrabold",
  },
  watermarkContainer: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  watermark: {
    width: 230,
    opacity: 0.8,
  },
  TableTitle: {
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: 70,
    fontSize: 10,
    color: "#1F766C",
  },
  tableTitleContainer: {
    flexWrap: "wrap",
    alignItems: "flex-start",
    alignSelf: "flex-end",
    marginBottom: 20,
  },
  tableHeaderContainer: {
    fontFamily: "bukraregular",
    flexDirection: "row",
    backgroundColor: "#EBF2F2",
    height: 25.5,
    alignItems: "center",
    borderRadius: 4,
    color: "#232323",
    justifyContent: "space-around",
    alignSelf: "flex-end",
    marginLeft: 70,
    fontSize: 9,
    width: "100%",
  },
});

export default styles;
