import { base64ToBlob, convertBlobToFile } from "../ApiHelpers";

export const convertFileToBase64 = (file) => {
  if (!file) return;
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      resolve(base64String);
    };
    reader.readAsDataURL(file);
  });
};

export const convertFileToBase64WithPrefix = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result; // Keep the data URL intact

      resolve(base64String);
    };
    reader.readAsDataURL(file);
  });
};

export const convertToBlob = (attachment) => {
  return base64ToBlob(attachment);
};

export const openPDFHandler = (attachment) => {
  if (!attachment) return null;
  var blob = convertToBlob(attachment);
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getBlobInfo = (attachment) => {
  let blob = convertToBlob(attachment);

  if (!blob["type"] || !blob["size"]) return null;

  let type = blob["type"].split("/")[1];
  let size = formatBytes(blob["size"]);

  return { type, size };
};

export const getConvertedCurrentAttachment = (attachment) => {
  if (!attachment) return null;
  const blobCurrentAttachment = base64ToBlob(attachment);
  const blobToFile = convertBlobToFile(blobCurrentAttachment);

  return { file: blobToFile };
};

/**
 *
 * @param {number} fileSize - raw file size before any calculations
 * @param {object} maxSize - {size: number, unit: string | "kb", "mb", "gb", "bytes"}
 * @default fileSize 1
 * @default maxSize {size: 1, unit: 'GB'}
 * @returns boolean | it returns true in case the file size is lower than the max size
 */
export const validateFileSize = (fileSize, maxSize) => {
  let convertedFileSize = fileSize;
  let isValidSize = true;

  switch (maxSize.unit) {
    case "KB":
    case "kb":
    case "k":
      convertedFileSize = fileSize / 1024;
      isValidSize = convertedFileSize < maxSize.size;
      break;
    case "MB":
    case "mb":
    case "m":
      convertedFileSize = fileSize / (1024 * 1024);
      isValidSize = convertedFileSize < maxSize.size;
      break;
    case "GB":
    case "gb":
    case "g":
      convertedFileSize = fileSize / 1073741824;
      isValidSize = convertedFileSize < maxSize.size;
      break;
    default:
      isValidSize = convertedFileSize < maxSize.size;
      break;
  }
  return isValidSize;
};

export const validateFileExtension = (file, availableExtensions) => {
  if (!file) return false;

  let isValidExtension = true;

  const fileExtension = file.name.split(".").at(-1);

  if (availableExtensions.length === 0) return true;

  if (availableExtensions.indexOf(fileExtension) === -1) {
    return false;
  }

  return isValidExtension;
};

export const handleOpenPdf = (path) => {
  window.open(path, "_blank");
};

export const windowOpenPDFHandler = (file) => {
  const blob = base64ToBlob(file);
  const blobUrl = URL.createObjectURL(blob);
  handleOpenPdf(blobUrl);
};
