import { useEffect, useState } from "react";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTheme } from "@emotion/react";
import ImagePreview from "./ImagePreview/ImagePreview";
import AddImage from "./AddImage/AddImage";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { _void } from "utils/Objects/Objects";
import BaseCamera from "core-ui/BaseCamera/BaseCamera";
import BaseModal from "core-ui/BaseModal/BaseModal";
import ConfirmUploadAttachments from "components/AllModalContent/ConfirmUploadAttachments/ConfirmUploadAttachments";
import { base64ToBlob, convertBlobToFile } from "utils/ApiHelpers";
import { isContentReady } from "utils/Array/Arrays";

const MAXIMUM_AVAILABLE_PHOTOS = 5;

const BaseCameraMultiple = (props) => {
  const { palette } = useTheme();

  const requiredLength = Math.min(
    props.requiredLength,
    MAXIMUM_AVAILABLE_PHOTOS
  );

  const [photos, setPhotos] = useState(props.photos || []);

  useEffect(() => {
    setPhotos(props.photos);
  }, [props.photos]);

  const [displayImage, setDisplayImage] = useState(null);

  const isCapturingAvailable = photos?.length < MAXIMUM_AVAILABLE_PHOTOS;

  const [uploadPhotosError, setUploadPhotosError] = useState(null);

  const noPhotos = Array.isArray(photos) && photos.length === 0;

  useEffect(() => {
    setPhotos(props.photos);
  }, [props.photos]);

  const [showCamera, setShowCamera] = useState(false);
  const openCameraHandler = () => {
    setShowCamera(true);
  };
  const closeCameraHandler = () => {
    setShowCamera(false);
  };
  const onChangePhoto = (photo) => {
    if (photo && photo.saved) {
      setPhotos((prevState) => prevState.concat(photo));
    }
  };

  const onDeleteHandler = (index) => {
    setPhotos((prevState) => prevState.filter((_, idx) => idx !== index));
  };
  const onConfirmUploadHandler = () => {
    setShowConfirmModal(true);
  };

  const onUploadPhotos = () => {
    if (photos.length < requiredLength) {
      setUploadPhotosError({
        message: `يجب إلتقاط  ${requiredLength} صورة لكل نتيجة`,
      });
      return;
    }
    setUploadPhotosError(null);

    if (!props.uploadFn) return;

    const upload = (photo) => {
      const blob = base64ToBlob(photo);

      let fileOfBlob = convertBlobToFile(blob);

      const formData = new FormData();

      formData.append("file", fileOfBlob);

      props.uploadFn(formData);
    };

    photos.forEach(({ photo }) => upload(photo));
  };
  ////////// BASE MULTIPLE CAMERA

  ////////// BASE MODAL
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };
  ////////// BASE MODAL

  return (
    <>
      <BaseModal
        show={displayImage}
        py={"2rem"}
        px={"4rem"}
        onHide={() => setDisplayImage(null)}
        closeIcon
        title={"صور النتائج"}
        modalContentWrapperSx={{ textAlign: "center" }}
      >
        <img src={displayImage} className="img-fluid img-fit" />
      </BaseModal>

      <BaseModal
        show={showConfirmModal}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <ConfirmUploadAttachments
          onConfirm={onUploadPhotos}
          isLoading={props.isUploading || props.uploadResponse?.isLoading}
          onHide={onLeavePageHandler}
        />
      </BaseModal>

      <BaseCamera
        show={showCamera}
        onHide={closeCameraHandler}
        mainContainerSx={{ width: "130rem" }}
        onChange={onChangePhoto}
        isSuccessUpload={props.uploadResponse?.isSuccess}
        uploadErrors={props.uploadResponse?.error}
      />

      <BaseCard variant="secondary" sx={{ mt: 2 }} px="4rem" py="4rem">
        {!isContentReady(photos) && props.isReadonlyMode && (
          <Box>
            <Typography
              sx={{
                fontSize: "1.6rem",
                fontWeight: "bold",
                color: "primary.main",
                textAlign: "center",
              }}
            >
              لا يوجد بيانات
            </Typography>
          </Box>
        )}
        {photos.length >= 0 && (
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={4}
              flexWrap={"wrap"}
            >
              {photos.map(({ photo, id }, idx) => {
                let key = id || photo;
                return (
                  <ImagePreview
                    key={key}
                    photo={photo}
                    idx={idx}
                    palette={palette}
                    onDelete={onDeleteHandler}
                    isUploading={
                      props.isUploading || props.uploadResponse?.isLoading
                    }
                    isReadonlyMode={props.isReadonlyMode}
                    onDisplay={(img) => setDisplayImage(img)}
                  />
                );
              })}
              {!props.isReadonlyMode &&
                isCapturingAvailable &&
                !props.isUploading &&
                !props.uploadResponse?.isLoading && (
                  <AddImage
                    onOpenCamera={openCameraHandler}
                    palette={palette}
                  />
                )}
            </Stack>
            {!props.isReadonlyMode && (
              <Box mt={3} textAlign={"end"}>
                <BaseButton
                  onClick={onConfirmUploadHandler}
                  sx={{ minWidth: "9rem" }}
                  disabled={noPhotos}
                  isLoading={
                    props.isUploading || props.uploadResponse?.isLoading
                  }
                >
                  تأكيد
                </BaseButton>
              </Box>
            )}
          </>
        )}
      </BaseCard>

      {!props.isReadonlyMode && uploadPhotosError && (
        <FormHelperText error variant="error" sx={{ fontSize: "1.1rem" }}>
          {uploadPhotosError.message}
        </FormHelperText>
      )}
    </>
  );
};

export default BaseCameraMultiple;

BaseCameraMultiple.defaultProps = {
  photos: [],
};
