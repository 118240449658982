import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import {
  useCheckEditableForLaboratoryQuery,
  useEditFieldTestRequestMutation,
  useGetInspectionForLabQuery,
  useSaveDraftFieldTestMutation,
  useSubmitAssignedRequestMutation,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { useForm } from "react-hook-form";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import RaiseResultsFieldTest from "../../AssignedRequestsDetails/Field/RaiseResultsFieldTest/RaiseResultsFieldTest";
import { prepareDataForFieldTestResultSubmission } from "../../AssignedRequestsDetails/Field/helpers";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { Helmet } from "react-helmet";
import useAlert from "hooks/useAlert";
import { AssignedRequestRaiseResults } from "services/StaticLookup/Breadcrumb";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { _scrollToTop } from "utils/DOM/DOM";
import LabAttachment from "../../AssignedRequestsDetails/Lab/LabAttachment/LabAttachment";
import { EditableStatusEnum } from "services/StaticLookup/EditableStatus";
import BaseModal from "core-ui/BaseModal/BaseModal";
import LabEditRequestModal from "components/AllModalContent/LabEditRequestModal/LabEditRequestModal";
import BaseTextArea from "core-ui/BaseTextArea/BaseTextArea";
import { AlertTypes } from "constants/AlertTypes";
import { convertFileToBase64 } from "utils/File/File";

let navigatedUserTimer;

const FieldAssignedRequestForm = (props) => {
  const params = useParams();

  const location = useLocation();

  const { state } = location;

  const isEditing = state?.isEditing;

  const { data: inspection, isFetching: isInspectionFetching } =
    useGetInspectionForLabQuery(params.id);

  const inspectionLayer = inspection?.inspectionLayersList[0];

  const { stations: stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData(inspection);

  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    control,
    register,
    setValue,
    unregister,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange", shouldUnregister: true });

  const formValues = watch();

  const inspectionLayerDTO = inspectionLayer;

  // FIELD
  const {
    alert: raiseResultsAlert,
    showAlertHandler: showRaiseResultsAlertHandler,
    hideAlertHandler: hideRaiseResultsAlertHandler,
  } = useAlert();

  const [saveDraftFieldTest, saveDraftFieldTestResponse] =
    useSaveDraftFieldTestMutation();

  const [submitFieldTest, submitFieldTestResponse] =
    useSubmitAssignedRequestMutation();

  const [editFieldTestRequest, editFieldTestRequestResponse] =
    useEditFieldTestRequestMutation();

  const { data: checkEditable, isFetching: isCheckEditableFetching } =
    useCheckEditableForLaboratoryQuery(inspection?.id, {
      skip: !inspection?.id,
    });

  const [enableDrafting, setEnableDrafting] = useState(true);

  async function edit(body) {
    try {
      return editFieldTestRequest(body);
    } catch (err) {
      throw err;
    }
  }
  async function draft(body) {
    try {
      return saveDraftFieldTest(body);
    } catch (err) {
      throw err;
    }
  }
  function submit() {
    submitFieldTest(inspection?.id);
  }

  const onDraftHandler = (cb) => {
    const backendData = prepareDataForFieldTestResultSubmission(
      formValues,
      stations
    );

    let serverData = {
      inspectionId: inspection?.id,
      body: { stations: backendData },
    };

    const mutationFn = async () => {
      if (isEditing) {
        let editedAttachment = await convertFileToBase64(
          formValues?.attachment?.file
        );
        return edit({
          ...serverData,
          body: {
            ...serverData.body,
            attachment: editedAttachment,
            editReason: formValues?.editReason || "",
          },
        });
      }
      return draft(serverData);
    };

    mutationFn()
      .then((d) => {
        setEnableDrafting(false);
        let message = isEditing
          ? SuccessCodes.SUCCESS_SUBMIT_EDIT_TEST_REQUEST_FORM
          : SuccessCodes.SUCCESS_ONLY_DRAFT_REQUEST_FORM;
        if (cb) {
          message = SuccessCodes.SUCCESS_SUBMIT_RESULTS;
          cb();
        }
        showRaiseResultsAlertHandler({
          show: true,
          type: AlertTypes.SUCCESS,
          message: message,
        });
      })
      .catch((err) => {
        showRaiseResultsAlertHandler({
          show: true,
          type: AlertTypes.ERROR,
          message: err.data?.errorCode || ErrorCodes.UNKNOWN_ERROR,
        });
      })
      .finally(() => {
        navigatedUserTimer = setTimeout(() => {
          navigate(`/assigned-requests/${inspection?.id}`, {
            state: {
              navigatedUserTimer,
            },
          });
        }, 3000);
        _scrollToTop();
      });
  };

  const onSubmitHandler = (data) => {
    let callback = submit;
    if (isEditing) {
      callback = null;
    }
    onDraftHandler(callback);
  };

  const onErrorHandler = (errors) => {
    console.log("[ERROR] - errors: ", errors);
  };

  // prettier-ignore
  const isDraftedNotSubmitted = inspection?.isRequestInitialSubmissionDrafted && !inspection?.submittedAt;

  // prettier-ignore
  const isSubmitted = !!inspection?.submittedAt;

  const isCTAsDisabled =
    saveDraftFieldTestResponse.isLoading ||
    editFieldTestRequestResponse.isLoading ||
    submitFieldTestResponse.isLoading;

  const raiseScoresSubmitButtonRef = useRef(null);

  const virtualStation = stations?.find((station) => station.isVirtual);

  const tableContent = virtualStation
    ? [virtualStation]
    : stations?.filter((s) => !s.isVirtual);

  const isComponentFetching =
    isInspectionFetching || isStationsFetching || isCheckEditableFetching;

  const shouldBeNavigatedBack =
    (checkEditable === EditableStatusEnum.TEST_NOT_COMPLETED && isSubmitted) ||
    (checkEditable !== EditableStatusEnum.CAN_EDIT &&
      checkEditable !== EditableStatusEnum.TEST_NOT_COMPLETED);

  useEffect(() => {
    if (!isInspectionFetching && !isStationsFetching) {
      if (shouldBeNavigatedBack) {
        let editError =
          editFieldTestRequestResponse.error?.data.errorCode ===
          ErrorCodes.REQUEST_STATUS_DOES_NOT_ALLOW_ACTION;

        navigate(`/assigned-requests/${inspection?.id}`, {
          state: {
            ...(editError && {
              editError:
                ErrorCodes.LABORATORY_EDIT_REQUEST_STATUS_DOES_NOT_ALLOW_ACTION,
            }),
          },
        });
      }
    }
  }, [
    isSubmitted,
    editFieldTestRequestResponse,
    shouldBeNavigatedBack,
    isStationsFetching,
    isInspectionFetching,
    inspection?.submittedAt,
  ]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };

  const showEditFieldRequestModal = () => {
    setShowConfirmModal(true);
  };

  const onConfirmEditHandler = () => {
    raiseScoresSubmitButtonRef.current.click();
  };

  const onClickSubmit = () => {
    return isEditing ? showEditFieldRequestModal() : onConfirmEditHandler();
  };

  const draftButton = (
    <BaseButton
      sx={{ minWidth: "12.2rem" }}
      onClick={onDraftHandler.bind(null, null)}
      variant="secondary"
      disabled={isCTAsDisabled}
      isLoading={isCTAsDisabled}
    >
      {t("حفظ كمسودة")}
    </BaseButton>
  );

  const submitButton = (
    <BaseButton
      onClick={onClickSubmit}
      sx={{ minWidth: "12.2rem" }}
      disabled={isCTAsDisabled}
      isLoading={isCTAsDisabled}
    >
      إرسال
    </BaseButton>
  );

  const cancelButton = (
    <BaseButton
      sx={{ minWidth: "12.2rem" }}
      onClick={() => navigate(-1)}
      variant="secondary"
      disabled={isCTAsDisabled}
      isLoading={isCTAsDisabled}
    >
      إلغاء
    </BaseButton>
  );

  const dashboardIntroTitle = isEditing
    ? `نموذج تعديل النتائج للطلب رقم ${inspection?.id}#`
    : `نموذج رفع النتائج للطلب رقم ${inspection?.id}#`;

  const dashboardIntroDescription = isEditing
    ? `يمكنك طلب تعديل النتائج من هذه الصفحة، حيث سيتم ارسال طلب التعديل لمدير النظام ليتم اعتماده`
    : `يرجى تعبئة النموذج التالي، كما يمكنك حفظ المدخلات بشكل مؤقت في حال رغبتك في إتمام النموذج لاحقًا.`;

  if (isComponentFetching) return <BaseLoader />;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة - رقم الطلب ${inspection?.id}`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>
      <BaseModal
        show={showConfirmModal}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <LabEditRequestModal
          onConfirm={onConfirmEditHandler}
          requestId={inspection?.id}
          onHide={onLeavePageHandler}
        />
      </BaseModal>

      <DashboardIntro
        title={dashboardIntroTitle}
        description={dashboardIntroDescription}
        variant={"secondary"}
        action={
          <DashboardIntroAction>
            <BaseAlert
              sx={{ mb: 2 }}
              show={raiseResultsAlert.show}
              type={raiseResultsAlert.type}
              message={raiseResultsAlert.message}
              destroy={hideRaiseResultsAlertHandler}
              autoClose={5}
            />
          </DashboardIntroAction>
        }
        breadcrumbData={AssignedRequestRaiseResults(
          { id: inspection?.id },
          isEditing
        )}
      />
      <BaseCard
        mt={6}
        py={"2rem"}
        px={"3rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <Box>
            {!isStationsFetching ? (
              <RaiseResultsFieldTest
                _tableContent={{ content: tableContent }}
                isRequestDetailsFetching={props.isRequestDetailsFetching}
                inspection={inspection}
                inspectionLayerDTO={inspectionLayerDTO}
                isDraftedNotSubmitted={isDraftedNotSubmitted}
                isSubmitted={isSubmitted}
                // DRAFTING STATE
                enableDrafting={enableDrafting}
                setEnableDrafting={setEnableDrafting}
                injectProps={{
                  errors,
                  control,
                  setValue,
                  register,
                  formValues,
                  unregister,
                  clearErrors,
                }}
              />
            ) : (
              <BaseLoader />
            )}
          </Box>
          <BaseButton
            ref={raiseScoresSubmitButtonRef}
            type="submit"
            sx={{ opacity: 0, visibility: "hidden", width: 0, height: 0 }}
          >
            HIDDEN SUBMIT BUTTON
          </BaseButton>
        </form>

        {isSubmitted && (
          <LabAttachment
            description={"ملف نتائج الاختبارات"}
            isEditing={enableDrafting}
            form={{
              errors,
              control,
              formValues,
            }}
            disabled={saveDraftFieldTestResponse.isLoading}
          />
        )}
        {isEditing && (
          <BaseTextArea
            inputContainerStyles={{ mt: 5 }}
            textAreaStyles={{ padding: "2rem" }}
            control={control}
            name={"editReason"}
            label={"سبب التعديل"}
            maxLength={800}
            rules={{
              required: {
                value: true,
                message: `الحقل مطلوب`,
              },
              maxLength: {
                value: 800,
                message: `تجاوزت الحد المسموح`,
              },
            }}
            htmlFor={"editReason"}
            placeholder={"يرجى كتابة سبب التعديل"}
            errors={errors}
          />
        )}
        <Box display={"flex"} justifyContent={"flex-end"} gap={5} mt={5} mb={3}>
          {!isEditing && draftButton}
          {isEditing && cancelButton}
          {submitButton}
        </Box>
      </BaseCard>
    </>
  );
};

export default FieldAssignedRequestForm;
