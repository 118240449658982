import { Box } from "@mui/material";
import BaseRadioWithLabel from "core-ui/BaseRadioWithLabel/BaseRadioWithLabel";
import React from "react";

function RecordQuestionnaire(props) {
  return (
    <Box mt={4}>
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل كان الاستشاري متواجد في الموقع عند إجراء الاختبار؟"}
        name={"consultantPresent"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل كان المختبر متواجد في الموقع عند إجراء الاختبار؟"}
        name={"labPresent"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
      <BaseRadioWithLabel
        sx={{ mb: 5 }}
        label={"هل كان المقاول متواجد في الموقع عند إجراء الاختبار؟"}
        name={"contractorPresent"}
        data={props.ExaminationRecordFormData}
        defaultValue={props.ExaminationRecordFormData[0].value}
        control={props.control}
        controllerRules={{
          required: {
            value: true,
            message: `يجب عليك الاختيار`,
          },
        }}
        errors={props.errors}
      />
    </Box>
  );
}

export default RecordQuestionnaire;
