import { useGetAspeltBaseCourseAttachmentQuery } from "modules/Contractor/apis/inspection-apis/asphalt-api";
import {
  useGetInspectionTestsDataForContractorQuery,
  useGetSubTestsForContractorQuery,
} from "modules/Contractor/apis/inspection-apis/inspection-api";
import ExaminationDataLab from "modules/Contractor/components/DataDetails/ExaminationData/Lab/ExaminationDataLab/ExaminationDataLab";
import React from "react";
import { useParams } from "react-router-dom";

function LabExaminationRequests({
  dataId,
  requestDetails,
  labtestappointment,
  isFetching,
  canReschedule,
  isAsphalt,
  asphaltReportData,
  inspectionLayer,
}) {
  const params = useParams();
  const {
    data: inspectionTestsDataForContractor,
    isFetching: isInspectionTestsFetching,
  } = useGetInspectionTestsDataForContractorQuery(params.id);

  // Sieve Size , sub test if it has "containSubtests" flag
  const hasSubTestsTest = inspectionTestsDataForContractor?.map((inspection) =>
    inspection?.inspectionTests?.find((test) => test?.containSubtests)
  );

  const hasSubTestsTestId = hasSubTestsTest?.[0]?.id ?? undefined;

  const { data: subTestsForContractor, isFetching: isSubTestsFetching } =
    useGetSubTestsForContractorQuery(hasSubTestsTestId, {
      skip: !hasSubTestsTestId,
    });
  const inspectionLayersId = inspectionLayer?.id;

  const {
    data: AspeltBaseCourseAttachment,
    isFetching: isAspeltBaseCourseAttachmentFetching,
  } = useGetAspeltBaseCourseAttachmentQuery(inspectionLayersId, {
    skip: !isAsphalt,
  });

  return (
    <ExaminationDataLab
      title={"تفاصيل الطلب"}
      request={requestDetails}
      inspectionLayer={inspectionLayer}
      dataId={dataId}
      labtestappointment={labtestappointment}
      isFetching={
        isFetching ||
        isInspectionTestsFetching ||
        isSubTestsFetching ||
        isAspeltBaseCourseAttachmentFetching
      }
      inspectionTestsData={inspectionTestsDataForContractor}
      subTests={subTestsForContractor}
      canReschedule={canReschedule}
      asphalet={isAsphalt}
      asphaltReportData={asphaltReportData}
      AspeltBaseCourseAttachment={AspeltBaseCourseAttachment}
    />
  );
}

export default LabExaminationRequests;
