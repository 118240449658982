import { TestTypes } from "constants/TestTypes";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import LabTechnicianRequestDetails from "./Lab";
import FieldTechnicianRequestDetails from "./Field";

const RequestData = (props) => {
  const isLab = props.inspection?.testType === TestTypes.LAB_TEST;

  return props.isInspectionSuccess ? (
    isLab ? (
      <LabTechnicianRequestDetails
        inspection={props.inspection}
        dataId={props.dataId}
      />
    ) : (
      <FieldTechnicianRequestDetails
        inspection={props.inspection}
        dataId={props.dataId}
      />
    )
  ) : (
    <BaseLoader />
  );
};

export default RequestData;
