import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { adminEditRequests } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";
import BaseSearch from "core-ui/BaseSearch/BaseSearch";
import { EditRequestsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { useLazyGetEditRequestsQuery } from "modules/Admin/apis/inspection-apis/edit-requests";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const EditRequests = () => {
  const { t } = useTranslation(["dashboard"]);

  const [searchTerm, setSearchTerm] = useState("");
  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getEditRequests, getEditRequestsResponse] =
    useLazyGetEditRequestsQuery();

  useEffect(() => {
    var timer = setTimeout(
      getEditRequests.bind(null, {
        page: searchTerm ? 0 : page,
        size: pageSize,
        sort: "id,desc",
        searchTerm,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, searchTerm]);

  const isNoSearchData = searchTerm && !isContentReady(getEditRequestsResponse.data?.content);
  const emptyTitle = isNoSearchData ? t("No Results") : t("no requests");
  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("No Edit Requests Desc");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Edit Requests")}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("Edit Requests")}
        breadcrumbData={EditRequestsBreadcrumb(t)}
        description={t(
          "تستعرض هذه الصفحة جميع طلبات تعديل النتائج المرفوعة من قبل المختبرات"
        )}
      />

      <BaseSearch
        placeholder="يمكنك البحث برقم طلب التعديل او برقم الطلب او باسم المختبر"
        name="EditRequestsSearch"
        getSearchTerm={setSearchTerm}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={adminEditRequests}
          content={getEditRequestsResponse.data?.content || []}
          isLoading={getEditRequestsResponse.isFetching}
          injectProps={{
            tableId: TABLE_ID.ADMIN_EDIT_REQUESTS,
          }}
          totalCount={20}
          emptyData={{
            title: emptyTitle,
            desc: emptyDesc,
            img: publicAssetsPath("images/documents2.svg"),
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: getEditRequestsResponse?.data?.totalElements,
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/edit-requests/${id}`,
          }}
        />
      </BaseCard>
    </>
  );
};

export default EditRequests;
