import { Box, Stack, Typography } from "@mui/material";
import MultipleLicensesModal from "components/AllModalContent/MultipleLicensesModal/MultipleLicensesModal";
import BaseModal from "core-ui/BaseModal/BaseModal";
import useShowBaseModal from "hooks/useShowBaseModal";
import { publicAssetsPath } from "utils/Paths/Paths";
import MultipleConsultantModel from "components/AllModalContent/MultipleConsultantModel/MultipleConsultantModel";

const MultipleConsultantDataItem = ({data , requestId}) => {
  const { show, onOpen, onHide } = useShowBaseModal();

  return (
    <>
      <BaseModal
        show={show}
        py={"4rem"}
        px={"4rem"}
        onHide={onHide}
        closeIcon
        {...(requestId && {
          title: `قائمة الاستشاريين لطلب الفحص رقم ${requestId}`,
        })}
      >
        <MultipleConsultantModel onHide={onHide} licenses={data} />
      </BaseModal>

      <Box className={"request-data-details__item"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={0.5}
          onClick={onOpen}
          sx={{ cursor: "pointer" }}
        >
          <Typography className={"request-data-details__value"}>
          استعراض بيانات الاستشاريين
          </Typography>
          <img
            src={publicAssetsPath("icons/popout-svgrepo.svg")}
            width="auto"
            alt="popout"
          />
        </Stack>
      </Box>
    </>
  );
};

export default MultipleConsultantDataItem;
