import { Box, Typography } from "@mui/material";
import BarcodesModal from "components/AllModalContent/BarcodesModal/BarcodesModal";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseViewModalDataItem from "core-ui/BaseViewModalDataItem/BaseViewModalDataItem";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { useState } from "react";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";

const CollectSampleData = (props) => {
  const { inspection, samplesData } = props;

  const [showConfirm, setShowConfirm] = useState(false);

  const onLeaveCardHandler = () => setShowConfirm(false);

  const printSamplesData = { ...inspection, ...samplesData };

  return (
    <>
      <BaseModal
        show={showConfirm}
        py={"4rem"}
        px={"4rem"}
        onHide={onLeaveCardHandler}
        closeIcon
        title={`رموز العينات (barcode) للطلب رقم #${inspection?.id}`}
        mainContainerSx={{
          width: {
            lg: "80rem",
          },
        }}
      >
        <BarcodesModal
          onHide={onLeaveCardHandler}
          samples={samplesData?.samples}
          samplesInfo={printSamplesData}
          onPrint
        />
      </BaseModal>

      <Box className={"request-data-details"} mt={4}>
        <Box className={"request-data-details__wrapper"}>
          <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الجمع
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(samplesData?.collectedAt) || ""}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                وقت الجمع
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayFormattedTimeHHMM(samplesData?.collectedAt) || ""}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                مصدر المواد
              </Typography>
              <Typography className={"request-data-details__value"}>
                {samplesData?.collectSampleDto?.materialSource || ""}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                عدد العينات التي تم جمعها
              </Typography>
              <Typography className={"request-data-details__value"}>
                {samplesData?.collectSampleDto?.numberOfSamples}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اسم المفتش
              </Typography>
              <Typography className={"request-data-details__value"}>
                {samplesData?.collectSampleDto?.inspectorName || ""}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                درجة الحرارة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {samplesData?.collectSampleDto?.weatherCondition || "لايوجد"}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                مكان اخذ العينة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {samplesData?.collectSampleDto?.location || ""}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رموز العينات (barcode)
              </Typography>

              <BaseViewModalDataItem
                value={"barcodes"}
                onClick={() => setShowConfirm(true)}
              />
            </Box>
          </WrapperContentGrid>
          <Box className={"request-data-details__item"} mt={4}>
            <Typography className={"request-data-details__label"}>
              هل كان المقاول متواجد في الموقع عند جمع العينة؟
            </Typography>
            <Typography className={"request-data-details__value"}>
              {!!samplesData?.collectSampleDto?.contractorPresent
                ? "نعم"
                : "لا" || ""}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CollectSampleData;
