import DisplayResultTest from "components/DisplayResultTest/DisplayResultTest";
import CombinedField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/CombinedField/CombinedField";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import FieldExaminationRecordConfirmResult from "components/TableUtilitiesComponents/TableDynamicFieldComponents/FieldExaminationRecordConfirmResult/FieldExaminationRecordConfirmResult";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import BaseUserTypeTag from "core-ui/BaseUserTypeTag/BaseUserTypeTag";
import CostTag from "modules/Admin/components/CompanyDataDetails/CostTag/CostTag";
import { formatDate, formatDateYYYYMMDD } from "utils/Date/Date";
import CertificateTestsStatus from "modules/Admin/components/Certificate/CertificateTestsStatus/CertificateTestsStatus";
import LongLength from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LongLength/LongLength";
import RequestRaiseScoreLab from "components/TableUtilitiesComponents/TableDynamicFieldComponents/RequestRaiseScore/RequestRaiseScoreLab/RequestRaiseScoreLab";
import { formatPhoneNumber, formattedNumber } from "utils/Numbers/Numbers";
import { renderLabAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import DisplayDateAndTime from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayDateAndTime/DisplayDateAndTime";
import { isNumber } from "utils/Numbers/Numbers";
import Minimum from "components/TableUtilitiesComponents/TableDynamicFieldComponents/MixDesignGradation/Minimum";
import Maximum from "components/TableUtilitiesComponents/TableDynamicFieldComponents/MixDesignGradation/Maximum";
import { Stack, Typography } from "@mui/material";
import { enLabelSx } from "constants/Typography";
import DisplayTimeRangeForLab from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayTimeRangeForLab/DisplayTimeRangeForLab";
import DisplayMinMaxSingleScores from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayMinMaxSingleScores/DisplayMinMaxSingleScores";
import MaxProctor from "components/TableUtilitiesComponents/TableDynamicFieldComponents/MaxProctor/MaxProctor";
import OptimumProctor from "components/TableUtilitiesComponents/TableDynamicFieldComponents/OptimumProctor/OptimumProctor";
import LongColumn from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LongColumn/LongColumn";
import FieldNotApplicableScoreFallback from "components/FieldNotApplicableScoreFallback/FieldNotApplicableScoreFallback";
import HiddenColumn from "components/TableUtilitiesComponents/TableDynamicFieldComponents/HiddenColumn/HiddenColumn";
import { pointsFormatter } from "utils/Formatter/Formatter";
import BaseTooltip from "core-ui/BaseTooltip/BaseTooltip";

// HANDLERS ***************************
export const fieldExaminationTestsCheckResultHandler = (score, row) => {
  if (row.technicianConfirmation && !isNumber(row.technicianScore)) {
    return "صحيحة";
  }
  return `غير صحيحة, النتيجة النهائية: ${row.technicianScore} `;
};

export const multipleLicensesColumnHandler = (cell) => {
  if (cell?.length === 1)
    return `${cell?.[0]?.licenseYear}/${cell?.[0]?.licenseNumber}`;
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      {cell
        ?.map((license) => `${license.licenseYear}/${license.licenseNumber}`)
        ?.join(", ")
        ?.substring(0, 14) + ".."}{" "}
      <BaseTooltip
        type={"tooltip-black"}
        text={
          "هذا الطلب متعدد الرخص، يمكنك معرفة المزيد من خلال الضغط على التفاصيل"
        }
      />
    </Stack>
  );
};

export const formatBoldLabel = (val, row) => {
  if (row.bold)
    return <span style={{ fonrtSize: "18px", fontWeight: "bold" }}>{val}</span>;
  return val;
};

export const formatBoldValue = (val, row) => {
  if (row.bold)
    return (
      <span style={{ fontWeight: "bold" }}>
        <span style={{ color: "#45897f" }}>{formattedNumber(val)}</span> نقطة
      </span>
    );
  return formattedNumber(val) + " نقطة";
};

export const fieldExaminationPointsConfirmationHandler = (cell, row) => {
  const isExaminationRecordPosted = cell.every(
    (point) => point.technicianConfirmation !== null
  );

  if (!isExaminationRecordPosted) return "لا يوجد";
  const isPointsChanged = cell.some((point) => !point.technicianConfirmation);
  if (!isPointsChanged) return "صحيحة";
  const updatedPoints = cell
    .map((point) =>
      !point.technicianConfirmation
        ? point.technicianCorrectedScore
        : point.labScore
    )
    .join(", ");

  return `غير صحيحة, النتيجة النهائية: ${updatedPoints} `;
};

export const transformStationToFrom = (cell, row) => {
  if (cell == 0) return "0+000";

  let _cell = cell?.toString();

  const stationPointLengthLessThan4 = (stationPoint) => stationPoint.length < 4;

  if (stationPointLengthLessThan4(_cell)) _cell = _cell.padStart(3, "0");

  const addStationPrefix = `0+${_cell}`;

  const displayStationPointProperFormat = _cell[0] + "+" + _cell.slice(1);

  const displayStationPoint = (stationPoint) => {
    if (stationPointLengthLessThan4(stationPoint)) {
      return addStationPrefix;
    } else {
      return displayStationPointProperFormat;
    }
  };

  if (!_cell.includes(".")) {
    return displayStationPoint(_cell);
  } else {
    const dotIndex = _cell.indexOf(".");
    const cellWithDot = _cell.slice(0, dotIndex);
    return displayStationPoint(cellWithDot);
  }
};

export const DisplayFromToStation = ({ from, to, layerNumber }) => {
  let layer = "";
  if (layerNumber)
    layer = (
      <>
        <br />
        رقم الطبقة: {layerNumber}
      </>
    );
  if (from === "N/A" && to === "N/A")
    return (
      <>
        N/A
        {layer}
      </>
    );
  return (
    <>
      {transformStationToFrom(from)} - {transformStationToFrom(to)}
      {layer}
    </>
  );
};
// HANDLERS ***************************

// HEADERS ***************************
export const drillingLicences = [
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "license number",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  { serverKey: "serviceProviderName", name: "service provider" },
  { serverKey: "expiryDate", name: "Expiry date" },
  {
    serverKey: "typeRefId",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
    name: "Type of license",
  },
];

export const licenseDetailsLocations = [
  { serverKey: "licenceCoordinates", name: "license_location" },
];
export const licenseDetailsLocationsURL = [
  {
    serverKey: "locationUrl",
    name: "license_location",
    hasComponent: {
      customProps: "location",
      comp: <LocationField />,
    },
  },
];

export const examinationRequests = [
  { serverKey: "id", name: "request_number" },
  {
    serverKey: "licenses",
    name: "license number",
    format: multipleLicensesColumnHandler,
  },
  { serverKey: "name", name: "TestLayer" },
  {
    serverKey: "testType",
    name: "path_type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "status",
    hasComponent: { comp: <BaseStatus /> },
    name: "Examination Status",
  },
];

export const consultantExaminationRequests = [
  { serverKey: "id", name: "request_number" },
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "contracting company",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  { serverKey: "name", name: "TestLayer" },
  {
    serverKey: "testType",
    name: "path_type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "collectedAt",
    format: (value, row) => formatDate(value),
    name: "date of test or sample collected",
  },
];

////////////////////////////////
export const licenseTests = [
  {
    serverKey: "name",
    name: "test name",
    fallback: " ",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: " ",
  },
  {
    serverKey: "",
    name: "minimum",
    hasComponent: { comp: <Minimum /> },
  },
  {
    serverKey: "",
    name: "maximum",
    hasComponent: { comp: <Maximum /> },
  },
];
export const subTestsTemplateSingle = [
  {
    serverKey: "name",
    name: "test name",
    fallback: " ",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: " ",
  },
  {
    serverKey: "",
    name: "limits",
    hasComponent: { comp: <Minimum /> },
  },
];
export const licenseTestsUAT = [
  {
    serverKey: "name",
    name: "test name",
    fallback: " ",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: " ",
  },
  {
    serverKey: "cost",
    name: "cost",
    fallback: " ",
    applyNumbers: false,
  },
];

export const licenseTestsLab = [
  { serverKey: "name", name: "test name", fallback: " " },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: " ",
  },
];

export const licenseTestsField = [
  { serverKey: "category", name: "test name", fallback: " " },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: " ",
  },
];
export const licenseTestsFieldUAT = [
  { serverKey: "name", name: "test name", fallback: " " },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: " ",
  },
  {
    serverKey: "cost",
    name: "cost",
    fallback: " ",
    applyNumbers: false,
  },
];

export const licenseTestsSummary = [
  {
    serverKey: "name",
    name: "description",
    fallback: " ",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "cost",
    name: "cost",
    fallback: " ",
    applyNumbers: false,
  },
];

export const licenseFieldTestsSummary = [
  {
    serverKey: "category",
    name: "description",
    fallback: " ",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
];

export const contractorExaminationDetailsTests = (show) => [
  {
    serverKey: "layerName",
    name: "test_name",
    format: (cell) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "testStandard",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
    name: "testـstandards",
  },
  {
    serverKey: "from",
    name: "start point",
    format: transformStationToFrom,
    fallbacl: "لا يوجد",
  },
  {
    serverKey: "to",
    name: "end point",
    format: transformStationToFrom,
    fallbacl: "لا يوجد",
  },
  {
    serverKey: "distance",
    name: "station length",
    fallback: "لا يوجد",
  },
  {
    serverKey: "layerNumber",
    name: "layer number",
    fallback: "لا يوجد",
  },
  {
    serverKey: "maxProctor",
    name: "max proctor",
    hasComponent: {
      comp: <LongColumn fallback={<FieldNotApplicableScoreFallback />} />,
    },
  },
  {
    serverKey: "optimumProctor",
    name: "optimum proctor",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
  {
    serverKey: "scoresList",
    name: "score percentage",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
  {
    serverKey: "testStatus",
    name: "status",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    show: show,
  },
];

export const contractorExaminationDetailsTestsVS = [
  {
    serverKey: "layerName",
    name: "test_name",
    format: (cell) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "testStandard",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
    name: "testـstandards",
  },
  {
    serverKey: "testStatus",
    name: "status",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
  },
  {
    serverKey: "scoresList",
    name: "score percentage",
    format: pointsFormatter,
  },
];

export const consultantExaminationDetailsTests = (show) => [
  {
    serverKey: "layerName",
    name: "test_name",
    format: (cell) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "testStandard",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
    name: "testـstandards",
  },
  {
    serverKey: "from",
    name: "start point",
    fallback: "لا يوجد",
    format: transformStationToFrom,
  },
  {
    serverKey: "to",
    name: "end point",
    fallback: "لا يوجد",
    format: transformStationToFrom,
  },
  {
    serverKey: "distance",
    name: "station length",
    fallback: "لا يوجد",
  },
  {
    serverKey: "layerNumber",
    name: "layer number",
    fallback: "لا يوجد",
  },

  {
    serverKey: "maxProctor",
    name: "max proctor",
    hasComponent: {
      comp: <LongColumn fallback={<FieldNotApplicableScoreFallback />} />,
    },
  },
  {
    serverKey: "optimumProctor",
    name: "optimum proctor",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
  {
    serverKey: "scoresList",
    name: "score percentage",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
  {
    serverKey: "testStatus",
    name: "status",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    show: show,
  },
];
export const consultantExaminationDetailsTestsVS = [
  {
    serverKey: "layerName",
    name: "test_name",
    format: (cell) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "testStandard",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
    name: "testـstandards",
  },
  {
    serverKey: "testStatus",
    name: "status",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
  },
  {
    serverKey: "scoresList",
    name: "score percentage",
    format: pointsFormatter,
  },
];

export const Tests = [
  { serverKey: "name", name: "test name", fallback: " " },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: " ",
  },
  {
    serverKey: "type",
    name: "test type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "status",
    name: "Test Status",
    hasComponent: { comp: <BaseStatus /> },
  },
  {
    serverKey: "result",
    name: "Result",
    hasComponent: {
      comp: <DisplayResultTest />,
    },
  },
  { serverKey: "finalScore", name: "score" },
];

export const newRequests = [
  { serverKey: "id", name: "request_number" },

  {
    serverKey: "status",
    hasComponent: { comp: <BaseStatus /> },
    name: "order_status",
  },
  {
    serverKey: "testType",
    name: "request_type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "scheduledAppointment",
    name: "Examinationـdate",
    hasComponent: {
      comp: <DisplayTimeRangeForLab />,
    },
  },
  {
    serverKey: "location",
    name: "license_location",
    hasComponent: {
      customProps: "location",
      comp: <LocationField />,
    },
  },
];

export const labTechnicianAssignedRequests = [
  { serverKey: "id", name: "request_number" },
  {
    serverKey: "appointmentTime",
    name: "Examinationـdate",
    format(cell, row) {
      const timeLabel = renderLabAppointmentTimeLabel(cell);
      return (
        <Typography
          component={"span"}
          sx={{ fontSize: "1.6rem" }}
        >{`${formatDateYYYYMMDD(
          row.appointmentDate
        )} - ${timeLabel}`}</Typography>
      );
    },
  },
  {
    serverKey: "locationUrl",
    name: "license_location",
    hasComponent: {
      customProps: "location",
      comp: <LocationField />,
    },
  },
];

export const labTechnicianNewRequests = [
  { serverKey: "id", name: "request_number" },

  {
    serverKey: "status",
    hasComponent: { comp: <BaseStatus /> },
    name: "order_status",
  },
  {
    serverKey: "testType",
    name: "request_type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "scheduledAppointment",
    name: "Examinationـdate",
    hasComponent: {
      comp: <DisplayTimeRangeForLab />,
    },
  },
  {
    serverKey: "location",
    name: "license_location",
    hasComponent: {
      customProps: "location",
      comp: <LocationField />,
    },
  },
];

export const fieldExaminationRecordFormTests = [
  { serverKey: "testName", name: "test name" },
  {
    serverKey: "inspectionPointDtos",
    name: "Result",
    format: (value) => value.map((v) => v.score).join(", "),
  },
  {
    serverKey: "id",
    name: "Confirm Result",
    hasComponent: {
      comp: <FieldExaminationRecordConfirmResult />,
    },
  },
];

export const assignedRequests = [
  { serverKey: "id", name: "request_number" },
  {
    serverKey: "status",
    hasComponent: {
      comp: <BaseStatus />,
    },
    name: "order_status",
  },
  {
    serverKey: ["samplestatus", "testType"],
    hasComponent: {
      customProps: ["status", "testType"],
      comp: <BaseStatus />,
    },
    name: "samples_status",
  },

  {
    serverKey: "testType",
    name: "request_type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: ["scheduledAppointment", "appointmentDate", "testType"],
    name: "Examinationـdate",
    hasComponent: {
      customProps: ["scheduledAppointment", "appointmentDate", "status"],
      comp: <BaseStatus />,
    },
  },
];

export const newRequestTests = [
  { serverKey: "testName", name: "test name" },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
  },
];

export const nonAssignedRequestTestsFeildWithNewStations = [
  {
    serverKey: "layerName",
    name: "test name",
  },
  {
    serverKey: "testStandard",
    name: "test name",
  },
  { serverKey: "from", name: "start point", format: transformStationToFrom },
  { serverKey: "to", name: "end point", format: transformStationToFrom },
  { serverKey: "distance", name: "station length" },
  { serverKey: "layerNumber", name: "layer number" },
];

export const assignedRequestTestsFeild = [
  {
    serverKey: "layerName",
    name: "test name",
  },
  { serverKey: "from", name: "start point", format: transformStationToFrom },
  { serverKey: "to", name: "end point", format: transformStationToFrom },
  { serverKey: "layerNumber", name: "layer number" },
  {
    serverKey: "maxProctor",
    name: "max proctor",
    hasComponent: {
      comp: <MaxProctor />,
    },
  },
  {
    serverKey: "optimumProctor",
    name: "optimum proctor",
    hasComponent: {
      comp: <OptimumProctor />,
    },
  },
];

export const assignedRequestTestsFeildSubmitted = (user) => {
  const isLaboratory = user === "lab";
  const maxProctorKey = isLaboratory ? "maxProctor" : "labMaxProctor";
  const optimumProctorKey = isLaboratory
    ? "optimumProctor"
    : "labOptimumProctor";
  return [
    {
      serverKey: "layerName",
      name: "test name",
    },
    {
      serverKey: "testStandard",
      name: "testـstandards",
    },
    {
      serverKey: "from",
      name: "start point",
      format: transformStationToFrom,
    },
    {
      serverKey: "to",
      name: "end point",
      format: transformStationToFrom,
    },
    { serverKey: "layerNumber", name: "layer number" },
    {
      serverKey: maxProctorKey,
      name: "max proctor",
      hasComponent: {
        comp: <LongColumn fallback={<FieldNotApplicableScoreFallback />} />,
      },
    },
    {
      serverKey: optimumProctorKey,
      name: "optimum proctor",
      hasComponent: {
        comp: <HiddenColumn />,
      },
    },
    {
      serverKey: "scoresList",
      name: "score percentage",
      hasComponent: {
        comp: <HiddenColumn />,
      },
    },
  ];
};

export const assignedRequestTestsFeildSubmittedVS = (user) => {
  const isLaboratory = user === "lab";
  const maxProctorKey = isLaboratory ? "maxProctor" : "labMaxProctor";
  const optimumProctorKey = isLaboratory
    ? "optimumProctor"
    : "labOptimumProctor";
  return [
    {
      serverKey: "layerName",
      name: "test name",
    },
    {
      serverKey: "testStandard",
      name: "testـstandards",
    },
    { serverKey: "layerNumber", name: "layer number" },
    {
      serverKey: maxProctorKey,
      name: "max proctor",
      hasComponent: {
        comp: <LongColumn fallback={<FieldNotApplicableScoreFallback />} />,
      },
    },
    {
      serverKey: optimumProctorKey,
      name: "optimum proctor",
      hasComponent: {
        comp: <HiddenColumn />,
      },
    },
    {
      serverKey: "scoresList",
      name: "score percentage",
      hasComponent: {
        comp: <HiddenColumn />,
      },
    },
  ];
};

export const technicianLabTestRequests = [
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "inspectionLayerDTO",
    innerProp: "id",
    name: "request_number",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "inspectionLayerDTO",
    innerProp: "name",
    name: "works_materials_test",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "inspectionLayerDTO",
    innerProp: "numberOfSamples",
    name: "SamplesNo",
    format: (value, row) => value * 3,
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "samplestatus",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    name: "order_status",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "collectedAt",
    name: "CollectionDate",
    format: (value, row) => formatDate(value),
    fallback: " ",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "deliveredToDistCenterAt",
    name: "DeliveryDate",
    format: (value, row) => formatDate(value),
    fallback: " ",
  },
];
export const technicianFieldTestRequests = [
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "inspectionLayerDTO",
    innerProp: "id",
    name: "request_number",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "inspectionLayerDTO",
    innerProp: "name",
    name: "works_materials_test",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "inspectionLayerDTO",
    innerProp: "numberOfSamples",
    name: "SamplesNo",
    format: (value, row) => value * 3,
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "samplestatus",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    name: "order_status",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "collectedAt",
    name: "CollectionDate",
    format: (value, row) => formatDate(value),
    fallback: " ",
  },
  {
    serverKey: "inspectionLayerSampleDto",
    innerKey: "deliveredToDistCenterAt",
    name: "DeliveryDate",
    format: (value, row) => formatDate(value),
    fallback: " ",
  },
];

export const assignedRequestTests = [
  { serverKey: "testName", name: "test name" },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
  },
  {
    serverKey: "testType",
    name: "test type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "score",
    name: "Result",
    hasComponent: {
      comp: <RequestRaiseScoreLab />,
    },
  },
];

export const sampleGradationTests = [
  { serverKey: "testName", name: "sieve size" },
  {
    serverKey: "score",
    name: "score percentage",
    hasComponent: {
      comp: <RequestRaiseScoreLab />,
    },
  },
];

export const assignedRequestTestsLabTestForm = [
  { serverKey: "testName", name: "test name" },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
  },
  {
    serverKey: "score",
    name: "score percentage",
    hasComponent: {
      comp: <RequestRaiseScoreLab />,
    },
  },
];
export const sampleGradationTestsLabTestForm = [
  {
    serverKey: "testName",
    name: "sieve size",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "score",
    name: "score percentage",
    hasComponent: {
      comp: <RequestRaiseScoreLab />,
    },
  },
];

export const distributionRequest = [
  { serverKey: "id", name: "request_number" },
  { serverKey: "labName", name: "Laboratory_name" },
  {
    serverKey: "numberOfSamples",
    name: "SamplesNo",
    fallback: " ",
  },
  {
    serverKey: "samplestatus",
    hasComponent: {
      customProps: "status",
      comp: <BaseStatus />,
    },
    name: "order_status",
  },
  {
    serverKey: "receivedByDistCenterAt",
    name: "receivedAt",
    format: (date, row) => formatDateYYYYMMDD(date),
    fallback: " ",
  },
  {
    serverKey: "deliveredToLabAt",
    name: "DeliveryDate",
    format: (date, row) => formatDateYYYYMMDD(date),
    fallback: " ",
  },
];

export const DrillingLicensesRequests = [
  { serverKey: "id", name: "request_number" },
  {
    serverKey: "location",
    name: "license_location",
    hasComponent: {
      customProps: "location",
      comp: <LocationField />,
    },
  },
  { serverKey: "name", name: "works_materials_test" },
  {
    serverKey: "testtype",
    name: "path_type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },

  {
    serverKey: "testtype",
    hasComponent: {
      customProps: "testType",
      comp: <BaseStatus />,
    },
    name: "Examination Status",
  },
];

export const CertificatePreviewTests = [
  {
    serverKey: "inspectionName",
    name: "TestLayer",
  },
  {
    serverKey: "labCode",
    name: "lab_code",
  },
  {
    serverKey: "requestNumber",
    name: "request_number",
  },
  {
    serverKey: "createdAt",
    name: "request_date",
    format: (value, row) => formatDateYYYYMMDD(value),
  },
  {
    serverKey: "inspectionlayerstatus",
    name: "Result",
    hasComponent: { customProps: "status", comp: <CertificateTestsStatus /> },
  },
];

export const CertificatePreviewFieldTests = [
  {
    serverKey: "inspectionName",
    name: "TestLayer",
  },
  {
    serverKey: ["from", "to", "layerNumber"],
    name: "station",
    hasComponent: {
      comp: <DisplayFromToStation />,
    },
  },
  {
    serverKey: "labCode",
    name: "lab_code",
  },
  {
    serverKey: "requestNumber",
    name: "request_number",
  },
  {
    serverKey: "createdAt",
    name: "request_date",
    format: (value, row) => formatDateYYYYMMDD(value),
  },
  {
    serverKey: "inspectionlayerstatus",
    name: "Result",
    hasComponent: { customProps: "status", comp: <CertificateTestsStatus /> },
  },
];

export const CollectSample = [
  {
    serverKey: "id",
    name: "request_number",
  },
  {
    serverKey: "name",
    name: "works_materials_test",
  },
  {
    serverKey: "contractorName",
    name: "contractor",
  },
  {
    serverKey: "sampleStatus",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    name: "order_status",
  },
  {
    serverKey: "numberOfSamples",
    name: "SamplesNo",
    format: (value, row) => value * 3,
  },

  {
    serverKey: ["appointmentTime", "appointmentDate"],
    name: "collect_appointment",
    hasComponent: {
      customProps: ["scheduledAppointment", "appointmentDate"],
      comp: <DisplayRequestAppointmentDate />,
    },
  },
];

export const FieldExamination = [
  {
    serverKey: "id",
    name: "request_number",
  },
  {
    serverKey: "name",
    name: "works_materials_test",
  },
  {
    serverKey: "contractorName",
    name: "contractor",
  },
  {
    serverKey: "assignmentStatus",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    name: "order_status",
  },
  {
    serverKey: ["appointmentTime", "appointmentDate"],
    name: "Examinationـdate",
    hasComponent: {
      customProps: ["scheduledAppointment", "appointmentDate"],
      comp: <DisplayRequestAppointmentDate />,
    },
  },
];

export const adminDrillingLicenses = [
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "license number",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  {
    serverKey: "contractorCompany",
    name: "contracting company",
  },
  {
    serverKey: "certificateIssued",
    name: "certificate status",
    hasComponent: {
      customProps: "status",
      comp: <BaseStatus />,
    },
  },
  { serverKey: "expiryDate", name: "Expiry date" },
];

export const adminUsersManagement = [
  {
    serverKey: "name",
    name: "name",
  },
  {
    serverKey: "email",
    name: "email",
  },
  {
    serverKey: "isActive",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    name: "account_status",
  },
  {
    serverKey: "mobileNumber",
    name: "mobile",
    format: (cell) => formatPhoneNumber(cell),
  },
  {
    serverKey: ["entitytype", "role"],
    name: "job",
    hasComponent: { customProps: ["type", "role"], comp: <BaseUserTypeTag /> },
  },
];

export const labUsersManagement = [
  {
    serverKey: "name",
    name: "name",
  },
  {
    serverKey: "email",
    name: "email",
  },
  {
    serverKey: "isActive",
    hasComponent: { customProps: "status", comp: <BaseStatus /> },
    name: "account_status",
  },
  {
    serverKey: "mobile",
    name: "mobile",
    format: (cell) => formatPhoneNumber(cell),
  },
];

export const AdminExaminationRequest = [
  { serverKey: "testName", name: "Tests" },
  {
    serverKey: "status",
    hasComponent: { comp: <BaseStatus /> },
    name: "Test Status",
  },
  {
    serverKey: "score",
    name: "Result",
    fallback: "غير محدد",
  },
  {
    serverKey: "result",
    hasComponent: {
      comp: <DisplayResultTest />,
    },
    name: "resultـstatus",
    fallback: "بنتظار المعالجة",
  },
  {
    serverKey: "score",
    name: "validity_of_result",
    show: "subTest",
    format: fieldExaminationTestsCheckResultHandler,
    fallback: "صحيحة",
  },
];

export const TechnicianRequestsIndex = [
  {
    serverKey: "inspectionId",
    name: "رقم الطلب",
  },
  {
    serverKey: "technicianName",
    name: "فني الرقابة",
  },
  {
    serverKey: "testType",
    name: "request_type",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: ["appointmentDate", "appointmentTime"],
    name: "Examinationـdate",
    format: (value, row) =>
      `${formatDateYYYYMMDD(value)} - ${renderLabAppointmentTimeLabel(
        row?.appointmentTime
      )}`,
  },
  {
    serverKey: "testStatus",
    hasComponent: {
      customProps: "status",
      comp: <BaseStatus />,
    },
    name: "order_status",
  },
];

export const invoicesIndex = [
  {
    serverKey: "id",
    name: "transaction_number",
  },
  {
    serverKey: "paymentTime",
    name: "date",
    format: (value, row) => formatDateYYYYMMDD(value),
    fallback: " ",
  },
  {
    serverKey: "points",
    name: "points",
    format: (value) => formattedNumber(value) + ` نقطة`,
  },
  {
    serverKey: "cost",
    name: "cost",
    format: (value, row) => formattedNumber(value + row.totalVat) + ` ريال`,
  },
];

export const displayInvoiceDetails = [
  {
    serverKey: "points",
    name: "الوصف",
    format: (value) => `باقة ` + value + ` نقطة`,
  },

  {
    serverKey: "cost",
    name: "cost",
    format: (value) => value + ` ريال`,
  },
];

export const ContractingCompanies = [
  {
    serverKey: "name",
    name: "اسم شركة المقاولات",
  },
  {
    serverKey: "crNumber",
    name: "الرقم الموحد للمنشأة",
  },
  {
    serverKey: "wallet",
    name: "رصيد النقاط",
    format: (value) => formattedNumber(value) + ` نقطة`,
  },
];

export const CompanyInvoicesHeader = [
  {
    serverKey: "id",
    name: "رقم العملية",
  },
  {
    serverKey: "paymentTime",
    name: "date",
    format: (value, row) => formatDateYYYYMMDD(value),
    fallback: " ",
  },
  {
    serverKey: "points",
    name: "النقاط المضافة",
    hasComponent: { customProps: "cost", comp: <CostTag /> },
  },
  {
    serverKey: "totalCost",
    name: "التكلفة",
    format: (value) => formattedNumber(value) + ` ريال`,
  },
  {
    serverKey: "walletBefore",
    name: "رصيد النقاط قبل العملية",
    format: (value) => formattedNumber(value) + ` نقطة`,
  },
  {
    serverKey: "walletAfter",
    name: "رصيد النقاط بعد العملية",
    format: (value) => formattedNumber(value) + ` نقطة`,
  },
];

export const CompanyPaymentsHeader = [
  {
    serverKey: "id",
    name: "رقم العملية",
  },
  { serverKey: "inspectionLayer", name: "رقم الطلب" },
  {
    serverKey: "transactionTime",
    name: "date",
    format: (value, row) => formatDateYYYYMMDD(value),
    fallback: " ",
  },
  {
    serverKey: "status",
    name: "نوع العملية",
    hasComponent: { comp: <BaseStatus /> },
  },
  {
    serverKey: ["deductedPoints", "status"],
    name: "التكلفة",
    hasComponent: { customProps: ["cost", "type"], comp: <CostTag /> },
  },
  {
    serverKey: "walletAfter",
    name: "رصيد النقاط",
    format: (value) => formattedNumber(value) + ` نقطة`,
  },
];

export const pointsPayments = [
  { serverKey: "id", name: "transaction_number" },
  { serverKey: "inspectionLayer", name: "رقم الطلب" },
  {
    serverKey: "transactionTime",
    name: "date",
    format: (value, row) => formatDateYYYYMMDD(value),
    fallback: " ",
  },
  {
    serverKey: "status",
    name: "transaction type",
    hasComponent: {
      comp: <BaseStatus />,
    },
  },
  {
    serverKey: ["deductedPoints", "status"],
    name: "التكلفة",
    hasComponent: { customProps: ["cost", "type"], comp: <CostTag /> },
  },
  {
    serverKey: "walletAfter",
    name: "points balance",
    format: (value) => formattedNumber(value) + ` نقطة`,
  },
];

export const pointsPaymentsDetails = [
  {
    serverKey: ["description", "bold"],
    name: "description",
    format: formatBoldLabel,
  },
  {
    serverKey: "deductedPoints",
    name: "cost",
    format: formatBoldValue,
  },
];

export const companyPaymentsDetails = [
  { serverKey: "subTestName", name: "description" },
  {
    serverKey: "subTestCost",
    name: "cost",
    format: (value) => formattedNumber(value) + ` نقطة`,
  },
];

export const adminLabRequests = [
  { serverKey: "id", name: "رقم الطلب" },
  { serverKey: "laboratoryName", name: "Laboratory_name" },
  {
    serverKey: "testType",
    name: "مسار الفحص",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
];

export const AdminRequestsHeaders = [
  { serverKey: "id", name: "request_number" },
  {
    serverKey: "licenses",
    name: "مقدم الطلب",
    format: (cell) => {
      return cell?.[0]?.contractorCompany;
    },
  },
  { serverKey: "name", name: "works_materials_test" },
  {
    serverKey: "testtype",
    hasComponent: {
      customProps: "testType",
      comp: <BaseStatus />,
    },
    name: "Examination Status",
  },
  {
    serverKey: "createdAt",
    name: "createdAt",
    hasComponent: {
      customProps: "createdAt",
      comp: <DisplayDateAndTime />,
    },
  },
  {
    serverKey: "testType",
    name: "مسار الفحص",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
];

export const adminEditRequests = [
  { serverKey: "id", name: "رقم طلب التعديل" },
  { serverKey: "inspectionId", name: "رقم الطلب" },
  {
    serverKey: "requestDate",
    name: "date",
    format: (value, row) => formatDateYYYYMMDD(value),
    fallback: " ",
  },
  { serverKey: "labName", name: "Laboratory_name" },
  {
    serverKey: "testType",
    name: "مسار الفحص",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
  {
    serverKey: "editRequestStatus",
    hasComponent: {
      customProps: "status",
      comp: <BaseStatus />,
    },
    name: "حالة طلب التعديل",
  },
];

export const contractorMixDesignGradationLimits = [
  {
    serverKey: "name",
    name: "Sieve Size",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "minPassScore",
    name: "minimum",
    hasComponent: { comp: <Minimum /> },
  },
  {
    serverKey: "maxPassScore",
    name: "maximum",
    hasComponent: { comp: <Maximum /> },
  },
];

export const contractorSieveMixDesignGradationLimits = [
  {
    serverKey: "testName",
    name: "Sieve Size",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "",
    name: "",
  },
  {
    serverKey: "minPassScore",
    name: "",
    hasComponent: { comp: <DisplayMinMaxSingleScores /> },
  },
  {
    serverKey: "status",
    name: "",
    hasComponent: { comp: <BaseStatus /> },
  },
  {
    serverKey: "score",
    name: "",
    fallback: "لا يوجد",
  },
];

export const contractorSieveAnalysisDesignGradationLimits = [
  {
    serverKey: "testName",
    name: "Sieve Size",
    format: (cell, row) => {
      return (
        <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
          {cell}
        </Typography>
      );
    },
  },
  {
    serverKey: "",
    name: "",
  },
  {
    serverKey: "minPassScore",
    name: "",
    hasComponent: { comp: <DisplayMinMaxSingleScores /> },
  },
  {
    serverKey: "status",
    name: "",
    hasComponent: { comp: <BaseStatus /> },
  },
  {
    serverKey: "score",
    name: "",
    fallback: "غير محدد",
  },
  {
    serverKey: "",
    name: "",
  },
];

export const consultantsRequests = [
  {
    serverKey: "requestNumber",
    name: "request_number",
  },
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "license number",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  {
    serverKey: "consultantCompanyName",
    name: "consulting company",
  },
  {
    serverKey: "status",
    hasComponent: { comp: <BaseStatus /> },
    name: "Request Status",
  },
];

export const adminLabRequestDetails = [
  { serverKey: "testName", name: "Test" },
  {
    serverKey: "testStandard",
    name: "testـstandards",
    hasComponent: { customProps: "testStandard", comp: <LongLength /> },
    fallback: "لا يوجد",
  },
];

export const adminFieldRequestDetails = [
  { serverKey: "layerName", name: "test_name" },
  {
    serverKey: "from",
    name: "start point",
    format: transformStationToFrom,
  },
  {
    serverKey: "to",
    name: "end point",
    format: transformStationToFrom,
  },
  {
    serverKey: "distance",
    name: "station length",
  },
  {
    serverKey: "layerNumber",
    name: "layer number",
  },
];

export const adminFieldRequestDetailsVS = [
  { serverKey: "layerName", name: "test_name" },
  {
    serverKey: "distance",
    name: "station length",
  },
  {
    serverKey: "layerNumber",
    name: "layer number",
  },
];

export const adminFieldExaminationRequest = [
  { serverKey: "layerName", name: "test_name" },
  {
    serverKey: "from",
    name: "start point",
    format: transformStationToFrom,
  },
  {
    serverKey: "to",
    name: "end point",
    format: transformStationToFrom,
  },
  {
    serverKey: "distance",
    name: "station length",
  },
  {
    serverKey: "layerNumber",
    name: "layer number",
  },
  {
    serverKey: "labMaxProctor",
    name: "max proctor",
    hasComponent: {
      comp: <LongColumn fallback={<FieldNotApplicableScoreFallback />} />,
    },
  },
  {
    serverKey: "labOptimumProctor",
    name: "optimum proctor",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
  {
    serverKey: "scoresList",
    name: "result percentage",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
];

export const adminFieldExaminationRequestVS = [
  { serverKey: "layerName", name: "test_name" },

  {
    serverKey: "distance",
    name: "station length",
  },
  {
    serverKey: "layerNumber",
    name: "layer number",
  },
  {
    serverKey: "labMaxProctor",
    name: "max proctor",
    hasComponent: {
      comp: <LongColumn fallback={<FieldNotApplicableScoreFallback />} />,
    },
  },
  {
    serverKey: "labOptimumProctor",
    name: "optimum proctor",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
  {
    serverKey: "scoresList",
    name: "result percentage",
    hasComponent: {
      comp: <HiddenColumn />,
    },
  },
];

export const LabEditRequest = [
  { serverKey: "id", name: "رقم طلب التعديل" },
  { serverKey: "inspectionId", name: "رقم الطلب" },
  {
    serverKey: "requestDate",
    name: "date",
    format: (value, row) => formatDateYYYYMMDD(value),
    fallback: " ",
  },
  {
    serverKey: "editRequestStatus",
    hasComponent: {
      customProps: "status",
      comp: <BaseStatus />,
    },
    name: "حالة طلب التعديل",
  },
  { serverKey: "labName", name: "Laboratory_name" },
  {
    serverKey: "testType",
    name: "مسار الفحص",
    hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
  },
];

export const TestsPrices = [
  {
    name: "test_name",
    serverKey: "testName",
    format: (value) => (
      <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>{value}</Typography>
    ),
  },
  { name: "cost", serverKey: "price", format: (value) => value + ` ريال` },
];

export const multipleLicensesTableHeader = [
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "license number",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  {
    name: "service provider",
    serverKey: "serviceProviderName",
  },
  {
    name: "Type of service",
    serverKey: "serviceName",
  },
  {
    name: "Type of drilling",
    serverKey: "drillingType",
  },
];

export const multipleLicensesViewTableHeader = [
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "license number",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
  {
    name: "service provider",
    serverKey: "serviceProviderName",
  },
  {
    name: "Expiry date",
    serverKey: "expiryDate",
  },
];

export const MultipleConsultantViewTableHeader = [
  {
    name: "consultantName",
    serverKey: "consultantName",
  },
  {
    name: "consultantMobile",
    serverKey: "consultantMobile",
    format: (cell) => formatPhoneNumber(cell),
  },
  {
    name: "consultantEmail",
    serverKey: "consultantEmail",
  },
  {
    serverKey: ["licenseYear", "licenseNumber"],
    name: "licenseAssigned",
    hasComponent: {
      customProps: ["field1", "field2"],
      comp: <CombinedField />,
    },
  },
];
