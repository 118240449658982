import React from "react";
import { Box, FormHelperText } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { isProduction, isUAT } from "utils/Env/Env";

const BaseRecaptcha = React.forwardRef((props, ref) => {
  const inputHasError = props.customError
    ? props.customError
    : props.errors
    ? props.errors[props.name]
    : false;

  const getSiteKey = () => {
    let sitekey = process.env.REACT_APP_SITE_KEY_DEV_RECAPTCHA;
    if (isUAT) {
      sitekey = process.env.REACT_APP_SITE_KEY_UAT_RECAPTCHA;
    }
    if (isProduction) {
      sitekey = process.env.REACT_APP_SITE_KEY_PROD_RECAPTCHA;
    }
    return sitekey;
  };

  return (
    <Box sx={{ ...props.sx }}>
      <ReCAPTCHA ref={ref} sitekey={getSiteKey()} onChange={props.onChange} />
      {inputHasError && (
        <Box>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {inputHasError.message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
});

export default BaseRecaptcha;
