import adminApi from "..";
import { queryBuilder } from "utils/ApiHelpers";

const adminUsersManagementApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/admin/userManagement/users?${q}`,
        };
      },
    }),
    getUser: builder.query({
      query: (id) => {
        return {
          url: `/api/admin/userManagement/users/${id}`,
        };
      },
      providesTags: ["User"],
    }),
    activateUser: builder.mutation({
      query: (body) => {
        return {
          url: `/api/admin/userManagement/user/${body.userId}/activate`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    deActivateUser: builder.mutation({
      query: (body) => {
        return {
          url: `/api/admin/userManagement/user/${body.userId}/deActivate`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    resendInvite: builder.mutation({
      query: (body) => {
        return {
          url: `/api/admin/userManagement/user/${body.userId}/reSendActivation`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    getUsersOffice: builder.query({
      query: () => {
        return {
          url: `/api/admin/assignments/technicianOffices`,
        };
      },
      providesTags: ["User"],
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useActivateUserMutation,
  useDeActivateUserMutation,
  useResendInviteMutation,
  useGetUsersOfficeQuery,
} = adminUsersManagementApi;

export default adminUsersManagementApi;
