import { Box, Grid, Stack, Typography } from "@mui/material";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import SupportPageForm from "components/Forms/SupportPageForm/SupportPageForm";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseContainer from "core-ui/Layouts/BaseContainer/BaseContainer";
import useAlert from "hooks/useAlert";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SupportPage = () => {
  const { t } = useTranslation(["common"]);
  const { isAuth } = useSelector((store) => store.auth);

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  return (
    <>
      {isAuth && <DashboardIntro mt={".1rem"} />}
      <BaseContainer
        sx={{ padding: isAuth ? "0 5rem 5rem 5rem" : "12rem 10rem" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              my: 3,
              fontSize: "2.7rem",
              fontWeight: "500",
              color: "base.black",
            }}
          >
            {t("support page title")}
          </Typography>
          <BaseAlert
            mb={4}
            show={alert.show}
            type={alert.type}
            message={alert.message}
            destroy={hideAlertHandler}
            autoClose={5}
            interpolation={alert.interpolation}
          />
        </Stack>
        <SupportPageForm showAlertHandler={showAlertHandler} />
      </BaseContainer>
    </>
  );
};

export default SupportPage;
