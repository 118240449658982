export const DATA_ID = {
  TECH_REQUEST_DETAILS: "tech_request_details",
  CENTER_REQUEST_DETAILS: "center_request_details",
  LAB_REQUEST_DETAILS: "lab_request_details",
  LAB_NO_ASSIGN_REQUEST_DETAILS: "lab_no_assign_request_details",
  CON_REQUESTS: "con_requests",
  ADMIN_LICENSES: "admin_licenses",
  ADMIN_REQUEST_DETAILS: "admin_request_details",
  ADMIN_USERS_DETAILS: "admin_users_details",
  LABORATORY_USERS_DETAILS: "laboratory_users_details",
  ADMIN_COMPANY: "ADMIN_COMPANY",
  ADMIN_LAB_REQUESTS: "ADMIN_LAB_REQUESTS",
  ADMIN_EDIT_REQUEST: "ADMIN_EDIT_REQUEST",
  CONSULTANT_CONSULTATION_REQUEST: "CONSULTANT_CONSULTATION_REQUEST",
  CONTRACTOR_CONSULTANT_REQUEST: "CONTRACTOR_CONSULTANT_REQUEST",
  LAB_EDIT_REQUEST: "LAB_EDIT_REQUEST",
};

export const AdminDecision = {
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
};

export const CONSULTATION_STATUS = {
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  PENDING: "PENDING",
};
