export const UsersTypes = [
  "contractor",
  "inspector",
  "laboratory",
  "resaa",
  "center",
  "consultant",
];

export const UsersRoles = ["SYSOPS", "CONSULTANT", "TECHNICIAN", "SYSADMIN"];

export const UsersTypesEnum = {
  DEFAULT: "contractor",
  CONTRACTOR: "contractor",
  INSPECTOR: "inspector",
  LABORATORY: "laboratory",
  RESAA: "resaa",
  CENTER: "center",
  CONSULTANT: "consultant",
};

export const UsersRolesEnum = {
  SYSOPS: "SYSOPS",
  CONSULTANT: "CONSULTANT",
  TECHNICIAN: "TECHNICIAN",
  ADMIN: "SYSADMIN",
  LAB_TECHNICIAN: "LAB_TECHNICIAN",
};

export const SELF_SUPERVISION = "SELF_SUPERVISION";
