import inspectionApi from "modules/Inspection/apis";

const consultantInspectionApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubTestsForConsultant: builder.query({
      query: (inspectionTestId) => ({
        url: `/api/inspection/inspectionSubTests/v2/consultant/${inspectionTestId}`,
      }),
    }),
  }),
});

export const { useGetSubTestsForConsultantQuery } = consultantInspectionApi;

export default consultantInspectionApi;
