import contractorApi from "..";

const contractorRegistrationApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    registerValidate: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/contractors/addContractor",
        method: "POST",
        body: body,
      }),
    }),
    contractorRegister: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/contractors/validate",
        method: "POST",
        body: body,
      }),
    }),
    contractorOTP: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/contractors/validateOTP",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useRegisterValidateMutation,
  useContractorRegisterMutation,
  useContractorOTPMutation,
} = contractorRegistrationApi;

export default contractorRegistrationApi;
