import React, { useCallback } from "react";
import {
  Box,
  RadioGroup,
  Typography,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";
import classes from "../../../../core-ui/BaseRadioGroup/BaseRadioGroup.module.scss";
import BaseCard from "core-ui/BaseCard/BaseCard";
import PaymentMethods from "../PaymentMethods/PaymentMethods";

const PaymentMethodSelector = (props) => {
  const { t } = useTranslation(["auth", "dashboard"]);
  const { palette } = useTheme();

  const selectedItemSx = useCallback(
    (item) =>
      item.value === props.value
        ? {
            background: palette.base.info,
          }
        : {},
    [palette.base.info, props.value]
  );

  return (
    <Box
      className={`${classes["base-radio-group"]}`}
      sx={{ width: "100%", ...props.containerStyles }}
    >
      {props.label && (
        <Typography className={classes["base-radio-group__label"]}>
          {props.label}
        </Typography>
      )}
      <Controller
        control={props.control}
        name={props.name}
        rules={{
          required: {
            value: true,
            message: `${t("field")} ${props.validation?.errorMessage} ${t(
              "required"
            )}`,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <RadioGroup
            name={name}
            value={value || ""}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e.target.value);
              }
              onChange(e);
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "2rem",
              ...props.inputContainerStyles,
            }}
          >
            {props.data.map((dataItem) => (
              <Box key={dataItem.id} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    borderBottom:
                      value === dataItem.value
                        ? "none"
                        : `1px solid ${palette.base.infoBorder}`,
                    borderLeft: `1px solid ${palette.base.infoBorder}`,
                    borderRight: `1px solid ${palette.base.infoBorder}`,
                    borderRadius:
                      value === dataItem.value ? "8px 8px 0 0" : "8px",
                    borderTop: `1px solid ${palette.base.infoBorder}`,
                    bgcolor: value === dataItem.value ? palette.base.info : "",
                  }}
                >
                  <FormControlLabel
                    value={dataItem.value}
                    control={
                      <Radio
                        checked={value === dataItem.value}
                        sx={{
                          color: palette.primary.main,
                          "&.Mui-checked": {
                            color: palette.secondary.main,
                          },
                        }}
                        disabled={dataItem.disabled}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.6rem",
                            ...props.labelSx,
                            mt: "0.5rem",
                          }}
                        >
                          {dataItem.name}
                        </Typography>
                        <Box>
                          {dataItem.value === "card" && <PaymentMethods />}
                        </Box>
                      </Box>
                    }
                    sx={{
                      ...props.inputStyles,
                      ...selectedItemSx(dataItem),
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 16px",
                    }}
                  />
                </Box>

                {value === dataItem.value && (
                  <BaseCard
                    sx={{
                      padding: "0.1rem 4rem",
                      borderLeft: `1px solid ${palette.base.infoBorder}`,
                      borderRight: `1px solid ${palette.base.infoBorder}`,
                      borderBottom: `1px solid ${palette.base.infoBorder}`,
                      borderTop: "none",
                      borderRadius: "0 0 8px 8px",
                      bgcolor: palette.base.info,
                    }}
                  >
                    {dataItem.content}
                  </BaseCard>
                )}
              </Box>
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  );
};

PaymentMethodSelector.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  containerStyles: PropTypes.object,
  inputContainerStyles: PropTypes.object,
  inputStyles: PropTypes.object,
  labelSx: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      content: PropTypes.node,
    })
  ).isRequired,
  validation: PropTypes.shape({
    errorMessage: PropTypes.string,
  }),
};

PaymentMethodSelector.defaultProps = {
  value: "",
  onChange: () => {},
  label: "",
  containerStyles: {},
  inputContainerStyles: {},
  inputStyles: {},
  labelSx: {},
  validation: {
    errorMessage: "",
  },
};

export default PaymentMethodSelector;
