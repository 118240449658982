import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useForm } from "react-hook-form";

import { useState } from "react";
import PatternValidationChecker from "components/PatternValidationChecker/PatternValidationChecker";
import { useRegisterValidateMutation } from "modules/Contractor/apis/auth-apis/register-api";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import useCatchUserType from "hooks/useCatchUserType";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import useLogin from "hooks/useLogin";
import { useCompleteConsultantRegistrationMutation } from "modules/Auth/apis/consultant-auth-apis/register-api";
import { useDispatch } from "react-redux";
import { authActions } from "store/auth-slice";

const RegisterFormCont = () => {
  const { t } = useTranslation(["auth"]);
  const [areAllCasesValid, setAreAllCasesValid] = useState("");

  const [contractorRegister] = useRegisterValidateMutation();
  const [consultantRegister] = useCompleteConsultantRegistrationMutation();

  const navigate = useNavigate();

  const { userRoleFromURL } = useCatchUserType();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm({ mode: "onChange" });

  const passwordValue = watch("password");

  const showAlertHandler = useOutletContext();

  const location = useLocation();
  const dispatch = useDispatch();

  const { state } = location;

  const { loginHandler } = useLogin();

  const successMessage = state.isForRegister
    ? SuccessCodes.SUCCESS_REGISTER
    : SuccessCodes.SUCCESS_FORGOT_PASSWORD;

  const onSubmitHandler = (data, event) => {
    event.preventDefault();
    if (areAllCasesValid) {
      const registerApi =
        userRoleFromURL === "consultant"
          ? consultantRegister
          : contractorRegister;

      registerApi({
        password: data.password,
        userid: state.userId,
      })
        .unwrap()
        .then((_) => {
          showAlertHandler({
            show: true,
            message: successMessage,
            type: AlertTypes.SUCCESS,
          });

          loginHandler({
            username: state.registerData.email,
            password: data.password,
          });
          dispatch(authActions.clearConsultantRegistrationData());

          if (!state.isForRegister) {
            navigate(`/auth/${userRoleFromURL}/login`, 2000);
          }
        })
        .catch((err) => {
          showAlertHandler({
            show: true,
            message: err.data.errorCode,
            type: AlertTypes.WARNING,
          });
        });
    }

    // submit values
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
    // submit values
  };

  const getAllResetPasswordValidationCases = (areCasesValid) => {
    setAreAllCasesValid(areCasesValid);
  };

  return (
    <Box className="register-form">
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <FormTitle title="إنشاء كلمة المرور " />
        <Box sx={{ marginBottom: "4rem" }}>
          <BaseInput
            inputContainerStyles={{ marginBottom: 3 }}
            htmlFor="password"
            label={t("password")}
            name={"password"}
            type="password"
            maxLength={15}
            register={{
              ...register("password", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("password")} ${t("required")}`,
                },
                minLength: {
                  value: 8,
                  message: ` ${t("need 8 chars")}`,
                },
              }),
            }}
            errors={errors}
          />{" "}
          <PatternValidationChecker
            password={passwordValue}
            isSubmitted={isSubmitted}
            getValidationCases={getAllResetPasswordValidationCases}
          />
          <BaseInput
            inputContainerStyles={{ marginBottom: 3 }}
            htmlFor="repassword"
            label={t("repeat password")}
            name={"repassword"}
            type="password"
            register={{
              ...register("repassword", {
                validate: (rePasswordValue) => {
                  if (passwordValue !== rePasswordValue) {
                    return `${t("Password does not match")}`;
                  }
                },
              }),
            }}
            errors={errors}
          />
        </Box>

        <Box sx={{ marginTop: 7, textAlign: "center" }}>
          <BaseButton
            sx={{ minWidth: "26rem" }}
            type="submit"
            disabled={isSubmitting}
          >
            {t("create account")}
          </BaseButton>
        </Box>
      </form>
    </Box>
  );
};

export default RegisterFormCont;
