import React from "react";
import { Box, Typography, useMediaQuery, FormHelperText } from "@mui/material";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { formattedNumber } from "utils/Numbers/Numbers";
import { useTheme } from "@emotion/react";
import { Controller, useForm } from "react-hook-form";

function CancelModal({
  show,
  closeModal,
  confirmCancel,
  id,
  totalPoints,
  isLoading,
}) {
  const { palette, breakpoints } = useTheme();
  const sm = useMediaQuery(breakpoints.down("sm"));

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const inputHasError = errors ? errors["cancelReason"] : false;

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const onLeaveHandler = () => {
    reset({ cancelReason: "" });
    closeModal();
  };

  const onSubmitHandler = (formData) => {
    confirmCancel(formData["cancelReason"], reset);
  };

  return (
    <BaseModal
      closeIcon
      show={show}
      title={`تأكيد طلب إلغاء فحص`}
      modalContentWrapperSx={{ paddingTop: "2rem" }}
      onHide={onLeaveHandler}
      mainContainerSx={{ width: "75rem", overflow: "visible" }}
    >
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <Box
          sx={{
            color: "grayDark.color",
            fontSize: "1.6rem",
            lineHeight: "3rem",
            px: "4rem",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: sm ? "1.5rem" : "1.6rem",
              color: "grayDark.color",
              mb: 2,
            }}
          >
            <label htmlFor="cancelReason">الرجاء ادخال سبب الإلغاء:</label>
          </Typography>
          <Box>
            <Controller
              control={control}
              name="cancelReason"
              rules={{
                required: {
                  value: true,
                  message: `الحقل مطلوب`,
                },
              }}
              render={({ field: { onChange, value = "", name } }) => {
                return (
                  <textarea
                    style={{
                      fontFamily: "times new roman",
                      fontSize: "1.5rem",
                      width: "100%",
                      height: "15rem",
                      padding: "1rem",
                      resize: "none",
                      border: `1px solid ${
                        inputHasError ? "#ffa0a0" : "#449A91"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: inputHasError ? "#fff7f7" : "",
                    }}
                    name={name}
                    id={name}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    maxLength={700}
                  />
                );
              }}
            />
          </Box>
          {inputHasError && (
            <Box>
              <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
                {inputHasError?.message}
              </FormHelperText>
            </Box>
          )}
        </Box>
        <Box px={sm ? "3rem" : "6rem"} mb={1}>
          <Typography
            sx={{
              mt: sm ? 0 : 2,
              fontSize: sm ? "1.5rem" : "1.6rem",
              color: "grayDark.color",
              mb: 4,
            }}
          >
            يرجى العلم أنه سيتم إلغاء طلب الفحص رقم{" "}
            <span
              style={{
                fontSize: "1.8rem",
                fontWeight: "bold",
              }}
            >
              #{id},
            </span>{" "}
            كما سيتم إرجاع{" "}
            <span
              style={{
                fontSize: "1.8rem",
                fontWeight: "bold",
                color: palette.primary.colorSoft10,
              }}
            >
              {formattedNumber(totalPoints)}
            </span>
            <span style={{ fontWeight: "bold", marginRight: "0.8rem" }}>
              نقطة
            </span>{" "}
            للمحفظة, هل أنت متأكد من قيامك بهذا الإجراء؟
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: sm ? "center" : "flex-end",
            gap: sm ? "1rem" : "",
            alignContent: "flex-end",
            flexWrap: "wrap",
            px: sm ? "3rem" : "6rem",
            mb: 3,
          }}
        >
          <BaseButton
            sx={{
              minWidth: "12.2rem",
              mr: 1,
              color: "text.tags.blueSoft",
            }}
            variant="transparent"
            onClick={onLeaveHandler}
          >
            إلغاء
          </BaseButton>
          <BaseButton
            sx={{ minWidth: "12.2rem", display: "flex", gap: 2 }}
            variant="secondary"
            type="submit"
            isLoading={isLoading}
          >
            تأكيد
          </BaseButton>
        </Box>
      </form>
    </BaseModal>
  );
}

export default CancelModal;
