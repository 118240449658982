export default `                   
var arabicLanguage = 'عربية‎';
var wpwlOptions = {
  style:"card",
  locale: "ar",
  brandDetection: true,
  brandDetectionType: "binlist",
  iframeStyles: {
    'card-number-placeholder': {
        'color': '#b3b3b3',
        'font-size': '1.2rem',
        'font-family': 'Tahoma',

    },
    'cvv-placeholder': {
        'color': '#b3b3b3',
        'font-size': '1.2rem',
        'font-family': 'Tahoma'
    }
  },
  
  onReady:function(){
    var holder = $('.wpwl-control-cardHolder').val();
    $(".wpwl-group-cardHolder").after($(".wpwl-group-expiry"));
    $(".wpwl-group-cardNumber").before($(".wpwl-group-cardHolder"));
    $('.wpwl-form-card').find('.wpwl-button-pay').on('click', function(e){
      validateHolder(e);
      isArabic(holder);
    });
    $('.wpwl-button-pay').html('دفع');  
  },
  onBeforeSubmitCard: function(e){
    var holder = $('.wpwl-control-cardHolder').val();
    if(!isArabic(holder)) {
      return false;
    }
    return validateHolder(e);
  },
}
function validateHolder(e){
  var holder = $('.wpwl-control-cardHolder').val();

  if (!isArabic(holder)){
    return false;
  }

  if(holder.trim().length < 3) {
    $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">اسم حامل البطاقة يجب أن يحتوى على 3 مدخلات على الأقل</div>');
    return false;
  }
  if (!isArabic(holder)){
    $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">اسم حامل البطاقة غير صحيح</div>');
    return false;
  }

  if (/[^a-zA-Z ]/gi.test(holder)){
    $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">غير مسموج بوجود ارقام في الاسم</div>');
    return false;
  }
  return true;
}
function isArabic(strInput){
  var arregex = /[\u0600-\u06FF]/;
  if (arregex.test(strInput)){
    $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">يجب ان يكون اسم حامل البطاقة باللغة الإنجليزية</div>');
    return false;
  }
  return true;
}
`;
