import { useEffect, useState } from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TableTitle from "components/Dashboard/TableTitle";
import {
  drillingLicences,
  examinationRequests,
} from "services/StaticLookup/TableHeader/TableHeader";
import { useTheme } from "@emotion/react";
import { useGetContractorLicensesQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import { publicAssetsPath } from "utils/Paths/Paths";
import { TABLE_ID, TABLE_LIMIT_PAGE_SIZE } from "constants/Table";
import {
  useGetContractorRequestsQuery,
  useGetOutdatedRequestFlagQuery,
} from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { Helmet } from "react-helmet";
import { availableTo } from "utils/Auth/Auth";
import { useSelector } from "react-redux";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import useAlert from "hooks/useAlert";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const DashboardIndex = (props) => {
  const { userType, userRole } = useSelector((store) => store.auth);
  const { t } = useTranslation(["dashboard"]);
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { data: contractorLicensesData, isFetching } =
    useGetContractorLicensesQuery({
      page: 0,
      size: TABLE_LIMIT_PAGE_SIZE,
      sort: 'id,desc'
    });

  const {
    data: contractorRequestsData,
    isFetching: isContractorRequestFetching,
  } = useGetContractorRequestsQuery({
    page: 0,
    size: TABLE_LIMIT_PAGE_SIZE,
  });

  const { data: outdatedFlag } = useGetOutdatedRequestFlagQuery();

  const navigate = useNavigate();

  const { palette } = useTheme();

  useEffect(() => {
    if (
      sysopsContractorRoleChecker(userType, userRole) &&
      outdatedFlag?.existsOutdatedRequests
    ) {
      showAlertHandler({
        show: true,
        message: "CONTRACTOR_OUTDATED_REQUEST_WARNING",
        type: AlertTypes.WARNING,
      });
    }
  }, [outdatedFlag]);

  const tableEmptyData = {
    img: publicAssetsPath("images/documents.svg"),
    title: t("No requests"),
    desc: t("No requests desc"),
    to: "/home/request-path",
  };

  if (sysopsContractorRoleChecker(userType, userRole)) {
    tableEmptyData.link = t("Create a test request");
  }
  const AddCheckupRequestButton = (props) => {
    return availableTo(
      userRole,
      sysopsContractorRoleChecker(userType, userRole),
      <BaseButton
        sx={{ minWidth: "17.3rem" }}
        onClick={() => {
          navigate("/home/request-path");
        }}
      >
        {t("request checkup")}
      </BaseButton>
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"الرئيسية"}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("home page")}
        description={
          <>
            {t("dashboard home page description")}
            <span style={{ fontWeight: 800 }}> عرض الكل.</span>
          </>
        }
        action={<AddCheckupRequestButton />}
        warning={
          <BaseAlert
            mb={3}
            show={alert.show}
            labelSx={{ fontWeight: "bold", color: "black" }}
            type={alert.type}
            icon={false}
            destroy={hideAlertHandler}
            message={alert.message}
            closeIconColor={"grayDark.color"}
          />
        }
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("drilling licences")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={"/drilling-licenses"}
        />

        <BaseTableIndex
          tableHeader={drillingLicences}
          content={contractorLicensesData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.LICENSE }}
          emptyData={{
            title: t("No new license"),
            desc: t("No new license desc"),
            img: publicAssetsPath("images/documents.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/drilling-licenses/${id}`,
          }}
        />

        {/* second section examination_requests */}
      </BaseCard>

      {/* second section examination_requests */}
      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("Examination requests")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={"/examination-requests"}
        />
        <BaseTableIndex
          tableHeader={examinationRequests}
          content={contractorRequestsData?.content || []}
          injectProps={{ tableId: TABLE_ID.REQUEST }}
          isLoading={isContractorRequestFetching}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/examination-requests/${id}`,
          }}
        />
      </BaseCard>
    </>
  );
};

export default DashboardIndex;
