import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

import Dashboard from "../pages";
import DashboardHome from "../pages/Dashboard/Home/DashboardIndex";
import DashboardIndex from "../pages/Dashboard/Home/DashboardIndex/DashboardIndex";
import RequestDetails from "../pages/Dashboard/Lab/CollectSamplesRequests/CollectSamplesRequestDetails/CollectSamplesRequestDetails";
import CollectSample from "../pages/Dashboard/Lab/CollectSamplesRequests/CollectSample";
import FieldExaminationRequests from "../pages/Dashboard/Field/FieldExaminationRequests/FieldExaminationRequests";
import FieldExaminationRequestDetails from "../pages/Dashboard/Field/FieldExaminationRequests/FieldExaminationRequestDetails/FieldExaminationRequestDetails";
import FieldExaminationRequestsIndex from "../pages/Dashboard/Field/FieldExaminationRequests";
import FieldExaminationRecordForm from "../pages/Dashboard/Field/FieldExaminationRequests/FieldExaminationRecordForm/FieldExaminationRecordForm";
import CollectSamplesRequests from "../pages/Dashboard/Lab/CollectSamplesRequests/CollectSamplesRequests";
import CollectSamplesRequestsIndex from "../pages/Dashboard/Lab/CollectSamplesRequests";

const technicianDashboardRoutes = {
  path: "/",
  element: <ProtectedRoute component={<Dashboard />} />,
  children: [
    {
      path: "home",
      element: <ProtectedRoute component={<DashboardHome />} />,
      children: [{ index: true, element: <DashboardIndex /> }],
    },
    {
      path: "collect-samples-requests",
      element: <ProtectedRoute component={<CollectSamplesRequests />} />,
      children: [
        {
          index: true,
          element: <CollectSamplesRequestsIndex />,
        },
        {
          path: ":id",
          element: <RequestDetails />,
        },
        { path: ":id/collect-sample", element: <CollectSample /> },
      ],
    },
    {
      path: "field-examination-requests",
      element: <ProtectedRoute component={<FieldExaminationRequests />} />,
      children: [
        {
          index: true,
          element: <FieldExaminationRequestsIndex />,
        },
        {
          path: ":id",
          element: <FieldExaminationRequestDetails />,
        },
        {
          path: ":id/examination-form",
          element: <FieldExaminationRecordForm />,
        },
      ],
    },
  ],
};

export default technicianDashboardRoutes;
