import AuthFormIntro from "components/Forms/_Utilites/AuthFormIntro/AuthFormIntro";
import MagicRegisterForm from "components/Forms/RegisterForm/MagicRegisterForm/MagicRegisterForm";
import CenteredWrapper from "core-ui/CenteredWrapper/CenteredWrapper";

import { Helmet } from "react-helmet";

const MagicRegister = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إنشاء كلمة المرور`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <CenteredWrapper width={"60rem"}>
        <AuthFormIntro />
        <MagicRegisterForm />
      </CenteredWrapper>
    </>
  );
};

export default MagicRegister;
