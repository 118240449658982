import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { examinationRequests } from "services/StaticLookup/TableHeader/TableHeader";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useLazyGetContractorRequestsQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { TABLE_ID } from "constants/Table";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Helmet } from "react-helmet";
import { ExaminationRequest } from "services/StaticLookup/Breadcrumb";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import { useSelector } from "react-redux";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import RequestsSearch from "modules/Admin/components/RequestsSearch/RequestsSearch";
import { isContentReady } from "utils/Array/Arrays";

const ExaminationRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const [searchTerm, setSearchTerm] = useState("");
  const [requestType, setRequestType] = useState("");

  const { userType, userRole } = useSelector((store) => store.auth);

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getRequests, getRequestsResponse] =
    useLazyGetContractorRequestsQuery();

  useEffect(() => {
    var timer = setTimeout(
      getRequests.bind(null, {
        page: searchTerm ? 0 : page,
        size: pageSize,
        sort: "id,desc",
        ...(searchTerm && { search: searchTerm }),
        ...(requestType && { testType: requestType }),
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, searchTerm, requestType]);

  const isNoSearchData =
    (searchTerm || requestType) &&
    !isContentReady(getRequestsResponse?.data?.content);

  const tableEmptyData = {
    img: publicAssetsPath("images/documents.svg"),
    title: isNoSearchData ? t("No Search") : t("No requests"),
    desc: isNoSearchData ? t("No Search Desc") : t("No requests desc"),
    to: isNoSearchData ? "" : "/home/request-path",
  };

  if (sysopsContractorRoleChecker(userType, userRole)) {
    tableEmptyData.link = isNoSearchData ? "" : t("Create a test request");
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات الفحص`}</title>
      </Helmet>

      <DashboardIntro
        title={`${t("Examination requests", {
          ns: "dashboard",
        })}`}
        description={t("Examination requests description", {
          ns: "dashboard",
        })}
        breadcrumbData={ExaminationRequest({})}
      />
      <RequestsSearch
        setSearchTerm={setSearchTerm}
        setRequestType={setRequestType}
        placeholder="يمكنك البحث برقم الطلب"
      />
      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={examinationRequests}
          content={getRequestsResponse?.data?.content || []}
          isLoading={getRequestsResponse?.isFetching}
          injectProps={{ tableId: TABLE_ID.REQUEST }}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/examination-requests/${id}`,
          }}
          pagination={{
            displayBy: [5, 10, 15],
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: getRequestsResponse?.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default ExaminationRequestsIndex;
