import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AdminUserManagementDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import BaseUserTypeTag from "core-ui/BaseUserTypeTag/BaseUserTypeTag";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { Box, Typography } from "@mui/material";
import { DATA_ID } from "constants/Details";
import {
  useActivateUserMutation,
  useDeActivateUserMutation,
  useGetUserQuery,
  useResendInviteMutation,
} from "modules/Admin/apis/users-management-apis/users-management-apis";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { defaultEmptyValue } from "constants/Empty";
import { _void } from "utils/Objects/Objects";

const AdminUserDetails = () => {
  const { t } = useTranslation(["dashboard"]);

  const params = useParams();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { data: user, isFetching: isFetchingUser } = useGetUserQuery(
    params?.id,
    {
      skip: !params?.id,
    }
  );

  const [activateUser, activateUserResponse] = useActivateUserMutation();
  const [deActivateUser, deActivateUserResponse] = useDeActivateUserMutation();
  const [resendInvite, resendInviteResponse] = useResendInviteMutation();

  const isUserActionSubmitting =
    activateUserResponse.isLoading ||
    deActivateUserResponse.isLoading ||
    isFetchingUser ||
    resendInviteResponse.isLoading;

  const [isUserAccountActive, setIsUserAccountActive] = useState(false);

  useEffect(() => {
    setIsUserAccountActive(!!user?.isActive);
  }, [user]);

  const ManageUserButtonAction = () => {
    const msg = !user
      ? _void
      : user.isActive
      ? "USER_DECTIVATED_SUCCESSFULLY"
      : "USER_ACTIVATED_SUCCESSFULLY";
    const fn = !user ? _void : user.isActive ? deActivateUser : activateUser;
    return (
      <BaseButton
        sx={{ minWidth: "17.3rem" }}
        onClick={changeUserActivationState.bind(null, fn, msg)}
        variant={isUserAccountActive ? "secondary" : ""}
        isLoading={isUserActionSubmitting}
      >
        {isUserAccountActive ? t("deactivate account") : t("activate account")}
      </BaseButton>
    );
  };

  const changeUserActivationState = (fn, msg) => {
    fn({ userId: user.id })
      .unwrap()
      .then(() => {
        showAlertHandler({
          show: true,
          message: SuccessCodes[msg],
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const resendUserInvite = () => {
    resendInvite({ userId: user.id })
      .unwrap()
      .then(() => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.USER_REINVITED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const DashboardIntroAction = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <BaseAlert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          destroy={hideAlertHandler}
          autoClose={10}
        />
        {user?.registered ? (
          <ManageUserButtonAction />
        ) : (
          <BaseButton
            onClick={resendUserInvite}
            disabled={!user?.reInvite}
            variant={!user?.reInvite ? "disabled-fancy" : ""}
            isLoading={isUserActionSubmitting}
          >
            {t("resend invite")}
          </BaseButton>
        )}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين - ${user?.name}`}</title>
      </Helmet>

      <DashboardIntro
        title={user?.name}
        isLoading={isFetchingUser}
        description={"يمكنك الاطلاع على معلومات المستخدم"}
        action={<DashboardIntroAction />}
        breadcrumbData={
          !user ? _void : AdminUserManagementDetailsBreadcrumb(user)
        }
      />

      <BaseCard py={"3rem"} px={"5rem"} mt={6} minHeight={"75%"}>
        <BaseDataWrapper
          title={"المعلومات العامة"}
          titleStyles={{ marginBottom: "3rem" }}
        >
          <BaseDataItem label={"الاسم"} value={user?.name} />
          <BaseDataItem
            label={"حالة الحساب"}
            valueComponentAs={"div"}
            value={
              <BaseStatus
                status={user?.isActive}
                dataId={DATA_ID.ADMIN_USERS_DETAILS}
              />
            }
          />
          {user?.role === "TECHNICIAN" && (
            <BaseDataItem
              label={"المكتب"}
              value={user?.entityName || defaultEmptyValue}
            />
          )}
          <Box>
            <Typography className={"request-data-details__label"}>
              الوظيفة
            </Typography>
            <BaseUserTypeTag type={user?.entitytype} role={user?.role} />
          </Box>
        </BaseDataWrapper>
        <hr />
        <BaseDataWrapper
          title={"معلومات التواصل"}
          titleStyles={{ marginBottom: "3rem" }}
        >
          <BaseDataItem label={"البريد الإلكتروني"} value={user?.email} />
          <BaseDataItem
            label={"رقم الجوال"}
            value={formatPhoneNumber(user?.mobileNumber)}
          />
        </BaseDataWrapper>
      </BaseCard>
    </>
  );
};

export default AdminUserDetails;
