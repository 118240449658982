import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getUserToken } from "services/Storage";
import useLogout from "./useLogout";
import { useSelector } from "react-redux";

const useCheckTokenExpiration = () => {
  const location = useLocation();

  const userToken = getUserToken();

  const { logout } = useLogout();

  const { isAuth } = useSelector((store) => store.auth);

  const currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() + 1);

  const logoutIfExpired = () => {
    if (
      new Date().getTime() >= new Date(userToken?.data?.exp * 1000).getTime()
    ) {
      logout();
    }
  };

  useEffect(() => {
    if (userToken) {
      var timer = setInterval(() => {
        logoutIfExpired();
      }, 5000);
      logoutIfExpired();
    }
    return () => clearInterval(timer);
  }, [location, isAuth]);
};

export default useCheckTokenExpiration;
