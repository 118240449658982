import { useCallback } from "react";
import { useState } from "react";

const useBaseTablePagination = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const onChangePaginationDataHandler = useCallback(
    (page, pageSize) => {
      setPage(page);
      setPageSize(pageSize);
    },
    [page, pageSize]
  );

  return {
    onChangePaginationDataHandler,
    page,
    pageSize,
  };
};

export default useBaseTablePagination;
