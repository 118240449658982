import { Typography } from "@mui/material";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useTranslation } from "react-i18next";

const Maximum = (props) => {
  const { t } = useTranslation(["auth", "dashboard"]);
  const prefix = props.prefix || "maximum";
  const applyValidationsOnInput = props.applyValidationsOnInput ?? true;

  if (
    applyValidationsOnInput &&
    !props.isMaxPassScoreRequired &&
    !props.isSingleScore
  ) {
    return <Typography sx={{ fontSize: "1.6rem" }}>لا ينطبق</Typography>;
  }

  return (
    <BaseInput
      sx={{ width: "15rem" }}
      htmlFor={`${prefix}${props.inputName}`}
      placeholder={
        applyValidationsOnInput &&
        !props.isMaxPassScoreRequired &&
        !props.isSingleScore
          ? "لا ينطبق"
          : "الدرجة"
      }
      disabled={
        applyValidationsOnInput &&
        !props.isMaxPassScoreRequired &&
        !props.isSingleScore
      }
      name={`${prefix}${props.inputName}-${props.id}`}
      register={{
        ...props.register(`${prefix}${props.inputName}-${props.id}`, {
          required: {
            value:
            props.inputName === 'MixGrade' || (
              applyValidationsOnInput &&
              ((props.isMaxPassScoreRequired &&
              !props.isSingleScore) || props.isSingleScore)),
            message: `${t("field")} ${t("required")}`,
            
          },
          maxLength: {
            value: 9,
            message: `تجاوت الحد المسموح`,
          },
          maxLength: {
            value: 9,
            message: `تجاوت الحد المسموح`,
          },
          pattern: {
            value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
            message: `${t(" يجب ان تكون المدخلات أرقام ")}`,
          },
        }),
      }}
      errors={props.errors}
    />
  );
};

export default Maximum;
