import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stations: [{
    id: 1,
    startPoint: "",
    endPoint: "",
    layerNumber: "",
    layerTest: "",
    length: "",
  }],
};

const stationInputsSlice = createSlice({
  name: "station-inputs",
  initialState: initialState,
  reducers: {
    setStations: (state, action) => {
      return {
        ...state,
        stations: [...action.payload]
      }
    },
    resetStations: (state, action) => {
      return {...initialState}
    },
  },
});

export const stationInputsActions = stationInputsSlice.actions;

export default stationInputsSlice.reducer;
