import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { drillingLicences } from "services/StaticLookup/TableHeader/Consultant";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY } from "constants/Table";
import { drillingLicenses } from "services/StaticLookup/Breadcrumb";
import { isContentReady } from "utils/Array/Arrays";
import useDebouncedQuery from "hooks/useDebouncedQuery";
import { useLazyGetConsultantLicensesQuery } from "modules/Consultant/apis/consultation-requests-apis/consultation-requests-apis";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { licenseYearsData } from "services/StaticLookup/LicensesYears";
import Search from "components/Search/Search";

const DrillingLicensesIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseYear, setLicenseYear] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [consultantLicensesData, consultantLicensesDataResponse] =
    useDebouncedQuery(useLazyGetConsultantLicensesQuery, 500);

  useEffect(() => {
    consultantLicensesData({
      page: licenseNumber ? 0 : page,
      size: pageSize,
      sortId: "id,desc",
      licenceNumber: licenseNumber,
      licenceYear: licenseYear,
    });
  }, [page, pageSize, licenseNumber, licenseYear]);

  const getLicenseNumber = (username) => {
    setLicenseNumber(username);
  };
  const getLicenseYear = (licenseYear) => {
    setLicenseYear(licenseYear.name || "");
  };

  const isNoSearchData =
    (licenseNumber || licenseYear) &&
    !isContentReady(consultantLicensesDataResponse.data?.content);
  const tableEmptyTitle = isNoSearchData
    ? t("No Search")
    : t("لا يوجد رخص تم إسنادها لك");
  const tableEmptyDescription = isNoSearchData
    ? t("No Search Desc")
    : t("يمكنك طلب إسناد الرخص لك وذلك من خلال الضغط على");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"رخص الحفر"}</title>
        <link rel="canonical" href="/drilling-licenses" />
      </Helmet>
      <DashboardIntro
        title={`${t("drilling licences", { ns: "dashboard" })}`}
        description={`تمكنك هذه الصفحة من البحث عن رخص الحفر واستعراض تفاصيل الرخصة`}
        breadcrumbData={drillingLicenses({})}
      />

      <Search
        getSearchValue={getLicenseNumber}
        getDropdownValue={getLicenseYear}
        input={{ type: "number", placeholder: "يمكنك البحث برقم الرخصة" }}
        dropdown={{
          label: "سنة الإصدار",
          placeholder: "حدد سنة الإصدار",
          data: licenseYearsData(),
        }}
      />

      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={drillingLicences}
          content={consultantLicensesDataResponse.data?.content || []}
          isLoading={consultantLicensesDataResponse?.isFetching}
          emptyData={{
            img: publicAssetsPath("images/documents5.svg"),
            title: tableEmptyTitle,
            desc: tableEmptyDescription,
            to: "/consultation-requests/raise-request",
            link: isNoSearchData ? "" : t("request for attribution of license"),
          }}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/drilling-licenses/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "رخصة",
            onChangePaginationDataHandler,
            totalCount: consultantLicensesDataResponse.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default DrillingLicensesIndex;
