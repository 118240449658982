export const serverKeysEnum = {
  CREATED_AT: "createdAt",
  LAB_ASSIGNED_AT: "labAssignedAt",
  COLLECTED_AT: "collectedAt",
  DIST_CENTER_DELIVERED_AT: "distCenterDeliveredAt",
  DIST_CENTER_RECEIVED_AT: "distCenterReceivedAt",
  LABORATORY_DELIVERED_AT: "laboratoryDeliveredAt",
  LABORATORY_RECEIVED_AT: "laboratoryReceivedAt",
  TECHNICIAN_ATTACHED_AT: "technicianAttachedAt",
  LABORATORY_SCORE_AT: "laboratoryScoreAt",

  ASSIGNED_LAB_NAME: "assignedLabName",
  ASSIGNMENT_HISTORY_LIST: "assignmentHistoryDtoList",
  LABORATORY_NAME: "laboratoryName",
  COMPLETED_AT: "completedAt",
  INSPECTION_SUB_TEST_HISTORY_LIST: "inspectionSubTestHistoryList",
  DE_ASSIGNMENT_HISTORIES: "deAssignmentHistories",
  DIST_CENTER_USERNAME: "distCenterUserName",
};

export const technicianStatus = {
  DONE: "DONE",
  REASSIGNED: "REASSIGNED",
  ASSIGNED: "ASSIGNED",
};
