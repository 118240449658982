import { combineReducers, configureStore } from "@reduxjs/toolkit";
import themeReducer from "./theme-slice/themeSlice";
import authReducer from "./auth-slice";
import authApi from "modules/Auth/apis/index";
import walletReducer from "./wallet-slice";
import contractorApi from "modules/Contractor/apis";
import adminApi from "modules/Admin/apis";
import contractorReducer from "./contractor-slice/contractor-slice";
import technicianReducer from "./technician-slice/technician-slice";
import sidemenuReducer from "./sidemenu-slice/sidemenu-slice";
import alertReducer from "./alert-slice/alert-slice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import dropdownReducer from "./dropdown-slice/dropdown-slice";
import stationInputsReducer from "./staions-inputs/station-inputs";
import draftReducer from "./draft-slice/draft-slice";
import publicApi from "modules/Public/apis";
import consultantApi from "modules/Consultant/apis";
import inspectionApi from "modules/Inspection/apis";
import consultantReducer from "./consultant-slice/consultant-slice";
import laboratoryReducer from "./laboratory-slice/laboratory-slice";

const rootReducer = combineReducers({
  // NORMAL REDUCERS
  auth: authReducer,
  theme: themeReducer,
  wallet: walletReducer,
  contractor: contractorReducer,
  consultant: consultantReducer,
  laboratory: laboratoryReducer,
  technician: technicianReducer,
  sidemenu: sidemenuReducer,
  alert: alertReducer,
  dropdown: dropdownReducer,
  stationInputs: stationInputsReducer,
  draft: draftReducer,

  // APIs REDUCERS
  [authApi.reducerPath]: authApi.reducer,
  [contractorApi.reducerPath]: contractorApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [consultantApi.reducerPath]: consultantApi.reducer,
  [inspectionApi.reducerPath]: inspectionApi.reducer,
});

const setupStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        authApi.middleware,
        contractorApi.middleware,
        adminApi.middleware,
        publicApi.middleware,
        consultantApi.middleware,
        inspectionApi.middleware
      ),
    //
    preloadedState,
    //
  });
  return store;
};

export default setupStore;

setupListeners(setupStore().dispatch);
