import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useForm } from "react-hook-form";
import { ValidEmailPattern } from "constants/Forms";
import { Typography } from "@mui/material";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import useCatchUserType from "hooks/useCatchUserType";
import { identityType } from "services/StaticLookup/Auth";
import { VerifyIDs } from "constants/Auth";
import { useContractorRegisterMutation } from "modules/Contractor/apis/auth-apis/register-api";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";

const RegisterFormMain = (props) => {
  const { t } = useTranslation(["auth", "common"]);
  const [idNumberTypeMaxLength, setIdNumberTypeMaxLength] = useState(0);

  const { userRoleFromURL } = useCatchUserType();

  const navigate = useNavigate();

  const showAlertHandler = useOutletContext();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const watchAll = watch();

  const { idTypeCode, idNumber } = watchAll;

  const [registerApi, registerApiResponse] = useContractorRegisterMutation();

  const idNumberOnFocus = (event) => {
    if (!idTypeCode) {
      setError("idTypeCode", {
        type: "idTypeCode",
        message: "حقل نوع الهوية مطلوب",
      });
    }
  };

  const setIdNumberMaxLength = useCallback(() => {
    let maxLength = 0;
    switch (idTypeCode?.id) {
      case "1":
      case "2":
      case "6":
      case "8":
      case "9":
        maxLength = 10;
        break;
      case "3":
        maxLength = 20;
        break;
      case "4":
        maxLength = 6;
        break;
      case "5":
      case "7":
        maxLength = 40;
        break;
    }
    setIdNumberTypeMaxLength(maxLength);
  }, [idTypeCode]);

  useEffect(() => {
    if (idTypeCode && idNumber) {
      setValue("idNumber", idNumber, { shouldValidate: true });
    }
    setIdNumberMaxLength();
  }, [idTypeCode]);

  const validateIdNumberField = (idNumber) => {
    let sudiId = /^[1][0-9]{9}$/;
    let iqamaNumber = /^[2][0-9]{9}$/;
    let gulfNumber = /[a-zA-Z-0-9]$/;
    let hafizatNufus = /^[0-9]{6}$/;
    let passport = /^[a-zA-Z0-9]*$/;
    let crNumber = /^[0-9]{10}$/;
    let other = /^[3-4][0-9]{9}$/;
    let establishmentReferenceNumber = /^[a-zA-Z0-9]*$/;

    if (idTypeCode.id == "1" && !idNumber.match(sudiId)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "2" && !idNumber.match(iqamaNumber)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "3" && !idNumber.match(gulfNumber)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "4" && !idNumber.match(hafizatNufus)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "5" && !idNumber.match(passport)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "6" && !idNumber.match(crNumber)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "7" && !idNumber.match(establishmentReferenceNumber)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "8" && !idNumber.match(other)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idTypeCode.id == "9" && !idNumber.match(other)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
  };

  const onSubmitHandler = (data, event) => {
    event.preventDefault();
    const idealData = {
      ...data,
      idTypeCode: data.idTypeCode.id,
    };

    registerApi(idealData)
      .unwrap()
      .then((responseData) => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_REGISTER,
          type: AlertTypes.SUCCESS,
        });
        setTimeout(() => {
          navigate(
            `/auth/${userRoleFromURL}/verify-otp`,
            {
              state: {
                verifyId: VerifyIDs.REGISTER,
                data: idealData,
                validateResponse: responseData,
              },
            },
            2000
          );
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.WARNING,
        });
      });
  };

  const onErrorHandler = (errors, event) => {
    // showAlertHandler(true);
    event.preventDefault();
    // submit values
  };

  const isSubmitButtonDisabled =
    registerApiResponse.isLoading || registerApiResponse.isSuccess;

  return (
    <Box className="register-form">
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <FormTitle title="إنشاء حساب" />
        <Typography sx={{ color: "grayDark.color", fontSize: "1.4rem" }} mb={4}>
          يرجى التأكد من أن البيانات المدخلة مطابقة للبيانات المسجلة في نظام
          الرخص.
        </Typography>

        <Typography
          sx={{ color: "grayDark.color", fontSize: "1.4rem", fontWeight: 500 }}
        >
          {t("الرقم الموحد للمنشأة")}
        </Typography>
        <Typography sx={{ color: "grayDark.color", fontSize: "1.4rem" }}>
          {t("رقم المنشأة الموحد في السجل التجاري لشركة المقاولات")}
        </Typography>

        <BaseInput
          htmlFor="crNumber"
          name="crNumber"
          maxLength={10}
          placeholder={"الرقم الموحد للمنشأة في السجل التجاري"}
          inputContainerStyles={{ marginBottom: 3 }}
          register={{
            ...register("crNumber", {
              required: {
                value: true,
                message: `${t("field")} ${t("الرقم الموحد للمنشأة")} ${t(
                  "required"
                )}`,
              },
              pattern: {
                value: /^[0-9]*$/,
                message: `فضلا تأكد من صحة المعلومات المدخلة`,
              },
              minLength: {
                value: 10,
                message: `فضلا تأكد من صحة المعلومات المدخلة`,
              },
            }),
          }}
          errors={errors}
        />
        <Box sx={{ marginBottom: 3 }}>
          <Typography
            sx={{
              color: "grayDark.color",
              fontSize: "1.4rem",
              fontWeight: 500,
            }}
          >
            {t("idTypeCode")}
          </Typography>
          <Typography sx={{ color: "grayDark.color", fontSize: "1.4rem" }}>
            {t("هوية المقاول المفوض")}
          </Typography>

          <BaseDropdown
            control={control}
            name="idTypeCode"
            required
            errors={errors}
            placeholder={t("idTypeCode")}
            data={identityType}
            selectMenuStyles={{ height: "12rem !important" }}
          />
        </Box>

        <BaseInput
          inputContainerStyles={{ marginBottom: 3 }}
          htmlFor="idNumber"
          label={t("idNumber")}
          name={"idNumber"}
          maxLength={idNumberTypeMaxLength}
          onFocus={idNumberOnFocus}
          register={{
            ...register("idNumber", {
              required: {
                value: true,
                message: `${t("field")} ${t("idNumber")} ${t("required")}`,
              },
              maxLength: {
                value: idNumberTypeMaxLength,
                message: `فضلا تأكد من صحة المعلومات المدخلة`,
              },
              validate: validateIdNumberField,
            }),
          }}
          errors={errors}
        />

        <BaseInput
          inputContainerStyles={{ marginBottom: 3 }}
          htmlFor="email"
          label={t("email")}
          name={"email"}
          type="email"
          register={{
            ...register("email", {
              required: {
                value: true,
                message: `${t("field")} ${t("email")} ${t("required")}`,
              },
              pattern: {
                value: ValidEmailPattern,
                message: `${t("Invalid email address")}`,
              },
            }),
          }}
          errors={errors}
        />
        <Box sx={{ marginTop: 7, textAlign: "center" }}>
          <BaseButton
            type="submit"
            disabled={isSubmitButtonDisabled}
            variant={isSubmitButtonDisabled ? "disabled" : ""}
          >
            {t("next", { ns: "common" })}
          </BaseButton>
        </Box>
      </form>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "1900px",
          px: "6.1rem",
        }}
        mt={4}
      >
        <Typography sx={{ fontSize: "1.4rem" }}>لديك حساب مسبق؟</Typography>
        <Link to={`/auth/${userRoleFromURL}/login`}>
          <Typography
            sx={{
              fontSize: "1.4rem",
              color: "primary.colorSoft2",
              fontWeight: "500",
            }}
          >
            تسجيل الدخول
          </Typography>
        </Link>
      </Grid>
    </Box>
  );
};

export default RegisterFormMain;
