const useGetVirtualStations = (stations) => {
  const virtualStation = stations?.find((station) => station.isVirtual);

  const tableContent = virtualStation
    ? [virtualStation]
    : stations?.filter((s) => !s.isVirtual);

  return { hasVirtual: !!virtualStation, tableContent };
};

export default useGetVirtualStations;
