import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { AdminUserManagementDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { _void } from "utils/Objects/Objects";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { DATA_ID } from "constants/Details";
import { useGetlabTechniciansByIdQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";

const UserDetails = () => {
  const params = useParams();


  const { data, isFetching: isUsersDataFetching } = useGetlabTechniciansByIdQuery
  (
    params?.id,
    {
      skip: !params?.id,
    })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين - ${data?.name}`}</title>
      </Helmet>

      <DashboardIntro
        title={data?.name}
        isLoading={isUsersDataFetching}
        description={"يمكنك الاطلاع على معلومات المستخدم"}
        breadcrumbData={
          !data ? _void : AdminUserManagementDetailsBreadcrumb(data)
        }
      />

      <BaseCard py={"3rem"} px={"5rem"} mt={6} minHeight={"75%"}>
        <BaseDataWrapper
          title={"المعلومات العامة"}
          titleStyles={{ marginBottom: "3rem" }}
        >
          <BaseDataItem label={"الاسم"} value={data?.name} />
          <BaseDataItem label={"رقم الهوية"} value={data?.nationalIdNumber} />

          <BaseDataItem
            label={"حالة الحساب"}
            valueComponentAs={"div"}
            value={
              <BaseStatus
                status={data?.isActive}
                dataId={DATA_ID.ADMIN_USERS_DETAILS}
              />
            }
          />{" "}
        </BaseDataWrapper>
        <hr />
        <BaseDataWrapper
          title={"معلومات التواصل"}
          titleStyles={{ marginBottom: "3rem" }}
        >
          <BaseDataItem label={"البريد الإلكتروني"} value={data?.email} />
          <BaseDataItem
            label={"رقم الجوال"}
            value={formatPhoneNumber(data?.mobile)}
          />
        </BaseDataWrapper>
      </BaseCard>
    </>
  );
};

export default UserDetails;
