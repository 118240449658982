import { TestTypes } from "constants/TestTypes";
import { useSelector } from "react-redux";
import LabAddCheckupSummary from "./Lab/LabAddCheckupSummary";
import FieldAddCheckupSummary from "./Field/FieldAddCheckupSummary";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useForm } from "react-hook-form";
import { _scrollToTop } from "utils/DOM/DOM";
import { usePostNewInspectionMutation } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import BaseModal from "core-ui/BaseModal/BaseModal";
import ChunksContentDataModal from "components/AllModalContent/ChunksContentDataModal/ChunksContentDataModal";
import { addNewInspectionLayer } from "./AddCheckupSummaryService";

const AddCheckupSummary = (props) => {
  const { handleSubmit } = useForm({
    mode: "onChange",
  });
  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const [postNewInspection, postNewInspectionResponse] =
    usePostNewInspectionMutation();

  const isField = raiseNewRequestData?.testType === TestTypes.FIELD_TEST;
  const isLab = raiseNewRequestData?.testType === TestTypes.LAB_TEST;
  const testType = raiseNewRequestData?.testType;
  const layersData = raiseNewRequestData?.layersData;
  const testsTemplates = raiseNewRequestData?.testsTemplates;

  const addNewInspectionLayerService = addNewInspectionLayer({
    data: raiseNewRequestData,
    resetAndLeave: props.resetAndLeave,
    navigateAfterPostNewInsepction: props.navigateAfterPostNewInsepction,
    onLeavePage: props.onLeavePageHandler,
    showAlert: props.indexShowAlertHandler,
    isWalletAvailable: props.isWalletAvailable,
    _licenseData: props._licenseData,
  });

  const onSubmit = () => {
    if (isLab) {
      addNewInspectionLayerService.onSubmitLabTest(postNewInspection);
    } else if (isField) {
      addNewInspectionLayerService.onSubmitFieldTest(postNewInspection);
    }
  };

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    // prettier-ignore
    const isLastStage = props.timeline.currentStage >= props.timeline.numberOfStages;

    if (isLastStage) {
      props.onOpenConfirmModalHandler();
      return;
    }
    onSubmit();
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const labProps = {
    testsTemplates,
    layersData,
  };

  const { isLoading, isSuccess } = postNewInspectionResponse;

  const prevStage = props.timeline.currentStage - 1;

  const sharedProps = {
    timeline: props.timeline,
    appointmentDate: raiseNewRequestData?.[`stage-${prevStage}-formData`]?.date,
    appointmentTime: raiseNewRequestData?.[`stage-${prevStage}-formData`]?.time,
    raiseNewRequestData,
    testsTemplates,
    testType,
    isLoading,
    isSuccess,
  };

  return (
    <>
      <BaseModal
        show={props.showConfirm}
        py={"6rem"}
        px={"4rem"}
        onHide={props.onLeavePageHandler}
      >
        <ChunksContentDataModal
          confirm={onSubmit}
          onHide={props.onLeavePageHandler}
        />
      </BaseModal>
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        {isLab ? (
          <LabAddCheckupSummary {...labProps} {...sharedProps} />
        ) : isField ? (
          <FieldAddCheckupSummary {...sharedProps} />
        ) : (
          <BaseLoader />
        )}
      </form>
    </>
  );
};

export default AddCheckupSummary;
