import { defaultEmptyValue } from "constants/Empty";
import { retrieveTimeFromHHMMSSstring } from "../Date/Date";

/**
 *
 * @param {date} formDate - The date object that user picked from BaseCalendar (BaseCalendar date object | JS new date object)
 * @param {string} availableTime - The available time as string in HH:MM:SS format - "10:00:00"
 * @returns The returned object consists of the end time hours and the end time minutes {endTimeHours: 10, endTimeMinutes: 00}
 * @default object - The default return value in case the parameters are  missing { endTimeHours: "00", endTimeMinutes: "00" }
 * @author Khaled
 */
export const calculateEndTime = (formDate, availableTime) => {
  const { hh, mm, ss } = retrieveTimeFromHHMMSSstring(availableTime);
  let endTimeHours = "00";
  let endTimeMinutes = "00";
  if (formDate) {
    let date = new Date(new Date(formDate).setHours(0, 0, 0, 0));

    date.setHours(+hh + 1);
    date.setMinutes(+mm + 30);
    endTimeHours =
      date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    endTimeMinutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  }

  return { endTimeHours, endTimeMinutes };
};

/**
 *
 * @param {string} time - The time is a string of HH:MM:SS format - "10:30:00"
 * @param {string} fallback - The fallback is a string that will be rendered in case the "HH" is falsy
 * @returns string - It returns a string of time with label AM/PM in Arabic (ص, م) - "10:30 ص"
 * @default string - لا يوجد
 */
export const renderLabAppointmentTimeLabel = (time, fallback) => {
  let { hh, mm, ss } = retrieveTimeFromHHMMSSstring(time);

  if (hh === undefined || hh === null) return fallback || defaultEmptyValue;

  if (hh >= 12) {
    if (hh > 12) {
      hh -= 12;
    }
    return `${hh}:${mm} م`;
  }
  if (hh == 0) {
    hh = 12;
  }
  return `${hh}:${mm} ص`;
};

/**
 *
 * @param {string} time - The time is a string of HH:MM:SS format - "10:30:00"
 * @param {string} fallback - The fallback is a string that will be rendered in case the "HH" is falsy
 * @returns string - It returns a string of time range (startTime - endTime am/pm) and the endTime is 30 minutes greater than startTime with label AM/PM in Arabic (ص, م) - "10:00 - 10:30 ص"
 * @default string - لا يوجد
 */
export const renderAppointmentTimeLabel = (time, fallback) => {
  let { hh, mm, ss } = retrieveTimeFromHHMMSSstring(time);
  if (!hh) return fallback || defaultEmptyValue;

  hh = parseInt(hh, 10);
  mm = parseInt(mm, 10);

  const endHh = hh + Math.floor((mm + 30) / 60);
  const endMm = (mm + 30) % 60;

  const amPm = hh >= 12 ? "م" : "ص";
  const startHh12 = hh > 12 ? hh - 12 : hh;
  const endHh12 = endHh > 12 ? endHh - 12 : endHh;
  const startTime = `${startHh12 || 12}:${mm.toString().padStart(2, "0")}`;
  const endTime = `${endHh12}:${endMm.toString().padStart(2, "0")}`;

  return `${startTime} - ${endTime} ${amPm}`;
};
