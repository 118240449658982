import Dashboard from "../pages/Dashboard";
import DashboardIndex from "../pages/Dashboard/Home/DashboardIndex";
import RequestCheckup from "../pages/Dashboard/RequestCheckup/RequestCheckup";
import GeneralInstructions from "../pages/Dashboard/GeneralInstructions/GeneralInstructions";
import DrillingLicenses from "../pages/Dashboard/DrillingLicenses";

import DashboardHome from "../pages/Dashboard/Home";
import DrillingLicensesIndex from "../pages/Dashboard/DrillingLicenses/DrillingLicensesIndex/DrillingLicensesIndex";
import DrillingLicensesDetails from "../pages/Dashboard/DrillingLicenses/DrillingLicensesDetails/DrillingLicensesDetails";
import ExaminationRequests from "../pages/Dashboard/ExaminationRequests";
import ExaminationRequestsIndex from "../pages/Dashboard/ExaminationRequests/ExaminationRequestsIndex/ExaminationRequestsIndex";
import ExaminationRequestsDetails from "../pages/Dashboard/ExaminationRequests/ExaminationRequestsDetails";
import ConsultantsRequests from "../pages/ConsultantsRequests";
import ConsultantsRequestsIndex from "../pages/ConsultantsRequests/ConsultantsRequestsIndex/ConsultantsRequestsIndex";
import ConsultantsRequestsDetails from "../pages/ConsultantsRequests/ConsultantsRequestsDetails/ConsultantsRequestsDetails";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import BookExamination from "../pages/BookExamination/BookExamination";
import Settings from "../pages/Settings";
import TransactionCenter from "../pages/TransactionCenter";
import InvoicesDetails from "../pages/TransactionCenter/Invoice/InvoicesDetails/InvoicesDetails";
import PointsPayments from "../pages/PointsPayments/PointsPayments";
import PointsPaymentsDetails from "../pages/PointsPayments/PointsPaymentsDetails/PointsPaymentsDetails";
import PointsPaymentsPage from "../pages/PointsPayments";
import EditRequest from "../pages/Dashboard/ExaminationRequests/ExaminationRequestsDetails/EditRequest";
import RequestPath from "../pages/Dashboard/RequestPath/RequestPath";

const contractorDashboardRoutes = {
  path: "/",
  element: <Dashboard />,
  children: [
    {
      path: "home",
      element: <ProtectedRoute component={<DashboardHome />} />,
      children: [
        { index: true, element: <DashboardIndex /> },
        { path: "request-path", element: <RequestPath /> },
        { path: "general-instructions", element: <GeneralInstructions /> },
        { path: "add-checkup-request", element: <RequestCheckup /> },
      ],
    },
    {
      path: "drilling-licenses",
      element: <ProtectedRoute component={<DrillingLicenses />} />,
      children: [
        { index: true, element: <DrillingLicensesIndex /> },
        { path: ":id", element: <DrillingLicensesDetails /> }, // we added * to fit the licenseNo string format with '/'
      ],
    },
    {
      path: "examination-requests",
      element: <ProtectedRoute component={<ExaminationRequests />} />,
      children: [
        { index: true, element: <ExaminationRequestsIndex /> },
        {
          path: ":id",
          element: <ExaminationRequestsDetails />,
        }, // we added * to fit the licenseNo string format with '/'
        {
          path: ":id/book-examination",
          element: <BookExamination />,
        },
        {
          path: ":id/edit-request",
          element: <EditRequest />,
        },
      ],
    },
    {
      path: "consultants-requests",
      element: <ProtectedRoute component={<ConsultantsRequests />} />,
      children: [
        { index: true, element: <ConsultantsRequestsIndex /> },
        {
          path: ":id",
          element: <ConsultantsRequestsDetails />,
        },
      ],
    },
    {
      path: "wallet/*",
      element: <ProtectedRoute component={<TransactionCenter />} />,
    },
    {
      path: "invoice-details/:id",
      element: <ProtectedRoute component={<InvoicesDetails />} />,
    },
    {
      path: "points-payments",
      element: <ProtectedRoute component={<PointsPaymentsPage />} />,
      children: [
        {
          index: true,
          element: <PointsPayments />,
        },
        {
          path: ":id",
          element: <PointsPaymentsDetails />,
        },
      ],
    },
    {
      path: "settings",
      element: <ProtectedRoute component={<Settings />} />,
    },
  ],
};

export default contractorDashboardRoutes;
