export const ResetPasswordValidationCases = [
  { label: "A-Z", isValid: undefined, pattern: /[A-Z]/ },
  { label: "a-z", isValid: undefined, pattern: /[a-z]/ },
  { label: "0-9", isValid: undefined, pattern: /[0-9]/ },
  { label: "symbols", isValid: undefined, pattern: /[-%$#@]/ },
];

export const LicenseValidationCases = [
  { label: "9665", isValid: undefined, pattern: /[9665]/ },
  { label: "055", isValid: undefined, pattern: /[055]/ },
];
