import authApi from "..";

const consultantRegistrationApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    consultantRegister: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/signUp",
        method: "POST",
        body: body,
      }),
    }),
    consultantOTP: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/validateOTP",
        method: "POST",
        body: body,
      }),
    }),
    resendOTP: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/resendOTP",
        method: "POST",
        body: body,
      }),
    }),

    completeConsultantRegistration: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/completeRegistrationOTP",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useConsultantRegisterMutation,
  useConsultantOTPMutation,
  useCompleteConsultantRegistrationMutation,
  useResendOTPMutation,
} = consultantRegistrationApi;

export default consultantRegistrationApi;
