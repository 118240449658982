import BaseTable from "core-ui/BaseTable/BaseTable";
import React, { useEffect, useMemo, useState } from "react";
import { DrillingLicensesRequests } from "services/StaticLookup/TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useLazyGetContractorlicensesRequestQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { Box, Stack } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseFilter from "core-ui/BaseFilter/BaseFilter";
import { consultantExaminationRequestsFilter } from "services/StaticLookup/Filter";
import { useSearchQuery } from "hooks/useSearchQuery";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

function DrillingLicensesRequest(props) {
  const { t } = useTranslation(["dashboard"]);
  const navigate = useNavigate();
  const { userType, userRole } = useSelector((store) => store.auth);

  const [requestType, setRequestType] = useState("");
  const [requestNumber, setRequestNumber] = useState("");

  const [getRequests, getRequestsResponse] =
    useLazyGetContractorlicensesRequestQuery();

  const requestsSearchQuery = useSearchQuery({
    requestNumber,
    testType: requestType,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      getRequests({
        request_id: props.data.id,
        request_search_query: requestsSearchQuery,
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [requestsSearchQuery, props.data.id]);

  const emptyTableData = {
    title: t("No requests"),
    desc: t("No requests desc"),
    img: publicAssetsPath("images/documents4.svg"),
  };

  if (sysopsContractorRoleChecker(userType, userRole)) {
    emptyTableData.extrInfo = "طلب فحص";
    emptyTableData.onClick = () => navigate("/home/request-path");
  }

  const onChangeRequestType = (value) => {
    setRequestType(value);
  };

  return (
    <Box mt={4} px={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
        flexWrap={"wrap"}
        gap={2}
        mb={2}
      >
        <BaseInput
          inputContainerStyles={{ marginBottom: "0px" }}
          sx={{ width: `35rem` }}
          htmlFor="transactionId"
          type="number"
          placeholder={"يمكنك البحث برقم الطلب"}
          name="transactionId"
          icon="search"
          onChange={setRequestNumber}
        />
        <BaseFilter
          value={requestType}
          data={consultantExaminationRequestsFilter}
          onChange={onChangeRequestType}
        />
      </Stack>
      <BaseTableIndex
        tableHeader={DrillingLicensesRequests}
        injectProps={{ tableId: TABLE_ID.REQUEST }}
        content={getRequestsResponse?.data || []}
        isLoading={getRequestsResponse.isFetching}
        emptyData={emptyTableData}
        withDetails={{
          paramsKey: "id",
          to: (id) => `/examination-requests/${id}`,
        }}
      />
    </Box>
  );
}

export default DrillingLicensesRequest;
