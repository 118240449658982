import { Box } from "@mui/material";

const BaseContainer = (props) => {
  return (
    <Box
      sx={{
        maxWidth: "150rem",
        margin: "auto",
        padding: "5rem",
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

export default BaseContainer;
