import {
  extractCycleItemByKeys,
  filterByValidInjectedDate,
  injectDataToHistoryItems,
  serverKeysEnum,
  sortCycleItemsByKey,
} from ".";

function createSortedCycle(sortedHistoryData) {
  const reAssignDateKey = "technicianAssignedAt";
  const assignDateKey = "labAssignedTime";
  const deAssignDateKey = "labDeAssignedTime";
  const subTestsDateKey = "scoreTime";

  const injectedDateKeyToBeOrderedBy = "_date";

  // DEASSIGN ALL ITEMS
  const deassignmentCycleItems = extractCycleItemByKeys(sortedHistoryData, [
    serverKeysEnum.DE_ASSIGNMENT_HISTORIES,
  ]);

  //////////////////////////// DEASSIGN
  const sortedDeAssignmentForDessignmentWithKeyProp = injectDataToHistoryItems(
    deassignmentCycleItems,
    {
      key: `${serverKeysEnum.DE_ASSIGNMENT_HISTORIES}-deassign`,
      _date: deAssignDateKey,
    }
  );

  //////////////////////////// REASSIGN/ASSIGN
  const reAssignmentCycleItems = extractCycleItemByKeys(sortedHistoryData, [
    serverKeysEnum.ASSIGNMENT_HISTORY_LIST,
  ]);

  const sortedTechnicianAssignmentWithKeyProp = injectDataToHistoryItems(
    reAssignmentCycleItems,
    {
      key: serverKeysEnum.ASSIGNMENT_HISTORY_LIST,
      _date: reAssignDateKey,
    }
  );

  //////////////////////////// LAB RESULTS
  const labResultsCycleItems = extractCycleItemByKeys(sortedHistoryData, [
    serverKeysEnum.INSPECTION_SUB_TEST_HISTORY_LIST,
  ]);

  const sortedLabResultsWithKeyProp = injectDataToHistoryItems(
    labResultsCycleItems,
    {
      key: serverKeysEnum.INSPECTION_SUB_TEST_HISTORY_LIST,
      _date: subTestsDateKey,
    }
  );

  //////////////////////////// TECHNICIAN RESULTS
  const technicianAttachmentsCycleItems = extractCycleItemByKeys(
    sortedHistoryData,
    [serverKeysEnum.TECHNICIAN_ATTACHED_AT]
  );

  const sortedTechnicianAttachmentsWithKeyProp = injectDataToHistoryItems(
    technicianAttachmentsCycleItems,
    {
      key: serverKeysEnum.TECHNICIAN_ATTACHED_AT,
      _date: serverKeysEnum.TECHNICIAN_ATTACHED_AT,
    }
  );

  //////////////////////////// DEASSIGN - FOR ASSIGN
  const sortedDeAssignmentForAssignmentWithKeyProp = injectDataToHistoryItems(
    deassignmentCycleItems,
    {
      key: `${serverKeysEnum.DE_ASSIGNMENT_HISTORIES}-assign`,
      _date: assignDateKey,
    }
  );

  //////////////////////////// COLLECTED AT
  const collectedAtCycleItems = extractCycleItemByKeys(sortedHistoryData, [
    serverKeysEnum.COLLECTED_AT,
  ]);

  const sortedCollectedAtWithKeyProp = injectDataToHistoryItems(
    collectedAtCycleItems,
    {
      key: serverKeysEnum.COLLECTED_AT,
      _date: serverKeysEnum.COLLECTED_AT,
    }
  );

  //////////////////////////// DIST_CENTER_DELIVERED_AT
  const distCenterDeliveredCycleItems = extractCycleItemByKeys(
    sortedHistoryData,
    [serverKeysEnum.DIST_CENTER_DELIVERED_AT]
  );

  const sortedDistCenterDeliveredWithKeyProp = injectDataToHistoryItems(
    distCenterDeliveredCycleItems,
    {
      key: serverKeysEnum.DIST_CENTER_DELIVERED_AT,
      _date: serverKeysEnum.DIST_CENTER_DELIVERED_AT,
    }
  );

  //////////////////////////// DIST_CENTER_RECEIVED_AT
  const distCenterReceivedCycleItems = extractCycleItemByKeys(
    sortedHistoryData,
    [serverKeysEnum.DIST_CENTER_RECEIVED_AT]
  );

  const sortedDistCenterReceivedWithKeyProp = injectDataToHistoryItems(
    distCenterReceivedCycleItems,
    {
      key: serverKeysEnum.DIST_CENTER_RECEIVED_AT,
      _date: serverKeysEnum.DIST_CENTER_RECEIVED_AT,
    }
  );

  //////////////////////////// LABORATORY_DELIVERED_AT
  const laboratoryDeliveredCycleItems = extractCycleItemByKeys(
    sortedHistoryData,
    [serverKeysEnum.LABORATORY_DELIVERED_AT]
  );

  const sortedLaboratoryDeliveredWithKeyProp = injectDataToHistoryItems(
    laboratoryDeliveredCycleItems,
    {
      key: serverKeysEnum.LABORATORY_DELIVERED_AT,
      _date: serverKeysEnum.LABORATORY_DELIVERED_AT,
    }
  );

  //////////////////////////// LABORATORY_RECEIVED_AT
  const laboratoryReceivedCycleItems = extractCycleItemByKeys(
    sortedHistoryData,
    [serverKeysEnum.LABORATORY_RECEIVED_AT]
  );

  const sortedLaboratoryReceivedWithKeyProp = injectDataToHistoryItems(
    laboratoryReceivedCycleItems,
    {
      key: serverKeysEnum.LABORATORY_RECEIVED_AT,
      _date: serverKeysEnum.LABORATORY_RECEIVED_AT,
    }
  );

  const sortedCycleItems = filterByValidInjectedDate(
    sortCycleItemsByKey(
      [
        ...sortedTechnicianAssignmentWithKeyProp,
        ...sortedDeAssignmentForAssignmentWithKeyProp,
        ...sortedDeAssignmentForDessignmentWithKeyProp,
        ...sortedLabResultsWithKeyProp,
        ...sortedTechnicianAttachmentsWithKeyProp,
        ...sortedCollectedAtWithKeyProp,
        ...sortedDistCenterDeliveredWithKeyProp,
        ...sortedDistCenterReceivedWithKeyProp,
        ...sortedLaboratoryDeliveredWithKeyProp,
        ...sortedLaboratoryReceivedWithKeyProp,
      ],
      injectedDateKeyToBeOrderedBy
    )
  );

  return {
    sortedCycleItems,
    reAssignmentCycleItems,
    deassignmentCycleItems,
  };
}

export default createSortedCycle;
