import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  availableDate: null,
  availableAppointmentTime: null,
  raiseNewRequestData: {},
  timeline: {},
};

const contractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    setupTimeline: (state, action) => {
      state.timeline = action.payload.timeline;
    },
    saveRaiseNewRequestData: (state, action) => {
      let raiseNewRequestDataTemp = { ...state.raiseNewRequestData };

      raiseNewRequestDataTemp = Object.assign(
        { ...raiseNewRequestDataTemp },
        action.payload.data
      );

      state.raiseNewRequestData = raiseNewRequestDataTemp;
    },
    resetRaiseNewRequestData: (state, action) => {
      state.raiseNewRequestData = initialState.raiseNewRequestData;
    },
    selectAvailableDate: (state, action) => {
      state.availableDate = action.payload.availableDate;
    },
    selectAvailableApointmentDate: (state, action) => {
      state.availableAppointmentTime = action.payload.availableAppointmentTime;
    },
  },
});

export const contractorActions = contractorSlice.actions;

export default contractorSlice.reducer;
