import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "bukrabold",
  src: `${process.env.PUBLIC_URL}/fonts/bukrabold.ttf`,
  format: "truetype",
});
Font.register({
  family: "bukraregular",
  src: `${process.env.PUBLIC_URL}/fonts/bukraregular.ttf`,
  format: "truetype",
});

export const styles = StyleSheet.create({
  title: {
    fontSize: 13.5,
    color: "#1F766C",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 22,
    marginTop: 50,
  },
  requestDataTitle: {
    marginTop: 50,
    alignSelf: "flex-end",
    fontSize: 10,
    color: "#1F766C",
  },
  requestDataContainer: {
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignSelf: "flex-end",
    flexWrap: "wrap",
    color: "#4A4949",
    fontSize: 9,
    width: "100%",
  },
  requestDataContent: {
    fontFamily: "bukraregular",
    fontSize: 8,
    color: "#4A4949",
    width: "33.3333333333333333334%",
  },
  commonText: {
    textAlign: "right",
    marginTop: 20,
    paddingLeft: 5,
  },
});
