import { Box } from "@mui/material";
import classes from "./PaymentFormPrevButton.module.scss";

const PaymentFormPrevButton = ({ onClick, ...props }) => {
  return (
    <Box {...props}>
      <button className={classes["prev-button"]} onClick={onClick}>
        &rarr;&nbsp;تغيير الباقة
      </button>
    </Box>
  );
};

export default PaymentFormPrevButton;
