import { TestTypes } from "constants/TestTypes";
import {
  useGetInspectionTestsDataForLabQuery,
  useGetSubTestsForLabQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";

const useGetTestsAndSubTests = (inspection) => {
  const isField = inspection.testType === TestTypes.FIELD_TEST;

  const {
    data: testsTemplates,
    isFetching: isTestsFetching,
    isSuccess: isTestsSuccess,
  } = useGetInspectionTestsDataForLabQuery(inspection.id, { skip: isField });

  const testsData = testsTemplates?.[0]?.inspectionTests;

  const hasSubTests = testsData?.find((test) => test.containSubtests);

  const { data: subTests, isFetching: isSubTestsFetching } =
    useGetSubTestsForLabQuery(hasSubTests?.id, {
      skip: isField || !hasSubTests?.id,
    });

  return {
    tests: {
      testsTemplates: testsTemplates || [],
      isTestsFetching,
      isTestsSuccess,
    },
    subTests: { tests: subTests || [], isSubTestsFetching },
    hasSubTests,
  };
};

export default useGetTestsAndSubTests;
