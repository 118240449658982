import { useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import FOHGoogleMap from "components/FOHGoogleMap/FOHGoogleMap";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";

const SoilLocationContent = (props) => {
  const { palette } = useTheme();

  const [mapValue, setMapValue] = useState(null);

  const { onChange, value } = props;

  const onMapValueChange = (value) => {
    setMapValue(value);
  };

  const onSaveMapValue = () => {
    if (onChange) {
      onChange(mapValue);
    }
  };

  return (
    <Box>
      <Typography fontSize={"1.6rem"} mb={2}>
        يرجى تحديد الموقع من خلال الخريطه ليتمكن فني الرقابة من جمع العينات
      </Typography>
      <Box height={"40rem"}>
        <FOHGoogleMap value={value} onChange={onMapValueChange} />
      </Box>
      <BaseCard variant={"secondary"} px={"1rem"} py={"1rem"} mt={3}>
        <Typography
          fontSize={"1.4rem"}
          fontWeight={"500"}
          color={palette.grayDark.color}
        >
          {mapValue?.formattedAddress}
        </Typography>
      </BaseCard>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={5}
      >
        <BaseButton
          onClick={() => {
            onSaveMapValue();
            props.onHide();
          }}
        >
          حفظ
        </BaseButton>
        <BaseButton
          variant="secondary"
          sx={{ minWidth: "12.2rem" }}
          onClick={props.onHide}
        >
          إلغاء
        </BaseButton>
      </Stack>
    </Box>
  );
};

export default SoilLocationContent;
