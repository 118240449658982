import { TestTypes } from "constants/TestTypes";

export const checkIfWalletAvailable = (
  testType,
  fieldCost,
  layers,
  contractorData
) => {
  if (testType === TestTypes.LAB_TEST) {
    const layerCost = (layers || []).reduce((acc, cur) => {
      return acc + (cur?.combinedLabTestCost + cur?.sampleCollectionCost);
    }, 0);

    return contractorData?.wallet >= layerCost;
  } else {
    return contractorData?.wallet >= fieldCost;
  }
};
