import { useLoginMutation } from "modules/Auth/apis/login-api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getLocalStorage,
  removeLocalStorage,
  setUserToken,
} from "services/Storage";
import { authActions } from "store/auth-slice";
import { parseJwt } from "utils/Auth/Auth";
import useCatchUserType from "./useCatchUserType";
import { useNavigate } from "react-router-dom";

const _dummy_user_data = {
  aud: "LABORATORY",
  iss: "fuhusat_auth_service",
  nbf: 1725206480,
  iat: 1725206485,
  exp: 1725210085,
  jti: "5015765c-a780-4810-a28d-0d38f8923735",
  user_role: "LAB_TECHNICIAN",
  user_email: "foh_lab@outlook.com",
  user_active: true,
  user_type: "LABORATORY",
  user_id: 199,
  user_mobile: "0500000032",
  user_name: "Belal - Arab Company lab",
  user_nationalId: "1000000030",
  "entity.crnumber": "1010172258",
  "entity.name": "Arab Company for Laboratories and Soil (ACES)",
  "entity.owner": null,
  "entity.location": "https://goo.gl/maps/A9sXMh4EthR2xUB69",
  "entity.id": 3,
  "entity.mobile": null,
  "entity.email": null,
};

const useLogin = () => {
  const [login, loginResponse] = useLoginMutation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { userRoleFromURL } = useCatchUserType();

  const [customError, setCustomError] = useState(null);

  const loginHandler = (loginData) => {
    login({ realm: `${userRoleFromURL}`, ...loginData })
      .unwrap()
      .then((data) => {
        setCustomError(null);

        const userData = parseJwt(data.accessToken);

        if (userData.user_type.toLowerCase() !== userRoleFromURL) {
          throw new Error("ILLEGAL_LOGIN");
        }
        setUserToken({ data: userData, token: data.accessToken });
        dispatch(
          authActions.login({
            isAuth: true,
            user: { data: userData, token: data.accessToken },
          })
        );
        const backlink = getLocalStorage("backlink");
        if (backlink) {
          navigate(backlink, { replace: true });
        }
      })
      .catch((err) => {
        if (err.message === "ILLEGAL_LOGIN") {
          setCustomError({ show: true, message: err.message });
        }
      });
  };

  useEffect(() => {
    return () => {
      removeLocalStorage("backlink");
    };
  }, []);

  return { loginHandler, loginResponse, customError };
};

export default useLogin;
