import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import AssignedRequests from "../pages/Dashboard/AssignedRequests";
import AssignedRequestsIndex from "../pages/Dashboard/AssignedRequests/AssignedRequestsIndex/AssignedRequestsIndex";
import AssignedRequestsDetails from "../pages/Dashboard/AssignedRequests/AssignedRequestsDetails/Index";
import FieldAssignedRequestForm from "../pages/Dashboard/AssignedRequests/AssignedRequestForm/Field";

const labTechnicianDashboardRoutes = {
  path: "/",
  element: <ProtectedRoute component={<Dashboard />} />,
  children: [
    {
      path: "home",
      element: <ProtectedRoute component={<AssignedRequests />} />,
      children: [{ index: true, element: <AssignedRequestsIndex /> }],
    },
    {
      path: "assigned-requests",
      element: <ProtectedRoute component={<AssignedRequests />} />,
      children: [
        { path: ":id", element: <AssignedRequestsDetails /> },
        { path: ":id/field-form", element: <FieldAssignedRequestForm /> },
      ],
    },
  ],
};

export default labTechnicianDashboardRoutes;
