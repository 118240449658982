import { publicAssetsPath } from "utils/Paths/Paths";

import BaseCard from "core-ui/BaseCard/BaseCard";
import EmptyList from "core-ui/EmpyList/EmptyList";

const BookExaminationEmptyList = (props) => {
  return (
    <BaseCard variant={"secondary"} px={props.px} py={props.py}>
      <EmptyList
        title={props.title}
        desc={props.desc}
        img={publicAssetsPath(`icons/${props.iconName}.svg`)}
      />
    </BaseCard>
  );
};

export default BookExaminationEmptyList;
