import { useGetAsphaltGradationTablesQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import EditRequestLab from "modules/Laboratory/components/DataDetails/EditRequest/EditRequestLab";
import React from "react";

function LabEditRequestDetails({ data , isFetching}) {
  const isAsphalt = data?.inspectionName?.includes("Asphalt");
  const { data: asphaltGradationTables } = useGetAsphaltGradationTablesQuery(
    data?.id,
    {
      skip: !data?.inspectionId || !isAsphalt,
    }
  );
  return (
    <EditRequestLab
      isAsphalt={isAsphalt}
      asphaltGradationTables={asphaltGradationTables}
      data={data}
      isFetching={isFetching}
    />
  );
}

export default LabEditRequestDetails;
