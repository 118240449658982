import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseTable from "core-ui/BaseTable/BaseTable";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import {
  CollectSample,
  FieldExamination,
} from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useGetTechnicianRequestsQuery } from "modules/Technician/apis/inspection-apis/inspection-api";
import { Helmet } from "react-helmet";
import { TABLE_ID, TABLE_LIMIT_PAGE_SIZE } from "constants/Table";
import { TestTypes } from "constants/TestTypes";
import TableTitle from "components/Dashboard/TableTitle";
import { useTheme } from "@emotion/react";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const DashboardIndex = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const { palette } = useTheme();

  const { data: technicianLabRequestsData, isFetching } =
    useGetTechnicianRequestsQuery({
      page: 0,
      pageSize: TABLE_LIMIT_PAGE_SIZE,
      testType: TestTypes.LAB_TEST,
    });

  const {
    data: technicianFieldRequestsData,
    isFetching: isTechnicianFieldRequestFetching,
  } = useGetTechnicianRequestsQuery({
    page: 0,
    pageSize: TABLE_LIMIT_PAGE_SIZE,
    testType: TestTypes.FIELD_TEST,
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("home page")}
        description={t("تستعرض هذه الصفحة أحدث الطلبات")}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("Sample collection requests")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={`/collect-samples-requests`}
        />
        <BaseTableIndex
          tableHeader={CollectSample}
          content={technicianLabRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.TECH_REQUESTS }}
          totalCount={20}
          emptyData={{
            title: t("NoNewRequest"),
            // desc: t("No New Request Desc"),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/collect-samples-requests/${id}`,
          }}
        />
      </BaseCard>

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("Field Examination Requests")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={"/field-examination-requests"}
        />
        <BaseTableIndex
          tableHeader={FieldExamination}
          content={technicianFieldRequestsData?.content || []}
          isLoading={isTechnicianFieldRequestFetching}
          injectProps={{
            tableId: TABLE_ID.TECH_REQUESTS,
            testType: TestTypes.FIELD_TEST,
          }}
          totalCount={20}
          emptyData={{
            title: t("NoNewRequest"),
            // desc: t("No New Request Desc"),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/field-examination-requests/${id}`,
          }}
        />
      </BaseCard>
    </>
  );
};

export default DashboardIndex;
