import { Box, Tab, Tabs } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Helmet } from "react-helmet";
import {
  drillingLicensesdetails,
  AdminRequestsDetailsBreadcrumb,
} from "services/StaticLookup/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { renderTabs } from "utils/Tabs/Tabs";
import { AdminExaminationRequestTabs } from "services/StaticLookup/Tabs";
import { useGetHistoryLogsQuery } from "modules/Technician/apis/inspection-apis/inspection-api";
import {
  useGetAppointmentReportQuery,
  useGetAttachmentForLaboratoryQuery,
  useGetTechnicianDataQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { TestTypes } from "constants/TestTypes";
import AttachmentsDataField from "../../../../components/DataDetails/AttachmentsData/Field/AttachmentsDataField";
import AttachmentsDataLab from "../../../../components/DataDetails/AttachmentsData/Lab/AttachmentsDataLab";
import RequestData from "modules/Admin/components/DataDetails/RequestData";
import { DATA_ID } from "constants/Details";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import { fromPage } from "constants/FromPages";
import { useLazyGetLicenseDetailsQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import HistoryLogger from "modules/Admin/components/DataDetails/HistoryLogs/HistoryLogger";
import {
  useGetTestsForAdminQuery,
  useGetSubTestsForAdminQuery,
  useGetInspectionForAdminQuery,
} from "modules/Admin/apis/inspection-apis/inspection-api";
import { useGetAppointmentsForAdminQuery } from "modules/Contractor/apis/appointments-apis/appointments-apis";

const ExaminationRequestDetails = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);
  const location = useLocation();
  const state = location.state;
  const selectedLicenseData = state?.selectedLicenseData;
  const fromIndex = state?.fromPage === fromPage.ADMIN_REQUESTS;

  const params = useParams();

  const handleChange = (event, newValue) => {
    setTabsActiveIndex(newValue);
  };

  const { data: inspection, isFetching } = useGetInspectionForAdminQuery(
    params.id
  );

  const isLab = inspection?.testType === TestTypes.LAB_TEST;

  const { data: inspectionTestsData } = useGetTestsForAdminQuery(
    inspection?.id,
    {
      skip: !inspection?.id,
    }
  );

  const hasSubTestsTest = inspectionTestsData?.inspectionTests?.find(
    (test) => test?.containSubtests
  );

  const isCancelled = inspection?.status === "CANCELED";

  const { data: subTestsForAdmin, isFetching: isSubTestsFetching } =
    useGetSubTestsForAdminQuery(hasSubTestsTest?.id, {
      skip: !isLab || !hasSubTestsTest?.id,
    });

  const { data: appointmentData } = useGetAppointmentsForAdminQuery(
    inspection?.id,
    {
      skip: !inspection?.id,
    }
  );
  const { data: appointmentReportData } = useGetAppointmentReportQuery(
    appointmentData?.id,
    { skip: isCancelled || !appointmentData?.id }
  );

  const [getLicenseDetails, getLicenseDetailsResponse] =
    useLazyGetLicenseDetailsQuery();

  useEffect(() => {
    if (fromIndex && inspection) {
      getLicenseDetails(inspection?.licenses?.[0]?.id);
    }
  }, [inspection]);

  const { data: technicianData } = useGetTechnicianDataQuery(inspection?.id, {
    skip: !inspection?.id,
  });

  const { data: historyLog } = useGetHistoryLogsQuery(inspection?.id, {
    skip: !inspection?.id,
  });

  const { data: attachment, isFetching: isAttachmentFetching } =
    useGetAttachmentForLaboratoryQuery(inspection?.id, {
      skip:
        isCancelled ||
        !inspection?.id ||
        !inspection?.isLabResultAttachmentSubmitted,
    });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {fromIndex
            ? `طلبات الفحص - الطلب رقم ${inspection?.id}`
            : `رخص الحفر - رقم الرخصة ${inspection?.licenseNumber}`}
        </title>
        <link
          rel="canonical"
          href={fromIndex ? "/requests" : "/drilling-licenses"}
        />
      </Helmet>

      {inspection && (
        <DashboardIntro
          title={`${t("order number", { ns: "dashboard" })} ${
            inspection?.id || ""
          }`}
          description={t("examination request page description", {
            ns: "dashboard",
          })}
          breadcrumbData={
            fromIndex
              ? AdminRequestsDetailsBreadcrumb({ id: inspection?.id })
              : drillingLicensesdetails({
                  licenseNumber: inspection?.licenseNumber,
                  licenseYear: inspection?.licenseYear,
                })
          }
        />
      )}
      <BaseCard mt={6} py={"1rem"} px={"1rem"}>
        {isFetching ? (
          <BaseLoader />
        ) : (
          <>
            <Tabs
              value={tabsActiveIndex}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              className="tabs"
            >
              {renderTabs(AdminExaminationRequestTabs).map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  sx={{
                    background:
                      tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                    fontSize: "1.6rem",
                  }}
                />
              ))}
            </Tabs>
            {tabsActiveIndex === 1 && (
              <RequestData
                inspection={inspection}
                isFetching={isFetching}
                dataId={DATA_ID.CON_REQUESTS}
                technicianData={technicianData}
                contractorCompanyInfo={
                  fromIndex
                    ? getLicenseDetailsResponse?.data
                    : selectedLicenseData
                }
                isLab={isLab}
                inspectionTestsData={inspectionTestsData}
                appointmentData={appointmentData}
                subTests={subTestsForAdmin?.inspectionSubTests}
                isSubTestsFetching={isSubTestsFetching}
                appointmentReportData={appointmentReportData}
              />
            )}
            {tabsActiveIndex === 2 && (
              <Box>
                {isLab ? (
                  <AttachmentsDataLab
                    inspection={inspection}
                    attachment={attachment}
                  />
                ) : (
                  <AttachmentsDataField
                    inspection={inspection}
                    isFetching={isFetching}
                    inspectionTestsData={inspectionTestsData}
                    appointmentData={appointmentData}
                    attachment={attachment}
                    appointmentReportData={appointmentReportData}
                  />
                )}
              </Box>
            )}
            {tabsActiveIndex === 3 && (
              <Box mx={3}>
                <BaseCardTitle mt={6}>التاريخ الزمني</BaseCardTitle>

                <HistoryLogger
                  data={historyLog}
                  historyType={inspection?.testType}
                  inspection={inspection}
                />
              </Box>
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default ExaminationRequestDetails;
