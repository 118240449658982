import BaseCard from "core-ui/BaseCard/BaseCard";
import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { Controller, useForm } from "react-hook-form";
import {
  useLazyGetCitiesByRegionsQuery,
  useGetRegionsQuery,
  useUpdateAddressMutation,
} from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useTranslation } from "react-i18next";
import BaseButton from "core-ui/BaseButton/BaseButton";

function SettingsForm(props) {
  const { t } = useTranslation(["dashboard"]);

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({ mode: "onChange" });

  const formData = watch();

  const { data: regions } = useGetRegionsQuery();
  const [getCities, getCitiesResponse] = useLazyGetCitiesByRegionsQuery();
  const [updateAddress, updateAddressResonse] = useUpdateAddressMutation();

  const isSavingData = updateAddressResonse.isLoading;

  // prettier-ignore
  const getValueOf = (key) => dirtyFields[key] ? formData[key] : props?.companyData?.[key];

  useEffect(() => {
    if (formData.regionId?.id) {
      getCities(formData.regionId.id);
      resetField("cityId");
    }
  }, [formData.regionId]);

  useEffect(() => {
    if (regions?.length > 0 && props?.companyData?.state) {
      getCities(
        regions?.find((region) => region?.name === props?.companyData?.state)
          ?.id
      );
      setValue(
        "regionId",
        regions?.find((region) => region?.name === props?.companyData?.state)
      );
    }
  }, [props?.companyData?.state, regions]);

  useEffect(() => {
    if (getCitiesResponse?.data?.length > 0 && props?.companyData?.city) {
      setValue(
        "cityId",
        getCitiesResponse?.data?.find(
          (city) => city?.name === props?.companyData?.city
        )
      );
    }
  }, [getCitiesResponse]);

  const onSubmitHandler = (data, event) => {
    event.preventDefault();
    const idealData = {
      district: data.district,
      cityId: data.cityId.id,
      regionId: data.regionId.id,
      postCode: data.postCode,
      street: data.street,
      buildingNo: data.buildingNo,
      vatNumber: data.vatNumber,
    };
    updateAddress(idealData)
      .unwrap()
      .then((responseData) => {
        props.showAlertHandler({
          show: true,
          message: SuccessCodes.PROFILE_UPDATED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
        props.setIsEditMode(false);
      })
      .catch((err) => {
        props.showAlertHandler({
          show: true,
          message:
            err.data?.errorCode || ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
      });
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  return (
    <>
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <BaseCard
          py={"1.9rem"}
          px={"2.5rem"}
          sx={{
            position: "relative",
            height: "100%",
            paddingBottom: "0rem",
          }}
        >
          <Box className={"request-data-details"}>
            <Box className={"request-data-details__wrapper"}>
              <Typography className={"request-data-details__title"}>
                بيانات المستخدم
              </Typography>
              <WrapperContentGrid
                sx={{ marginTop: 4 }}
                columnsWidth={24}
                gap={4}
              >
                <Box className={"request-data-details__item"}>
                  <BaseInput
                    disabled={true}
                    value={props?.userInfo?.name}
                    htmlFor="name"
                    name="name"
                    label={"الاسم"}
                  />
                </Box>
                <Box className={"request-data-details__item"}>
                  <BaseInput
                    disabled={true}
                    value={props?.userInfo?.nationalId}
                    htmlFor="nationalId"
                    name="nationalId"
                    label={"رقم الهوية/الإقامة"}
                  />
                </Box>
                <Box className={"request-data-details__item"}>
                  <BaseInput
                    disabled={true}
                    value={props?.userInfo?.email}
                    htmlFor="email"
                    name="email"
                    label={"البريد الإلكتروني"}
                  />
                </Box>
              </WrapperContentGrid>
            </Box>
          </Box>
        </BaseCard>
        <BaseCard
          mt={3}
          py={"1.9rem"}
          px={"2.5rem"}
          sx={{
            position: "relative",
            height: "100%",
          }}
        >
          <Box className={"request-data-details"}>
            <Box className={"request-data-details__wrapper"}>
              <Typography className={"request-data-details__title"}>
                بيانات شركة المقاولات
              </Typography>
              <WrapperContentGrid
                sx={{ marginTop: 4 }}
                columnsWidth={24}
                gap={4}
              >
                <Box className={"request-data-details__item"}>
                  <BaseInput
                    disabled={true}
                    value={props?.companyData?.name}
                    htmlFor="companyName"
                    name="companyName"
                    label={"اسم الشركة"}
                  />
                </Box>
                <Box className={"request-data-details__item"}>
                  <BaseInput
                    disabled={true}
                    value={props?.companyData?.crNumber}
                    htmlFor="crNumber"
                    name="crNumber"
                    label="الرقم الموحد للمنشأة"
                  />
                </Box>
                <Box className={"request-data-details__item"}>
                  <Controller
                    control={control}
                    name="vatNumber"
                    rules={{
                      required: {
                        value: true,
                        message: `${t("Field")} ${t("required")}`,
                      },
                      maxLength: {
                        value: 15,
                        message: ` يجب أن يتكون الرقم الضريبي من ١٥ رقم`,
                      },
                      minLength: {
                        value: 15,
                        message: ` يجب أن يتكون الرقم الضريبي من ١٥ رقم`,
                      },
                      pattern: {
                        value: /^(3)\d{13}(3)$/,
                        message: ` يجب أن يكون الرقم الضريبي صحيح`,
                      },
                    }}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <BaseInput
                          htmlFor="vatNumber"
                          label={`الرقم الضريبي *`}
                          value={getValueOf("vatNumber")}
                          onChange={onChange}
                          placeholder={"يرجى إدخال الرقم الضريبي"}
                          labelStyles={{
                            fontWeight: "bold",
                            color: "grayDark.color",
                          }}
                          name="vatNumber"
                          type={"number"}
                          register={{
                            ...register("vatNumber", {
                              required: {
                                value: true,
                                message: `${t("Field")} ${t("required")}`,
                              },
                              maxLength: {
                                value: 15,
                                message: ` يجب أن يتكون الرقم الضريبي من ١٥ رقم`,
                              },
                              minLength: {
                                value: 15,
                                message: ` يجب أن يتكون الرقم الضريبي من ١٥ رقم`,
                              },
                              pattern: {
                                value: /^(3)\d{13}(3)$/,
                                message: ` يجب أن يكون الرقم الضريبي صحيح`,
                              },
                            }),
                          }}
                          errors={errors}
                        />
                      );
                    }}
                  />
                </Box>
              </WrapperContentGrid>
            </Box>
            <hr />
            <Box className={"request-data-details__wrapper"}>
              <Typography className={"request-data-details__title"}>
                العنوان
              </Typography>
              <WrapperContentGrid
                sx={{ marginTop: 4, rowGap: "2rem !important" }}
                columnsWidth={24}
                gap={4}
              >
                <Box className={"request-data-details__item"}>
                  <BaseInput
                    disabled={true}
                    value={"الممكلة العربية السعودية"}
                    htmlFor="country"
                    name="country"
                    label={"الدولة"}
                  />
                </Box>

                <BaseDropdown
                  control={control}
                  name="regionId"
                  required
                  errors={errors}
                  label={`${t("region")} *`}
                  labelStyles={{
                    fontWeight: "bold",
                    color: "grayDark.color",
                  }}
                  onChange={(newValue) => {
                    getCities(newValue.id);
                  }}
                  placeholder={t("selectRegion")}
                  data={regions}
                  selectMenuStyles={{
                    height: "12rem !important",
                    border: "1px solid gray",
                    color: "black",
                  }}
                />

                <BaseDropdown
                  isLoading={getCitiesResponse.isFetching}
                  control={control}
                  name="cityId"
                  required
                  errors={errors}
                  label={`${t("city")} *`}
                  labelStyles={{
                    fontWeight: "bold",
                    color: "grayDark.color",
                  }}
                  placeholder={t("selectCity")}
                  data={getCitiesResponse.data || []}
                  selectMenuStyles={{ height: "12rem !important" }}
                />

                <Controller
                  control={control}
                  name="district"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("Field")} ${t("required")}`,
                    },
                  }}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <BaseInput
                        htmlFor="district"
                        label={`${t("district")} *`}
                        placeholder={t("selectDistrict")}
                        maxLength={30}
                        value={getValueOf("district")}
                        onChange={onChange}
                        labelStyles={{
                          fontWeight: "bold",
                          color: "grayDark.color",
                        }}
                        name="district"
                        register={{
                          ...register("district", {
                            required: {
                              value: true,
                              message: `${t("Field")} ${t("required")}`,
                            },
                          }),
                        }}
                        errors={errors}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="street"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("Field")} ${t("required")}`,
                    },
                  }}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <BaseInput
                        htmlFor="street"
                        label={"اسم الشارع *"}
                        placeholder={t("selectStreet")}
                        maxLength={50}
                        value={getValueOf("street")}
                        onChange={onChange}
                        labelStyles={{
                          fontWeight: "bold",
                          color: "grayDark.color",
                        }}
                        name="street"
                        register={{
                          ...register("street", {
                            required: {
                              value: true,
                              message: `${t("Field")} ${t("required")}`,
                            },
                          }),
                        }}
                        errors={errors}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="buildingNo"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("Field")} ${t("required")}`,
                    },
                  }}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <BaseInput
                        htmlFor="buildingNo"
                        label={`${t("buildingNo")} *`}
                        placeholder={t("selectBuildingNo")}
                        labelStyles={{
                          fontWeight: "bold",
                          color: "grayDark.color",
                        }}
                        name="buildingNo"
                        maxLength={4}
                        value={getValueOf("buildingNo")}
                        onChange={onChange}
                        type={"number"}
                        register={{
                          ...register("buildingNo", {
                            required: {
                              value: true,
                              message: `${t("Field")} ${t("required")}`,
                            },
                            maxLength: {
                              value: 4,
                              message: ` يجب أن يتكون رقم المبنى من ٤ أرقام`,
                            },
                            minLength: {
                              value: 4,
                              message: ` يجب أن يتكون رقم المبنى من ٤ أرقام`,
                            },
                          }),
                        }}
                        errors={errors}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="postCode"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("Field")} ${t("required")}`,
                    },
                    maxLength: {
                      value: 5,
                      message: ` يجب أن يتكون الرمز البريدي من ٥ أرقام`,
                    },
                    minLength: {
                      value: 5,
                      message: ` يجب أن يتكون الرمز البريدي من ٥ أرقام`,
                    },
                  }}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <BaseInput
                        htmlFor="postCode"
                        label={`${t("postCode")} *`}
                        placeholder={t("selectPostCode")}
                        labelStyles={{
                          fontWeight: "bold",
                          color: "grayDark.color",
                        }}
                        name="postCode"
                        value={getValueOf("postCode")}
                        onChange={onChange}
                        type={"number"}
                        register={{
                          ...register("postCode", {
                            required: {
                              value: true,
                              message: `${t("Field")} ${t("required")}`,
                            },
                            maxLength: {
                              value: 5,
                              message: ` يجب أن يتكون الرمز البريدي من ٥ أرقام`,
                            },
                            minLength: {
                              value: 5,
                              message: ` يجب أن يتكون الرمز البريدي من ٥ أرقام`,
                            },
                          }),
                        }}
                        errors={errors}
                      />
                    );
                  }}
                />
              </WrapperContentGrid>
            </Box>
          </Box>
        </BaseCard>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            gap: 2,
            marginTop: "2rem",
          }}
        >
          <BaseButton
            sx={{
              minWidth: "12.2rem",
              mr: 1,
            }}
            disabled={props.hideDisabled || isSavingData}
            variant={"secondary"}
            onClick={() => props.setIsEditMode(false)}
          >
            {t("إلغاء")}
          </BaseButton>
          <BaseButton
            sx={{ minWidth: "12.2rem" }}
            type={"submit"}
            isLoading={isSavingData}
          >
            {t("حفظ")}
          </BaseButton>
        </Box>
      </form>
    </>
  );
}

export default SettingsForm;
