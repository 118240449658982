import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, Grid } from "@mui/material";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { Controller, useForm } from "react-hook-form";
import BaseCalendar from "core-ui/BaseCalendar/BaseCalendar";
import BaseTimePicker from "core-ui/BaseTimePicker/BaseTimePicker";
import { baseCalendarDateFormatter } from "utils/Date/Date";
import { formattedNumber } from "utils/Numbers/Numbers";
import { useTheme } from "@emotion/react";
import { calculateEndTime } from "utils/BookAppointment/BookAppointment";
import ResubmitNewAppointment from "modules/Contractor/components/DataDetails/ResubmitNewAppointment/ResubmitNewAppointment";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { ScheduleAppointment } from "services/ScheduleAppointment";
import { _void } from "utils/Objects/Objects";

function RescheduleModal({
  show,
  closeModal,
  t,
  confirmReschedule,
  id,
  totalPoints,
  isField,
  isLab,
  isLoading,
}) {
  const { handleSubmit, control, reset, watch } = useForm({ mode: "onChange" });
  const formData = watch();

  const { palette, breakpoints } = useTheme();
  const sm = useMediaQuery(breakpoints.down("sm"));

  const [disableTime, setDisableTime] = useState(true);

  const scheduleAppointment = new ScheduleAppointment(formData?.date, {
    isLab,
    isField,
  });

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const onLeaveHandler = () => {
    closeModal();
  };

  const onSubmitHandler = (formData) => {
    const scheduledTime = `${
      formData.time.$H < 10 ? `0${formData.time.$H}` : formData.time.$H
    }:${formData.time.$m < 10 ? `0${formData.time.$m}` : formData.time.$m}`;

    const scheduledDate = baseCalendarDateFormatter(formData.date);

    const { endTimeHours, endTimeMinutes } = calculateEndTime(
      formData.date,
      `${formData.time.$H}:${formData.time.$m}:00`
    );

    const appointmentData = {
      scheduledTime,
      scheduledDate,
      endTime: `${endTimeHours}:${endTimeMinutes}`,
    };

    confirmReschedule(appointmentData);
  };

  useEffect(() => {
    if (!show) {
      reset();
      setDisableTime(true);
    }
  }, [show]);

  return (
    <BaseModal
      closeIcon
      show={show}
      title={`إعادة رفع الطلب رقم ${id}`}
      modalContentWrapperSx={{ paddingTop: "2rem" }}
      onHide={isLoading ? _void : onLeaveHandler}
      mainContainerSx={{ width: "75rem", overflow: "visible" }}
    >
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <Box px={sm ? "3rem" : "6rem"} mb={1}>
          <Typography
            sx={{
              mt: sm ? 0 : 4,
              fontSize: sm ? "1.5rem" : "1.6rem",
              color: "grayDark.color",
              mb: 3,
            }}
          >
            لإعادة رفع هذا الطلب سوف يتم خصم{" "}
            <span
              style={{
                fontSize: "1.8rem",
                fontWeight: "bold",
                color: palette.primary.colorSoft10,
              }}
            >
              {formattedNumber(totalPoints)}
            </span>
            <span style={{ fontWeight: "bold", marginRight: "0.8rem" }}>
              نقطة
            </span>
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontSize: sm ? "1.5rem" : "1.6rem",
              color: "grayDark.color",
              mb: 1.5,
            }}
          >
            يرجى تحديد موعد الفحص مع العلم بأنه{" "}
            <span style={{ fontWeight: "bold" }}>
              يجب تحديد موعد الاختبار الحقلي قبل 24 ساعة من الموعد، ونخلي
              مسؤوليتنا في حال عدم قبول المختبر للطلب.
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: sm ? "center" : "space-between",
            alignItems: "flex-start",
            gap: sm ? "1rem" : "4rem",
            width: "100%",
            px: sm ? "3rem" : "6rem",
            mt: "5rem",
            flexWrap: sm ? "wrap" : "",
          }}
        >
          <Controller
            control={control}
            name="date"
            rules={{
              required: "فضلا حدد تاريخاً",
            }}
            render={({
              field: { onChange, name, value },
              formState: { errors },
            }) => {
              let formattedValue = baseCalendarDateFormatter(
                value,
                "dddd, YYYY/MM/DD"
              );
              if (value) {
                setDisableTime(false);
              }
              return (
                <>
                  <BaseCalendar
                    disableWeekends={true}
                    hijri={false}
                    placeholder={formattedValue || "حدد تاريخ الفحص"}
                    name={name}
                    label="التاريخ"
                    containerSx={{ width: sm ? "100%" : "24rem" }}
                    sx={{ width: sm ? "100%" : "24rem" }}
                    value={value || ""}
                    minDate={scheduleAppointment.minDateCalculation()}
                    errors={errors}
                    format={"dddd, YYYY/MM/DD"}
                    onChange={onChange}
                  />
                </>
              );
            }}
          />
          <Controller
            control={control}
            name="time"
            rules={{
              required: {
                value: true,
                message: "فضلا حدد وقتاً",
              },
            }}
            render={({
              field: { onChange, name, value },
              formState: { errors },
            }) => {
              return (
                <>
                  <BaseTimePicker
                    label={"الوقت"}
                    placeholder={"تحديد وقت الفحص"}
                    minTime={scheduleAppointment.minTimeCalculation()}
                    maxTime={scheduleAppointment.maxTimeCalculation()}
                    containerStyles={{ width: sm ? "100%" : "24rem" }}
                    onChange={onChange}
                    format={"hh:mm A"}
                    value={value || ""}
                    errors={errors}
                    name={name}
                    disabled={disableTime}
                  />
                </>
              );
            }}
          />
        </Box>
        {isField && (
          <Box
            sx={{
              // alignItems: "flex-start",
              // gap: sm ? "1rem" : "4rem",
              width: "100%",
              px: sm ? "3rem" : "6rem",
              mb: "3rem",
              // flexWrap: sm ? "wrap" : "",
            }}
          >
            <Typography
              sx={{
                color: "grayDark.color",
                fontSize: "1.6rem ",
                fontWeight: "500",
                mb: "2rem",
              }}
            >
              تفاصيل الموعد
            </Typography>
            <Grid item>
              <BaseCard
                variant="secondary"
                py={"0rem"}
                px={"1rem"}
                minHeight="4rem"
                borderRadius=".9rem"
                alignItems="flex-start"
              >
                <ResubmitNewAppointment data={formData} />
              </BaseCard>
            </Grid>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: sm ? "center" : "flex-end",
            gap: sm ? "1rem" : "",
            alignContent: "flex-end",
            flexWrap: "wrap",
            px: sm ? "3rem" : "6rem",
            mb: 3,
          }}
        >
          <BaseButton
            sx={{
              minWidth: "12.2rem",
              mr: 1,
              color: "text.tags.blueSoft",
            }}
            variant="transparent"
            onClick={onLeaveHandler}
            isLoading={isLoading}
          >
            إلغاء
          </BaseButton>
          <BaseButton
            sx={{ minWidth: "12.2rem", display: "flex", gap: 2 }}
            variant="secondary"
            type="submit"
            isLoading={isLoading}
          >
            تأكيد
          </BaseButton>
        </Box>
      </form>
    </BaseModal>
  );
}

export default RescheduleModal;
