import { Box, Typography } from "@mui/material";
import classes from "./BaseTypeTag.module.scss";
import { useTheme } from "@emotion/react";
import { alpha } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";

const BaseTypeTag = ({ type }) => {
  let lowerCaseType = type?.toString()?.toLowerCase() || "";

  if (lowerCaseType === "عادية" || lowerCaseType === "عادي") {
    lowerCaseType = "1";
  }

  const { palette } = useTheme();
  const stylesVariantions = {
    ...(lowerCaseType === "normal" ||
      (lowerCaseType === "1" && {
        backgroundColor: alpha(palette.background.tags.blue, 0.5),
      })),
    ...(lowerCaseType === "urgent" ||
      (lowerCaseType === "2" && {
        backgroundColor: alpha(palette.background.tags.orangeSoft, 0.5),
      })),
    ...(lowerCaseType === "lab" && {
      backgroundColor: alpha(palette.background.tags.green, 0.5),
    }),
    ...(lowerCaseType === "field" && {
      backgroundColor: alpha(palette.background.tags.orange, 0.5),
    }),
  };
  const renderLabel = () => {
    let label = "لا يوجد";

    switch (lowerCaseType) {
      case "normal":
      case "1":
        label = "عادية";
        break;
      case "urgent":
      case "2":
        label = "طارئة";
        break;
      case "lab":
        label = "معملي";
        break;
      case "field":
        label = "حقلي";
        break;
      default:
        label = defaultEmptyValue;
        break;
    }

    return label;
  };
  return (
    <Box
      sx={{ ...stylesVariantions }}
      className={`${classes["license-type"]} ${
        classes[`license-type--${lowerCaseType}`]
      }`}
    >
      <Typography className={classes["license-type__text"]}>
        {renderLabel()}
      </Typography>
    </Box>
  );
};

export default BaseTypeTag;
