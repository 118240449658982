/**
 *
 * @param {Array<any>} data - The original array that will extract chunks from it
 * @param {number} chunk_size - the number of the first chunk length to be extracted
 * @param {number} maxPerPage - The number of the length of rest of chunks
 * @returns {Array<Array<any>>} - It returns an array of arrays(chunks)
 */
export function createChunk(data, chunk_size, maxPerPage) {
  let chunks = [];

  if (maxPerPage <= 0) {
    chunks.push(data.slice(chunks.flat().length, chunk_size || 1));
  }

  for (let i = 0; i < data.length && maxPerPage > 0; i++) {
    let flattedChunks = chunks.flat();
    let end = i === 0 ? chunk_size || 1 : flattedChunks.length + maxPerPage;
    if (flattedChunks.length >= data.length) break;

    chunks.push(data.slice(flattedChunks.length, end));
  }

  return chunks;
}
