export function hasLayersChanged(prevLayers, newLayers) {
  if (prevLayers.length !== newLayers.length) return true;
  for (let i = 0; i < newLayers.length; i++) {
    if (
      prevLayers[i].id !== newLayers[i].id ||
      prevLayers[i].name !== newLayers[i].name
    ) {
      return true;
    }
  }
  return false;
}
