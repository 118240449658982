import { Box, FormHelperText, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import baseInputClasses from "../BaseInput/BaseInput.module.scss";

const BaseTextArea = (props) => {
  const inputHasError = props.errors ? props.errors?.[props.name] : false;

  const inputDisabled = props.disabled;

  const dynamicInputWidth = props?.sx?.width
    ? `min(100%, ${props.sx.width})`
    : "100%";

  const defaultValidationRules = {
    required: {
      value: true,
      message: `الحقل مطلوب`,
    },
  };

  return (
    <Box
      mb={5}
      sx={{ ...props.inputContainerStyles, width: dynamicInputWidth }}
    >
      {props.label && (
        <InputLabel
          sx={{
            ...props.labelStyles,
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: 500,
            marginBottom: 2,
          }}
          htmlFor={props.htmlFor}
        >
          {props.label}
        </InputLabel>
      )}
      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules || defaultValidationRules}
        render={({ field: { onChange, value = "", name } }) => {
          return (
            <textarea
              className={`
              ${baseInputClasses["base-input"]} 
              ${
                baseInputClasses[
                  `base-input--${inputHasError ? "invalid" : undefined}`
                ]
              }
              ${
                baseInputClasses[
                  `base-input--${inputDisabled ? "disabled" : undefined}`
                ]
              }
            `}
              style={{
                fontFamily: "times new roman",
                fontSize: "1.5rem",
                width: "100%",
                height: "15rem",
                padding: "1rem",
                resize: "none",
                borderRadius: "5px",
                ...props.textAreaStyles,
              }}
              name={name}
              id={name}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              maxLength={props.maxLength}
              placeholder={props.placeholder || props.name}
              disabled={inputDisabled}
            />
          );
        }}
      />
      {inputHasError && (
        <Box>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {inputHasError.message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
};

export default BaseTextArea;
