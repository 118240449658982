import { Box, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { nonAssignedRequestTestsFeildWithNewStations } from "services/StaticLookup/TableHeader/TableHeader";
import classes from "../NewRequestsDetails.module.scss";
import { DATA_ID } from "constants/Details";

import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import FieldNewRequestData from "modules/Laboratory/components/DataDetails/NewRequestData/Field/FieldNewRequestData";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";

const FieldNewRequestsDetails = (props) => {
  const inspectionLayer = props.inspection?.inspectionLayersList[0];

  const { stations } = useGetStationsWithTestData(props.inspection);

  return (
    <BaseCard mt={6} py={"5rem"} px={"7rem"}>
      <FieldNewRequestData
        inspection={props.inspection}
        inspectionLayer={inspectionLayer}
        dataId={DATA_ID.LAB_NO_ASSIGN_REQUEST_DETAILS}
      />
      <Box sx={{ mt: 5 }}>
        <Box>
          <Typography className={classes["new-requests-details__title"]}>
            الاختبارات
          </Typography>
        </Box>
        <BaseTableCard
          tableHeader={nonAssignedRequestTestsFeildWithNewStations}
          content={stations?.filter((s) => !s.isVirtual)}
        />
      </Box>
    </BaseCard>
  );
};

export default FieldNewRequestsDetails;
