import { constractorAuthRoutes } from "modules/Contractor/routes/AuthRoutes";
import { consultantAuthRoutes } from "modules/Consultant/routes/AuthRoutes";

import publicAuthRoutes from "modules/Public/routes/AuthRoutes";
import { combineRoutesAsChildren } from "services/Routes";

const combinedAuthRoutes = combineRoutesAsChildren(publicAuthRoutes, [
  constractorAuthRoutes,
  consultantAuthRoutes,
]);

const allAuthRoutes = combinedAuthRoutes;

export default allAuthRoutes;
