import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";

const ReassignTechnicianModal = (props) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <>
      <Box sx={{ mb: props?.isAvailable === false ? 5 :14.5 }}>
        <BaseDropdown
          name="technicianName"
          control={props.control}
          required
          selectMenuStyles={{ height: "16rem !important" }}
          errors={props.errors}
          label={"فني الرقابة"}
          labelStyles={{
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: 500,
          }}
          placeholder="حدد فني الرقابة"
          data={props.techniciansData}
          onChange={props.checkTechnicianAvailability}
        />
      </Box>
      {props?.isAvailable === false && (
        <Typography sx={{ color: "grayDark.color1", fontSize: "1.4rem" }} mb={5}>
          يرجى العلم أن موعد فحص هذا الطلب يتعارض مع أحد الطلبات المسندة مسبقًا
          الى هذا الفني، هل أنت متأكد من قيامك بذلك؟
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
          gap: "2rem",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "11.2rem",
          }}
          variant={"secondary"}
          onClick={props.onHide}
          disabled={props.isLoading}
        >
          {t("إلغاء")}
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "11.2rem" }}
          type="submit"
          isLoading={props.isLoading}
        >
          {t("تأكيد")}
        </BaseButton>
      </Box>
    </>
  );
};

export default ReassignTechnicianModal;
