import React from "react";
import { Box, Typography } from "@mui/material";
import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import {
  licenseTestsField,
  licenseTestsFieldUAT,
} from "services/StaticLookup/TableHeader/TableHeader";
import Layouts from "services/Layouts";
import { bottomBorderedTableTd } from "constants/Table";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";

const FieldAddCheckupTests = (props) => {
  if (!props.raiseNewRequestData?.testsTemplates) return <BaseLoader />;

  const testsTemplates = props.raiseNewRequestData?.testsTemplates;

  const fieldTableDescription =
    "تم تحديد الاختبارات التالية بناء على نوع اختبار مواد الأعمال المدنية و نوع مسار الفحص المحدد في الخطوة السابقة";

  const mainFieldTableContent = testsTemplates?.map((test) => ({
    category: test.category,
    ...test.subTestTemplateDTOList[0],
  }));

  return (
    <Box>
      <BaseAlert
        show={props.showAlert}
        my="2.3rem"
        icon={false}
        type={AlertTypes.INFO}
      >
        الاختبارات الحقلية تتطلب حجز موعد مع المختبر عند جاهزية الموقع
      </BaseAlert>
      {props.label && (
        <Typography sx={{ fontSize: "1.6rem" }} color={"grayDark.color1"}>
          {props.label}
        </Typography>
      )}
      <BaseAlert show={true} type={AlertTypes.INFO}>
        <Typography
          sx={{
            fontSize: "inherit !important",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          عزيزي المقاول,
        </Typography>
        نحيطكم علماً بأن تكلفة الاختبار تشمل خمس نقاط فحص
      </BaseAlert>

      <Box mt={4}>
        <BaseTableCard
          title={"الاختبارات"}
          description={fieldTableDescription}
          tableHeader={
            Layouts.allowAsphaltReport
              ? licenseTestsField
              : licenseTestsFieldUAT
          }
          content={mainFieldTableContent || []}
          tableSx={{
            ...bottomBorderedTableTd,
          }}
          tableContainerSx={{ maxHeight: "100%" }}
        />
      </Box>

      <TimelineCallToActions timeline={props.timeline} />
    </Box>
  );
};

export default FieldAddCheckupTests;
