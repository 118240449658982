import { technicianStatus } from "./enums";

export function filterList(logList) {
  return logList.filter(Boolean); // <- this returns only the TRUE value
}

export function getReassignedTechnicians(technician) {
  return technician.status === technicianStatus.REASSIGNED;
}

export function getAssignedOrReassignedTechnicians(technician) {
  return (
    technician.status === technicianStatus.REASSIGNED ||
    technician.status === technicianStatus.ASSIGNED
  );
}

export function isEqualKey(key, inputKey) {
  return key === inputKey;
}

export function isValidArray(array) {
  return Array.isArray(array) && array.length > 0;
}

export function findTechnicianByStatusIfExists(
  searchInList,
  findFrom = "find"
) {
  if (searchInList) {
    const find = findFrom === "last" ? "findLast" : "find";
    return searchInList[find](
      (tech) =>
        tech.status === technicianStatus.DONE ||
        tech.status === technicianStatus.ASSIGNED ||
        tech.status === technicianStatus.REASSIGNED
    );
  }
}

export function findTechnicianByStatus(
  searchInList,
  status,
  findFrom = "find"
) {
  if (searchInList) {
    const find = findFrom === "last" ? "findLast" : "find";
    return searchInList[find]((tech) => tech.status === status);
  }
}

export function filterByValidInjectedDate(list) {
  return list.filter((item) => item._date);
}
