import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTheme } from "@emotion/react";
import { rejectionReasons } from "services/StaticLookup/RejectionReasons";
import { valueChecker } from "utils/Numbers/Numbers";

const FieldExaminationActualScores = ({ ...props }) => {
  const { breakpoints } = useTheme();

  const lg = useMediaQuery(breakpoints.down("lg"));

  const gridColumnSpanForConfirmation = `1 / span ${
    props.props.tableHeader.length - 1
  }`;

  const scoresList = props.element.scoresList;

  const skipReason = props.element.skipReason;

  const skipReasonLabel = rejectionReasons.find(
    (reason) => reason.id === skipReason
  );

  return (
    <BaseCard
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${
          lg ? 3 : props.props.tableHeader.length
        } , 1fr)`,
      }}
      px={"2rem"}
      py={"1rem"}
      variant={"alternative-light"}
      mt={2}
    >
      <Box
        sx={{
          gridColumn: lg ? `1 / -1` : gridColumnSpanForConfirmation,
          gridRow: 1,
        }}
      >
        <Box display={"flex"} gap={1}>
          <Typography sx={{ fontSize: "1.4rem" }}>
            النتيجة{" "}
            <Typography
              component={"span"}
              fontSize={"inherit"}
              fontWeight={"bold"}
            >
              (بعد طلب التعديل من قبل المختبر) {skipReason && ":"}
            </Typography>
          </Typography>
          {skipReason && (
            <Typography sx={{ fontSize: "1.4rem" }}>
              لم يتم اجراء الاختبار، السبب: {skipReasonLabel.name}
            </Typography>
          )}
        </Box>
      </Box>
      {!skipReason && (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.5rem",
              gridColumn: lg ? 2 : `${props.props.tableHeader.length}`,
              gridRow: lg ? 3 : 1,
              ml: 4,
            }}
          >
            <Typography sx={{ fontSize: "1.6rem" }}>
              {scoresList
                ?.map((score) => score?.score)
                ?.filter((s) => s !== null && s !== undefined)
                .join(", ")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              gridColumn: lg ? 1 : `${props.props.tableHeader.length - 2}`,
              gridRow: lg ? 3 : 1,
              ml: 4,
            }}
          >
            <Typography sx={{ fontSize: "1.6rem" }}>
              {`  ${props.element.maxProctor} `}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              gridColumn: lg ? 3 : `${props.props.tableHeader.length - 1}`,
              gridRow: lg ? 2 : 1,
              ml: 4,
            }}
          >
            <Typography sx={{ fontSize: "1.6rem" }}>
              {`${props.element.optimumProctor}`}
            </Typography>
          </Box>
        </>
      )}
    </BaseCard>
  );
};

export default FieldExaminationActualScores;
