import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AuthFormIntro = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <Typography
        variant="h3"
        marginBottom={1}
        fontSize={32}
        color={"grayDark.color"}
      >
        {t("welcome")}
      </Typography>
      <Typography
        variant="h3"
        fontSize={32}
        sx={{
          color: "primary.main",
          marginBottom: "4rem",
          fontWeight: "bold",
        }}
      >
        {t("fuhosat platform")}
      </Typography>
    </>
  );
};

export default AuthFormIntro;
