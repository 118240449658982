import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import { SIDEBAR_WIDTH } from "constants/Layout";

import Footer from "../Footer/Footer";
import SideMenu from "../SideMenu/SideMenu";

const DashboardLayout = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: matches ? "1fr" : `${SIDEBAR_WIDTH} 1fr`,
          overflow: matches ? "hidden" : "unset",
          minHeight: "100vh",
        }}
      >
        <SideMenu />

        <Box
          sx={{
            gridColumn: matches ? 1 : 2,
            padding: matches ? "3rem 2rem" : "3rem 6rem",
          }}
        >
          {props.children}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default DashboardLayout;
