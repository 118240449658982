import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import DashboardIndex from "../pages/Dashboard/Home/DashboardIndex/DashboardIndex";
import DashboardHome from "../pages/Dashboard/Home/DashboardIndex";
import NewRequests from "../pages/Dashboard/NewRequests";
import AssignedRequests from "../pages/Dashboard/AssignedRequests";
import AssignedRequestsIndex from "../pages/Dashboard/AssignedRequests/AssignedRequestsIndex/AssignedRequestsIndex";
import AssignedRequestsDetails from "../pages/Dashboard/AssignedRequests/AssignedRequestsDetails/Index";
import NewRequestsIndex from "../pages/Dashboard/NewRequests/NewRequestsIndex/NewRequestsIndex";
import NewRequestsDetails from "../pages/Dashboard/NewRequests/NewRequestsDetails";
import LabAssignedRequestForm from "../pages/Dashboard/AssignedRequests/AssignedRequestForm/Lab";
import FieldAssignedRequestForm from "../pages/Dashboard/AssignedRequests/AssignedRequestForm/Field";
import EditRequests from "../pages/Dashboard/EditRequests";
import EditRequestsIndex from "../pages/Dashboard/EditRequests/EditRequestsIndex";
import EditRequestDetails from "../pages/Dashboard/EditRequests/EditRequestsDetilas";
import UserManagement from "../pages/UserManagement";
import UserDetails from "../pages/UserManagement/UserDetails/UserDetails";
import AddUser from "../pages/UserManagement/AddUser/AddUser";
import ViewUsers from "../pages/UserManagement/ViewUsers/ViewUsers";

const labDashboardRoutes = {
  path: "/",
  element: <ProtectedRoute component={<Dashboard />} />,
  children: [
    {
      path: "home",
      element: <ProtectedRoute component={<DashboardHome />} />,
      children: [{ index: true, element: <DashboardIndex /> }],
    },
    {
      path: "new-requests",
      element: <ProtectedRoute component={<NewRequests />} />,
      children: [
        { index: true, element: <NewRequestsIndex /> },
        { path: ":id", element: <NewRequestsDetails /> },
      ],
    },
    {
      path: "assigned-requests",
      element: <ProtectedRoute component={<AssignedRequests />} />,
      children: [
        { index: true, element: <AssignedRequestsIndex /> },
        { path: ":id", element: <AssignedRequestsDetails /> },
        { path: ":id/lab-form", element: <LabAssignedRequestForm /> },
        { path: ":id/field-form", element: <FieldAssignedRequestForm /> },
      ],
    },
    {
      path: "edit-requests",
      element: <ProtectedRoute component={<EditRequests />} />,
      children: [
        { index: true, element: <EditRequestsIndex /> },
        { path: ":id", element: <EditRequestDetails /> },
      ],
    },
    {
      path: "users-management",
      element: <ProtectedRoute component={<UserManagement />} />,
      children: [
        { index: true, element: <ViewUsers /> },
        {
          path: ":id",
          element: <ProtectedRoute component={<UserDetails />} />,
        },
        {
          path: "add",
          element: <ProtectedRoute component={<AddUser />} />,
        },
      ],
    },
  ],
};

export default labDashboardRoutes;
