import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DATA_ID } from "constants/Details";
import { defaultEmptyValue } from "constants/Empty";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { useTranslation } from "react-i18next";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import BaseAttachment from "core-ui/BaseAttachment/BaseAttachment";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { renderLabAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import TestsTableField from "./TestsTableField";

function EditRequestField({ stations, data, isStationsFetching }) {
  const { t } = useTranslation(["dashboard"]);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <BaseDataWrapper
        title="تفاصيل الطلب"
        titleStyles={{ marginBottom: "3rem" }}
      >
        <BaseDataItem
          label="اسم المختبر"
          value={data?.labName || defaultEmptyValue}
        />
        <BaseDataItem label="رقم الطلب" value={data?.inspectionId} />
        <BaseDataItem
          label="اختبار مواد الأعمال المدنية"
          value={data?.inspectionName}
        />
        <BaseDataItem
          label="حالة طلب التعديل"
          valueComponentAs="div"
          value={
            <BaseStatus
              status={data?.status}
              dataId={DATA_ID.ADMIN_EDIT_REQUEST}
            />
          }
        />

        <BaseDataItem
          label="نوع الفحص"
          valueComponentAs="div"
          value={<BaseTypeTag type={data?.testtype} />}
        />

        <BaseDataItem
          label="موعد الفحص"
          valueComponentAs="div"
          value={`${formatDateYYYYMMDD(
            data?.appointmentDate
          )} ${renderLabAppointmentTimeLabel(data?.appointmentTime)}`}
        />

        <BaseDataItem
          label="المدة المتوقعة"
          value={
            data?.labEstimatedTime
              ? `${data?.labEstimatedTime} أيام`
              : defaultEmptyValue
          }
        />

        <BaseDataItem label="رقم محاولة التعديل" value={data?.attemptOrder} />
      </BaseDataWrapper>

      <hr style={{ marginTop: "3rem", marginBottom: "3rem" }} />
      <Box>
        <Box className={"request-data-details"}>
          <Typography className={"request-data-details__title"}>
            {t("سبب طلب التعديل")}
          </Typography>
          <Typography fontSize={"1.6rem"} color={"grayDark.color"}>
            {data?.editReason || defaultEmptyValue}
          </Typography>
        </Box>
      </Box>

      <hr style={{ marginTop: "5rem" }} />

      <Box mt={5} mb={0} className="request-data-details__wrapper">
        <Typography className="request-data-details__title">
          الاختبارات
        </Typography>
        <TestsTableField
          data={data}
          stations={stations}
          isLoading={isStationsFetching}
        />

        <hr style={{ marginTop: "5rem" }} />

        <BaseDataWrapper
          columns={smDown ? 24 : 36}
          title="ملفات نتائج الاختبارات"
          mt="0 !important"
        >
          <Box>
            {data?.updatedAttachment && (
              <Typography sx={{ fontSize: "1.5rem" }} mb={1}>
                قبل التعديل
              </Typography>
            )}
            {data?.currentAttachment ? (
              <BaseAttachment file={data?.currentAttachment} />
            ) : (
              <Typography sx={{ fontSize: "1.5rem" }} mb={1}>
                لا يوجد
              </Typography>
            )}
          </Box>

          {data?.updatedAttachment && (
            <Box>
              <Typography sx={{ fontSize: "1.5rem" }} mb={1}>
                بعد التعديل
              </Typography>
              <BaseAttachment file={data?.updatedAttachment} />
            </Box>
          )}
        </BaseDataWrapper>
      </Box>
    </>
  );
}

export default EditRequestField;
