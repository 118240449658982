import { queryBuilder } from "utils/ApiHelpers";
import inspectionApi from "../../../Inspection/apis";

const inspectionLabApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getLaboratoryTechnicianTestsRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/inspection/inspections/technicianAssignedRequests?${q}`,
        };
      },
    }),
    submitAssignedRequestScores: builder.mutation({
      query: ({ inspectionId, body }) => ({
        url: `/api/inspection/inspections/v2/FIELD/${inspectionId}/save`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Request"],
    }),
  }),
});

export const {
  useGetLaboratoryTechnicianTestsRequestsQuery,
  useSubmitAssignedRequestScoresMutation,
} = inspectionLabApi;

export default inspectionLabApi;
