import { useState } from "react";
import { Box } from "@mui/material";
import { _scrollToTop } from "utils/DOM/DOM";
import { _void } from "utils/Objects/Objects";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import FieldTestLabDoubleLayer from "components/TableUtilitiesComponents/TableDoubleLayerComponents/FieldTestLabDoubleLayer";
import { assignedRequestTestsFeild } from "services/StaticLookup/TableHeader/TableHeader";

const RaiseResultsFieldTest = (props) => {
  const {
    isSubmitted,
    _tableContent,
    isRequestDetailsFetching,
    enableDrafting,
    isDraftedNotSubmitted,

    injectProps: { allowRaiseResult, setValue },
  } = props;

  const [stationInputs, setStationInputs] = useState({});

  return (
    <Box sx={{ mt: 5 }}>
      <BaseTableDoubleCard
        title="يرجى إدخال نتائج الإختبارات لنقاط الـ station التالية"
        description={" "}
        tableHeader={assignedRequestTestsFeild}
        isLoading={isRequestDetailsFetching}
        content={_tableContent.content}
        injectProps={{
          ...props.injectProps,
          allowRaiseResult,
          isSubmitted,
          enableDrafting,
          isDraftedNotSubmitted,
        }}
        doubleLayer={() => {
          return {
            visibility: true,
            component: (
              <FieldTestLabDoubleLayer
                setValue={setValue}
                ignoreStackingContext
                isSubmitted={isSubmitted}
                stationInputs={stationInputs}
                setStationInputs={setStationInputs}
              />
            ),
          };
        }}
      />
    </Box>
  );
};

export default RaiseResultsFieldTest;
