import React, { useEffect, useState } from "react";
import { calculateLength } from "utils/Station/Station";
import { Box, Typography } from "@mui/material";

const LayerLength = (props) => {
  const {
    formData,
    id,
    showAlertHandler,
    setValue,
    setlengthErr,
    setError,
    clearErrors,
    lengthErr,
  } = props;

  const [length, setLength] = useState("-");

  const from = formData[`station-${id}-from`];
  const to = formData[`station-${id}-to`];

  useEffect(() => {
    const val = calculateLength(from, to);
    if (val === "error") {
      setlengthErr((prevStations) => {
        const updatedStations = [...prevStations];
        updatedStations.push(id);
        return updatedStations;
      });

      setError(`station-${id}-length`, { type: "end-point-invalid" });
      setLength("-");
      setValue(`station-${id}-length`, "");
    } else {
      if (lengthErr?.length > 0 && lengthErr.includes(id)) {
        setlengthErr((prevStations) => {
          const updatedEroorStations = [...prevStations];

          updatedEroorStations.splice(prevStations.indexOf(id), 1);
          return updatedEroorStations;
        });
      }
      clearErrors(`station-${id}-length`);
      if (val !== "-") {
        setLength(val);
        setValue(`station-${id}-length`, val);
      } else {
        setLength("-");
        setValue(`station-${id}-length`, "");
      }
    }
  }, [from, to, showAlertHandler]);

  return (
    <Box mt={1} pl={5}>
      <Typography sx={{ fontSize: "1.6rem" }}>{length}</Typography>
    </Box>
  );
};

export default LayerLength;
