import { useRef } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import classes from "../BaseUploadFile/UploaderProgressCard/UploaderProgressCard.module.scss";

function BaseProgressBar(props) {
  const progressRef = useRef();
  const progressLabelRef = useRef();
  const progress = props.progress > 100 ? 100 : props.progress;
  return (
    <Box className={classes["uploader-progress__bar"]}>
      <Typography
        component="span"
        className={classes["uploader-progress__bar--label"]}
        color={props.progress >= 50 ? "white" : "primary.main"}
        ref={progressLabelRef}
      >
        {`${progress}%`}
      </Typography>

      <Typography
        component="span"
        width={`${progress}%`}
        className={classes["uploader-progress__bar--process"]}
        ref={progressRef}
      ></Typography>
    </Box>
  );
}

export default BaseProgressBar;

BaseProgressBar.defaultProps = {
  progress: 0,
};
