import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import DisplayResultTest from "components/DisplayResultTest/DisplayResultTest";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import DisplayMinMaxSingleScores from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayMinMaxSingleScores/DisplayMinMaxSingleScores";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SieveTable from "modules/Laboratory/pages/Dashboard/AssignedRequests/AssignedRequestsDetails/Lab/SieveTable/SieveTable";
import DisplayScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayScore/DisplayScore";
import { enLabelSx } from "constants/Typography";

function LabExaminationRequestTests({
  inspectionTestsData,
  asphalet,
  subTests,
  isFetching,
  ...props
}) {
  const adminAsphaletSieveMixDesignGradationLimits = [
    {
      serverKey: "testName",
      name: "Sieve Size",
      format: (cell, row) => {
        return (
          <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
            {cell}
          </Typography>
        );
      },
    },
    { name: "" },
    { name: "", hasComponent: { comp: <DisplayMinMaxSingleScores /> } },
    {
      serverKey: "score",
      name: "",
      fallback: "لا يوجد",
    },
  ];

  const adminSieveMixDesignGradationLimits = [
    {
      serverKey: "testName",
      name: "Sieve Size",
      format: (cell, row) => {
        return (
          <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
            {cell}
          </Typography>
        );
      },
    },
    {
      serverKey: "status",
      name: "",
      show: !asphalet,
      hasComponent: { comp: <BaseStatus tableId={TABLE_ID.SUB_TEST_STATUS} /> },
    },
    { name: "", hasComponent: { comp: <DisplayMinMaxSingleScores /> } },
    {
      serverKey: "score",
      name: "",
      fallback: "غير محدد",
    },
    { name: "" },
  ];

  const adminLabExaminationRequest = useMemo(() => [
    { serverKey: "testName", name: "Test" },
    {
      serverKey: "status",
      hasComponent: { comp: <BaseStatus /> },
      name: "Test Status",
    },
    {
      name: "limits",
      hasComponent: { comp: <DisplayMinMaxSingleScores /> },
      // show: !!(requiresAspeltReport && asphalet),
    },
    {
      serverKey: "score",
      name: "Result",
      hasComponent: { comp: <DisplayScore /> },
    },
    {
      serverKey: "result",
      name: "resultـstatus",
      show: !asphalet,
      hasComponent: {
        comp: <DisplayResultTest />,
      },
      fallback: "بنتظار المعالجة",
    },
  ]);

  return (
    <Box>
      <BaseTableDoubleCard
        doubleLayer={(element) => ({
          visibility: true,
          component: (
            <SieveTable
              tableHeader={
                asphalet
                  ? adminAsphaletSieveMixDesignGradationLimits
                  : adminSieveMixDesignGradationLimits
              }
              content={subTests?.inspectionSubTests}
              isLoading={isFetching}
              injectProps={{}}
            />
          ),
        })}
        withSpecificAction={(element, triggeredElements) => ({
          condition: element?.containSubtests,
          actionType: "dropdown",
          component: (
            <KeyboardArrowDownIcon
              color="primary"
              fontSize="large"
              sx={{
                cursor: "pointer",
                transition: "all .5s ease",
                transform: triggeredElements?.[element.id]
                  ? "rotate(180deg)"
                  : "",
              }}
            />
          ),
        })}
        tableHeader={adminLabExaminationRequest}
        content={inspectionTestsData}
        isLoading={isFetching}
        injectProps={{
          mainData: props?.testsData || [],
          layersData: props?.layersData,
          tableId: TABLE_ID.SUB_TEST_STATUS,
        }}
      />
    </Box>
  );
}

export default LabExaminationRequestTests;
