import React, { useState } from "react";
import classes from "./LongLength.module.scss";
import { Box, Typography } from "@mui/material";

const LongLength = ({ testStandard }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (testStandard.length > 12) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <Box
        className={classes["long-length-container"]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {testStandard.length <= 12 ? (
          <Typography sx={{ fontSize: "inherit" }}>{testStandard}</Typography>
        ) : (
          <>
            <Box className={classes["custom-tooltip-trigger"]}>
              {`${testStandard.substring(0, 16)}...`}
            </Box>
            {showTooltip && (
              <Typography
                className={classes["custom-tooltip"]}
                sx={{ direction: "ltr" }}
              >
                {testStandard}
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default LongLength;
