import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drafts: {},
};

const draftSlice = createSlice({
  name: "draft",
  initialState: initialState,
  reducers: {
    saveDraft: (state, action) => {
      state.drafts = {
        ...state.drafts,
        [action.payload.userId]: {
          ...state.drafts[action.payload.userId],
          [action.payload.inspectionLayerId]: action.payload.inspectionLayer,
        },
      };
    },
    resetDrafts: (state, action) => {
      state.drafts = {};
    },
  },
});

export const draftActions = draftSlice.actions;

export default draftSlice.reducer;
