import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { renderTabs } from "utils/Tabs/Tabs";
import { TransactionCenterTabs } from "services/StaticLookup/Tabs";
import useAlert from "hooks/useAlert";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import Wallet from "./Payment/Wallet";
import { walletBreadcrumb } from "services/StaticLookup/Breadcrumb";
import InvoiceIndex from "./Invoice/InvoiceIndex/Invoice";

const TransactionCenter = () => {
  const { t } = useTranslation(["dashboard"]);
  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const handleChange = (_, newValue) => {
    setTabsActiveIndex(newValue);
  };

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  return (
    <>
      <DashboardIntro
        title={t("المحفظـة", { ns: "wallet" })}
        variant="secondary"
        description={t(
          "يمكنك شحن نقاط المحفظة وإستخدامها لدفع تكلفة الاختبارات",
          { ns: "wallet" }
        )}
        action={
          <DashboardIntroAction>
            <Box>
              <BaseAlert
                show={alert.show}
                type={alert.type}
                message={alert.message}
                destroy={hideAlertHandler}
                autoClose={10}
                closeIcon={false}
                interpolation={alert.interpolation}
              />
            </Box>
          </DashboardIntroAction>
        }
        breadcrumbData={walletBreadcrumb(t)}
      />
      <BaseCard
        mt={6}
        py={"2rem"}
        px={"2rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <>
          <Tabs
            value={tabsActiveIndex}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            className="tabs"
          >
            {renderTabs(TransactionCenterTabs).map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                sx={{
                  background: tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                  fontSize: "1.6rem",
                }}
              />
            ))}
          </Tabs>
          {tabsActiveIndex === 1 && (
            <Wallet indexShowAlertHandler={showAlertHandler} />
          )}
          {tabsActiveIndex === 2 && <InvoiceIndex />}
        </>
      </BaseCard>
    </>
  );
};

export default TransactionCenter;
