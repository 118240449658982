import { Box, Typography } from "@mui/material";
import classes from "./BookExaminationData.module.scss";
import LicenseDataFallbackComp from "../../../../../components/Dashboard/DashboardDrillingLicensesData/LicenseDataFallbackComp";
import {
  baseCalendarDateFormatter,
  displayFormattedTimeHHMM,
  displayFormattedTimeHHMMwithoutPMAM,
} from "utils/Date/Date";

const BookExaminationData = (props) => {
  const date = new Date(props?.data.time);
  const selectionTimeDate = new Date(date);
  selectionTimeDate.setMinutes(selectionTimeDate.getMinutes() + 30);

  if (!props.data?.time) {
    return (
      <Box>
        <LicenseDataFallbackComp
          title="يرجى تحديد التاريخ والوقت لعرض تفاصيل الموعد"
          icon="empty.svg"
        />
      </Box>
    );
  }
  if (props.data) {
    return (
      <Box className={classes["drilling-data"]} mt={4}>
        <Box className={classes["drilling-data__wrapper"]}>
          <Box className={classes["drilling-data__item"]}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography className={classes["drilling-data__label"]}>
                التاريخ:
              </Typography>
              <Typography
                className={classes["drilling-data__value"]}
                marginLeft={1}
              >
                {`${baseCalendarDateFormatter(
                  props?.data.date,
                  "dddd, YYYY/MM/DD"
                )}`}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography className={classes["drilling-data__label"]}>
                الوقت:
              </Typography>
              <Typography
                className={classes["drilling-data__value"]}
                marginLeft={1}
              >
                {displayFormattedTimeHHMMwithoutPMAM(props?.data.time)} -{" "}
                {`${displayFormattedTimeHHMM(selectionTimeDate)}`}
              </Typography>
            </Box>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes["drilling-data__label"]}>
                مدة الفحص:
              </Typography>
              <Typography
                className={classes["drilling-data__value"]}
                marginLeft={1}
              >
                30 دقيقة
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default BookExaminationData;
