import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

const ContractorDashboard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return <Outlet />;
};

export default ContractorDashboard;
