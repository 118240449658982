import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "services/Api";

const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({}),
});

export const {} = publicApi;

export default publicApi;
