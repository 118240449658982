import contractorApi from "..";
import { queryBuilder } from "utils/ApiHelpers";

const contractorConsultationApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractorConsultationRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/contractor/contractors/consultationrequests?${q}`,
        };
      },
      providesTags: ["Consultation-Requests"],
    }),
    getContractorConsultationRequestDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/contractor/contractors/consultationrequests/${id}`,
        };
      },
      providesTags: ["Consultation-Request-Details"],
    }),
    approveOrRejectConsultationRequest: builder.mutation({
      query: (body) => ({
        url: `/api/contractor/contractors/handlePendingConsultationRequest`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Consultation-Request-Details", "Consultation-Requests"],
    }),
  }),
});

export const {
  useGetContractorConsultationRequestsQuery,
  useGetContractorConsultationRequestDetailsQuery,
  useApproveOrRejectConsultationRequestMutation,
} = contractorConsultationApi;

export default contractorConsultationApi;
