import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "bukrabold",
  src: `${process.env.PUBLIC_URL}/fonts/bukrabold.ttf`,
  format: "truetype",
});
Font.register({
  family: "bukraregular",
  src: `${process.env.PUBLIC_URL}/fonts/bukraregular.ttf`,
  format: "truetype",
});

export const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    borderBottomWidth: 0,
    borderColor: "#EDEFF2",
    alignItems: "center",
    fontFamily: "bukraregular",
    marginTop: 5,
    paddingBottom: 5,
    width: "100%",
    fontSize: 8,
    // marginBottom: 10,
  },
  tableDescription: {
    fontSize: 8,
  },
  // الوصف
  decContent: {
    fontSize: 8,
    flex: 1,
    marginRight: "20px",
    flexDirection: "row-reverse",
    marginBottom: 5,
  },
  // التكلفة
  costContent: {
    fontSize: 8,
    flex: 1 / 3,
    flexDirection: "row-reverse",
    marginRight: "200px",
    marginBottom: 5,
  },
  // التكلفة الكلية
  totalContainer: {
    flexDirection: "row",
    borderTopWidth: 1,
    borderColor: "#EDEFF2",
    padding: 10,
    fontSize: 8,
    alignItems: "center",
    width: "100%",
  },
  totalCost: {
    fontSize: 8,
    flex: 1,
    marginRight: "11%",
    flexDirection: "row-reverse",
  },
  totalCostContent: {
    fontSize: 8,
    flex: 1 / 3,
    flexDirection: "row-reverse",
    marginRight: "20%",
    marginBottom: 5,
  },
  onuseLabel: {
    // marginLeft: "230px",
  },
});

export default styles;
