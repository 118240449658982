import { Box, Typography } from "@mui/material";

const InstructionItem = (props) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content 1fr",
        gap: "1.4rem",
      }}
    >
      <Box
        component={"span"}
        sx={{
          alignSelf: "start",
          width: "5rem",
          height: "5rem",
          background: "#ddeceb",
          borderRadius: "50%",
          display: "block",
          alignSelf: "start",
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.4rem",
          fontWeight: "500",
        }}
      >
        {props.number}
      </Box>
      <Typography
        sx={{ fontSize: "1.4rem", fontWeight: "500", marginTop: ".8rem" }}
      >
        {props.children}
      </Typography>
    </Box>
  );
};

export default InstructionItem;
