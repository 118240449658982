import { Box, Tab, Tabs } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetLicenseDetailsQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import DrillingLicensesData from "../../../../components/DataDetails/DrillingLicensesData/DrillingLicensesData";
import { Helmet } from "react-helmet";
import { drillingLicensesdetails } from "services/StaticLookup/Breadcrumb";
import { fromPage } from "constants/FromPages";
import DrillingSites from "modules/Contractor/components/DataDetails/DrillingSites/DrillingSites";
import React, { useState } from "react";
import { renderTabs } from "utils/Tabs/Tabs";
import { DrillingLicensesRequestTabs } from "services/StaticLookup/Tabs";
import DrillingLicensesRequest from "../DrillingLicensesRequest/DrillingLicensesRequest";
import { useSelector } from "react-redux";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { previwPDFFromBlobToFile } from "utils/ApiHelpers";
import useAlert from "hooks/useAlert";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import { useInspectionCertificatesMutation } from "modules/Contractor/apis/inspection-apis/inspection-api";

const DrillingLicensesDetails = () => {
  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();

  const { userType, userRole } = useSelector((store) => store.auth);

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const params = useParams();

  //prettier-ignore
  const { data: licenseData, isFetching , isSuccess} = useGetLicenseDetailsQuery(params.id);

  const onClickHandler = () => {
    navigate("/home/request-path", {
      state: {
        fromPage: fromPage.RAISE_NEW_REQUEST_LICENSE_DETAILS,
        selectedLicenseData: licenseData,
      },
    });
  };

  const AddCheckupRequestButton = (props) => {
    return (
      <BaseButton sx={{ minWidth: "17.3rem" }} onClick={onClickHandler}>
        {t("request checkup")}
      </BaseButton>
    );
  };

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [certificatesView, certificatesViewResponse] =
    useInspectionCertificatesMutation();

  const openCertificateInNewTab = () => {
    certificatesView({
      licenseNumber: licenseData?.licenseNumber,
      licenseYear: licenseData?.licenseYear,
    })
      .unwrap()
      .then((response) => {
        const pdfData = response.base64;
        const { newTab, pdfContent } = previwPDFFromBlobToFile(pdfData);
        newTab.document.write(`
              <html>
              <head>
              <title>شهادة إتمام الفحص</title>
            </head>
                <body>
                <embed src="${pdfContent}" width="100%" height="100%" type="application/pdf">
                </body>
              </html>
            `);
      })
      .catch((error) => {
        console.error(error);
        showAlertHandler({
          show: true,
          message: error.data.errorCode,
          type: AlertTypes.WARNING,
        });
      });
  };

  const ViewCertificateButton = () => {
    return (
      <BaseButton
        sx={{ minWidth: "17.3rem" }}
        onClick={openCertificateInNewTab}
        isLoading={certificatesViewResponse.isLoading}
      >
        {t("view certificate")}
      </BaseButton>
    );
  };

  const PageActionButton = () => {
    //prettier-ignore
    return isFetching ? null :  licenseData?.certificateIssued ? (
      <ViewCertificateButton />
    ) : (
      !sysopsContractorRoleChecker(userType, userRole) ? null : <AddCheckupRequestButton />
    );
  };

  const handleChange = (_, newValue) => {
    setTabsActiveIndex(newValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`رخص الحفر - رقم الرخصة ${licenseData?.licenseNumber}`}</title>
        <link rel="canonical" href="/drilling-licenses" />
      </Helmet>

      <DashboardIntro
        title={`${t("license number", { ns: "dashboard" })} ${
          licenseData?.licenseNumber || ""
        }`}
        description={t("drilling licenses page description", {
          ns: "dashboard",
        })}
        action={
          <Box>
            <PageActionButton />
            <BaseAlert
              show={alert.show}
              type={alert.type}
              message={alert.message}
              destroy={hideAlertHandler}
              autoClose={10}
              closeIcon={false}
            />
          </Box>
        }
        breadcrumbData={drillingLicensesdetails({
          licenseNumber: licenseData?.licenseNumber,
          licenseYear: licenseData?.licenseYear,
        })}
      />
      <BaseCard mt={6} py={"1rem"} px={"1rem"}>
        {isFetching ? (
          <BaseLoader />
        ) : (
          <>
            <Tabs
              value={tabsActiveIndex}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              className="tabs"
            >
              {renderTabs(DrillingLicensesRequestTabs).map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  sx={{
                    background:
                      tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                    fontSize: "1.6rem",
                  }}
                />
              ))}
            </Tabs>
            {tabsActiveIndex === 1 && isSuccess && (
              <Box>
                <DrillingLicensesData data={licenseData} />
                <DrillingSites
                  onClickHandler={onClickHandler}
                  location={[licenseData]}
                  selectedLicense={licenseData}
                  userData={{ userType, userRole }}
                />
              </Box>
            )}
            {tabsActiveIndex === 2 && (
              <Box>
                <DrillingLicensesRequest data={licenseData} />
              </Box>
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default DrillingLicensesDetails;
