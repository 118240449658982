import { Box } from "@mui/material";

const DashboardIntroAction = (props) => {
  return (
    <Box
      sx={{
        ...props.sx,
        width: props.fluid ? "100%" : "min(45rem, 100%)",
        textAlign: "end",
      }}
    >
      {props.children}
    </Box>
  );
};

export default DashboardIntroAction;
