import React from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseTable from "core-ui/BaseTable/BaseTable";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { useGetTechnicianRequestsQuery } from "modules/Technician/apis/inspection-apis/inspection-api";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

import { CollectSample } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { TestTypes } from "constants/TestTypes";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const CollectSamplesRequestsIndex = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const { data: technicianRequestsData, isFetching } =
    useGetTechnicianRequestsQuery({
      page,
      pageSize,
      testType: TestTypes.LAB_TEST,
    });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("Collect Samples Requests")}
        description={
          "هذه القائمة تستعرض طلبات جمع العينات التي يجب تسليمها لمركز التوزيع."
        }
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseCardTitle>طلبات جمع العينات</BaseCardTitle>
        <BaseTableIndex
          tableHeader={CollectSample}
          content={technicianRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{
            tableId: TABLE_ID.TECH_REQUESTS,
            testType: TestTypes.LAB_TEST,
          }}
          totalCount={20}
          emptyData={{
            title: t("NoNewRequest"),
            desc: t("No New Request Desc"),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/collect-samples-requests/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلبات",
            onChangePaginationDataHandler,
            totalCount: technicianRequestsData?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default CollectSamplesRequestsIndex;
