import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import RequestData from "../../../components/DataDetails/RequestData/RequestData";
import SampleConfirmModal from "../../../components/SampleSubmission/SampleConfirmModal/SampleConfirmModal";
import SamplesSelectBox from "../../../components/SampleSubmission/SamplesSelectBox/SamplesSelectBox";
import { RequestsDetail } from "services/StaticLookup/Breadcrumb";
import BaseVerticalTimeline from "core-ui/BaseVerticalTimeline/BaseVerticalTimeline";
import useVerticalTimeline from "hooks/useVerticalTimeline";
import {
  useConfirmDeliveringTheSamplesMutation,
  useConfirmRecivingTheSamplesMutation,
  useGetInspectionForCenterQuery,
  useGetSamplesForCenterQuery,
} from "modules/DistributionCenter/apis/inspection-apis/inspection-api";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { DATA_ID } from "constants/Details";
import { displayFormattedTimeHHMM } from "utils/Date/Date";
import BarcodesModal from "components/AllModalContent/BarcodesModal/BarcodesModal";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseViewModalDataItem from "core-ui/BaseViewModalDataItem/BaseViewModalDataItem";

const RequestDetails = () => {
  const { t } = useTranslation(["dashboard"]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSentBardcodes, setShowSentBardcodes] = useState(false);
  const [showSamplesConfirm, setShowSamplesConfirm] = useState(false);
  const [showSamplesBox, setShowSamplesBox] = useState(false);
  const [checkedSamples, setCheckedSamples] = useState([]);

  const onLeaveCardHandler = () => setShowConfirm(false);

  const onLeaveSentBarcodesCardHandler = () => setShowSentBardcodes(false);

  const onLeaveSamplesCardHandler = () => {
    if (confirmDeliveringTheSamplesResponse.isLoading) return;
    setShowSamplesConfirm(false);
  };

  const { vTimelineRef, completeStage, onHide } = useVerticalTimeline();

  const params = useParams();

  const [confirmRecivingTheSamples, confirmRecivingTheSamplesResponse] =
    useConfirmRecivingTheSamplesMutation();

  const [confirmDeliveringTheSamples, confirmDeliveringTheSamplesResponse] =
    useConfirmDeliveringTheSamplesMutation();

  const { data: inspection, isSuccess: isInspectionSuccess } =
    useGetInspectionForCenterQuery(params.id);

  const inspectionLayer = inspection?.inspectionLayersList?.[0];

  const { data: samplesData } = useGetSamplesForCenterQuery(params.id);

  const printSamplesData = { ...inspection, ...samplesData };

  useEffect(() => {
    if (samplesData?.receivedByDistCenterAt) {
      completeStage(1);
    }
    if (samplesData?.deliveredToLabAt) {
      completeStage(2);
    }
    if (samplesData?.receivedByDistCenterAt && samplesData?.deliveredToLabAt) {
      onHide();
    }
  }, [inspection, samplesData]);

  const handelConfirmationSample = () => {
    confirmRecivingTheSamples({
      inspectionId: inspection?.id,
    })
      .unwrap()
      .then(() => {
        completeStage(1);
      });
  };

  const handelConfirmDelivery = async () => {
    confirmDeliveringTheSamples({
      inspectionId: inspection?.id,
      formattedCodes: checkedSamples,
    })
      .unwrap()
      .then(() => {
        setShowSamplesConfirm(false);
        setShowSamplesBox(false);
        completeStage(2);
        onHide();
      });
  };

  const isConfirmDeliveringTheSamplesDisable =
    !samplesData?.deliveredToDistCenterAt;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{` الطلبات- طلب رقم ${inspection?.id}`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={`${t(`طلب رقم ${inspection?.id}`, {
          ns: "dashboard",
        })}`}
        description={t("تستعرض الصفحة تفاصيل الطلب ومعلومات المختبر", {
          ns: "dashboard",
        })}
        breadcrumbData={RequestsDetail({
          id: inspection?.id,
        })}
      />
      <BaseModal
        show={showConfirm}
        py={"4rem"}
        px={"4rem"}
        onHide={onLeaveCardHandler}
        closeIcon
        title={`رموز العينات (barcode) للطلب رقم #${inspection?.id}`}
        mainContainerSx={{
          width: {
            lg: "80rem",
          },
        }}
      >
        <BarcodesModal
          onHide={onLeaveCardHandler}
          samples={samplesData?.samples}
          samplesInfo={printSamplesData}
          onPrint
        />
      </BaseModal>

      <BaseModal
        show={showSentBardcodes}
        py={"4rem"}
        px={"4rem"}
        onHide={onLeaveSentBarcodesCardHandler}
        closeIcon
        title={`رموز العينات (barcode) للطلب رقم #${inspection?.id}`}
        mainContainerSx={{
          width: {
            lg: "80rem",
          },
        }}
      >
        <BarcodesModal
          onHide={onLeaveSentBarcodesCardHandler}
          samples={samplesData?.samples.filter(
            (item) =>
              item.status === "DELIVERED_TO_LAB" ||
              item.status === "RECEIVED_BY_LAB"
          )}
        />
      </BaseModal>

      <SampleConfirmModal
        showSamplesConfirm={showSamplesConfirm}
        onLeaveSamplesCardHandler={onLeaveSamplesCardHandler}
        inspection={inspection}
        checkedSamples={checkedSamples}
        handelConfirmDelivery={handelConfirmDelivery}
        isLoading={confirmDeliveringTheSamplesResponse.isLoading}
        t={t}
      />

      <BaseCard mt={6} py={"5rem"} px={"7rem"}>
        <RequestData
          inspection={inspection}
          isInspectionSuccess={isInspectionSuccess}
          samplesData={samplesData}
          dataId={DATA_ID.CENTER_REQUEST_DETAILS}
        />
        <BaseVerticalTimeline sx={{ marginTop: 3 }} ref={vTimelineRef}>
          <Box>
            <BaseCardTitle> استلام العينة</BaseCardTitle>
            {!samplesData?.receivedByDistCenterAt ? (
              <Box>
                <Typography
                  sx={{
                    color: "grayDark.color",
                    fontSize: "1.6rem",
                    mb: "2.5rem",
                  }}
                >
                  سيقوم فني الرقابة بتوصيل العينات إلى مركز التوزيع
                </Typography>

                <Stack direction={"row"} spacing={1}>
                  <BaseButton
                    sx={{ minWidth: "17.3rem" }}
                    onClick={handelConfirmationSample}
                    isLoading={confirmRecivingTheSamplesResponse.isLoading}
                    disabled={isConfirmDeliveringTheSamplesDisable}
                  >
                    تأكيد استلام العينة
                  </BaseButton>
                </Stack>
              </Box>
            ) : (
              <>
                <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 20 }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "base.black",
                        fontSize: "1.4rem",
                        mb: "2rem",
                        mt: "2.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      تاريخ الاستلام
                    </Typography>

                    <Typography
                      sx={{ fontSize: "1.6rem", color: "primary.colorSoft4" }}
                    >
                      {formatDateYYYYMMDD(samplesData?.receivedByDistCenterAt)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "base.black",
                        fontSize: "1.4rem",
                        mb: "2rem",
                        mt: "2.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      وقت الاستلام
                    </Typography>{" "}
                    <Typography
                      sx={{ fontSize: "1.6rem", color: "primary.colorSoft4" }}
                    >
                      {displayFormattedTimeHHMM(
                        samplesData?.receivedByDistCenterAt
                      )}
                    </Typography>
                  </Box>
                  {samplesData?.samples[0]?.formattedCode && (
                    <Box className={"request-data-details__item"}>
                      <Typography
                        sx={{
                          color: "base.black",
                          fontSize: "1.4rem",
                          mb: "2rem",
                          mt: "2.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        رموز العينات (barcode)
                      </Typography>

                      <BaseViewModalDataItem
                        value={"barcodes"}
                        onClick={() => setShowConfirm(true)}
                      />
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>

          <Box>
            <BaseCardTitle mb={1}>تسليم العينة إلى المختبر </BaseCardTitle>

            <Box>
              {!samplesData?.deliveredToLabAt ? (
                showSamplesBox ? (
                  <SamplesSelectBox
                    t={t}
                    receivedSamples={samplesData?.samples}
                    checkedSamples={checkedSamples}
                    setCheckedSamples={setCheckedSamples}
                    setShowSamplesConfirm={setShowSamplesConfirm}
                    setShowSamplesBox={setShowSamplesBox}
                    isLoading={confirmDeliveringTheSamplesResponse.isLoading}
                  />
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        color: "grayDark.color",
                        fontSize: "1.6rem",
                        mb: "2.5rem",
                      }}
                    >
                      عليك إيصال العينات إلى المختبر
                    </Typography>

                    <Stack direction={"row"} spacing={1}>
                      <BaseButton
                        sx={{ minWidth: "17.3rem" }}
                        isLoading={
                          confirmDeliveringTheSamplesResponse.isLoading
                        }
                        disabled={!samplesData?.receivedByDistCenterAt}
                        onClick={() => setShowSamplesBox(true)}
                      >
                        تأكيد تسليم العينة
                      </BaseButton>
                    </Stack>
                  </Box>
                )
              ) : (
                <>
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", columnGap: 20 }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: "base.black",
                          fontSize: "1.4rem",
                          mb: "2rem",
                          mt: "2.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        تاريخ التسليم
                      </Typography>
                      <Typography
                        sx={{ fontSize: "1.6rem", color: "primary.colorSoft4" }}
                      >
                        {formatDateYYYYMMDD(samplesData?.deliveredToLabAt)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "base.black",
                          fontSize: "1.4rem",
                          mb: "2rem",
                          mt: "2.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        وقت التسليم
                      </Typography>{" "}
                      <Typography
                        sx={{ fontSize: "1.6rem", color: "primary.colorSoft4" }}
                      >
                        {displayFormattedTimeHHMM(
                          samplesData?.deliveredToLabAt
                        )}
                      </Typography>
                    </Box>
                    {samplesData?.samples[0]?.formattedCode && (
                      <Box className={"request-data-details__item"}>
                        <Typography
                          sx={{
                            color: "base.black",
                            fontSize: "1.4rem",
                            mb: "2rem",
                            mt: "2.5rem",
                            fontWeight: "bold",
                          }}
                        >
                          رموز العينات المرسلة للمختبر
                        </Typography>

                        <BaseViewModalDataItem
                          value={"barcodes"}
                          onClick={() => setShowSentBardcodes(true)}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </BaseVerticalTimeline>
      </BaseCard>
    </>
  );
};

export default RequestDetails;
