export const TABLE_LIMIT_PAGE_SIZE = 6;

export const TABLE_ID = {
  REQUEST: "request",
  DRILLING_LICENSES_REQUEST: "drilling_licenses_request",
  LICENSE: "license",
  ADMIN_LICENSES: "admin_licenses",
  ADMIN_TECHNICIAN_REQUESTS: "admin_technician_requests",
  TEST: "test",
  TEST_STATUS: "test_status",
  SUB_TEST_STATUS: "sub_test_status",
  FIELD_EXAMINATION: "field_examination",
  LAYER_STATUS: "layer_status",
  TECH_REQUESTS: "tech_requests",
  LAB_ASSIGNED_REQUESTS: "lab_assigned_requests",
  CENTER_REQUESTS: "center_requests",
  LAB_NO_ASSIGNED_REQUESTS: "lab_no_assigned_requests",
  CERTIFICATE_TESTS: "CERTIFICATE_TESTS",
  ADMIN_USERS: "admin_users",
  ADMIN_CONTRACTING_COMPANIES: "ADMIN_CONTRACTING_COMPANIES",
  COMPANY_INVOICES: "COMPANY_INVOICES",
  COMPANY_PAYMENTS: "COMPANY_PAYMENTS",
  CONTRACTOR_PAYMENTS: "CONTRACTOR_PAYMENTS",
  ADMIN_LAB_REQUESTS: "ADMIN_LAB_REQUESTS",
  ADMIN_REQUESTS: "ADMIN_REQUESTS",
  ADMIN_EDIT_REQUESTS: "ADMIN_EDIT_REQUESTS",
  ADMIN_EDITED_REQUESTS_TESTS: "ADMIN_EDITED_REQUESTS_TESTS",
  CONTRACTOR_CONSULTANT_REQUESTS: "CONTRACTOR_CONSULTANT_REQUESTS",
  CONSULTANT_CONSULTATION_REQUESTS: "CONSULTANT_CONSULTATION_REQUESTS",
  SUB_TESTS_STATUS: "sub_tests_status",
  LAB_EDIT_REQUESTS: "lab_edit_requests",
  ADMIN_EDITED_REQUESTS_TESTS_FIELD: "ADMIN_EDITED_REQUESTS_TESTS_FIELD",
};
export const TABLE_DISPLAY_BY = [5, 10, 15, 20, 25];

export const bottomBorderedTableTd = {
  td: {
    borderBottom: "0.1rem solid #F4F4F4",
  },
};
