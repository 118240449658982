import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./styles";

export const Header = ({ mainTitle, subtitles }) => {
  const swapParentheses = (str) => {
    return str.replace(/[\u0028\u0029]/g, (match) =>
      match === "(" ? ")" : "("
    );
  };

  return (
    <>
      <View
        style={{
          flexWrap: "wrap",
          alignItems: "flex-start",
          alignSelf: "flex-end",
          marginRight: -10,
        }}
      >
        <Image
          src={"/assets/images/Logo2-min.png"}
          style={{
            width: 62,
            height: 30,
          }}
        />
      </View>
      <View>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{mainTitle}</Text>
        </View>
        {subtitles.map((subtitleItem, idx) => (
          <View key={idx}>
            <Text style={styles.requestDataTitle}>{subtitleItem.title}</Text>
            <View style={styles.requestDataContainer}>
              {Object.entries(subtitleItem.data).map(([key, value], index) => (
                <View key={index} style={styles.requestDataContent}>
                  <Text style={styles.commonText}>{key}</Text>
                  <Text
                    style={[styles.commonText, { fontFamily: "bukrabold" }]}
                  >
                    {swapParentheses(value)}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        ))}
        <View style={{ marginTop: 50 }}></View>
      </View>
    </>
  );
};

export default Header;
