export const requestCheckupDetailsTabs = [
  "تفاصيل الطلب",
  "الاختبارات",
  "الدفعات",
];

export const requestAssignedRequestsDetailsTabs = [
  "تفاصيل الطلب",
  "الاختبارات",
];

export const DrillingLicensesRequestTabs = ["الرخصة", "طلبات الفحص"];

export const AdminDrillingLicensesRequestTabs = ["الرخصة", "الطلبات"];

export const AdminExaminationRequestTabs = [
  "الطلب",
  "التقارير والمرفقات",
  "التاريخ الزمني",
];

export const FieldExaminationRequests = ["تفاصيل الطلب", "الاختبارات"];

export const TransactionCenterTabs = ["المحفظة", "الفواتير"];
export const TransactionCenterTabsUAT = ["المحفظة"];


export const AdminContractingCompaniesTabs = [
  "بيانات الشركة",
  "كشف النقاط",
  "عمليات شحن المحفظة",
];
export const AdminContractingCompaniesTabsUAT = ["بيانات الشركة", "كشف النقاط"];
