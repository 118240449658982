import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DEFAULT_HOME_PAGE_ROUTE } from "services/Routes";

const useSetActiveSidemenuLink = (navData, selectItemHandler) => {
  const { showSidemenu } = useSelector((store) => store.sidemenu);
  const location = useLocation();

  useEffect(() => {
    function setActiveSidemenuLink(navData) {
      let isCurrentLinkActive = false;
      navData.forEach((ndata, parentIndex) => {
        if (location.pathname.includes(ndata.to)) {
          isCurrentLinkActive = true;
          selectItemHandler(parentIndex + 1);
          return;
        } else if (ndata.children) {
          ndata.children.forEach((childData, childIndex) => {
            if (location.pathname.includes(childData.to)) {
              isCurrentLinkActive = true;
              selectItemHandler(`${parentIndex}.${childIndex + 1}`);
              return;
            }
          });
        }
      });
      if (!isCurrentLinkActive) {
        //prettier-ignore
        let defaultIndex = location.pathname === DEFAULT_HOME_PAGE_ROUTE ? 0 : -1;
        selectItemHandler(defaultIndex);
      }
    }
    setActiveSidemenuLink(navData);
  }, [showSidemenu, location]);
};

export default useSetActiveSidemenuLink;
