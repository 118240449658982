/**
 *
 * @param {object} formRef - useRef()
 * @returns - It return a filtered list that only contains HTMLInput elements
 */
export const getFormInputs = (formRef) => {
  return Array.from(formRef.current.elements).filter(
    (item) => item.localName === "input"
  );
};

/**
 * @description It resets all input values
 * @param {Array<HTMLInputElement>} formInputs
 */
export const resetFormInputs = (formInputs) => {
  formInputs.forEach((input) => (input.value = ""));
};

/**
 *
 * @param {object} formData - It collect all form input keys
 * @param {string} removeProp - It is a string that will be removed from formData object
 * @returns {boolean} - It returns true in case all inputs have a value
 */
export const checkAllFieldssAreFilled = (formData, removeProp) => {
  let areAllFilled = true;

  if (removeProp) {
    delete formData[removeProp];
  }

  for (let key in formData) {
    if (!formData[key]) {
      return false;
    }
  }
  return areAllFilled;
};
