export const UsersJobs = [
  { id: 1, name: "فني التشغيل", type: "RESAA", role: "SYSADMIN" },
  { id: 2, name: "فني الرقابة", type: "RESAA", role: "TECHNICIAN" },
  { id: 3, name: "مسؤول مركز التوزيع", type: "CENTER", role: "SYSOPS" },
  { id: 4, name: "فني المختبر", type: "LABORATORY", role: "SYSOPS" },
];

export const UsersJobsSupportPage = [
  { id: 1, name: "مقاول", type: "CONTRACTOR", role: "SYSOPS" },
  { id: 2, name: "فني المختبر", type: "LABORATORY", role: "SYSOPS" },
  { id: 3, name: "فني الرقابة", type: "RESAA", role: "TECHNICIAN" },
  { id: 4, name: "ممثل مركز التوزيع", type: "CENTER", role: "SYSOPS" },
  { id: 5, name: "مدير النظام", type: "RESAA", role: "SYSADMIN" },
  { id: 6, name: "استشاري", type: "CONSULTANT", role: "SYSOPS" },
];
