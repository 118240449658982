export const paymentFilterCriteria = [
  { id: 1, label: "الكل", value: "" },
  { id: 2, label: "خصم نقاط", value: "DEDUCTED" },
  { id: 3, label: "استرداد نقاط", value: "REFUND" },
];

export const consultantExaminationRequestsFilter = [
  { id: 1, label: "الكل", value: "" },
  { id: 2, label: "معملي", value: "LAB" },
  { id: 3, label: "حقلي", value: "FIELD" },
];
