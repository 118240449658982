import CategorizedTableBody from "../CategorizedTableBody/CategorizedTableBody";
import MainTableBody from "../MainTableBody/MainTableBody";

export const withMainTableBody = (BaseTable) => {
  return (props) => {
    return (
      <BaseTable {...props}>
        {(baseTableProps) => {
          return (
            <MainTableBody {...props}>
              {(tableBodyProps) => {
                return props.children({
                  ...props,
                  ...tableBodyProps,
                  renderTableCell: baseTableProps,
                });
              }}
            </MainTableBody>
          );
        }}
      </BaseTable>
    );
  };
};

export const withCategorizedTableBody = (BaseTable) => {
  return (props) => {
    return (
      <BaseTable {...props}>
        {(baseTableProps) => {
          return (
            <CategorizedTableBody {...props} renderTableCell={baseTableProps}>
              {(categorizedTableProps) => {
                return props.children({
                  ...props,
                  ...categorizedTableProps,
                  renderTableCell: baseTableProps,
                });
              }}
            </CategorizedTableBody>
          );
        }}
      </BaseTable>
    );
  };
};
