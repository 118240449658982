import React from "react";
import BaseTable from "core-ui/BaseTable/BaseTable";
import IndexTableCell from "./components/IndexTableCell";
import {
  withCategorizedTableBody,
  withMainTableBody,
} from "./withMainTableBody";
import CardTableCell from "./components/CardTableCell";
import RowBaseTable from "../RowBaseTable/RowBaseTable";

const TableComponent = withMainTableBody(BaseTable);

export const BaseTableTemplate = (props) => {
  return (
    <TableComponent {...props}>
      {(mainProps) => {
        return (
          <RowBaseTable rowSx={props.rowSx} {...mainProps}>
            {(rowProps) => {
              return props.children({
                renderTableCell: mainProps.renderTableCell,
                ...rowProps,
              });
            }}
          </RowBaseTable>
        );
      }}
    </TableComponent>
  );
};

export const BaseTableIndex = (props) => {
  return (
    <BaseTableTemplate {...props}>
      {(rowProps) => {
        return <IndexTableCell {...rowProps} />;
      }}
    </BaseTableTemplate>
  );
};

export const BaseTableCard = (props) => {
  return (
    <BaseTableTemplate
      {...props}
      tableSx={{
        borderSpacing: "0 12px",
      }}
      rowSx={{
        background: "#41958E05",
        outline: "1px solid #3F777340",
        borderRadius: "5px",
        "td:first-of-type": {
          borderLeft: "1px solid #3F777340",
        },
        "td:last-of-type": {
          borderRight: "1px solid #3F777340",
        },
      }}
    >
      {(rowProps) => {
        return <CardTableCell {...rowProps} />;
      }}
    </BaseTableTemplate>
  );
};

const CategorizedTableComponent = withCategorizedTableBody(BaseTable);

export const BaseTableCategorized = (props) => {
  return (
    <CategorizedTableComponent {...props}>
      {(categorizedTableProps) => {
        const uniqueId = crypto.randomUUID().substring(0, 5);
        return (
          <IndexTableCell
            key={uniqueId}
            {...categorizedTableProps}
            element={categorizedTableProps.categoryElement}
          />
        );
      }}
    </CategorizedTableComponent>
  );
};
