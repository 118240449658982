import React, { useEffect, useState } from "react";
import BaseTable from "core-ui/BaseTable/BaseTable";
import { DrillingLicensesRequests as DrillingLicensesRequest } from "services/StaticLookup/TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useLazyGetContractorlicensesRequestQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { Box } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import { fromPage } from "constants/FromPages";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { isContentReady } from "utils/Array/Arrays";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

function DrillingLicensesRequests(props) {
  const { t } = useTranslation(["dashboard"]);
  const [requestNumber, setRequestNumber] = useState("");

  //props.data.id
  const [getRequests, getRequestsResponse] =
    useLazyGetContractorlicensesRequestQuery();

  const requestsSearchQuery = requestNumber
    ? `?requestNumber=${requestNumber}`
    : "";

  useEffect(() => {
    let timer = setTimeout(
      getRequests.bind(null, {
        request_id: props.data.id,
        request_search_query: requestsSearchQuery,
      }),
      500
    );

    return () => clearTimeout(timer);
  }, [requestNumber]);

  const isNoSearchData =
    requestNumber && !isContentReady(getRequestsResponse.data);

  const emptyTitle = isNoSearchData ? t("No Search") : t("No requests");

  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("No requests desc");

  return (
    <Box mt={4} ml={4}>
      <BaseInput
        sx={{ width: `35rem` }}
        htmlFor="requestNumber"
        label={"البحث"}
        type="number"
        placeholder={"يمكنك البحث برقم الطلب"}
        name="requestNumber"
        icon="search"
        onChange={setRequestNumber}
      />
      <BaseTableIndex
        title={t("الطلبات")}
        description={t("قائمة بجميع طلبات الفحص لمواقع الحفر")}
        tableHeader={DrillingLicensesRequest}
        injectProps={{ tableId: TABLE_ID.REQUEST }}
        content={getRequestsResponse.data || []}
        isLoading={getRequestsResponse.isFetching}
        emptyData={{
          title: emptyTitle,
          desc: emptyDesc,
          img: publicAssetsPath("images/documents2.svg"),
        }}
        withDetails={{
          paramsKey: "id",
          to: (id) => `examination-request/${id}`,
          state: {
            fromPage: fromPage.ADMIN_LICENSE_DETAILS,
            selectedLicenseData: props.data,
          },
        }}
      />
    </Box>
  );
}

export default DrillingLicensesRequests;
