import { Box } from "@mui/system";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";
import { ValidEmailPattern } from "constants/Forms";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseInput from "core-ui/BaseInput/BaseInput";
import useCatchUserType from "hooks/useCatchUserType";
import { useResetPwdMutation } from "modules/Auth/apis/reset-password-api";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";

const EmailResetPasswordForm = (props) => {
  const { t } = useTranslation(["auth", "common"]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { userRoleFromURL } = useCatchUserType();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [sendResetPassword, sendResetPasswordResponse] = useResetPwdMutation();

  const onSubmitHandler = (data) => {
    sendResetPassword({ entityType: userRoleFromURL, ...data })
      .unwrap()
      .then((_) => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_RESET_PASSWORD,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: !!err.data.errorCode,
          message: err.data.errorCode,
          type: AlertTypes.WARNING,
        });
      });
  };

  const onErrorHandler = () => {};

  const isSubmitButtonDisabled =
    sendResetPasswordResponse.isLoading || sendResetPasswordResponse.isSuccess;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إعادة تعيين كلمة المرور`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <BaseCard px={"3rem"} py={"2rem"}>
        <BaseAlert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          destroy={hideAlertHandler}
        />
        <Box py={"8rem"} px={"8rem"}>
          <FormTitle title="إعادة تعيين كلمة المرور" />
          <form onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}>
            <BaseInput
              type="email"
              htmlFor="email"
              label={t("auth:email")}
              name="email"
              register={{
                ...register("email", {
                  required: {
                    value: true,
                    message: `${t("field")} ${t("auth:email")} ${t(
                      "required"
                    )}`,
                  },
                  pattern: {
                    value: ValidEmailPattern,
                    message: `${t("Invalid email address")}`,
                  },
                }),
              }}
              errors={errors}
            />
            <Box sx={{ marginTop: 10, textAlign: "center" }}>
              <BaseButton
                type="submit"
                disabled={isSubmitButtonDisabled}
                variant={isSubmitButtonDisabled ? "disabled" : ""}
              >
                {t("common:submit")}
              </BaseButton>
            </Box>
          </form>
        </Box>
      </BaseCard>
    </>
  );
};

export default EmailResetPasswordForm;
