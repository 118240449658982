import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "services/Api";
import { REACT_APP_AUTH_BASE_URL } from "utils/Env/Env";

// RTK Query

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: axiosBaseQuery({
    baseUrl: REACT_APP_AUTH_BASE_URL,
  }),
  endpoints: (builder) => ({
    home: builder.query({
      query: () => ({
        url: "/index",
      }),
    }),
  }),
});

export const { useHomeQuery } = authApi;

export default authApi;
