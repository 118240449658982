import React from "react";
import { Box, Typography } from "@mui/material";

const RowNumber = (props) => {
  return (
    <Box
      px={1.8}
      mx={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `transparent linear-gradient(180deg,#347a7c59 200%,#52b6a459 100%) 0% 0% no-repeat padding-box`,
        width: "3.7rem",
        borderRadius: "0.5rem",
        height: "3.8rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.6rem",
          fontWeight: 500,
          color: "grayDark.color",
        }}
      >
        {props?.elementIndex + 1}
      </Typography>
    </Box>
  );
};

export default RowNumber;
