import authApi from ".";

const resetPasswordApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    resetPwd: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/resetpwd",
        method: "POST",
        body: body,
      }),
    }),
    resetPassword: builder.query({
      query: (token) => ({
        url: `/api/user/register/reset_password/${token}`,
      }),
    }),
    updatePassword: builder.mutation({
      query: (body) => ({
        url: `/api/user/register/updatePassword`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useResetPasswordQuery,
  useResetPwdMutation,
  useUpdatePasswordMutation,
} = resetPasswordApi;
