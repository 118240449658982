import { Box, Typography } from "@mui/material";

const BaseDataItem = (props) => {
  return (
    <Box className={"request-data-details__item"}>
      <Typography
        className={"request-data-details__label"}
        component={props.labelComponentAs || "p"}
      >
        {props.label}
      </Typography>
      <Typography
        className={
          !props.valueClassName
            ? `request-data-details__value`
            : props.valueClassName
        }
        component={props.valueComponentAs || "p"}
        sx={{ ...props.valueSx, marginTop: 1 }}
      >
        {props.value}
      </Typography>
    </Box>
  );
};

export default BaseDataItem;

BaseDataItem.defaultProps = {
  labelComponentAs: "p",
  valueComponentAs: "p",
};
