import { queryBuilder } from "utils/ApiHelpers";
import contractorApi from "..";

const contractorLicensesApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractorLicenses: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/contractor/licenses/get?${q}`,
        };
      },
    }),
    getAdminLicenses: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/contractor/licenses/list?${q}`,
        };
      },
    }),
    getLicenseDetails: builder.query({
      query: (id) => ({
        url: `/api/contractor/licenses/${id}`,
      }),
      providesTags: ["License-Details"],
    }),
    getContractorLicense: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/licenses/fetchLicense",
        method: "POST",
        body: body,
      }),
    }),
    createContractorLicense: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/licenses",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetContractorLicensesQuery,
  useLazyGetContractorLicensesQuery,
  useGetLicenseDetailsQuery,
  useLazyGetLicenseDetailsQuery,
  useGetContractorLicenseMutation,
  useCreateContractorLicenseMutation,
  useGetAdminLicensesQuery,
  useLazyGetAdminLicensesQuery,
} = contractorLicensesApi;

export default contractorLicensesApi;
