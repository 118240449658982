import { formattedNumber } from "utils/Numbers/Numbers";

const CostTag = ({ cost, type }) => {
  const color =
    type === "DEDUCTED" ? "#FF0017" : type === "HELD" ? "#6E6E6E" : "#50A200";
  const sign = type === "DEDUCTED" || type === "HELD" ? "-" : "+";

  return (
    <div>
      <span
        style={{
          color,
          marginLeft: "1rem",
          fontWeight: "bold",
        }}
      >
        {sign}
      </span>
      {formattedNumber(cost)} نقطة
    </div>
  );
};

export default CostTag;
