import { InputLabel, FormHelperText, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import arabic from "./arabic";
import gregorian from "react-date-object/calendars/gregorian";
import arabic_ar from "react-date-object/locales/arabic_ar";
import gregorian_ar from "react-date-object/locales/gregorian_ar";
import "./custom.css";
import { formatHijriDate } from "utils/Date/Date";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { useTheme } from "@emotion/react";

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const hijriMonths = [
  ["محرم", "محرم"],
  ["صفر", "صفر"],
  ["ربیع الأول", "ربیع الأول"],
  ["ربیع الثاني", "ربیع الثاني"],
  ["جمادی الأول", "جمادی الأول"],
  ["جمادی الثاني", "جمادی الثاني"],
  ["رجب", "رجب"],
  ["شعبان", "شعبان"],
  ["رمضان", "رمضان"],
  ["شوال", "شوال"],
  ["ذي القعدة ", "ذي القعدة"],
  ["ذي الحجة", "ذي الحجة"],
];

const gregorianMonths = [
  ["يناير", "يناير"],
  ["فبراير", "فبراير"],
  ["مارس", "مارس"],
  ["إبريل", "إبريل"],
  ["مايو", "مايو"],
  ["يونيو", "يونيو"],
  ["يوليو", "يوليو"],
  ["أغسطس", "أغسطس"],
  ["سبتمبر", "سبتمبر"],
  ["أكتوبر", "أكتوبر"],
  ["نوفمبر", "نوفمبر"],
  ["ديسمبر", "ديسمبر"],
];

const BaseCalendar = (props) => {
  const { breakpoints } = useTheme();
  const sm = useMediaQuery(breakpoints.down("sm"));

  const inputHasError = props.errors ? props.errors[props.name] : false;

  const months = props.hijri ? hijriMonths : gregorianMonths;

  const weekDays = props.hijri
    ? ["السبت", "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعه"]
    : ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعه", "السبت"];

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const minDate = props.hijri ? formatHijriDate(props.minDate) : props.minDate;

  return (
    <>
      <Box
        mb={5}
        sx={{ width: props.containerSx?.width || "100%", ...props.containerSx }}
      >
        <InputLabel
          sx={{
            color: "grayDark.color",
            mb: 2,
            fontSize: "1.6rem",
            fontWeight: "bold",
            ...props.labelStyles,
          }}
          htmlFor={props.htmlFor}
        >
          {props.label || "التاريخ"}
        </InputLabel>
        <Box
          sx={{ position: "relative", ...props.sx }}
          aria-label="base-calendar"
          className={`${props.disabled ? "base-calendar--disabled" : ""}`}
        >
          <DatePicker
            name={props.name}
            mapDays={
              props.disableWeekends
                ? ({ date }) => {
                    let isWeekend = [5].includes(date.weekDay.index);
                    if (isWeekend)
                      return {
                        disabled: true,
                      };
                  }
                : null
            }
            containerClassName={inputHasError ? "error" : ""}
            digits={digits}
            weekDays={weekDays}
            months={months}
            minDate={props.minDate}
            render={
              <InputIcon placeholder={props.placeholder || "اختر التاريخ"} />
            }
            onChange={props.onChange}
            calendar={props.hijri ? arabic : gregorian}
            locale={props.hijri ? arabic_ar : gregorian_ar}
            format={props.format || "YYYY MM DD"}
            required={props.required}
            editable={false}
            disabled={props.disabled}
            className={`
              base-calendar
              ${sm ? "rmdp-mobile" : ""}
              ${inputHasError ? "cbase-calendar--invalid" : ""}
              
             `}
          />
        </Box>

        {inputHasError && (
          <Box mb={-2.9}>
            <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
              {inputHasError.message}
            </FormHelperText>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BaseCalendar;

BaseCalendar.defaultProps = {
  format: "YYYY MM DD",
  placeholder: "اختر التاريخ",
  hijri: false,
  disableWeekends: false,
  disabled: false,
  label: "التاريخ",
  containerSx: {},
  labelSx: {},
  sx: {},
};
