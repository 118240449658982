import React from "react";
import Barcode from "react-barcode";

const BaseBarcode = ({
  value = "",
  format = "CODE128",
  width = 2,
  height = 100,
  fontSize = 13,
}) => {
  return (
    <Barcode
      value={value}
      format={format}
      width={width}
      height={height}
      fontSize={fontSize}
    />
  );
};

export default BaseBarcode;
