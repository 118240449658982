import BaseTable from "core-ui/BaseTable/BaseTable";
import React, { useEffect } from "react";
import { DrillingLicensesRequests } from "services/StaticLookup/TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useLazyGetContractorlicensesRequestQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { Box } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

function DrillingLicensesRequest(props) {
  const { t } = useTranslation(["dashboard"]);
  const navigate = useNavigate();
  const { userType, userRole } = useSelector((store) => store.auth);

  const [getRequests, getRequestsResponse] =
    useLazyGetContractorlicensesRequestQuery();

  useEffect(() => {
    getRequests({ request_id: props.data.id }, true);
  }, []);

  const emptyTableData = {
    title: t("No requests"),
    desc: t("No requests desc"),
    img: publicAssetsPath("images/documents4.svg"),
  };

  if (sysopsContractorRoleChecker(userType, userRole)) {
    emptyTableData.extrInfo = "طلب فحص";
    emptyTableData.onClick = () => navigate("/home/request-path");
  }

  return (
    <Box mt={4} px={2}>
      <BaseTableIndex
        title={t("الطلبات")}
        description={t("قائمة بجميع طلبات الفحص لمواقع الحفر")}
        tableHeader={DrillingLicensesRequests}
        injectProps={{ tableId: TABLE_ID.REQUEST }}
        content={getRequestsResponse?.data || []}
        isLoading={getRequestsResponse.isFetching}
        emptyData={emptyTableData}
        withDetails={{
          paramsKey: "id",
          to: (id) => `/examination-requests/${id}`,
        }}
      />
    </Box>
  );
}

export default DrillingLicensesRequest;
