import BaseCameraMultiple from "core-ui/BaseCameraMultiple/BaseCameraMultiple";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";

const PreviewMultipleAttachments = (props) => {
  return props.isFetchingAttachments ? (
    <BaseLoader />
  ) : !props.isAttachmentIdsEmpty ? (
    <BaseCameraMultiple
      photos={props.photos}
      isReadonlyMode={props.isReadonlyMode}
      uploadFn={props.uploadFn}
      uploadResponse={props.uploadResponse}
    />
  ) : null;
};

export default PreviewMultipleAttachments;
