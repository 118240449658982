import { blue, orange } from "@mui/material/colors";
import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
export default function dynamicTheme(mode) {
  return extendTheme({
    mode,
    ...(mode === "light"
      ? {
          palette: {
            base: {
              white: "#FFF",
              black: "#000",

              valid: "#4b964e",
              invalid: "#b41515",

              success: "#EDF8E2",
              successBorder: "#77C728",

              successSecondary: "#77C72826",
              successBorderSecondary: "#77C72866",

              orange: "#E65D1B",
              orangeDark: "#D95211",
              orangeSoft: "#FD7D3F",

              watting: "#C65718",

              info: "#F8FBFB",
              infoBorder: "#CCE3E3",
              infoBackground: "#2896c710 ",
              infoBorder2: "#2871C762",
              infoDark: "#2877c7",
              darkBlue: "#374CEF",
              purple: "#6900D9",

              lightBlue: "#008CAD",

              warning: "#fff5d8",
              warningBorder: "#fae19a",

              danger: "#fff7f7",
              dangerBorder: "#ffa0a0",
              dangerText: "#d15a56",

              dangerLight: "#FFA87E4D",
              dangerBorderLight: "#FFA87E66",

              status: {
                labAssigned: "#314DF8",
                collected: "#6900DA",
                orange: "#FD7D3F",
                infoDark: "#0079CD",
                brown: "#7E5D5D",
              },
            },

            primary: {
              main: "#45897f",
              colorSoft1: "#5a8a8b",
              colorSoft2: "#20766c",
              colorSoft3: "#00856D",
              colorSoft4: "#377F80",
              colorSoft5: "#689488",
              colorSoft6: "#3F77735A",
              colorSoft7: "#00856D36",
              colorSoft8: "#9BC9C5",
              colorSoft9: "#1F766C57",
              colorSoft10: "#347A7C",
              colorSoft11: "#248b80",
              colorSoft12: "#00503F",
              colorSoft13: "#1F766C",
              colorSoft14: "#449a91",
              colorSoft15: "#41958E05",
              colorSoft16: "#418177",
              colorSoft17: "#4181771A",
            },

            secondary: {
              main: "#77c728",
              color: "#50a200",
              color2: "#bae393",
            },

            background: {
              primary: "#FBFDFD",
              default: "#F7F8FA",
              disabled: "#D9D9DA",
              colorSoft1: "#F7F8FA",
              colorSoft2: "#FAFAFB",
              colorSoft3: "#EBF2F2",
              colorSoft4: "#41958E0F",
              colorSoft5: "#E9F2F2",
              colorSoft6: "#F7F7F7",
              colorSoft7: "#CCE3E391",
              colorSoft8: "#CCE3E366",
              colorSoft9: "#C5DDDBB3",
              colorSoft10: "#F4F4F4",
              colorSoft11: "#E8F0EF",

              tags: {
                blue: "#b0e8e3",
                blueSoft: "176,232,227",
                navySoft: "40, 104, 180",
                orangeSoft: "255,201,169",
                green: "#77c72879",
                greenSoft: "222,242,203",
                orange: "#ff9455",
              },
            },

            text: {
              tags: {
                navy: "#2868B4",
                blueDark: "#0a5859",
                orangeDark: "#8c301d",
                blueSoft: "#1f766c",
                orangeSoft: "#a84632",
                originLight: "#C65718",
                greenSoft: "#00786c",
                orange: "#F09B00",
              },
            },

            borders: {
              primary: "#B9CECC",
              infoSoft: "#E6E7E9",
              disabled: "#D9D9DA",
              colorDark: "#84848426",
              colorSoft: "#C3D4D2",
              colorSoft2: "#edeff2",
              colorSoft3: "#3F77735A",
              colorSoft4: "#B1B1B1",
              colorSoft5: "#2896C710",
              colorSoft6: "#e2e2ea",
              colorSoft7: "#B5BABA",
            },

            graySoft: {
              color: "#b3b3b3",
              color2: "#6C6C6C",
              color3: "#6E6E6E",
              color4: "#454545",
              color5: "#707070",
              color6: "#797979",
              color7: "#747474",
              color8: "#D9D9DA",
              color9: "#7E7D7D",
            },

            grayDark: {
              color: "#525252",
              color1: "#232323",
              color2: "#848484",
              color3: "#595959",
              color4: "#8E8E8E",
              color5: "#4A4949",
              color6: "#6e6e6e",
              color7: "#454545",
              color8: " #525252",
              color9: "#4F4F4F",
              color10: "#8B8B8B",
              color11: "#202020",
              color12: "#383838",
              color13: "#000000D9",
            },
          },
          layout: {
            mobSidemenuWidth: "28rem",
          },
          typography: {
            fontFamily: "FuhosatFont, sans-serif",
          },
          zIndex: { modal: "99999 !important" },
          overrides: {
            MuiTooltip: {
              tooltip: {
                fontSize: "2em",
                color: "yellow",
                backgroundColor: "red",
              },
            },
          },
        }
      : {
          palette: {
            primary: {
              main: blue[500],
            },
            background: {
              default: "#444",
            },
          },
          typography: {
            fontFamily: "FuhosatFont, sans-serif",
          },
        }),
    direction: "rtl",
  });
}
