import { useEffect, useState } from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { adminLabRequests } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { isContentReady } from "utils/Array/Arrays";
import BaseSearch from "core-ui/BaseSearch/BaseSearch";
import { LabRequestsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { useLazyGetLabRequestsQuery } from "modules/Admin/apis/inspection-apis/lab-apis";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const LabRequests = () => {
  const { t } = useTranslation(["dashboard"]);

  const [searchTerm, setSearchTerm] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getLabRequests, getLabRequestsResponse] = useLazyGetLabRequestsQuery();

  useEffect(() => {
    var timer = setTimeout(
      getLabRequests.bind(null, {
        page: searchTerm ? 0 : page,
        size: pageSize,
        sort: "id,desc",
        search: searchTerm,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, searchTerm]);

  const getSearchTerm = (technicianName) => {
    setSearchTerm(technicianName);
  };

  const isNoSearchData =
    searchTerm && !isContentReady(getLabRequestsResponse?.data?.content);

  const emptyTitle = isNoSearchData ? t("No Results") : t("no requests");
  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("no lab requests desc");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة للمختبرات`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("lab Requests")}
        breadcrumbData={LabRequestsBreadcrumb(t)}
        description={t(
          "تمكنك هذه الصفحة من الاطلاع على الطلبات الحالية المسندة للمختبرات، كما يمكنك أيضًا معرفة تفاصيل الطلب و إعادته إلى نظام التوزيع حتى يتم قبوله من مختبر آخر"
        )}
      />

      <BaseSearch
        placeholder="يمكنك البحث برقم الطلب او باسم المختبر"
        name="labSearch"
        getSearchTerm={getSearchTerm}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={adminLabRequests}
          content={getLabRequestsResponse?.data?.content || []}
          isLoading={getLabRequestsResponse.isFetching}
          injectProps={{
            tableId: TABLE_ID.ADMIN_LAB_REQUESTS,
          }}
          totalCount={20}
          emptyData={{
            title: emptyTitle,
            desc: emptyDesc,
            img: publicAssetsPath("images/documents2.svg"),
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: getLabRequestsResponse?.data?.totalElements,
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/lab-requests/${id}`,
          }}
        />
      </BaseCard>
    </>
  );
};

export default LabRequests;
