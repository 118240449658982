import AuthFormIntro from "components/Forms/_Utilites/AuthFormIntro/AuthFormIntro";
import CenteredWrapper from "core-ui/CenteredWrapper/CenteredWrapper";
import { Outlet } from "react-router-dom";

const ResetPassword = () => {
  return (
    <CenteredWrapper>
      <AuthFormIntro />
      <Outlet />
    </CenteredWrapper>
  );
};

export default ResetPassword;
