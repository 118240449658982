import contractorApi from "..";

const contractorAppointmentApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    addNewAppointment: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/appointments/book",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["C-Request-Details"],
    }),
    checkLabAvailability: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/appointments/labAvailability",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["C-Request-Details"],
    }),
    getAppointmentDetails: builder.query({
      query: (appointmentId) => ({
        url: `/api/contractor/appointments/${appointmentId}`,
      }),
    }),
    rescheduleAppointment: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/appointments/reschedule",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        "C-Request-Details",
        "Wallet",
        "Reschedule-Appointment",
      ],
    }),
    getAppointmentsForAdmin: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/contractor/appointments/v2/admin/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Record-Form", "C-Request-Details"],
    }),
  }),
});

export const {
  useGetAppointmentsForAdminQuery,
  useAddNewAppointmentMutation,
  useGetAppointmentDetailsQuery,
  useCheckLabAvailabilityMutation,
  useRescheduleAppointmentMutation,
} = contractorAppointmentApi;

export default contractorAppointmentApi;
