import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseSearch from "core-ui/BaseSearch/BaseSearch";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { useLazyGetEditRequestsQuery } from "modules/Admin/apis/inspection-apis/edit-requests";
import { useLazyGetLabRequestsQuery } from "modules/Admin/apis/inspection-apis/lab-apis";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LabEditRequests } from "services/StaticLookup/Breadcrumb";
import { LabEditRequest } from "services/StaticLookup/TableHeader/TableHeader";
import { isContentReady } from "utils/Array/Arrays";
import { publicAssetsPath } from "utils/Paths/Paths";

function EditRequestsIndex() {
  const { t } = useTranslation(["dashboard"]);
  const [searchTerm, setSearchTerm] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getEditRequests, getEditRequestsResponse] =
    useLazyGetEditRequestsQuery();

  useEffect(() => {
    var timer = setTimeout(
      getEditRequests.bind(null, {
        page: searchTerm ? 0 : page,
        size: pageSize,
        sort: "id,desc",
        searchTerm,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, searchTerm]);

  const getSearchTerm = (technicianName) => {
    setSearchTerm(technicianName);
  };

  const isNoSearchData = searchTerm && !isContentReady(getEditRequestsResponse.data?.conten);
  const tableEmptyTitle = isNoSearchData ? t("No Search") : t("لا يوجد طلبات");
  const tableEmptyDescription = isNoSearchData
    ? t("No Search Desc")
    : t("ستجد هنا جميع طلبات التعديل");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"طلبات تعديل النتيجة"}</title>
        <link rel="canonical" href="/edit-requests" />
      </Helmet>
      <DashboardIntro
        title={`${t("Requests to modify results", { ns: "dashboard" })}`}
        description={
          "يمكنك من خلال هذه الصفحة تتبع حالة طلبات التعديل التي تم رفعها."
        }
        breadcrumbData={LabEditRequests(t)}
      />

      <BaseSearch
        placeholder="يمكنك البحث برقم طلب التعديل أو رقم طلب الفحص"
        name="labSearch"
        getSearchTerm={getSearchTerm}
      />

      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={LabEditRequest}
          content={getEditRequestsResponse.data?.content || []}
          isLoading={getEditRequestsResponse?.isFetching}
          injectProps={{
            tableId: TABLE_ID.LAB_EDIT_REQUESTS,
          }}
          emptyData={{
            img: publicAssetsPath("images/documents5.svg"),
            title: tableEmptyTitle,
            desc: tableEmptyDescription,
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/edit-requests/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "رخصة",
            onChangePaginationDataHandler,
            totalCount: getEditRequestsResponse.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
}

export default EditRequestsIndex;
