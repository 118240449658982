import { Box, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseModal from "core-ui/BaseModal/BaseModal";
import { publicAssetsPath } from "utils/Paths/Paths";

const DeAssignModal = ({
  showConfirm,
  onLeaveHandler,
  requestDetails,
  handelConfirm,
  t,
  isRequesting,
}) => (
  <BaseModal
    show={showConfirm}
    modalContentWrapperSx={{ paddingTop: "2rem" }}
    onHide={onLeaveHandler}
  >
    <Box px={"2rem"} sx={{ display: "flex", mb: 1 }}>
      <img
        src={publicAssetsPath("icons/exclamationMark.svg")}
        style={{ width: "4rem" }}
      />
      <Typography
        sx={{
          mt: 2,
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: "grayDark.color",
          ml: 2,
          mb: 1.5,
        }}
      >
        إعادة الطلب لنظام التوزيع
      </Typography>
    </Box>
    <hr />
    <Typography
      sx={{
        color: "grayDark.color",
        fontSize: "1.6rem",
        lineHeight: "3rem",
        px: "4rem",
        mb: 6,
        mt: 3,
      }}
    >
      يرجى العلم أنه سيتم إعادة الطلب رقم{" "}
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {requestDetails?.id}
      </span>{" "}
      المسند لمختبر{" "}
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {requestDetails?.labName}
      </span>{" "}
      إلى نظام التوزيع ليتم قبوله من مختبر آخر.
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "flex-end",
        flexWrap: "wrap",
        px: "4rem",
        mb: 1,
      }}
    >
      <BaseButton
        sx={{
          minWidth: "12.2rem",
          mr: 1,
          color: "text.tags.blueSoft",
        }}
        variant={"transparent"}
        disabled={isRequesting}
        onClick={onLeaveHandler}
      >
        {t("إلغاء")}
      </BaseButton>
      <BaseButton
        sx={{ minWidth: "12.2rem", display: "flex", gap: 2 }}
        variant={isRequesting ? "disabled" : "secondary"}
        disabled={isRequesting}
        onClick={handelConfirm}
      >
        {isRequesting ? <BaseLoader /> : t("تأكيد")}
      </BaseButton>
    </Box>
  </BaseModal>
);

export default DeAssignModal;
