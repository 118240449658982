export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const removeLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const clearStorage = () => {
  localStorage.clear();
};

export const setUserToken = (data) => {
  setLocalStorage("userAuthData", data);
  saveUserType(data.data.user_type);
};

export const getUserToken = () => {
  return getLocalStorage("userAuthData");
};

export const removeUserToken = () => {
  removeLocalStorage("userAuthData");
};

export const saveUserType = (type) => {
  setLocalStorage("cachedUserType", type.toLowerCase());
};

export const getUserType = () => {
  return getLocalStorage("cachedUserType");
};

export const removeUserType = () => {
  removeLocalStorage("cachedUserType");
};
