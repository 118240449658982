import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdownComponent";
import BaseRadio from "core-ui/BaseRadio/BaseRadio";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import ConfirmEditRequest from "components/AllModalContent/ConfirmEditRequest/ConfirmEditRequest";

import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import { transformStationToFrom } from "services/StaticLookup/TableHeader/TableHeader";
import { prepareTemplateLayers } from "utils/RaiseNewRequest/RaiseNewRequest";

import { useDispatch } from "react-redux";
import { stationInputsActions } from "store/staions-inputs/station-inputs";

import {
  useEditStationsMutation,
  useGetContractorInspectionQuery,
  useGetMaterialsDataQuery,
  useLazyGetDevicesDataQuery,
  useLazyGetLayersDataQuery,
} from "modules/Contractor/apis/inspection-apis/inspection-api";

import { ExaminationEditRequests } from "services/StaticLookup/Breadcrumb";
import { checkStationsOverlapping } from "utils/Station/Station";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import { fromPage } from "constants/FromPages";
import BaseModal from "core-ui/BaseModal/BaseModal";
import { hasLayersChanged } from "utils/hasLayersChanged/hasLayersChanged";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import StationsTable from "modules/Contractor/features/RaiseNewRequest/AddCheckupSiteSpecs/Field/StationsTable/StationsTable";

function EditRequest() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyLarge = useMediaQuery(theme.breakpoints.only("lg"));
  const { t } = useTranslation();
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [stationsHasErrors, setStationsHasErrors] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    unregister,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  const { stations, isLoading } = useGetStationsWithTestData({
    id,
    isCustomResponse: true,
  });
  const { data: contractorRequestDetails } =
    useGetContractorInspectionQuery(id);
  const { data: materialsData } = useGetMaterialsDataQuery();
  const [getDevicesData, { data: devices }] = useLazyGetDevicesDataQuery();
  const [getLayersData, getLayersDataResponse] = useLazyGetLayersDataQuery();
  const [editRequestInspection, editRequestInspectionResponse] =
    useEditStationsMutation();
  const prevLayersData = useRef();

  const formData = watch();

  useEffect(() => {
    if (materialsData) {
      const material = materialsData.find(
        (material) => material.id === contractorRequestDetails?.materialId
      );
      setSelectedMaterial(material);
    }
  }, [materialsData, contractorRequestDetails?.materialId]);

  useEffect(() => {
    if (contractorRequestDetails?.materialId) {
      getDevicesData({ materialId: contractorRequestDetails?.materialId });
    }
    if (contractorRequestDetails?.deviceId) {
      getLayersData({
        deviceId: contractorRequestDetails?.deviceId,
        testType: "FIELD",
      });
    }
  }, [contractorRequestDetails]);

  useEffect(() => {
    const currentLayersData = getLayersDataResponse?.data;
    if (!isLoading && stations?.length > 0 && currentLayersData) {
      if (
        !prevLayersData.current ||
        hasLayersChanged(prevLayersData.current, currentLayersData)
      ) {
        stations.forEach((station) => {
          const matchedLayer = currentLayersData.find(
            (layer) => layer.name === station.layerName
          );
          setValue(
            `station-${station.id}-from`,
            transformStationToFrom(station.from)
          );
          setValue(
            `station-${station.id}-to`,
            transformStationToFrom(station.to)
          );
          setValue(`station-${station.id}-layerNumber`, station.layerNumber);
          setValue(`station-${station.id}-length`, station.distance.toString());
          if (matchedLayer) {
            setValue(`station-${station.id}-layerTest`, {
              id: matchedLayer.id,
              name: matchedLayer.name,
            });
          }
        });

        prevLayersData.current = currentLayersData;
      }
    }
  }, [stations, isLoading, setValue, getLayersDataResponse?.data]);

  const handelCancelEdit = () => {
    navigate(-1);
    reset();
    dispatch(stationInputsActions.resetStations());
  };

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    setShowConfirmModal(true);
  };
  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };
  const onConfirmEditHandler = () => {
    // Check for overlapping stations once and store the result
    const overlappingStations = checkStationsOverlapping(formData);
    if (overlappingStations?.length > 0) {
      setStationsHasErrors(overlappingStations);
      return;
    } else {
      setStationsHasErrors([]);
    }

    // Prepare template layers and proceed with the inspection request
    const selectedTemplateLayers = prepareTemplateLayers(formData);
    editRequestInspection({
      body: { selectedTemplateLayers: selectedTemplateLayers },
      InspectionId: contractorRequestDetails?.id,
    })
      .unwrap()
      .then((response) => {
        navigate(`/examination-requests/${id}`, {
          state: { fromPageId: fromPage.SUCCESS_UPDATE_REQUEST },
        });
        dispatch(stationInputsActions.resetStations());
      })
      .catch((error) => {
        showAlertHandler({
          show: true,
          message: error.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const onErrorHandler = (error, event) => {
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات الفحص - رقم الطلب	${id}`}</title>
      </Helmet>

      <DashboardIntro
        title={t(`تعديل الطلب رقم ${id}`, {
          ns: "dashboard",
        })}
        description={""}
        breadcrumbData={ExaminationEditRequests({
          id: id,
        })}
        action={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            destroy={hideAlertHandler}
            autoClose={5}
            closeIcon={false}
          />
        }
      />
      <BaseModal
        show={showConfirmModal}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <ConfirmEditRequest
          onConfirm={onConfirmEditHandler}
          requestId={id}
          onHide={onLeavePageHandler}
        />
      </BaseModal>

      <BaseCard py={"3rem"} px={"3rem"}>
        <BaseCardTitle>{"نموذج تعديل طلب فحص"}</BaseCardTitle>
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <Grid container>
            <Grid
              item
              lg={6}
              xs={12}
              mt={sm ? 1 : 8.4}
              gap={4}
              sx={{
                display: "flex",
                flexWrap: onlyLarge || sm ? "wrap" : "nowrap",
              }}
            >
              <Box
                mb={sm ? 0 : 7}
                sx={{ width: "100%", minWidth: sm ? "100vw" : "26.4rem" }}
                mt={sm ? 1 : 0}
              >
                <Typography
                  mb={2}
                  sx={{
                    color: "grayDark.color",
                    fontSize: "1.4rem",
                    fontWeight: 500,
                  }}
                >
                  {"نوع مسار الفحص"}
                </Typography>
                <BaseRadio checked={true} label={"حقلي"} />
              </Box>

              <Box mb={sm ? 0 : 7} sx={{ width: "100%" }}>
                <BaseDropdown
                  control={control}
                  required
                  name="material"
                  errors={errors}
                  label="نوع المادة"
                  labelStyles={{
                    color: "grayDark.color",
                    fontSize: "1.4rem",
                    fontWeight: 500,
                  }}
                  mainStyles={{
                    minWidth: sm ? "100vw" : "26.4rem",
                    width: "100%",
                  }}
                  value={{
                    id: selectedMaterial?.id,
                    name: selectedMaterial?.serviceNameAr,
                  }}
                  selectMenuStyles={{ height: "10rem" }}
                  disabled
                />
              </Box>

              <Box mb={sm ? 0 : 7} sx={{ width: "100%" }}>
                <BaseDropdown
                  control={control}
                  itemDirection="ltr"
                  required
                  name={"device"}
                  errors={errors}
                  label={"نوع الجهاز"}
                  labelStyles={{
                    color: "grayDark.color",
                    fontSize: "1.4rem",
                    fontWeight: 500,
                  }}
                  mainStyles={{
                    minWidth: sm ? "100vw" : "26.4rem",
                    width: "100%",
                  }}
                  placeholder="تحديد نوع الجهاز"
                  value={{ id: devices?.[0]?.id, name: devices?.[0]?.name }}
                  selectMenuStyles={{ height: "10rem" }}
                  emptyListPlaceholder="يرجى تحديد نوع المادة أولا"
                  disabled
                />
              </Box>
            </Grid>
          </Grid>

          {stations?.length > 0 ? (
            <StationsTable
              register={register}
              errors={errors}
              control={control}
              unregister={unregister}
              formData={formData}
              setValue={setValue}
              stationsHasErrors={stationsHasErrors}
              setStationsHasErrors={setStationsHasErrors}
              clearErrors={clearErrors}
              setError={setError}
              stations={stations}
              layersData={getLayersDataResponse?.data}
            />
          ) : (
            <BaseLoader />
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <BaseButton
              sx={{
                minWidth: "12.2rem",
                mr: 1,
              }}
              variant={"secondary"}
              onClick={handelCancelEdit}
              isLoading={editRequestInspectionResponse.isLoading}
            >
              {t("إلغاء")}
            </BaseButton>
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              type={"submit"}
              isLoading={editRequestInspectionResponse.isLoading}
            >
              {t("تأكيد")}
            </BaseButton>
          </Box>
        </form>
      </BaseCard>
    </>
  );
}

export default EditRequest;
