import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResetPasswordValidationCases } from "services/StaticLookup/Validations";
import classes from "./PatternValidationChecker.module.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import BaseIcon from "core-ui/BaseIcon/BaseIcon";

const PatternValidationChecker = ({
  password,
  isSubmitted,
  getValidationCases,
}) => {
  const { t } = useTranslation(["auth"]);
  //prettier-ignore
  const [validationChecker, setValidationChecker] = useState(ResetPasswordValidationCases);

  useEffect(() => {
    let casesValidity = validationChecker
      .map((validCase) => validCase.isValid)
      .every((valid) => valid);

    getValidationCases(casesValidity);
  }, [validationChecker]);

  function changeValidationCasesValidityOnPasswordChange() {
    setValidationChecker((prevState) => {
      let newValidationChecker = prevState.map((validCase) => {
        if (password) {
          if (validCase.pattern.test(password)) {
            validCase.isValid = true;
          } else {
            validCase.isValid = isSubmitted ? false : undefined;
          }
        } else {
          validCase.isValid = isSubmitted ? false : undefined;
        }
        return { ...validCase };
      });

      return newValidationChecker;
    });
  }

  useEffect(() => {
    changeValidationCasesValidityOnPasswordChange();
  }, [password, isSubmitted]);

  const renderValidationCaseIcon = (isValid) => {
    const validationIcon =
      isValid === undefined &&  isValid === false ? (
        <BaseIcon icon={<CircleIcon />} size="xs" color="base.invalid" />
      ) : isValid === true ? (
        <BaseIcon icon={<CheckCircleIcon />} size="xs" color="base.valid" />
      ) : (
        <BaseIcon icon={<CancelIcon />} size="xs" color="base.invalid" />
      );
    return validationIcon;
  };
  return (
    <>
      <Box mb={3}>
        <Typography color={"graySoft.color2"} sx={{ fontSize: "1.4rem" }}>
          {t("password message 8")}
        </Typography>
        <Box mt={1} color={"graySoft.color2"} className="reset-password-viewer">
          {validationChecker.map((validCase) => {
            const isValidClass =
              validCase.isValid === undefined
                ? ""
                : validCase.isValid === true
                ? "valid"
                : "invalid";
            return (
              <Box key={validCase.label} display="flex" gap={1} mb={0.5}>
                {renderValidationCaseIcon(validCase.isValid)}
                <Typography
                  className={`${classes["reset-password-viewer__checker"]} ${
                    classes[`reset-password-viewer__checker--${isValidClass}`]
                  }`}
                >
                  {t(validCase.label)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default PatternValidationChecker;
