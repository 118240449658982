import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { isNumber } from "../Numbers/Numbers";
import { rejectionReasons } from "services/StaticLookup/RejectionReasons";

export const pointsFormatter = (cell, row) => {
  // if the score is a number and the inspectionPointDtos array (which is cell in this function) is empty consider this an old field request or a lab request and show the score field
  // if (Array.isArray(cell) && cell?.length === 0 && isNumber(row?.score)) {
  //   return row?.score;
  // } else
  if (Array.isArray(cell) && cell?.length !== 0) {
    const isExaminationRecordPosted = cell.every(
      (score) => score.technicianConfirmation !== null
    );
    if (!isExaminationRecordPosted) {
      return <BaseStatus status="TEST_NOT_READY" />;
    }
    // if the inspectionPointDtos array (which is cell in this function) is not empty consider this a new field request show the 5 point finalScore fields joined together
    // finalScore returns "score" if the score is correct (confirmed by tech) and returns corrected score if the score is not correct
    return cell.map((point) => point.score).join(", ");
  } else {
    return <BaseStatus status="TEST_NOT_READY" />;
  }
};

export const fieldExaminationPointsConfirmationHandler = (cell, row) => {
  // this function is to display the validity of the scores "صحيحة" "غير صحيحة"
  // and then display the corrected scores (which is different between old and new field requests)
  // first we have to determine old field request from new (before 5 points score and after)
  // we check the score field and the inspectionPointDtos array (which is "cell" in this function)

  if (!isNumber(row?.score) && Array.isArray(cell) && cell?.length !== 0) {
    // if the score is empty and the inspectionPointDtos array is not we will consider this a new field request and rely on the 5 finalScore points
    const isExaminationRecordPosted = cell.every(
      (point) => point.technicianConfirmation !== null
    );

    if (!isExaminationRecordPosted) {
      return <BaseStatus status="TEST_NOT_READY" />;
    }

    const isPointsChanged = cell.some((point) => !point.technicianConfirmation);

    if (!isPointsChanged) {
      return "صحيحة";
    }

    const updatedPoints = cell
      .map((point) => {
        if (point.technicianCorrectedScore) {
          return point.technicianCorrectedScore;
        } else if (point.currentScore) {
          // 👈 we need to check this (currentScore)
          return point.currentScore;
        } else {
          if (point.score) return point.score;
        }
        return "";
      })
      .join(", ");

    return `غير صحيحة, النتيجة النهائية: ${updatedPoints} `;
  } else if (
    Array.isArray(cell) &&
    cell?.length === 0 &&
    (isNumber(row?.score) || isNumber(row?.currentScore))
  ) {
    // if the score is a number and the inspectionPointDtos array is empty consider this an old field request and rely on the technicianScore field
    if (row.technicianConfirmation && !isNumber(row.technicianScore))
      return "صحيحة";

    if (!row.technicianConfirmation && isNumber(row.technicianScore)) {
      return `غير صحيحة, النتيجة النهائية: ${row.technicianScore} `;
    }
  } else {
    // otherwise show "not ready" status
    return <BaseStatus status="TEST_NOT_READY" />;
  }
};

export const pointHandler = (cell, row) => {
  //////////////////
  // this function is to display the scores (which is different between old and new field requests)
  // first we have to determine old field request from new (before 5 points score and after)
  // we check the score field and the inspectionPointDtos array (which is cell in this function)

  if (Array.isArray(cell) && cell?.length === 0 && isNumber(row?.score)) {
    // if the score is a number and the inspectionPointDtos array is empty consider this an old field request and rely on the score field
    return row?.score;
  } else if (Array.isArray(cell) && cell?.length !== 0) {
    const isExaminationRecordPosted = cell.every(
      (point) => point.labScore !== null
    );

    if (!isExaminationRecordPosted) {
      return <BaseStatus status="TEST_NOT_READY" />;
    }
    // if the inspectionPointDtos array is not empty consider this a new field request and rely on the 5 points scores (joined together)
    return cell.map((point) => point.labScore).join(", ");
  } else {
    return <BaseStatus status="TEST_NOT_READY" />;
  }
};

export const editedPointsFormatter = (cell, row) => {
  if (Array.isArray(cell) && cell?.length !== 0) {
    const scoresArr = cell.map((point) => {
      if (point.updatedScore) {
        return point.updatedScore;
      } else {
        return "لا يوجد";
      }
    });

    if (scoresArr.every((el) => el === "لا يوجد")) {
      return "لا يوجد";
    }

    return scoresArr.join(", ");
  } else if (isNumber(row?.updatedScore)) {
    return row?.updatedScore;
  } else {
    return "لا يوجد";
  }
};

export const currentPointsFormatter = (cell, row) => {
  if (Array.isArray(cell) && cell?.length !== 0) {
    const scoresArr = cell.map((point) => {
      if (point.currentLabScore) {
        return point.currentLabScore;
      } else {
        return "لا يوجد";
      }
    });

    if (scoresArr.every((el) => el === "لا يوجد")) {
      return "لا يوجد";
    }

    return scoresArr.join(", ");
  } else if (isNumber(row?.currentScore)) {
    return row?.currentScore;
  } else {
    return "لا يوجد";
  }
};

export const extractCurrentLabScores = (editedStation) => {
  const getReasonMessage = (reasonId) => {
    const reason = rejectionReasons.find((reason) => reason.id === reasonId);
    return `لم يتم اجراء الاختبار، السبب: ${reason?.name}`;
  };

  if (!editedStation) {
    return "لا يوجد";
  }

  const labScores =
    editedStation.testStationMappingEdits?.flatMap((edit) => {
      if (!edit.currentScores) {
        return editedStation.currentSkipReason &&
          editedStation.currentSkipReason !== "CONSULTANT_REJECTION"
          ? [getReasonMessage(editedStation.currentSkipReason)]
          : [];
      }
      return edit.currentScores.map((score) =>
        score.labScore !== null
          ? score.labScore
          : editedStation.updatedSkipReason &&
            editedStation.updatedSkipReason !== "CONSULTANT_REJECTION"
          ? getReasonMessage(editedStation.updatedSkipReason)
          : null
      );
    }) || [];

  const validLabScores = labScores.filter(
    (score) => score !== null && typeof score === "number"
  );

  if (validLabScores.length > 0) {
    return validLabScores.join(", ");
  }

  if (
    editedStation.updatedSkipReason &&
    editedStation.updatedSkipReason !== "CONSULTANT_REJECTION" &&
    (!editedStation.testStationMappingEdits ||
      editedStation.testStationMappingEdits.length === 0)
  ) {
    return getReasonMessage(editedStation.updatedSkipReason) || "لا يوجد";
  }

  return "لا يوجد";
};

export const getSingleLicense = (licenses) => {
  return licenses?.[0];
};
