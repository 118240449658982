import React from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTranslation } from "react-i18next";
import { NewRequestDetail } from "services/StaticLookup/Breadcrumb";

import CollectSampleForm from "components/Forms/CollectSampleForm/CollectSampleForm";
import { Helmet } from "react-helmet";

import { useOutletContext, useParams } from "react-router-dom";

function CollectSample() {
  const { t } = useTranslation(["dashboard"]);

  const params = useParams();

  const { showAlertHandler } = useOutletContext();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`نموذج جمع العينة - طلب رقم ${params.id}`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>
      <DashboardIntro
        title={t("CollectSamples")}
        description={t("FormCollectSamplesDesc")}
        breadcrumbData={NewRequestDetail({ id: params.id })}
      />

      <BaseCard mt={6} py={"5rem"} px={"7rem"}>
        <CollectSampleForm showAlertHandler={showAlertHandler} />
      </BaseCard>
    </>
  );
}

export default CollectSample;
