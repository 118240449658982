import { TableCell } from "@mui/material";
import { displayCell } from "../../TableHead/TableHeader";

const IndexTableRow = ({ headerItem, element, renderTableCell }) => {
  return (
    <TableCell
      key={element.id}
      sx={{
        background: "base.white",
        borderBottom: "1px solid borders.colorSoft2",
        whiteSpace: "nowrap",
        fontSize: "1.6rem",
        display: displayCell(headerItem, element, false),
      }}
    >
      {renderTableCell(element, headerItem)}
    </TableCell>
  );
};

export default IndexTableRow;
