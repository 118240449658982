export const OTP = {
  OTP_TIMER_DURATION: 5,
  OTP_NUMBER_OF_KEYS: 5,
};

export const ValidEmailPattern = 
  /^(?![.])(?!.*[.]{2})(?!.*[.]@)(?!.*@[-])(?!.*@.*[.]{2})[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

export const LICENSE_YEAR_DATE_RANGE = 5;
export const LICENSE_YEAR_DATE_FROM = 5;
