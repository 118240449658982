import { Box, List, Typography } from "@mui/material";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import { Fragment } from "react";
import { formattedNumber } from "utils/Numbers/Numbers";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import {
  baseCalendarDateFormatter,
  displayFormattedTimeHHMM,
} from "utils/Date/Date";
import { VAT_PERCENT } from "constants/Vat";
import { licenseTestsSummary } from "services/StaticLookup/TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import RaiseNewRequestService from "../../RaiseNewRequestService";

const LabAddCheckupSummary = (props) => {
  const { t } = useTranslation(["wallet"]);

  const textSX = {
    fontSize: "1.6rem",
  };

  const flexClasses = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const alignedText = {
    width: "50%",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  };

  const justifyEnd = {
    justifyContent: "flex-end",
    paddingInlineEnd: "10rem",
  };

  const { labTotalTestsCost: totalTestsCost, labTotalSampleCostValue } =
    RaiseNewRequestService.getTotalCostForTest(props.raiseNewRequestData);

  return (
    <Box>
      <BaseDataWrapper mt={1} title={"ملخص الطلب"}>
        {props?.layersData && (
          <BaseDataItem
            label={"اختبار مواد الأعمال المدنية"}
            value={props?.layersData[0]?.name}
          />
        )}
        <BaseDataItem
          label={"مسار الفحص"}
          valueComponentAs={"div"}
          value={<BaseTypeTag type={props?.testType} />}
        />

        <BaseDataItem
          label={"موعد الفحص"}
          value={`${baseCalendarDateFormatter(
            props?.appointmentDate,
            "dddd, YYYY/MM/DD"
          )} - ${displayFormattedTimeHHMM(props?.appointmentTime)}`}
        />

        <BaseDataItem
          label={" المدة المتوقعة"}
          value={
            props.testsTemplates?.[0]?.estimatedTime
              ? `${props.testsTemplates?.[0]?.estimatedTime} أيام`
              : "لايوجد"
          }
        />
      </BaseDataWrapper>

      <BaseCardTitle>التكلفة</BaseCardTitle>
      <Typography sx={{ color: "grayDark.color1", fontSize: "1.6rem" }}>
        {` أسعار الاختبارات لا تشمل ضريبة القيمة المضافة (${VAT_PERCENT}%)`}
      </Typography>

      <>
        <Box mt={4}>
          <BaseTableIndex
            justifyColumnsBetween
            tableHeader={licenseTestsSummary}
            content={props?.testsTemplates || []}
            tableSx={{
              border: {
                tableCell: "0.1rem solid",
                color: "#f9f9f9",
                borderInlineColor: "transparent",
              },
            }}
            tableContainerSx={{ maxHeight: "100%" }}
          />
        </Box>
        <Box className="item" mt={3}>
          {(props?.layersData || []).slice(0, 1).map((layer) => {
            return (
              <Fragment key={layer.id}>
                <List>
                  <Box sx={{ ...flexClasses, mb: 2, ml: 2 }}>
                    <Box sx={{ ...flexClasses }}>
                      <Typography sx={textSX}>
                        {t("تكلفة الاختبارات المعملية")}
                      </Typography>
                    </Box>

                    <Box sx={{ ...flexClasses, ...alignedText, ...justifyEnd }}>
                      <Typography sx={{ ...textSX, fontSize: "1.6rem", mr: 1 }}>
                        {formattedNumber(layer.combinedLabTestCost)}
                      </Typography>
                      <Typography sx={{ ...textSX }}>نقطة </Typography>
                    </Box>
                  </Box>
                </List>
              </Fragment>
            );
          })}
        </Box>
        <Box sx={{ ...flexClasses, mb: 2, ml: 2 }}>
          <Box sx={{ ...flexClasses }}>
            <Typography sx={textSX}>{t("تكلفة جمع العينات")}</Typography>
          </Box>

          <Box sx={{ ...flexClasses, ...alignedText, ...justifyEnd }}>
            <Typography sx={{ ...textSX, fontSize: "1.6rem", mr: 1 }}>
              {formattedNumber(labTotalSampleCostValue)}{" "}
            </Typography>
            <Typography sx={{ ...textSX }}>نقطة </Typography>
          </Box>
        </Box>
        {/* THIS BOX SHOULD BE A COMPONENT */}
        <Box sx={{ ...flexClasses, mb: 2 }}>
          <Typography
            sx={{ fontSize: "1.6rem", fontWeight: 500 }}
            color={"grayDark.color1"}
            ml={2}
          >
            {"التكلفة الكلية"}
          </Typography>
          <Box sx={{ ...flexClasses, ...alignedText, ...justifyEnd }}>
            <Typography
              sx={{
                ...textSX,
                fontSize: "2.2rem",
                ml: 1,
                color: "primary.colorSoft10",
                fontWeight: 500,
              }}
            >
              {formattedNumber(totalTestsCost)}
            </Typography>
            <Typography
              sx={{
                ...textSX,
                fontSize: "1.6rem",
                ml: 1,
                color: "base.black",
                fontWeight: 500,
              }}
            >
              نقطة{" "}
            </Typography>
          </Box>
        </Box>
      </>
      <TimelineCallToActions
        timeline={props.timeline}
        isPrevButtonDisabled={props.isLoading}
        isNextButtonDisabled={props.isSuccess || props.isLoading}
      />
    </Box>
  );
};

export default LabAddCheckupSummary;
