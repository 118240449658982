import React from "react";
import EmailResetPasswordForm from "components/Forms/ResetPassword/EmailResetPasswordForm/EmailResetPasswordForm";
import ResetPasswordForm from "components/Forms/ResetPassword/ResetPasswordForm/ResetPasswordForm";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Login from "modules/Auth/pages/Login/Login";
import ResetPassword from "../../Auth/pages/ResetPassword/ResetPassword";
import VerifyOtp from "../../Auth/pages/VerifyOtp/VerifyOtp";
import MagicRegister from "../../Auth/pages/Register/MagicRegister";
import { UsersTypes } from "constants/UsersTypes";

const authChildren = UsersTypes.map((user) => [
  {
    path: `${user}/login`,
    element: <ProtectedRoute component={<Login />} />,
  },
  {
    path: `${user}/reset-password`,
    element: <ProtectedRoute component={<ResetPassword />} />,
    children: [
      {
        index: true,
        element: <ProtectedRoute component={<EmailResetPasswordForm />} />,
      },
      {
        path: "confirm/:token",
        element: <ProtectedRoute component={<ResetPasswordForm />} />,
      },
    ],
  },
  {
    path: `${user}/verify-otp`,
    element: <ProtectedRoute component={<VerifyOtp />} />,
  },

  {
    path: `${user}/register/:token`,
    element: <ProtectedRoute component={<MagicRegister />} />,
  },
]);

const LazyAuthPage = React.lazy(() => import("../../Auth/pages"));

const publicAuthRoutes = {
  path: "auth",
  element: <LazyAuthPage />,
  children: authChildren.flat(),
};

export default publicAuthRoutes;
