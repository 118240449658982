import authApi from ".";

const loginApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/api/user/auth/login",
        method: "POST",
        body: body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: "/api/user/auth/logout",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useLogoutMutation, useLoginMutation } = loginApi;
