import React, { Fragment } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useNavigate } from "react-router-dom";
import { _void } from "utils/Objects/Objects";
import ActionRow from "../ActionRow/ActionRow";

const MainTableBody = (props) => {
  const navigate = useNavigate();

  const navigateWithDataHandler = (element) => {
    let toPath = element[[props.withDetails.paramsKey]];
    if (props.withDetails.serverKey && !props.withDetails.innerKey) {
      toPath =
        element[[props.withDetails.serverKey]][props.withDetails.paramsKey];
    } else if (props.withDetails.serverKey) {
      toPath =
        element[[props.withDetails.serverKey]][props.withDetails.innerKey][
          props.withDetails.paramsKey
        ];
    }
    navigate(
      props.withDetails.to(toPath),
      props.withDetails.transferData
        ? {
            state: { ...element },
          }
        : props.withDetails.state
        ? {
            state: { ...props.withDetails.state },
          }
        : {}
    );
    if (props.withDetails.action) {
      props.withDetails.action(element);
    }
  };

  const InjectedComponent = (rowProps) =>
    React.cloneElement(props.injectCompBetweenRows, {
      ...rowProps,
      ...props.injectProps,
      insertProps: {
        props: { ...props.insertedProps },
        insertNewPropsHandler: props.insertNewPropsHandler,
      },
    });

  return (
    <TableBody>
      {!props.isTableContentEmpty ? (
        props.content.map((element, idx) => {
          return (
            <Fragment key={idx}>
              {props.children({ navigateWithDataHandler, element, ...props })}

              {props.injectCompBetweenRows && (
                <TableRow>
                  <TableCell colSpan={"100%"}>
                    {InjectedComponent(element)}
                  </TableCell>
                </TableRow>
              )}
              {props.withActionRow && idx === props.content.length - 1 && (
                <ActionRow
                  colSpan={props.tableHeader.length}
                  rowStyles={props.withActionRow?.rowStyles}
                  cellStyles={props.withActionRow?.cellStyles}
                  textStyles={props.withActionRow?.textStyles}
                  description={props.withActionRow?.description}
                />
              )}
            </Fragment>
          );
        })
      ) : (
        <BaseLoader />
      )}
    </TableBody>
  );
};

export default MainTableBody;
