import { Box, Typography } from "@mui/material";
import { VAT_PERCENT } from "constants/Vat";

const TaxValue = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Typography color={"grayDark.color9"} fontSize={"1.6rem"} width={"50%"}>
        {`ضريبة القيمة المضافة ${VAT_PERCENT}%`}
      </Typography>
      <Typography
        sx={{ fontSize: "1.6rem" }}
        color={"grayDark.color9"}
        width={"50%"}
        paddingInline={"1.6rem"}
      >
        {props.value} ريال
      </Typography>
    </Box>
  );
};

export default TaxValue;
