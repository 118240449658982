import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "services/Api";
import { REACT_APP_CONTRACTOR_BASE_URL } from "utils/Env/Env";

// RTK Query
const consultantApi = createApi({
  reducerPath: "consultantApi",
  baseQuery: axiosBaseQuery({
    baseUrl: REACT_APP_CONTRACTOR_BASE_URL,
  }),
  endpoints: (builder) => ({}),
});

export default consultantApi;
