import { Box } from "@mui/material";
import { forwardRef } from "react";
import "./BaseVerticalTimeline.scss";

const BaseVerticalTimeline = forwardRef((props, ref) => {
  return (
    <Box className={"vertical-timeline"}>
      <span></span>
      <Box sx={props.sx} className={"vertical-timeline__list"} ref={ref}>
        {props.children && props.children}
      </Box>
    </Box>
  );
});

export default BaseVerticalTimeline;
