import AuthFormIntro from "components/Forms/_Utilites/AuthFormIntro/AuthFormIntro";
import LoginForm from "components/Forms/LoginForm/LoginForm";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";
import BaseCard from "core-ui/BaseCard/BaseCard";
import CenteredWrapper from "core-ui/CenteredWrapper/CenteredWrapper";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import messages from "services/AlertMessages/messages";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import useAlert from "hooks/useAlert";

const Login = (props) => {
  const { alert, showAlertHandler } = useAlert();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`تسجيل الدخول`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>
      <CenteredWrapper>
        <AuthFormIntro />
        <BaseCard py={"2rem"} px={"4rem"}>
          <BaseAlert
            mt={0}
            mb={4}
            show={alert.show}
            type={alert.type}
            labelSx={{ fontWeight: "bold" }}
          >
            {!alert.message ? messages.EMPTY_MESSAGE : messages[alert?.message]}
          </BaseAlert>
          <Box py={"3rem"} px={"5rem"}>
            <FormTitle title="تسجيل الدخول" />
            <LoginForm getErrorResponse={showAlertHandler} />
          </Box>
        </BaseCard>
      </CenteredWrapper>
    </>
  );
};

export default Login;
