import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BaseRadioWithLabel = (props) => {
  const errors = props.customError
    ? props.customError
    : props.errors
    ? props.errors[props.name]
    : null;

  return (
    <Box sx={props.sx}>
      {props.label && (
        <Typography
          sx={{
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          {props?.label}
        </Typography>
      )}
      <Stack direction={"row"} alignItems={"center"} spacing={0.5} mt={1}>
        <Controller
          name={props?.name}
          control={props?.control}
          rules={props?.controllerRules}
          render={({ field: { onChange, value } }) => {
            return (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <RadioGroup
                  row
                  name="accept"
                  onChange={onChange}
                  defaultValue={props.defaultValue}
                  value={value || ""}
                >
                  {props?.data.map((item, idx) => {
                    return (
                      <FormControlLabel
                        key={idx}
                        value={item.value}
                        control={
                          <Radio
                            checkedIcon={<CheckCircleIcon />}
                            sx={{
                              color: "primary.colorSoft6",
                              "&.Mui-checked": {
                                color: "secondary.main",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: "1.4rem" }}>
                            {item?.label}
                          </Typography>
                        }
                      />
                    );
                  })}
                </RadioGroup>

                {errors && (
                  <FormHelperText
                    error
                    variant="error"
                    sx={{ fontSize: "1.1rem" }}
                  >
                    {errors.message}
                  </FormHelperText>
                )}
              </Box>
            );
          }}
        />
      </Stack>
    </Box>
  );
};

export default BaseRadioWithLabel;
