import { Helmet } from "react-helmet";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { CompanyPaymentDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { useParams } from "react-router-dom";
import CompanyPaymentData from "./CompanyPaymentData";
import { useGetPaymentQuery } from "modules/Contractor/apis/payment-apis/payment-apis";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Box } from "@mui/material";
import PrintTransaction from "modules/Contractor/components/PrintTransaction/PrintTransaction";
import { TransactionTypes } from "constants/TransactionTypes";

const CompanyPaymentDetails = () => {
  const params = useParams();

  const {
    data: transactionData,
    isLoading,
    isFetching,
  } = useGetPaymentQuery(params?.paymentsId, {
    skip: !params?.paymentsId,
  });

  const DashboardIntroAction = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <PrintTransaction
          disabled={transactionData?.status === TransactionTypes.HELD}
          data={transactionData}
        />
      </Box>
    );
  };

  return (
    <>
      {isLoading && <BaseLoader />}

      <Helmet>
        <meta charSet="utf-8" />
        <title>{`تفاصيل الفاتورة`}</title>
      </Helmet>

      <DashboardIntro
        title={`بيانات العملية رقم ${params.paymentsId}`}
        variant="secondary"
        breadcrumbData={CompanyPaymentDetailsBreadcrumb({
          id: params.paymentsId,
          companyId: params.companyId,
          name: transactionData?.contractorName,
        })}
        action={<DashboardIntroAction />}
      />
      <BaseCard mt={2} py={"1rem"} px={"3rem"}>
        <CompanyPaymentData data={transactionData} isFetching={isFetching} />
      </BaseCard>
    </>
  );
};

export default CompanyPaymentDetails;
