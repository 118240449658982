import { useLoadScript, Marker, GoogleMap } from "@react-google-maps/api";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useCallback, useEffect, useMemo, useState } from "react";

const FOHGoogleMap = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const { onChange, value } = props;

  const defaultCenter = useMemo(
    () => ({ lat: 24.7107929690732, lng: 46.674071297088005 }),
    []
  );

  const [center, setCenter] = useState(
    value ? { lat: value.lat, lng: value.lng } : null
  );

  const [latlng, setLatLng] = useState(
    value ? { lat: value.lat, lng: value.lng } : null
  );

  const setLatLngHandler = (lat, lng) => {
    setLatLng({ lat, lng });
  };

  useEffect(() => {
    const success = (pos) => {
      const position = value || {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      };
      setCenter(position);
      setLatLng(position);
    };
    const error = (err) => {
      setCenter(defaultCenter);
      setLatLng(defaultCenter);
    };
    const getCurrentLocation = () => {
      navigator.geolocation.getCurrentPosition(success, error);
    };
    getCurrentLocation();
  }, []);

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    const getAddress = async () => {
      if (latlng && map) {
        const geocoder = new window.google.maps.Geocoder();
        const _latlng = new window.google.maps.LatLng(latlng.lat, latlng.lng);
        if (_latlng) {
          geocoder.geocode({ location: _latlng }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
              map.setCenter(results[0].geometry.location);

              let locationItem = results.find((res) => {
                if (res.types.includes("plus_code")) {
                  return res.types.includes("plus_code");
                }
              });
              let streetAddress = results.find((res) => {
                if (res.types.includes("street_address")) {
                  return res.types.includes("street_address");
                }
              })?.formatted_address;

              if (!locationItem) {
                locationItem = results[0];
              }

              if (!streetAddress) {
                streetAddress = results.find((res) => {
                  if (res.types.includes("premise")) {
                    return res.types.includes("premise");
                  }
                })?.formatted_address;
              }

              if (onChange) {
                onChange({
                  lat: latlng.lat,
                  lng: latlng.lng,
                  formattedAddress: locationItem?.formatted_address,
                  streetAddress: streetAddress || "NOT_FOUND",
                });
              }
            }
          });
        }
      }
    };
    getAddress();
  }, [center, latlng, map]);

  const onMapChange = (e) => {
    setLatLngHandler(e.latLng.lat(), e.latLng.lng());
  };

  return isLoaded ? (
    <GoogleMap
      zoom={10}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerStyle={{ width: "100%", height: "100%" }}
      onClick={onMapChange}
    >
      <Marker position={latlng} draggable onDragEnd={onMapChange} />
    </GoogleMap>
  ) : (
    <BaseLoader />
  );
};

export default FOHGoogleMap;
