import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { ReactComponent as ExclamationMarkIcon } from "assets/icons/exclamationMark.svg";

const LabEditRequestModal = (props) => {
  const { palette } = useTheme();

  const { t } = useTranslation(["dashboard"]);

  const onLeavePageHandler = () => {
    // ADD RESET TO COLLECTED SAMPLE DATA HERE FROM REDUX
    props.onHide();
  };

  const onConfirmHandler = () => {
    onLeavePageHandler();
    props.onConfirm();
  };
  return (
    <>
      <Box sx={{ mb: 14 }}>
        <Box sx={{ display: "flex", mb: 2 }}>
          <ExclamationMarkIcon />

          <Typography
            sx={{
              mt: 2,
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "grayDark.color",
              ml: 2,
              mb: 1.5,
            }}
          >
            {"تأكيد طلب تعديل النتائج"}
          </Typography>
        </Box>
        <hr />
        <Typography
          sx={{
            mt: 3,
            fontSize: "1.8rem",
            color: "base.black",
            fontWeight: "400",
          }}
        >
          {`يرجى العلم أنه سيتم رفع طلب تعديل لنتائج الطلب رقم ${props.requestId}، حيث سيتم اعتماده حال قبوله من قبل مدير النظام.`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
            mr: 1,
            color: `${palette.text.tags.blueSoft}`,
          }}
          variant={"transparent"}
          onClick={props.onHide}
          disabled={props.isLoading}
        >
          {t("إلغاء")}
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          variant={"secondary"}
          isLoading={props.isLoading}
          onClick={onConfirmHandler}
        >
          {t("تأكيد")}
        </BaseButton>
      </Box>
    </>
  );
};

export default LabEditRequestModal;
