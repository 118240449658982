import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLightTheme: true,
  theme: "light",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      state.theme = state.isLightTheme ? "dark" : "light";
      state.isLightTheme = !state.isLightTheme;
    },
  },
});

export const themeActions = themeSlice.actions;

export default themeSlice.reducer;
