import React from "react";

import Home from "../pages/index";

const LazyHomePage = React.lazy(() => import("../pages/Home/Home"));
const LazyFAQ = React.lazy(() => import("../pages/FAQ/FAQ"));
const LazyAbout = React.lazy(() => import("../pages/About/About"));
const LazyTermsAndConditions = React.lazy(() =>
  import("../pages/TermsAndConditions/TermsAndConditions")
);
const LazyLogin = React.lazy(() => import("../pages/Login"));

const publicDashboardRoutes = [
  {
    path: "/",
    element: <Home />,
    children: [
      { index: true, element: <LazyHomePage /> },
      { path: "faq", element: <LazyFAQ /> },
      { path: "about", element: <LazyAbout /> },
      { path: "terms-and-conditions", element: <LazyTermsAndConditions /> },
    ],
  },
  { path: "login", element: <LazyLogin /> },
];

export default publicDashboardRoutes;
