import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell } from "@mui/material";

export const displayCell = (headerItem, dataItem) => {
  const display = dataItem
    ? headerItem.show === true || headerItem.show === false
      ? headerItem.show
        ? "table-cell"
        : "none"
      : dataItem[headerItem.show] === undefined
      ? true
      : dataItem[headerItem.show] === true ||
        dataItem[headerItem.show] === false
      ? "table-cell"
      : "none"
    : "table-cell";
  return display;
};

const TableHeader = (props) => {
  const { palette } = useTheme();
  const { t } = useTranslation(["common", "home", "payments", "dashboard"]);
  const { tableHeadSx } = props;

  const columnsN = props.justifyColumnsBetween
    ? "1.15"
    : props.tableHeader.length;

  const tableHeadCellSx = {
    background: `${palette.background.colorSoft3}`,
    fontWeight: "bold",
    fontSize: "1.6rem",
    width: `calc(100% / ${columnsN})`,
    textWrap: "balance",
    lineHeight: 1.3,
    ...tableHeadSx,
  };

  return (
    <TableHead sx={{ whiteSpace: "nowrap" }}>
      <TableRow>
        {props.tableHeader.map((headerItem, idx) => {
          const dataItem = props.content?.find((dataItem) => dataItem.id);
          return (
            <TableCell
              key={idx}
              sx={{
                ...tableHeadCellSx,
                ...headerItem.customSx,
                display: displayCell(headerItem, dataItem),
              }}
            >
              {t(headerItem.name, { ns: "tableHeader" })}
            </TableCell>
          );
        })}
        {props.withAction && (
          <TableCell sx={tableHeadCellSx}>
            {props.withAction.header || null}
          </TableCell>
        )}
        {props.withDetails && (
          <TableCell sx={tableHeadCellSx}>{null}</TableCell>
        )}
        {props.withDelete && <TableCell sx={tableHeadCellSx}>{null}</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
