import { valueChecker } from "utils/Numbers/Numbers";

export const prepareDataForFieldTestResultSubmission = (
  formValues,
  stations
) => {
  return Object.keys(formValues)
    .filter((k) => !Number.isNaN(+k)) // this filter will pickup all stations only
    .map((id) => {
      const notApplicable = formValues?.[id]?.["not-applicable"];
      if (notApplicable) {
        return {
          id,
          skipReason: formValues?.[id]?.["not-applicable-reason"]?.id || null,
        };
      }
      const thisStation = stations.find(
        (contentItem) => +contentItem.id === +id
      );

      return {
        id: valueChecker(id),
        maxProctor: valueChecker(formValues?.[id]?.["max-proctor"]),
        optimumProctor: valueChecker(formValues?.[id]?.["optimum-proctor"]),
        tests: thisStation?.tests?.map((test) => {
          return {
            inspectionTestId: +test.inspectionTestId,
            scores: notApplicable
              ? null
              : Object.values(formValues[id].scores)
                  .filter((s) => s !== null || s !== undefined)
                  .map((v) => valueChecker(v)),
          };
        }),
      };
    });
};
