import Dashboard from "../../Contractor/pages/Dashboard";
import DashboardIndex from "../pages/Dashboard/Home/DashboardIndex";

import DashboardHome from "../pages/Dashboard/Home";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

import DrillingLicenses from "../pages/DrillingLicenses";
import DrillingLicensesIndex from "../pages/DrillingLicenses/DrillingLicensesIndex/DrillingLicensesIndex";
import DrillingLicensesDetails from "../pages/DrillingLicenses/DrillingLicensesDetails/DrillingLicensesDetails";

import ExaminationRequests from "../pages/ExaminationRequests";
import ExaminationRequestsIndex from "../pages/ExaminationRequests/ExaminationRequestsIndex/ExaminationRequestsIndex";
import ExaminationRequestsDetails from "../pages/ExaminationRequests/ExaminationRequestsDetails";

import ConsultationRequests from "../../Consultant/pages/ConsultationRequests";
import ConsultationRequestsIndex from "../../Consultant/pages/ConsultationRequests/ConsultationRequestsIndex/ConsultationRequestsIndex";
import ConsultationRequestsDetails from "../../Consultant/pages/ConsultationRequests/ConsultationRequestsDetails/ConsultationRequestsDetails";

import RaisingRequest from "../pages/RaiseConsultationRequest";

const consultantDashboardRoutes = {
  path: "/",
  element: <Dashboard />,
  children: [
    {
      path: "home",
      element: <ProtectedRoute component={<DashboardHome />} />,
      children: [{ index: true, element: <DashboardIndex /> }],
    },

    {
      path: "drilling-licenses",
      element: <ProtectedRoute component={<DrillingLicenses />} />,
      children: [
        { index: true, element: <DrillingLicensesIndex /> },
        { path: ":id", element: <DrillingLicensesDetails /> },
      ],
    },
    {
      path: "examination-requests",
      element: <ProtectedRoute component={<ExaminationRequests />} />,
      children: [
        { index: true, element: <ExaminationRequestsIndex /> },
        {
          path: ":id",
          element: <ExaminationRequestsDetails />,
        },
      ],
    },
    {
      path: "consultation-requests",
      element: <ProtectedRoute component={<ConsultationRequests />} />,
      children: [
        { index: true, element: <ConsultationRequestsIndex /> },
        {
          path: ":id",
          element: <ConsultationRequestsDetails />,
        },
        { path: "raise-request", element: <RaisingRequest /> },
      ],
    },
  ],
};

export default consultantDashboardRoutes;
