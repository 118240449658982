import { Box, Typography, useMediaQuery } from "@mui/material";
import classes from "./BaseTableDoubleCard.module.scss";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { defaultEmptyValue } from "constants/Empty";
import { _void } from "utils/Objects/Objects";
import { useNavigate } from "react-router-dom";
import WithDetails from "../WithDetails/WithDetails";
import { displayCell } from "core-ui/BaseTable/TableHead/TableHeader";
import { isContentReady } from "utils/Array/Arrays";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import EmptyTable from "core-ui/BaseTable/EmptyTable/EmptyTable";

const BaseTableDoubleCard = (props) => {
  const navigate = useNavigate();

  const { palette } = useTheme();

  const { content, doubleLayer, withSpecificAction } = props;

  const { t } = useTranslation(["common", "home", "payments", "dashboard"]);

  const [insertedProps, setInsertedProps] = useState({});

  const insertNewPropsHandler = useCallback((newProps) => {
    setInsertedProps((prevState) => ({ ...prevState, ...newProps }));
  }, []);

  const tableHeaderSx = {
    background: `${palette.background.colorSoft3}`,
    borderRadius: ".5rem",
  };

  const tableHeadCellSx = {
    fontWeight: "bold",
    fontSize: "1.6rem",
    borderRadius: "5px",
    padding: "1.6rem",
  };

  const tableBodySx = {
    padding: "1.6rem 0",
  };

  const tableBodyCellSx = {
    borderSpacing: "0 12px",
    background: "#41958E05",
    outline: "1px solid #3F777340",
    borderRadius: "5px",
    marginBottom: "2rem",
  };

  const renderTableCell = (dataItem, headerItem, elementIndex, columnIndex) => {
    let renderedTableCell = dataItem[headerItem.serverKey];

    const customFormat = (dataItem) => headerItem.format;
    const _format = customFormat(dataItem);

    if (headerItem.applyNumbers === false)
      return renderedTableCell || headerItem?.fallback || defaultEmptyValue;

    // WE ADDED THIS TO GIVE A FALLBACK VALUE IN CASE IF IS THERE NO hasComponent Key in the headerItem
    if (
      (renderedTableCell === null ||
        renderTableCell === undefined ||
        renderTableCell === "" ||
        renderTableCell === false) &&
      !headerItem.hasComponent
    )
      return headerItem?.fallback || defaultEmptyValue;

    const isBasicHeaderItem = !headerItem.innerKey && !headerItem.innerProp;
    const hasInnerKey = headerItem.innerKey && !headerItem.innerProp;
    const hasInnerProp = headerItem.innerKey && headerItem.innerProp;

    const _serverKey = Array.isArray(headerItem.serverKey)
      ? headerItem.serverKey[0]
      : headerItem.serverKey;

    if (hasInnerProp) {
      renderedTableCell =
        dataItem[_serverKey][headerItem.innerKey][headerItem.innerProp] ??
        headerItem?.fallback;

      if (headerItem.format) {
        renderedTableCell = _format(
          dataItem[_serverKey][headerItem.innerKey][headerItem.innerProp] ??
            headerItem?.fallback
        );
      }
    }

    if (hasInnerKey) {
      renderedTableCell =
        dataItem[_serverKey][headerItem.innerKey] ?? headerItem?.fallback;
      if (headerItem.format) {
        renderedTableCell =
          _format(dataItem[_serverKey][headerItem.innerKey], dataItem) ??
          headerItem?.fallback;
      }
    }

    if (isBasicHeaderItem && headerItem.format) {
      renderedTableCell =
        _format(dataItem[_serverKey], dataItem) ?? headerItem?.fallback;
    }

    if (headerItem.hasComponent) {
      const arePropsArray = Array.isArray(headerItem.hasComponent.customProps);
      const extraProps = headerItem.hasComponent.extraProps;

      let componentProps = {
        ...dataItem,
        ...extraProps,
        tableHeaderItem: { ...headerItem },
        elementId: dataItem.id,
        elementIndex,
        tableHeader: props.tableHeader,
        columnIndex,
        insertProps: {
          props: { ...insertedProps },
          insertNewPropsHandler: insertNewPropsHandler,
        },
      };

      if (arePropsArray) {
        if (hasInnerKey) {
          headerItem.hasComponent.customProps.forEach((prop, idx) => {
            componentProps[prop] =
              dataItem[headerItem.serverKey][headerItem.innerKey[idx]] ??
              headerItem?.fallback;
          });
        } else if (hasInnerProp) {
          headerItem.hasComponent.customProps.forEach((prop, idx) => {
            componentProps[prop] =
              dataItem[headerItem.serverKey][headerItem.innerKey][
                headerItem.innerProp[idx]
              ] ?? headerItem?.fallback;
          });
        } else if (isBasicHeaderItem) {
          headerItem.hasComponent.customProps.forEach((prop, idx) => {
            componentProps[prop] =
              dataItem[headerItem.serverKey[idx]] ?? headerItem?.fallback;
          });
        }
      } else {
        // 1. CASE SERVER KEY
        if (isBasicHeaderItem) {
          componentProps = {
            ...componentProps,
            [headerItem.hasComponent.customProps]:
              dataItem[headerItem.serverKey] ?? headerItem?.fallback,
          };
        }
        // 1. CASE SERVER KEY && INNER KEY
        else if (hasInnerKey) {
          componentProps = {
            ...componentProps,
            [headerItem.hasComponent.customProps]:
              dataItem[headerItem.serverKey][headerItem.innerKey] ??
              headerItem?.fallback,
          };
        }
        // 3. CASE SERVER KEY && INNER KEY && INNER PROP KEY
        else if (hasInnerProp) {
          componentProps = {
            ...componentProps,
            [headerItem.hasComponent.customProps]:
              dataItem[headerItem.serverKey][headerItem.innerKey][
                headerItem.innerProp
              ] ?? headerItem?.fallback,
          };
        }
      }
      if (!!props.injectProps) {
        componentProps = {
          ...props.injectProps,
          ...componentProps,
        };
      }
      if (props.subData) {
        componentProps = {
          ...componentProps,
          ...dataItem[props.subData.subDataListServerKey],
        };
      }
      renderedTableCell = React.cloneElement(
        headerItem.hasComponent.comp,
        componentProps
      );
    }
    return renderedTableCell;
  };

  const renderedDoubleLayerComponent = (element) => {
    if (!doubleLayer || !doubleLayer(element)?.component) return null;

    const doubleLayerComponent = React.cloneElement(
      doubleLayer(element)?.component,
      {
        ...insertedProps,
        insertNewPropsHandler,
        props: { ...props },
        element: { ...element },
      }
    );
    return doubleLayerComponent;
  };

  const navigateWithDataHandler = (element) => {
    let toPath = element[[props.withDetails.paramsKey]];
    if (props.withDetails.serverKey && !props.withDetails.innerKey) {
      toPath =
        element[[props.withDetails.serverKey]][props.withDetails.paramsKey];
    } else if (props.withDetails.serverKey) {
      toPath =
        element[[props.withDetails.serverKey]][props.withDetails.innerKey][
          props.withDetails.paramsKey
        ];
    }
    navigate(
      props.withDetails.to(toPath),
      props.withDetails.transferData
        ? {
            state: { ...element },
          }
        : props.withDetails.state
        ? {
            state: { ...props.withDetails.state },
          }
        : {}
    );
    if (props.withDetails.action) {
      props.withDetails.action(element);
    }
  };

  const { breakpoints } = useTheme();

  const lg = useMediaQuery(breakpoints.down("lg"));

  const gridColumns = props.tableHeader?.filter(
    (hItem) => hItem.show !== false
  ).length;

  const extraUtilityColumns =
    +!!props.withAction + +!!props.withDetails + +!!props.withSpecificAction;

  const gridTemplateExtraColumns =
    extraUtilityColumns > 0
      ? `repeat(${extraUtilityColumns}, max-content)`
      : "";

  const doubleLayerVisibilityState = doubleLayer && doubleLayer()?.visibility;

  const [showDoubleLayer, setShowDoubleLayer] = useState(
    doubleLayerVisibilityState
  );

  const createWithSpecificActionComponent = (element, withSpecificAction) => {
    if (!withSpecificAction) return null;
    return React.cloneElement(withSpecificAction.component, {
      onClick: () => {
        if (withSpecificAction?.action) {
          withSpecificAction.action(element);
        }
        if (!withSpecificAction.ignoreState) {
          triggerVisibility(element);
        }
      },
    });
  };

  // withSpecificAction(contentItem)?.actionType === 'dropdown'

  useEffect(() => {
    if (isContentReady(content) && doubleLayer) {
      content.forEach((contentItem) => {
        let initialVisibilityValue = false;

        if (withSpecificAction) {
          if (doubleLayer(contentItem)?.specificRowId?.length > 0) {
            if (
              doubleLayer(contentItem)?.specificRowId?.includes(contentItem?.id)
            ) {
              initialVisibilityValue = true;
            } else {
              if (
                withSpecificAction(contentItem)?.condition &&
                doubleLayer &&
                doubleLayer(contentItem)?.visibility
              ) {
                initialVisibilityValue = true;
              }
            }
          } else {
            if (doubleLayer) {
              if (
                doubleLayer(contentItem)?.visibility ||
                withSpecificAction(contentItem)?.actionType === "dropdown"
              ) {
                initialVisibilityValue = true;
              }
            }
          }
        } else if (doubleLayer) {
          if (doubleLayer(contentItem)?.visibility) {
            initialVisibilityValue = true;
          }
        }

        setShowDoubleLayer((state) => ({
          ...state,
          [contentItem.id]: initialVisibilityValue,
        }));
      });
    }
  }, [content, doubleLayer, withSpecificAction]);

  const doubleLayerVisibility = (element) => {
    if (!doubleLayer || !showDoubleLayer?.[element.id]) return false;
    if (
      typeof showDoubleLayer === "boolean" &&
      !showDoubleLayer &&
      doubleLayer &&
      doubleLayer(element)?.specificRowId?.length > 0 &&
      doubleLayer(element)?.specificRowId?.includes(element?.id)
    ) {
      return true;
    }

    return (
      (showDoubleLayer !== null &&
        typeof showDoubleLayer === "object" &&
        element?.id in showDoubleLayer &&
        showDoubleLayer[element.id]) ||
      (typeof showDoubleLayer === "boolean" && showDoubleLayer)
    );
  };

  const triggerVisibility = (element) => {
    setShowDoubleLayer((state) => ({
      ...state,
      [element.id]: !state?.[element.id],
    }));
  };

  const actionComponentSx = {
    gridColumn: "1 / -1",
    background: "#41958E00",
    border: "1px solid #3F777340",
    borderRadius: "5px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "5rem",
    padding: "1rem;",
    marginBottom: props.withActionRow?.insertFirst ? "2rem" : "",
    order: props.withActionRow?.insertFirst ? "-1" : "0",
  };

  return (
    <Box
      sx={{
        ...props.tableMainWrapperSx,
      }}
    >
      {props.title && <BaseCardTitle mb={1}>{props.title}</BaseCardTitle>}

      {props.description && (
        <Typography
          sx={{ fontSize: "1.6rem" }}
          color={"grayDark.color1"}
          mb={3}
        >
          {props.description}
        </Typography>
      )}

      <Box
        className={classes["base-table-double"]}
        sx={{
          gridTemplateColumns: props.columnsWidth
            ? props.columnsWidth
            : `repeat(${
                lg ? 3 : gridColumns
              }, 1fr) ${gridTemplateExtraColumns}`,
        }}
      >
        <Box
          className={classes["base-table-double__header"]}
          sx={{
            ...tableHeaderSx,
          }}
        >
          {props.tableHeader.map((headerItem) => {
            const dataItem = props.content?.find((dataItem) => dataItem.id);

            return (
              <Box
                key={headerItem.serverKey || headerItem.name}
                sx={{
                  ...tableHeadCellSx,
                  display: displayCell(headerItem, dataItem),
                }}
                className={["base-table-double__header-item"]}
              >
                {t(headerItem.name, { ns: "tableHeader" })}
              </Box>
            );
          })}
        </Box>
        <Box
          className={classes["base-table-double__body"]}
          sx={{
            ...tableBodySx,
          }}
        >
          {!isContentReady(props.content) && props.emptyData ? (
            <EmptyTable
              component="div"
              sx={{
                gridColumn: "1 / -1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              emptyData={props.emptyData}
            />
          ) : !props.isTableContentEmpty ? (
            isContentReady(props.content) &&
            props.content.map((element, elementIndex) => {
              const withSpecificAction = props.withSpecificAction
                ? props.withSpecificAction(
                    { ...element, elementIndex: elementIndex },
                    showDoubleLayer
                  )
                : null;
              const WithSpecificActionComponent =
                createWithSpecificActionComponent(element, withSpecificAction);

              const isDropdownSpecificAction = props.withSpecificAction
                ? props.withSpecificAction(element).condition &&
                  props.withSpecificAction(element).actionType === "dropdown" &&
                  true
                : null;

              return (
                <Box
                  key={element.id}
                  className={classes["base-table-double__body-item"]}
                  sx={{
                    ...tableBodyCellSx,
                  }}
                >
                  <Box className={`${classes["base-table-double__body-item"]}`}>
                    {props.tableHeader.map((headerItem, columnIndex) => {
                      if (
                        insertedProps?.hiddenColumns?.includes(
                          `${columnIndex}-${element.id}`
                        )
                      ) {
                        return null;
                      }

                      return (
                        <Box
                          key={headerItem.serverKey + columnIndex + 1}
                          className={`${columnIndex}-${element.id}`}
                          sx={{
                            background: "base.white",
                            borderBottom: "1px solid borders.colorSoft2",
                            whiteSpace: "nowrap",
                            fontSize: "1.6rem",
                            padding: "1.6rem",
                            textWrap: "wrap",
                            display: displayCell(headerItem, element, false),
                            cursor: isDropdownSpecificAction ? "pointer" : "",
                            ...(!!insertedProps?.tableCellParentSx?.[
                              `${columnIndex}-${element.id}`
                            ] &&
                              insertedProps?.tableCellParentSx?.[
                                `${columnIndex}-${element.id}`
                              ]),
                          }}
                          onClick={
                            isDropdownSpecificAction
                              ? triggerVisibility.bind(null, element)
                              : _void
                          }
                        >
                          {renderTableCell(
                            element,
                            headerItem,
                            elementIndex,
                            columnIndex
                          )}
                        </Box>
                      );
                    })}
                    {props.withAction && (
                      <Box
                        className={classes["base-table-double__details-link"]}
                        sx={{
                          background: "base.white",
                          whiteSpace: "nowrap",
                          "&:hover": {
                            color: "primary.colorSoft3",
                          },
                          gridColumn: "-1",
                        }}
                      >
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={
                            props.withAction.action
                              ? props.withAction.action.bind(null, element)
                              : _void
                          }
                          style={{
                            color: "grayDark.color1",
                          }}
                        >
                          {props.withAction.comp}
                        </Box>
                      </Box>
                    )}
                    {withSpecificAction?.condition && (
                      <Box
                        sx={{
                          gridColumn: "-1",
                          padding: "1rem .5rem",
                        }}
                      >
                        {WithSpecificActionComponent}
                      </Box>
                    )}
                    {props.withDetails && (
                      <WithDetails
                        component={"div"}
                        navigateWithDataHandler={navigateWithDataHandler}
                        element={element}
                        sx={{ gridColumn: "-1" }}
                      />
                    )}
                  </Box>
                  {doubleLayerVisibility(element, elementIndex) &&
                    props.withSpecificAction &&
                    props.withSpecificAction(element)?.condition && (
                      <Box sx={{ gridColumn: "1 / -1", padding: "1.6rem" }}>
                        <hr style={{ marginBlock: "2rem" }} />
                        {renderedDoubleLayerComponent(element)}
                      </Box>
                    )}
                  {doubleLayerVisibility(element, elementIndex) &&
                    !props.withSpecificAction && (
                      <Box sx={{ gridColumn: "1 / -1", padding: "1.6rem" }}>
                        <hr style={{ marginBlock: "2rem" }} />
                        {renderedDoubleLayerComponent(element)}
                      </Box>
                    )}
                </Box>
              );
            })
          ) : (
            // ##
            <BaseLoader />
            // ##
          )}
          {props.withActionRow && (
            <Box sx={actionComponentSx}>
              {props.withActionRow.actionComponent}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BaseTableDoubleCard;
