import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { rejectionReasons } from "services/StaticLookup/RejectionReasons";

const FieldNotApplicableScoreFallback = (props) => {
  const { palette } = useTheme();

  const isLabSubmittedTheResults = props.submittedFromLabAt;

  const dynamicSkipReason = props?.[props.skipReasonKey || "skipReason"];

  const reason = rejectionReasons?.find(
    (reason) => reason.id === dynamicSkipReason
  );

  return (
    <Box
      sx={{
        background: palette.primary.colorSoft17,
        display: "flex",
        justifyContent: "center",
        gap: "5px",
        borderRadius: "3px",
        fontSize: "1.4rem",
      }}
      px={"2rem"}
      py={"1.3rem"}
    >
      {!isLabSubmittedTheResults || !dynamicSkipReason ? (
        <Typography fontSize="inherit">
          بانتظار إدخال البيانات من قبل المختبر
        </Typography>
      ) : (
        <>
          <Typography fontSize="inherit">
            لم يتم اجراء الاختبار، السبب:
          </Typography>
          <Typography fontSize="inherit" fontWeight={"bold"}>
            {reason?.name}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default FieldNotApplicableScoreFallback;
