import inspectionApi from "../../../Inspection/apis";

const inspectionContractorApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getAspeltReportStatus: builder.query({
      query: (inspection_layer_id) => ({
        url: `/api/inspection/aspeltGradationLimits/contractor/inspectionLayer/${inspection_layer_id}`,
      }),
    }),
    getAspeltBaseCourseAttachment: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionLayers/${inspectionLayerId}/aspeltBaseCourseAttachment`,
      }),
    }),
  }),
});

export const {
  useGetAspeltReportStatusQuery,
  useGetAspeltBaseCourseAttachmentQuery,
} = inspectionContractorApi;

export default inspectionContractorApi;
