import { UsersRolesEnum, UsersTypesEnum } from "constants/UsersTypes";

/**
 *
 * @param {string | undefined} token - User token retrieved from the server when the user Logged in successfully
 * @returns {object} - It returns an object of decoded data from the original token
 */
export function parseJwt(token) {
  if (!token) return null;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

/**
 *
 * @param {string} userType - Its a string that describe the current logged in user type
 * @param {Array<string> | string} usersTypes - It can be an array or a string that has another user type to compare with
 * @param {string | undefined} comp  - The component that will be rendered in case the current userType equal to usersTypes(2nd argument)
 * @returns {false | HTMLElement} - It returns either a falsy value or an comp<HTMLElement>(3rd argument)
 */
export function availableTo(userType, usersTypes, comp) {
  let show = false;
  let isArray = Array.isArray(usersTypes);

  if (isArray) {
    show = usersTypes.includes(userType);
  } else if (typeof usersTypes === "string") {
    show = userType === usersTypes;
  }

  // return show;
  return show && comp;
}

/**
 *
 * @description It returns the role of SYSOPS if the current user is "CONTRACTOR" and he has a role of "SYSOPS"
 * @param {string} userType - The current user type that logged in to the system successfully
 * @param {string} userRole - The user role of the current logged in user
 * @returns {string} - It returns SYSOPS user role in case of true
 */
export function sysopsContractorRoleChecker(userType, userRole) {
  // prettier-ignore
  if (!userType || !userRole || typeof userType !== 'string' || typeof userRole !== 'string') return false;

  return (
    userType.toLowerCase() === UsersTypesEnum.CONTRACTOR.toLowerCase() &&
    userRole.toLowerCase() === UsersRolesEnum.SYSOPS.toLowerCase() &&
    UsersRolesEnum.SYSOPS
  );
}

export function sysopsConsultantRoleChecker(userType, userRole) {
  // prettier-ignore
  if (!userType || !userRole || typeof userType !== 'string' || typeof userRole !== 'string') return false;

  return (
    userType.toLowerCase() === UsersTypesEnum.CONSULTANT.toLowerCase() &&
    userRole.toLowerCase() === UsersRolesEnum.SYSOPS.toLowerCase() &&
    UsersRolesEnum.SYSOPS
  );
}

export function sysopsLaboratoryRoleChecker(userType, userRole) {
  // prettier-ignore
  if (!userType || !userRole || typeof userType !== 'string' || typeof userRole !== 'string') return false;

  return (
    userType.toLowerCase() === UsersTypesEnum.LABORATORY.toLowerCase() &&
    userRole.toLowerCase() === UsersRolesEnum.SYSOPS.toLowerCase() &&
    UsersRolesEnum.SYSOPS
  );
}
export function laboratoryTechnicianRoleChecker(userType, userRole) {
  // prettier-ignore
  if (!userType || !userRole || typeof userType !== 'string' || typeof userRole !== 'string') return false;

  return (
    userType.toLowerCase() === UsersTypesEnum.LABORATORY.toLowerCase() &&
    userRole.toLowerCase() === UsersRolesEnum.LAB_TECHNICIAN.toLowerCase() &&
    UsersRolesEnum.LAB_TECHNICIAN
  );
}

export function sysopsCenterRoleChecker(userType, userRole) {
  // prettier-ignore
  if (!userType || !userRole || typeof userType !== 'string' || typeof userRole !== 'string') return false;

  return (
    userType.toLowerCase() === UsersTypesEnum.CENTER.toLowerCase() &&
    userRole.toLowerCase() === UsersRolesEnum.SYSOPS.toLowerCase() &&
    UsersRolesEnum.SYSOPS
  );
}

export function sysadminRoleChecker(userType, userRole) {
  // prettier-ignore
  if (!userType || !userRole || typeof userType !== 'string' || typeof userRole !== 'string') return false;

  return (
    userType.toLowerCase() === UsersTypesEnum.RESAA.toLowerCase() &&
    userRole.toLowerCase() === UsersRolesEnum.ADMIN.toLowerCase() &&
    UsersRolesEnum.ADMIN
  );
}

export function technicianRoleChecker(userType, userRole) {
  // prettier-ignore
  if (!userType || !userRole || typeof userType !== 'string' || typeof userRole !== 'string') return false;

  return (
    userType.toLowerCase() === UsersTypesEnum.RESAA.toLowerCase() &&
    userRole.toLowerCase() === UsersRolesEnum.TECHNICIAN.toLowerCase() &&
    UsersRolesEnum.TECHNICIAN
  );
}
