import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { AsseigndeRequestIndx } from "services/StaticLookup/Breadcrumb";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { labTechnicianAssignedRequests } from "services/StaticLookup/TableHeader/TableHeader";
import { useGetLaboratoryTechnicianTestsRequestsQuery } from "modules/LaboratoryTechnician/apis/inspection-apis/inspection-api";

const AssignedRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const { data, isFetching } = useGetLaboratoryTechnicianTestsRequestsQuery({
    page,
    size: pageSize,
    sortId: "id,desc",
    testType: "FIELD",
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <DashboardIntro
        title={`${t("assigned requests", { ns: "dashboard" })}`}
        description={t("assigned requests desc", {
          ns: "dashboard",
        })}
        breadcrumbData={AsseigndeRequestIndx(t)}
      />

      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={labTechnicianAssignedRequests}
          content={data?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.LAB_ASSIGNED_REQUESTS }}
          emptyData={{
            title: t("No requests"),
            desc: t("LabNoRequest"),
            img: publicAssetsPath("images/documents3.svg"),
          }}
          withDetails={{
            transferData: true,
            paramsKey: "id",
            to: (id) => `/assigned-requests/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "طلب",
            totalCount: data?.totalElements,
            onChangePaginationDataHandler,
          }}
        />
      </BaseCard>
    </>
  );
};

export default AssignedRequestsIndex;
