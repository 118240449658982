export default {
  CONTRACTOR_BALANCE_CODE: "CONTRACTOR_BALANCE_CODE",
  NOT_FOUND_ERROR: "NOT_FOUND_ERROR",
  INTEGRATION_SERVICE_ERROR: "INTEGRATION_SERVICE_ERROR",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
  LICENSE_EXPIRED: "LICENSE_EXPIRED",
  LICENSE_TYPE_NOT_SUPPORTED: "LICENSE_TYPE_NOT_SUPPORTED",
  CONTRACTOR_NOT_EXIST_IN_INTEGRATION_SERVICE:
    "CONTRACTOR_NOT_EXIST_IN_INTEGRATION_SERVICE",
  NO_APPOINTMENTS_AVAILABLE: "NO_APPOINTMENTS_AVAILABLE",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  FAILED_PAYMENT_TRANSACTION: "FAILED_PAYMENT_TRANSACTION",
  ALREADY_CERTIFICATED_LICENSE: "ALREADY_CERTIFICATED_LICENSE",
  NO_ATTACHMENT_EXIST_FOR_THIS_LICENSE: "NO_ATTACHMENT_EXIST_FOR_THIS_LICENSE",
  UNKNOWN_TRY_AGAIN_ERROR_CODE: "UNKNOWN_TRY_AGAIN_ERROR_CODE",
  CONTRACTOR_BALANCE: "CONTRACTOR_BALANCE",
  FAILED_PAYMENT_TRANSACTION_GENERIC_ERROR:
    "FAILED_PAYMENT_TRANSACTION_GENERIC_ERROR",
  FILE_DOWNLOAD_EXCEPTION: "FILE_DOWNLOAD_EXCEPTION",
  FAILURE_APPROVE_EDIT_REQUEST: "FAILURE_APPROVE_EDIT_REQUEST",
  CONTRACTOR_NO_BALANCE: "CONTRACTOR_NO_BALANCE",
  REJECTED_EDIT_REQUEST: "REJECTED_EDIT_REQUEST",
  STATIONS_REQUIRED: "STATIONS_REQUIRED",
  APPOINTMENT_REQUIRED: "APPOINTMENT_REQUIRED",
  CONSULTANT_INFO_NEEDED: "CONSULTANT_INFO_NEEDED",
  LICENSE_ALREADY_ADDED: "LICENSE_ALREADY_ADDED",
  ASPELT_ATTACHMENT_REQUIRED: "ASPELT_ATTACHMENT_REQUIRED",
  CONTRACTOR_APPOINTMENT_BOOKING: "CONTRACTOR_APPOINTMENT_BOOKING",
  CAN_NOT_SCHEDULE_APPOINTMENT_BEFORE_REQUIRED_HOURS:
    "CAN_NOT_SCHEDULE_APPOINTMENT_BEFORE_REQUIRED_HOURS",
  FAILED_SUBMIT_ISSUE: "FAILED_SUBMIT_ISSUE",
  FILE_TOO_LARGE: "FILE_TOO_LARGE",
  WRONG_FILE_EXTENSION: "WRONG_FILE_EXTENSION",
  REQUEST_STATUS_DOES_NOT_ALLOW_ACTION: "REQUEST_STATUS_DOES_NOT_ALLOW_ACTION",
  LABORATORY_EDIT_REQUEST_STATUS_DOES_NOT_ALLOW_ACTION:
    "LABORATORY_EDIT_REQUEST_STATUS_DOES_NOT_ALLOW_ACTION",
  EMAIL_ALREADY_REGISTERED: "EMAIL_ALREADY_REGISTERED",
  NATIONAL_ID_REGISTERED: "NATIONAL_ID_REGISTERED",
  EMAIL_ALREADY_REGISTERED: "EMAIL_ALREADY_REGISTERED",
  LICENSE_CONTRACTOR_NOT_MATCHED: "LICENSE_CONTRACTOR_NOT_MATCHED",
  LICENSE_NOT_FETCHED: "LICENSE_NOT_FETCHED",
  LICENSE_ALREADY_HAS_SUPERVISION: "LICENSE_ALREADY_HAS_SUPERVISION",
  LICENSE_HAS_PENDING_CONSULTANT_REQUEST:
    "LICENSE_HAS_PENDING_CONSULTANT_REQUEST",
  LICENSE_CERTIFICATE_ALREADY_ISSUED: "LICENSE_CERTIFICATE_ALREADY_ISSUED",
  LICENSE_ALREADY_ASSIGNED_TO_YOURSELF: "LICENSE_ALREADY_ASSIGNED_TO_YOURSELF",
  CONSULTANT_REQUEST_ALREADY_HANDLED_BEFORE:
    "CONSULTANT_REQUEST_ALREADY_HANDLED_BEFORE",
  STATIONS_STARTPOINT_IS_BIGGER_THAN_ENDPOINT:
    "STATIONS_STARTPOINT_IS_BIGGER_THAN_ENDPOINT",
  STATIONS_STARTPOINT_IS_OVERLAPPING: "STATIONS_STARTPOINT_IS_OVERLAPPING",
  REQUEST_CANNOT_BE_CANCELLED: "REQUEST_CANNOT_BE_CANCELLED",
  TESTED_BY_LAB: "TESTED_BY_LAB",
  LIMIT_EXCEEDED: "LIMIT_EXCEEDED",
  REQ_CAN_NOT_BE_ASSIGNED: "Assignment Status Must Be New or Assigned",
};
