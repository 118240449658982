import { Typography } from "@mui/material";
import React from "react";

function ValueUpdatedScore({ currentScore, updatedScore }) {
  
  const checkUpdatedScore = currentScore === updatedScore;
  const isValidScore =
    currentScore !== null &&
    currentScore !== undefined &&
    updatedScore !== null &&
    updatedScore !== undefined;

  return (
    <Typography
      component={"span"}
      sx={{
        backgroundColor:
          !checkUpdatedScore && isValidScore ? "rgba(255, 165, 0, 0.502)" : "transparent",
        padding: "0.5em 2em",
        borderRadius: "5px",
        fontSize: "1.6rem",
      }}
    >
      {!checkUpdatedScore ? updatedScore : ""}
    </Typography>
  );
}

export default ValueUpdatedScore;
