import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useForm } from "react-hook-form";
import { ValidEmailPattern } from "constants/Forms";
import { Typography } from "@mui/material";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { useCallback, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import useCatchUserType from "hooks/useCatchUserType";
import { consultantIdentityType } from "services/StaticLookup/Auth";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";
import BasePhoneInput from "core-ui/BasePhoneInput/BasePhoneInput";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { VerifyIDs } from "constants/Auth";
import { useConsultantRegisterMutation } from "modules/Auth/apis/consultant-auth-apis/register-api";
import messages from "services/AlertMessages/messages";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "store/auth-slice";

const RegisterFormConsultant = (props) => {
  const { t } = useTranslation(["auth", "common"]);
  const { userRoleFromURL } = useCatchUserType();
  const navigate = useNavigate();
  const showAlertHandler = useOutletContext();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const watchAll = watch();

  const { idType, nationalId } = watchAll;

  const [registerApi, registerApiResponse] = useConsultantRegisterMutation();

  const idNumberOnFocus = (event) => {
    if (!idType) {
      setError("idType", {
        type: "idType",
        message: "حقل نوع الهوية مطلوب",
      });
    }
  };

  const registrationData = useSelector(
    (state) => state.auth.consultantRegistrationData.registerDataForm
  );

  const location = useLocation();

  const cameFromOtp = location.state?.fromOtp;

  useEffect(() => {
    if (cameFromOtp && registrationData) {
      Object.entries(registrationData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [cameFromOtp, registrationData, setValue]);

  const validateIdNumberField = (nationalId) => {
    let sudiId = /^[1][0-9]{9}$/;
    let iqamaNumber = /^[2][0-9]{9}$/;
    if (idType?.id === "0" && !sudiId.test(nationalId)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
    if (idType?.id === "1" && !iqamaNumber.test(nationalId)) {
      return `فضلا تأكد من صحة المعلومات المدخلة`;
    }
  };
  useEffect(() => {
    if (idType && nationalId) {
      let errorMessage = validateIdNumberField(nationalId);
      if (errorMessage) {
        setError("nationalId", {
          type: "manual",
          message: errorMessage,
        });
      } else {
        clearErrors("nationalId");
      }
    }
  }, [idType, nationalId, setError, clearErrors]);

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    const idealData = {
      ...data,
      idType: data.idType.id,
    };
    registerApi(idealData)
      .unwrap()
      .then((responseData) => {
        dispatch(
          authActions.saveConsultantRegistrationData({
            registerDataForm: { ...data },
          })
        );
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_REGISTER,
          type: AlertTypes.SUCCESS,
        });
        setTimeout(() => {
          navigate(
            `/auth/${userRoleFromURL}/verify-otp`,
            {
              state: {
                verifyId: VerifyIDs.REGISTER,
                data: idealData,
                validateResponse: responseData,
              },
            },
            2000
          );
        });
      })
      .catch((err) => {
        let errorMessage =
          err && err.data.errorCode
            ? err.data.errorCode
            : ErrorCodes.NOT_AUTHORIZED;
        setShowErrorAlert(true);
        setErrorAlertMessage(errorMessage);
      });
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const isSubmitButtonDisabled =
    registerApiResponse.isLoading || registerApiResponse.isSuccess;

  return (
    <Box>
      <>
        {showErrorAlert && (
          <BaseAlert show={showErrorAlert} type={AlertTypes.WARNING} mb={2}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  fontWeight: "500",
                  marginLeft: "0.5rem",
                }}
              >
                {messages[errorAlertMessage]}
              </Typography>
              {(errorAlertMessage === ErrorCodes.EMAIL_ALREADY_REGISTERED ||
                errorAlertMessage === ErrorCodes.NATIONAL_ID_REGISTERED) && (
                <Typography
                  component={Link}
                  to={`/auth/${userRoleFromURL}/login`}
                  sx={{
                    fontSize: "1.4rem",
                    color: "primary.colorSoft2",
                    fontWeight: "500",
                    marginLeft: "0.5rem",
                  }}
                >
                  تسجيل الدخول
                </Typography>
              )}
            </Box>
          </BaseAlert>
        )}
      </>
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <FormTitle title="إنشاء حساب" />

        <Typography sx={{ color: "grayDark.color", fontSize: "1.5rem" }} mb={4}>
          يرجى التأكد من أن رقم السجل التجاري والاسم الخاص بشركة الاستشارات
          مطابقة لبيانات السجل التجاري الخاص بشركة الاستشارات.
        </Typography>

        <WrapperContentGrid
          sx={{ marginBottom: "4rem", flexDirection: "row" }}
          columnsWidth={20}
          gap={2}
        >
          <BaseInput
            htmlFor="crNumberCus"
            name="crNumber"
            label={t("crNumberCus")}
            placeholder={t("crNumber")}
            maxLength={10}
            register={{
              ...register("crNumber", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("crNumber")} ${t("required")}`,
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: `يجب أن يكون رقم السجل التجاري صحيح`,
                },
                minLength: {
                  value: 10,
                  message: `يجب أن يكون رقم السجل التجاري صحيح`,
                },
              }),
            }}
            errors={errors}
          />

          <BaseInput
            htmlFor="NameConsultingCompany"
            label={t("Name of consulting company")}
            name="companyName"
            maxLength={100}
            placeholder={"اسم الشركة"}
            register={{
              ...register("companyName", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("اسم الشركة")} ${t("required")}`,
                },
              }),
            }}
            errors={errors}
          />
          <BaseInput
            htmlFor="ConsultantName"
            label={t("ConsultantName")}
            name="name"
            maxLength={70}
            placeholder={t("ConsultantName")}
            register={{
              ...register("name", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("ConsultantName")} ${t(
                    "required"
                  )}`,
                },
              }),
            }}
            errors={errors}
            Consultant
            Name
          />

          <Box sx={{ marginBottom: "4rem" }}>
            <Typography
              sx={{
                color: "grayDark.color",
                fontSize: "1.4rem",
                fontWeight: 500,
              }}
            >
              {t("idType")}
            </Typography>
            <BaseDropdown
              control={control}
              name="idType"
              required
              errors={errors}
              placeholder={t("idType")}
              data={consultantIdentityType}
              selectMenuStyles={{ height: "12rem !important" }}
            />
          </Box>

          <BaseInput
            htmlFor="nationalId"
            label={t("nationalId")}
            name={"nationalId"}
            maxLength={10}
            onFocus={idNumberOnFocus}
            register={{
              ...register("nationalId", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("idNumber")} ${t("required")}`,
                },
                maxLength: {
                  value: 10,
                  message: `فضلا تأكد من صحة المعلومات المدخلة`,
                },
                validate: validateIdNumberField,
              }),
            }}
            errors={errors}
          />

          <BasePhoneInput
            htmlFor="mobile"
            label={t("dashboard:phoneNumber")}
            name="mobile"
            errors={errors}
            control={control}
            placeholder="05 xxx xxxx"
            register={{
              ...register("mobile", {
                required: {
                  value: true,
                  message: `${t("حقل رقم الجوال مطلوب ")} `,
                },
              }),
            }}
          />
          <BaseInput
            htmlFor="email"
            label={t("email")}
            name={"email"}
            type="email"
            register={{
              ...register("email", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("email")} ${t("required")}`,
                },
                pattern: {
                  value: ValidEmailPattern,
                  message: `${t("Invalid email address")}`,
                },
              }),
            }}
            errors={errors}
          />
        </WrapperContentGrid>
        <Box sx={{ marginTop: 7, textAlign: "center" }}>
          <BaseButton
            type="submit"
            disabled={isSubmitButtonDisabled}
            variant={isSubmitButtonDisabled ? "disabled" : ""}
          >
            {t("next", { ns: "common" })}
          </BaseButton>
        </Box>
      </form>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "1900px",
          px: "6.1rem",
        }}
        mt={4}
        gap={1}
      >
        <Typography sx={{ fontSize: "1.4rem" }}>لديك حساب مسبق؟</Typography>
        <Link to={`/auth/${userRoleFromURL}/login`}>
          <Typography
            sx={{
              fontSize: "1.4rem",
              color: "primary.colorSoft2",
              fontWeight: "500",
            }}
          >
            تسجيل الدخول
          </Typography>
        </Link>
      </Grid>
    </Box>
  );
};

export default RegisterFormConsultant;
