import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Notes = (props) => {
  const { t } = useTranslation(["dashboard"]);
  return (
    props.notes && (
      <Box className={"request-data-details"}>
        <Typography className={"request-data-details__title"}>
          {t("notes")}
        </Typography>
        <Typography fontSize={"1.6rem"} color={"grayDark.color"}>
          {props.notes}
        </Typography>
      </Box>
    )
  );
};

export default Notes;
