import authApi from ".";

const userApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    addNewUser: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/addUser",
        method: "POST",
        body: body,
      }),
    }),
    support: builder.mutation({
      query: (body) => ({
        url: "/api/user/jira-ticket/jiratickets",
        method: "POST",
        body,
      }),
    }),
    addNewLabTechnician: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/addLaboratoryTech",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Add-New-User"],
    }),
  }),
});

export const {
  useAddNewUserMutation,
  useSupportMutation,
  useAddNewLabTechnicianMutation,
} = userApi;
