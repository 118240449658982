import { isValidArray } from "./utilites";

export function extractCycleItemByKeys(sortedTransformedData, cycleKeys) {
  let cycleItems = [];

  for (let [key, value] of sortedTransformedData) {
    if (cycleKeys.includes(key)) {
      const hasValue = !!value;
      const idealValue = Array.isArray(value) ? value : [{ [key]: value }];
      if (hasValue) {
        cycleItems.push(...idealValue);
      }
    }
  }
  return cycleItems;
}

export function sortCycleItemsByKey(cycleItems, dateKey) {
  return [...cycleItems].sort(
    (a, b) => new Date(a[dateKey]) - new Date(b[dateKey])
  );
}

export function injectDataToHistoryItems(list, keys) {
  return list.map((item) => {
    return { ...item, ...keys, _date: item[keys._date] };
  });
}

export function createLogConfig(config) {
  // prettier-ignore
  if (!isValidArray(config.isBackendValueExists) && !config.isBackendValueExists) {
    return null
  }

  const returnedConfigObject = {};

  for (let key in config) {
    returnedConfigObject[key] = config[key] || "";
  }
  return returnedConfigObject;
}
