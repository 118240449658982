import * as React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./PdfStyles";
import { Footer } from "./Footer/Footer";
import Header from "./Header/Header";
import { createChunk } from "utils/CreateChunk/CreateChunk";

export const BasePDF = ({
  title,
  subtitles,
  tableTitle,
  TableTitle1,
  TableTitle2,
  children,
  toBeChunkedData,
  tableTitle1Margin,
  tableTitle2Margin,
}) => {
  const CHUNK_SIZE = 5;
  const MAX_ITEM_PER_PAGE = 12;

  const chunks = createChunk(toBeChunkedData, CHUNK_SIZE, MAX_ITEM_PER_PAGE)
    ? createChunk(toBeChunkedData, CHUNK_SIZE, MAX_ITEM_PER_PAGE)
    : [];

  return (
    <Document>
      {chunks.map((chunk, pageIndex, org) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          {pageIndex === 0 && (
            <>
              <Header mainTitle={title} subtitles={subtitles} />
              <View>
                <View style={styles.tableTitleContainer}>
                  <Text style={styles.TableTitle}>{tableTitle}</Text>
                </View>
                <View style={styles.tableHeaderContainer}>
                  <View>
                    <Text style={{ marginLeft: tableTitle1Margin || 6 }}>
                      {TableTitle1}
                    </Text>
                  </View>
                  <View>
                    <Text style={{ marginLeft: tableTitle2Margin || 200 }}>
                      {TableTitle2}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}

          {children(chunk, pageIndex, org)}

          <View style={styles.watermarkContainer}>
            <Image
              src={"/assets/images/water-mark.png"}
              style={styles.watermark}
            />
          </View>

          <Footer
            fixed
            footerText={
              "هذه الوثيقة مرسلة من النظام الآلي ولا تحتاج إلى توقيع."
            }
          />
        </Page>
      ))}
    </Document>
  );
};
