export const EditableStatusEnum = {
  CAN_EDIT: "CAN_EDIT",
  PENDING_ADMIN: "PENDING_ADMIN",
  TEST_NOT_COMPLETED: "TEST_NOT_COMPLETED",
  EXCEEDED_7_DAYS: "EXCEEDED_7_DAYS",
  CERTIFICATE_IS_ISSUED: "CERTIFICATE_IS_ISSUED",
  LIMIT_EXCEEDED: "LIMIT_EXCEEDED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
};
