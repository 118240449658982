import { Box, Typography } from "@mui/material";
import classes from "./DashboardUserInfo.module.scss";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { publicAssetsPath } from "utils/Paths/Paths";
import { availableTo, sysopsContractorRoleChecker } from "utils/Auth/Auth";

import { formattedNumber } from "utils/Numbers/Numbers";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { getUserToken } from "services/Storage";
import useBreakpoint from "hooks/useBreakpoint";
import { ReactComponent as SettingIcon } from "assets/icons/setting.svg";

const BaseCardStyles = {
  display: "flex",
  alignItems: "center",
  px: "1rem",
  py: "1rem",
  borderRadius: "5px",
};

const DashboardUserInfo = (props) => {
  const { t } = useTranslation();

  const { userType, user, userRole } = useSelector((store) => store.auth);

  const matches = useBreakpoint();

  const loggedInUserDataFromStorage = getUserToken();

  if (matches) return null;

  return (
    <>
      {!props.isLoading && user ? (
        <Box className={classes["dashboard-user-info"]}>
          {availableTo(
            userRole,
            sysopsContractorRoleChecker(userType, userRole),
            <Box className={classes["dashboard-user-info__settings"]}>
              <Box onClick={() => {}}>
                <BaseCard {...BaseCardStyles}>
                  <Link
                    to={`/settings`}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <SettingIcon />
                  </Link>
                </BaseCard>
              </Box>
            </Box>
          )}
          {availableTo(
            userRole,
            sysopsContractorRoleChecker(userType, userRole),
            <Box className={classes["dashboard-user-info__details"]}>
              <BaseCard {...BaseCardStyles}>
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    src={publicAssetsPath("icons/wallet.svg")}
                    width="auto"
                  />
                  <Box>
                    <Link
                      to={`/wallet`}
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        className={classes["dashboard-user-info__details-item"]}
                      >
                        <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                          {t("wallet", { ns: "dashboard" })}:
                        </Typography>
                      </Box>
                      <Box
                        className={
                          classes["dashboard-user-info__wallet-points"]
                        }
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.4rem",
                            ml: 0.5,
                          }}
                        >
                          {formattedNumber(props.contractorData?.wallet || 0)}
                        </Typography>
                      </Box>
                      <Box
                        className={classes["dashboard-user-info__details-item"]}
                      >
                        <Typography sx={{ ml: 0.5 }}>
                          {t("point", { ns: "dashboard" })}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </BaseCard>
            </Box>
          )}
          <Box
            className={classes["dashboard-user-info__user"]}
            display={"flex"}
            alignItems={"center"}
          >
            <Typography sx={{ fontSize: "1.6rem" }}>
              {t("hi", { ns: "common" })}
            </Typography>
            <p className={classes["dashboard-user-info__user-name"]}>
              {loggedInUserDataFromStorage?.data?.user_name || ""}
            </p>
          </Box>
        </Box>
      ) : (
        <BaseLoader />
      )}
    </>
  );
};

export default DashboardUserInfo;
