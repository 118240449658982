import { Box, Typography } from "@mui/material";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseTable from "core-ui/BaseTable/BaseTable";
import React, { useEffect } from "react";
import { licenses } from "services/StaticLookup/TableHeader/Consultant";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

function AddedLicense({ addedLicenses, onDeleteLicense }) {
  return (
    <Box>
      <BaseCardTitle mb={3}>{"الرخص المضافة"}</BaseCardTitle>
      <BaseTableIndex
        tableHeader={licenses}
        emptyData={{
          desc: "ستظهر هنا جميع الرخص التي تتم إضافتها",
        }}
        content={addedLicenses}
        withAction={{
          comp: (
            <CloseIcon
              fontSize="large"
              sx={{
                color: "primary.main",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              <DeleteIcon />
            </CloseIcon>
          ),
          action: (license) => {
            onDeleteLicense(license.id);
          },
        }}
        withActionRow={{
          description:
            "يمكنك البحث عن رخصة أخرى و إضافتها إلى قائمة الرخص المضافة",
        }}
      />
    </Box>
  );
}
export default AddedLicense;
