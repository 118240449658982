import adminApi from "../../../Admin/apis";
import { queryBuilder } from "utils/ApiHelpers";

const subusersApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getlabTechnicians: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/admin/laboratorys/assignment/labTechnicians?${q}`,
        };
      },
      providesTags: ["Lab-Tech-List"],
    }),
  }),
});

export const { useGetlabTechniciansQuery } = subusersApi;

export default subusersApi;
