import { useState } from "react";
import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import { defaultEmptyValue } from "constants/Empty";
import BaseModal from "core-ui/BaseModal/BaseModal";
import StationsDataModal from "components/AllModalContent/StationsDataModal/StationsDataModal";
import {
  useGeStationNumberQuery,
  useGetAppointmentDetailsForLabQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { renderLabAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { formattedNumber } from "utils/Numbers/Numbers";

const FieldNewRequestData = (props) => {
  const { data: stations } = useGeStationNumberQuery({
    id: props.inspection.id,
  });

  const [showStation, setShowStation] = useState(false);

  const onLeavePageHandler = () => setShowStation(false);

  const { data: appointmentData } = useGetAppointmentDetailsForLabQuery(
    props.inspection.id
  );

  return (
    <Box>
      <BaseModal
        show={showStation}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <StationsDataModal onHide={onLeavePageHandler} stations={stations} />
      </BaseModal>
      <Box className={"request-data-details"} mt={4}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid columnsWidth={props.columns || 30} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection?.id || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الطلب
              </Typography>
              <BaseStatus
                status={props.inspection?.status}
                dataId={props.dataId}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اختبار مواد الأعمال المدنية
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection?.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              تكلفة الاختبار
            </Typography>
            <Typography className={"request-data-details__value"}>
              {`${formattedNumber(props.inspection?.labPrice)} ريال` || defaultEmptyValue}
            </Typography>
          </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الموقع
              </Typography>
              <LocationField location={props.inspection?.licenses[0]?.locationUrl} />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نوع الفحص
              </Typography>
              <BaseTypeTag type={props.inspection?.testType} />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                المدة المتوقعة
              </Typography>
              <Typography className={"request-data-details__value"}>
                1 أيام
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ موعد الفحص
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                {formatDateYYYYMMDD(appointmentData?.scheduledDate)}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                وقت الفحص
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                {renderLabAppointmentTimeLabel(appointmentData?.scheduledTime)}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
      </Box>
    </Box>
  );
};

export default FieldNewRequestData;
