import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetLicenseDetailsQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Helmet } from "react-helmet";
import { drillingLicensesdetails } from "services/StaticLookup/Breadcrumb";
import { fromPage } from "constants/FromPages";
import DrillingSites from "modules/Contractor/components/DataDetails/DrillingSites/DrillingSites";
import { renderTabs } from "utils/Tabs/Tabs";
import { DrillingLicensesRequestTabs } from "services/StaticLookup/Tabs";
import DrillingLicensesRequest from "../DrillingLicensesRequest/DrillingLicensesRequest";
import { useSelector } from "react-redux";
import DrillingLicensesData from "modules/Consultant/components/DrillingLicensesData/DrillingLicensesData";

const DrillingLicensesDetails = () => {
  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();

  const { userType, userRole } = useSelector((store) => store.auth);

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const params = useParams();

  //prettier-ignore
  const { data: licenseData, isFetching , isSuccess} = useGetLicenseDetailsQuery(params.id);

  const onClickHandler = () => {
    navigate("/home/request-path", {
      state: {
        fromPage: fromPage.RAISE_NEW_REQUEST_LICENSE_DETAILS,
        selectedLicenseData: licenseData,
      },
    });
  };

  const handleChange = (_, newValue) => {
    setTabsActiveIndex(newValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`رخص الحفر - رقم الرخصة ${licenseData?.licenseNumber}`}</title>
        <link rel="canonical" href="/drilling-licenses" />
      </Helmet>

      <DashboardIntro
        title={`${t("license number", { ns: "dashboard" })} ${
          licenseData?.licenseNumber || ""
        }`}
        description={`تستعرض الصفحة بيانات الرخصة والطلبات المتعلقة بها`}
        breadcrumbData={drillingLicensesdetails({
          licenseNumber: licenseData?.licenseNumber,
          licenseYear: licenseData?.licenseYear,
        })}
      />

      <BaseCard mt={6} py={"1rem"} px={"1rem"}>
        {isFetching ? (
          <BaseLoader />
        ) : (
          <>
            <Tabs
              value={tabsActiveIndex}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              className="tabs"
            >
              {renderTabs(DrillingLicensesRequestTabs).map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  sx={{
                    background:
                      tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                    fontSize: "1.6rem",
                  }}
                />
              ))}
            </Tabs>
            {tabsActiveIndex === 1 && isSuccess && (
              <Box>
                <DrillingLicensesData data={licenseData} />
                <DrillingSites
                  onClickHandler={onClickHandler}
                  location={[licenseData]}
                  selectedLicense={licenseData}
                  userData={{ userType, userRole }}
                />
              </Box>
            )}
            {tabsActiveIndex === 2 && (
              <DrillingLicensesRequest data={licenseData} />
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default DrillingLicensesDetails;
