import { useCallback, useState } from "react";

const useAlert = () => {
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    type: null,
  });

  const showAlertHandler = useCallback((data) => {
    setAlert({
      show: data.show,
      message: data.message,
      type: data.type,
      interpolation: data.interpolation,
    });
  }, []);

  const hideAlertHandler = useCallback(() => {
    setAlert({ show: false, message: null, type: null, interpolation: {} });
  }, []);

  return {
    showAlertHandler,
    hideAlertHandler,
    alert,
  };
};

export default useAlert;
