import { Box, FormHelperText, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseTooltip from "core-ui/BaseTooltip/BaseTooltip";
import { useTranslation } from "react-i18next";
import SoilLocationContent from "./SoilLocationContent/SoilLocationContent";
import { useState } from "react";
import BaseModal from "core-ui/BaseModal/BaseModal";
import { useTheme } from "@emotion/react";

const SoilLocation = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [showModal, setShowModal] = useState(false);

  const { palette } = useTheme();

  const { setValue, name, value, licenseLayerName } = props;

  const error = props.errors[name];

  const onLeavePageHandler = () => {
    setShowModal(false);
  };

  const onShowModalHandler = () => {
    setShowModal(true);
  };

  const asphalet = licenseLayerName?.includes("Asphalt");

  return (
    <>
      <BaseModal
        show={showModal}
        py={"3rem"}
        px={"2rem"}
        onHide={onLeavePageHandler}
        title="تحديد الموقع"
      >
        <SoilLocationContent
          onHide={onLeavePageHandler}
          setValue={setValue}
          name={name}
          onChange={props.onChange}
          value={value}
        />
      </BaseModal>
      <Box
        mb={1}
        sx={{ mt: props.containerStyles.mt || 8, ...props.containerStyles }}
      >
        <Box display={"flex"} alignItems={"center"} gap={"1rem"} name={name}>
          <BaseCardTitle sm={props.sm} mb={1} mt={1}>
            {`موقع أخذ العينة`}
          </BaseCardTitle>
          {!asphalet && (
            <BaseTooltip type={"tooltip-black"} text={"موقع التشويه"} />
          )}
        </Box>

        {props.value?.formattedAddress ? (
          <Typography
            fontSize={"1.4rem"}
            mb={3}
            fontWeight={"500"}
            color={palette.primary.colorSoft13}
          >
            {props.value.formattedAddress}
          </Typography>
        ) : (
          <Typography fontSize={"1.4rem"} mb={3}>
            يرجى تحديد الموقع من خلال الخريطه ليتمكن فني الرقابة من جمع العينات
          </Typography>
        )}

        <BaseButton
          sx={{ minWidth: "12.2rem", fontSize: props.sm ? "1.4rem" : "1.6rem" }}
          variant={"secondary"}
          onClick={onShowModalHandler}
        >
          {t("تحديد الموقع")}
        </BaseButton>

        {error && (
          <Box mb={-2.9}>
            <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
              {error.message}
            </FormHelperText>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SoilLocation;
