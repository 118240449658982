import EditRequestLab from "modules/Admin/components/DataDetails/EditRequest/Lab/EditRequestLab";
import React from "react";

function LabEditRequestDetails({ data , isFetching}) {
  const isAsphalt = data?.inspectionName?.includes("Asphalt");
  return (
    <EditRequestLab
      isAsphalt={isAsphalt}
      data={data}
      isFetching={isFetching}
    />
  );
}

export default LabEditRequestDetails;
