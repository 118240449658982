/**
 *
 * @param {number} layerLong - The loyer's long number
 * @returns {number} - It returns the number of required stations based on layer's long
 */
export const calculateStationsNumber = (layerLong) => {
  if (layerLong > 0 && layerLong <= 100) {
    return 1;
  }
  if (layerLong > 100 && layerLong <= 200) {
    return 2;
  }
  if (layerLong > 200 && layerLong <= 300) {
    return 3;
  }
  if (layerLong > 300 && layerLong <= 400) {
    return 4;
  }
  if (layerLong > 400) {
    return 5;
  }
  return 1;
};

export const getStationsIds = (stationData) => {
  return Object.keys(stationData)
    .filter((name) => name.startsWith("station") && name.includes("to"))
    .map((station) =>
      parseInt(station.replace("station-", "").replace("-to", ""))
    );
};

export const transformStationToFloat = (station) =>
  parseFloat(station.replace("+", ""));

/**
 *
 * @param {object} stationsData - The collected stations data
 * @returns {Array<{to: number, from: number}>} - It returns a list of combined to/from keys that describes the station start to end points
 */
export const prepareStationServerData = (stationsData, withId) => {
  const stationsCount = getStationsIds(stationsData);

  let stations = stationsCount.map((id) => {
    let serverStationObject = {};
    if (withId) serverStationObject.id = id;

    serverStationObject.to = transformStationToFloat(
      stationsData[`station-${id}-to`]
    );
    serverStationObject.from = transformStationToFloat(
      stationsData[`station-${id}-from`]
    );
    serverStationObject.layerNumber =
      +stationsData[`station-${id}-layerNumber`];
    serverStationObject.layerTest =
      stationsData[`station-${id}-layerTest`];
    serverStationObject.length = +stationsData[`station-${id}-length`];

    return serverStationObject;
  });

  return stations;
};

export const checkStationsOverlapping = (stationsData) => {
  const stations = prepareStationServerData(stationsData, true);

  let overlappingStations = [];

  if (stations.length < 2) return [];

  for (let i = 0; i < stations.length; i++) {
    const iFrom = stations[i]?.from;
    const iTo = stations[i]?.to;

    for (let j = i + 1; j < stations.length; j++) {
      const jFrom = stations[j]?.from;
      const jTo = stations[j]?.to;

      if (
        (iFrom !== jFrom && iTo !== jTo) &&
        (iTo <= jFrom || iFrom >= jTo || 
        ((iTo !== 0 && !iTo) || 
        (jFrom !== 0 && !jFrom) ||
        (iFrom !== 0 && !iFrom) ||
        (jTo !== 0 && !jTo)))
      ) {
        continue;
      } else if (!overlappingStations.includes(stations[j]?.id)) {
        overlappingStations.push(stations[j]?.id);
      }
    }
  }

  return overlappingStations;
};

export const calculateLength = (from, to) => {
  let result = "-";

  if (from && to && from.includes("+") && to.includes("+")) {
    const modifiedFrom = transformStationToFloat(from);
    const modifiedTo = transformStationToFloat(to);

    const fromMeters = from.split("+")[1];
    const fromMetersDec = fromMeters.split(".")[0];
    const toMeters = to.split("+")[1];
    const toMetersDec = toMeters.split(".")[0];

    if (
      fromMeters &&
      toMeters &&
      fromMetersDec.length === 3 &&
      toMetersDec.length === 3
    ) {
      if (modifiedTo !== modifiedFrom && modifiedTo >= modifiedFrom) {
        result = `${Math.round((modifiedTo - modifiedFrom) * 1000) / 1000}`;
      } else {
        result = "error";
      }
    }
  }
  return result;
};


export const getTestsTemplateData = (stationsData) => {
  const stations = prepareStationServerData(stationsData, true);

  let testTempArray = stations.map(station => {
    let testTempObject = {};
    testTempObject.combinedFieldTestCost = station?.layerTest?.combinedFieldTestCost;
    testTempObject.id = station?.layerTest?.id;
    testTempObject.name = station?.layerTest?.name;
    testTempObject.inspectionTemplate = station?.layerTest?.inspectionTemplate;
    testTempObject.estimatedTime = station?.layerTest?.estimatedTime;
    return testTempObject;
  })
  
  return testTempArray
};
