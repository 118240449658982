import BaseTable from "core-ui/BaseTable/BaseTable";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { consultationRequests } from "services/StaticLookup/TableHeader/Consultant";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useGetConsultationRequestsQuery } from "modules/Consultant/apis/consultation-requests-apis/consultation-requests-apis";
import { TABLE_ID } from "constants/Table";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Helmet } from "react-helmet";
import { ConsultantsRequest } from "services/StaticLookup/Breadcrumb";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useEffect } from "react";
import useAlert from "hooks/useAlert";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const ConsultationRequestsIndex = () => {
  const { t } = useTranslation(["dashboard"]);
  const location = useLocation();
  const navigate = useNavigate();
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const { data: consultationRequestsData, isFetching } =
    useGetConsultationRequestsQuery({ page, size: pageSize, sort: "id,desc" });

  const tableEmptyData = {
    img: publicAssetsPath("images/documents.svg"),
    title: t("No requests"),
    desc: t("no consultation requests desc"),
  };

  const AddConsultationRequestButton = (props) => {
    return (
      <BaseButton
        sx={{ minWidth: "17.3rem" }}
        onClick={() => navigate("/consultation-requests/raise-request")}
      >
        {t("request for attribution of license")}
      </BaseButton>
    );
  };

  useEffect(() => {
    const actionAlert = location.state?.alert;
    if (actionAlert) {
      showAlertHandler(actionAlert);
      navigate("", { replace: true }); //clear state on refresh
    }
  }, [location, showAlertHandler, navigate]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلبات إسناد الرخص`}</title>
      </Helmet>

      <DashboardIntro
        title={`${t("consultation requests", {
          ns: "dashboard",
        })}`}
        description={
          <>
            يمكنك في هذه الصفحة استعراض جميع طلبات إسناد الرخص، كما يمكنك أيضًا
            رفع
            <span style={{ fontWeight: 800 }}> طلب إسناد على رخصة </span>
            جديدة.
          </>
        }
        action={<AddConsultationRequestButton />}
        breadcrumbData={ConsultantsRequest({})}
        warning={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            destroy={hideAlertHandler}
            autoClose={5}
            icon={false}
            closeIcon={true}
            message={alert.message}
            width={"40rem"}
          />
        }
      />
      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={consultationRequests}
          content={consultationRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.CONSULTANT_CONSULTATION_REQUESTS }}
          emptyData={tableEmptyData}
          withDetails={{
            paramsKey: "requestNumber",
            to: (id) => `/consultation-requests/${id}`,
          }}
          pagination={{
            displayBy: [5, 10, 15],
            label: "طلب",
            onChangePaginationDataHandler,
            totalCount: consultationRequestsData?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default ConsultationRequestsIndex;
