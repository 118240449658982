import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { ReactComponent as ExclamationMarkIcon } from "assets/icons/exclamationMark.svg";

const CollectSampleDataModal = (props) => {
  const navigate = useNavigate();

  const { palette } = useTheme();

  const { t } = useTranslation(["dashboard"]);

  const onLeavePageHandler = () => {
    // ADD RESET TO COLLECTED SAMPLE DATA HERE FROM REDUX
    props.onHide();
    navigate(-1);
  };

  return (
    <>
      <Box sx={{ mb: 14 }}>
        <Box sx={{ display: "flex", mb: 2 }}>
          <ExclamationMarkIcon />
          <Typography
            sx={{
              mt: 2,
              // display: "flex",
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "grayDark.color",
              ml: 2,
              mb: 1.5,
            }}
          >
            {/* {props.text} */}
            هل انت متأكد من مغادرة الصفحة؟
          </Typography>
        </Box>
        <hr />
        <Typography
          sx={{
            mt: 3,
            // display: "flex",
            fontSize: "1.6rem",
            color: "text.tags.blueSoft",
          }}
        >
          {/* {props.descr} */}
          لن يقوم النظام بحفظ البيانات المدخلة عند مغادرة الصفحة
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
            mr: 1,
            // color: "text.tags.blueSoft",
            color: `${palette.text.tags.blueSoft}`,
          }}
          variant={"transparent"}
          // onClick={prevButtonHandler} // popup message (  "هل انت متأكد من مغادرة الصفحة؟ لن يقوم النظام بحفظ المعلومات عند مغادرة الصفحة" "البقاء" "المغادرة")
          onClick={props.onHide}
        >
          {t("التراجع")}
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          variant={"secondary"}
          onClick={onLeavePageHandler}
        >
          {t("مغادرة")}
        </BaseButton>
      </Box>
    </>
  );
};

export default CollectSampleDataModal;
