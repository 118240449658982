import { Box, Typography } from "@mui/material";
import BaseFancyRadioGroup from "core-ui/BaseFancyRadioGroup/BaseFancyRadioGroup";
import { AlertTypes } from "constants/AlertTypes";
import {
  displayAvailableExtensionsSupportForm,
  supportFormAvailableExtensions,
} from "constants/File";
import { ValidEmailPattern } from "constants/Forms";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BasePhoneInput from "core-ui/BasePhoneInput/BasePhoneInput";
import BaseTextArea from "core-ui/BaseTextArea/BaseTextArea";
import BaseUploadFile from "core-ui/BaseUploadFile/BaseUploadFile";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { useSupportMutation } from "modules/Auth/apis/user-api";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { incidents } from "services/StaticLookup/Incidents";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { UsersJobsSupportPage } from "services/StaticLookup/UsersJobs";
import { _scrollToTop } from "utils/DOM/DOM";
import ReCAPTCHA from "react-google-recaptcha";
import BaseRecaptcha from "core-ui/BaseRecaptcha/BaseRecaptcha";

const SupportPageForm = (props) => {
  const { t } = useTranslation(["common", "dashboard", "auth"]);

  const recaptchaRef = useRef();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return { requestTypeId: incidents[0].value };
    }, []),
  });

  const formData = watch();

  useEffect(() => {
    let timer;
    const clearAttachmentsErrors = () => {
      if (formData.attachment_1?.error) {
        clearErrors("attachment_1");
        setValue("attachment_1", undefined);
      }
      if (formData.attachment_2?.error) {
        clearErrors("attachment_2");
        setValue("attachment_2", undefined);
      }
    };
    timer = setTimeout(clearAttachmentsErrors, 1500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [formData]);

  const [postIssue, postIssueResponse] = useSupportMutation();

  const { isAuth, user } = useSelector((store) => store.auth);

  const getUserByRole = () =>
    UsersJobsSupportPage.find((userItem) => {
      return (
        userItem.role === user?.user_role && userItem.type === user?.user_type
      );
    });

  const onSubmitHandler = (data, event) => {
    const formData = new FormData();

    if (data.attachment_1?.file) {
      formData.append("attachments", data.attachment_1?.file);
    }
    if (data.attachment_2?.file) {
      formData.append("attachments", data.attachment_2?.file);
    }

    // prettier-ignore
    formData.append("name", user ? user.user_name : data.name);
    // prettier-ignore
    formData.append("email", user ? user.user_email : data.email);
    // prettier-ignore
    formData.append("contactNumber",  user ? user.user_mobile : data.contactNumber);
    // prettier-ignore
    formData.append("role", user ? getUserByRole()?.id : data.role.id);
    // prettier-ignore
    formData.append("requestTypeId", data.requestTypeId);
    // prettier-ignore
    formData.append("summary", data.summary);
    // prettier-ignore
    formData.append("description", data.description);

    postIssue(formData)
      .unwrap()
      .then((data) => {
        props.showAlertHandler({
          show: true,
          type: AlertTypes.SUCCESS,
          message: SuccessCodes.SUCCESS_SUBMIT_ISSUE,
          interpolation: { issue_key: data.issue_key },
        });
        reset();
        recaptchaRef.current.reset();
      })
      .catch((err) => {
        console.log("ERROR:", err);
        props.showAlertHandler({
          show: true,
          type: AlertTypes.ERROR,
          message: err.data?.errorCode || ErrorCodes.UNKNOWN_ERROR,
        });
      });
  };

  const onErrorHandler = (errors, event) => {
    console.log("ERRORS: ", errors);
  };

  const [addNewFile, setAddNewFIle] = useState(false);

  return (
    <BaseCard px={"5rem"} py={"5rem"}>
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        {!isAuth && (
          <>
            <BaseCardTitle sx={{ fontSize: "2rem" }} mb={3}>
              {t("communication information")}
            </BaseCardTitle>
            <WrapperContentGrid columnsWidth={31} gapX={4}>
              <BaseInput
                htmlFor="name"
                label={t("name", { ns: "dashboard" })}
                placeholder={t("name", { ns: "dashboard" })}
                maxLength={100}
                labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
                name="name"
                register={{
                  ...register("name", {
                    required: {
                      value: true,
                      message: `${t("field", { ns: "auth" })} ${t("name", {
                        ns: "dashboard",
                      })} ${t("required", { ns: "auth" })}`,
                    },
                  }),
                }}
                errors={errors}
              />
              <BasePhoneInput
                htmlFor="contactNumber"
                label={t("communication number", { ns: "dashboard" })}
                name="contactNumber"
                errors={errors}
                control={control}
                maxLength={10}
                placeholder="05 xxx xxxx"
                register={{
                  ...register("contactNumber", {
                    pattern: {
                      value: /^\+9665[0-9]{8}$/,
                      message: t("رقم التواصل غير صحيح"),
                    },
                  }),
                }}
                requiredMessage={t("حقل رقم التواصل مطلوب")}
              />
              <BaseInput
                type="email"
                htmlFor="email"
                label={t("email", { ns: "dashboard" })}
                name={"email"}
                register={{
                  ...register("email", {
                    required: {
                      value: true,
                      message: `${t("field", { ns: "auth" })} ${t("email", {
                        ns: "auth",
                      })} ${t("required", { ns: "auth" })}`,
                    },
                    pattern: {
                      value: ValidEmailPattern,
                      message: `${t("Invalid email address", { ns: "auth" })}`,
                    },
                  }),
                }}
                errors={errors}
              />
              <BaseDropdown
                control={control}
                name={"role"}
                required
                errors={errors}
                label={t("job", { ns: "dashboard" })}
                labelStyles={{
                  color: "grayDark.color",
                  fontSize: "1.4rem",
                  fontWeight: 500,
                }}
                placeholder={t("select a job", { ns: "dashboard" })}
                data={UsersJobsSupportPage}
              />
            </WrapperContentGrid>
            <hr style={{ marginBlock: "5rem" }} />
          </>
        )}
        <BaseCardTitle sx={{ fontSize: "2rem" }} mb={3}>
          {t("request information")}
        </BaseCardTitle>
        <Typography
          sx={{
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: 500,
            mb: 2,
          }}
        >
          {t("request type", { ns: "dashboard" })}
        </Typography>
        <Controller
          control={control}
          name="requestTypeId"
          rules={{
            required: {
              value: true,
              message: `${t("field", { ns: "auth" })} ${t("request type", {
                ns: "dashboard",
              })} ${t("required", { ns: "auth" })}`,
            },
          }}
          render={({ field: { onChange, value, name, ref } }) => {
            return (
              <BaseFancyRadioGroup
                name={name}
                value={value}
                data={incidents}
                onChange={onChange}
              />
            );
          }}
        />
        <Box mt={6}>
          <BaseInput
            htmlFor="summary"
            label={t("request address", { ns: "dashboard" })}
            placeholder={t("request address", { ns: "dashboard" })}
            maxLength={100}
            labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
            name="summary"
            register={{
              ...register("summary", {
                required: {
                  value: true,
                  message: `${t("field", { ns: "auth" })} ${t(
                    "request address",
                    { ns: "dashboard" }
                  )} ${t("required", {
                    ns: "auth",
                  })}`,
                },
              }),
            }}
            errors={errors}
          />
        </Box>
        <Box mt={6}>
          <BaseTextArea
            textAreaStyles={{ padding: "2rem" }}
            control={control}
            maxLength={2000}
            name={"description"}
            label={t("description", { ns: "common" })}
            htmlFor={"description"}
            placeholder={t("support form description placeholder", {
              ns: "dashboard",
            })}
            errors={errors}
          />
        </Box>
        <Box mt={6}>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <BaseCardTitle sm={"true"}>
              {t("attachments", { ns: "common" })}
            </BaseCardTitle>
            <Typography component={"span"} fontSize={"1.25rem"}>
              (اختياري)
            </Typography>
          </Box>
          <BaseUploadFile
            control={control}
            maxSize={{ size: 5, unit: "MB" }}
            name={"attachment_1"}
            uploadedFile={formData.attachment_1}
            readOnly
            availableExtensions={supportFormAvailableExtensions}
            displayAvailableExtensions={displayAvailableExtensionsSupportForm}
            errors={errors}
          />
          {addNewFile ? (
            <BaseUploadFile
              control={control}
              maxSize={{ size: 5, unit: "MB" }}
              name={"attachment_2"}
              uploadedFile={formData.attachment_2}
              readOnly
              availableExtensions={supportFormAvailableExtensions}
              displayAvailableExtensions={displayAvailableExtensionsSupportForm}
              errors={errors}
            />
          ) : (
            <Box
              sx={{
                height: "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid`,
                borderColor: "primary.main",
                borderRadius: ".3rem",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "1.4rem",
                  color: "primary.main",
                  cursor: "pointer",
                }}
                onClick={() => setAddNewFIle(true)}
              >
                <Typography
                  component={"span"}
                  fontSize={"1.7rem"}
                  fontWeight={"inherit"}
                >
                  +
                </Typography>{" "}
                {t("add extra file", { ns: "common" })}
              </Typography>
            </Box>
          )}
          <Controller
            control={control}
            name="recaptcha"
            rules={{
              required: {
                value: true,
                message: `${t("field", { ns: "auth" })} ${t("Recaptcha", {
                  ns: "auth",
                })} ${t("required", { ns: "auth" })}`,
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <BaseRecaptcha
                  ref={recaptchaRef}
                  sx={{ mt: 5 }}
                  name={name}
                  onChange={onChange}
                  errors={errors}
                />
              );
            }}
          />
        </Box>

        <Box display={"flex"} justifyContent={"flex-end"} mt={5}>
          <BaseButton
            type="submit"
            disabled={postIssueResponse.isLoading}
            variant={postIssueResponse.isLoading ? "disabled" : ""}
          >
            {postIssueResponse.isLoading ? <BaseLoader /> : t("submit")}
          </BaseButton>
        </Box>
      </form>
    </BaseCard>
  );
};

export default SupportPageForm;
