import RegisterFormConsultant from "components/Forms/RegisterForm/RegisterFormConsultant/RegisterFormConsultant";
import RegisterFormCont from "components/Forms/RegisterForm/RegisterFormCont/RegisterFormCont";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Register from "modules/Auth/pages/Register/Register";

const consultantAuthRoutes = {
  path: "consultant",
  children: [
    {
      path: "register",
      element: <ProtectedRoute component={<Register />} />,
      children: [
        {
          index: true,
          element: <ProtectedRoute component={<RegisterFormConsultant />} />,
        },
        {
          path: "cont",
          element: <ProtectedRoute component={<RegisterFormCont />} />,
        },
      ],
    },
  ],
};

export { consultantAuthRoutes };
