import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import { defaultEmptyValue } from "constants/Empty";
import { formattedNumber } from "utils/Numbers/Numbers";

const LabNewRequestData = (props) => {
  return (
    <Box className={"request-data-details"} mt={4}>
      <Box className={"request-data-details__wrapper"}>
        <Typography className={"request-data-details__title"}>
          تفاصيل الطلب
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 30} gap={4}>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              رقم الطلب
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspection?.id || defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              حالة الطلب
            </Typography>
            <BaseStatus
              status={props.inspection?.status}
              dataId={props.dataId}
            />
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              اختبار مواد الأعمال المدنية
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspectionLayer?.name || defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              تكلفة الاختبار
            </Typography>
            <Typography className={"request-data-details__value"}>
              {`${formattedNumber(props.inspection?.labPrice)} ريال` || defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              نوع الفحص
            </Typography>
            <BaseTypeTag type={props.inspection?.testType} />
          </Box>

          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              عدد العينات{" "}
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.samplesData?.numberOfSamples}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              المدة المتوقعة
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspection?.labEstimatedTime} أيام
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />
    </Box>
  );
};

export default LabNewRequestData;
