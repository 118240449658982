export const isContentReady = (list) => Array.isArray(list) && list.length > 0;

export const getFromStaticLookup = (lookup, findBy, value) => {
  if (!value) return "";
  return lookup.find((item) => item[findBy] === value);
};

export const countLengthRecursively = (list) => {
  let count = 0;
  list.forEach((item) => {
    count++;
    if (item.children) {
      item.children.forEach((_2) => count++);
    }
  });

  return count;
};
