import { isContentReady } from "utils/Array/Arrays";
import classes from "../BaseSmartSearch.module.scss";

const SmartSearchList = (props) => {
  if (props.isLoading) return;

  return (
    props.value &&
    props.showSmartSearchList && (
      <ul
        className={`${classes["smart-search-list"]} ${
          !isContentReady(props.data) ? classes["smart-search-list--empty"] : ""
        }`}
      >
        {isContentReady(props.data) ? (
          props.data.map((item) => {
            return (
              <li
                className={classes["smart-search-list__item"]}
                key={item.id}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(item);
                  }
                  props.setShowSmartSearchList(false);
                }}
              >
                {props.dynamicItemKey(item)}
              </li>
            );
          })
        ) : (
          <li className={classes["smart-search-list__item"]}>لا يوجد تطابق</li>
        )}
      </ul>
    )
  );
};

export default SmartSearchList;
