import { Box, Typography } from "@mui/material";
import classes from "./BaseStatus.module.scss";
import BaseTooltip from "core-ui/BaseTooltip/BaseTooltip";
import { DATA_ID } from "constants/Details";
import { TABLE_ID } from "constants/Table";
import { defaultEmptyValue } from "constants/Empty";
import { TestTypes } from "constants/TestTypes";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";

const BaseStatus = ({ status, ...props }) => {
  let statusClass = status;
  const renderLabel = () => {
    let label = null;
    switch (status) {
      case "SKIPPED":
        if (
          props.tableId === TABLE_ID.SUB_TEST_STATUS &&
          !props.isRequestDone
        ) {
          label = "تحت الإجراء";
          statusClass = "CREATED";
          break;
        }
        label = "لم يتم إجراء الاختبار";
        statusClass = "EXPIRED";
        break;
      case "OUTDATED":
        if (
          props.dataId === DATA_ID.CON_REQUESTS ||
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS
        ) {
          label = "ملغي";
          statusClass = "OUTDATED";
          break;
        }
        break;
      case "CANCELED":
        if (
          props.dataId === DATA_ID.CON_REQUESTS ||
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS
        ) {
          label = "ملغي";
          statusClass = "CANCELED";
          break;
        }
        break;
      case "PENDING":
      case "PENDING_ADMIN":
        if (
          props.dataId === DATA_ID.CONSULTANT_CONSULTATION_REQUEST ||
          props.dataId === DATA_ID.CONTRACTOR_CONSULTANT_REQUEST ||
          props.tableId === TABLE_ID.CONSULTANT_CONSULTATION_REQUESTS ||
          props.tableId === TABLE_ID.CONTRACTOR_CONSULTANT_REQUESTS
        ) {
          label = "بانتظار الإسناد";
          statusClass = "CONSULTANT_PENDING";
          break;
        }
        if (
          props.tableId === TABLE_ID.ADMIN_EDIT_REQUESTS ||
          props.tableId === TABLE_ID.LAB_EDIT_REQUESTS ||
          props.dataId === DATA_ID.ADMIN_EDIT_REQUEST
        ) {
          label = "بانتظار الاعتماد";
          statusClass = "PENDING";
          break;
        }

        break;
      case "APPROVED":
        if (
          props.dataId === DATA_ID.CONSULTANT_CONSULTATION_REQUEST ||
          props.dataId === DATA_ID.CONTRACTOR_CONSULTANT_REQUEST ||
          props.tableId === TABLE_ID.CONSULTANT_CONSULTATION_REQUESTS ||
          props.tableId === TABLE_ID.CONTRACTOR_CONSULTANT_REQUESTS
        ) {
          label = "تم الإسناد";
          statusClass = "APPROVED";
          break;
        }
        if (
          props.dataId === DATA_ID.ADMIN_EDIT_REQUEST ||
          props.tableId === TABLE_ID.ADMIN_EDIT_REQUESTS ||
          props.dataId === DATA_ID.LAB_EDIT_REQUESTS
        ) {
          label = "مقبول";
          statusClass = "APPROVED";
          break;
        }
        break;
      case "REJECTED":
        if (
          props.dataId === DATA_ID.CONSULTANT_CONSULTATION_REQUEST ||
          props.dataId === DATA_ID.CONTRACTOR_CONSULTANT_REQUEST ||
          props.tableId === TABLE_ID.CONSULTANT_CONSULTATION_REQUESTS ||
          props.tableId === TABLE_ID.CONTRACTOR_CONSULTANT_REQUESTS
        ) {
          label = "تم الرفض";
          statusClass = "CONSULTANT_REJECTED";
          break;
        }
        if (
          props.dataId === DATA_ID.ADMIN_EDIT_REQUEST ||
          props.tableId === TABLE_ID.ADMIN_EDIT_REQUESTS ||
          props.dataId === DATA_ID.LAB_EDIT_REQUESTS
        ) {
          label = "مرفوض";
          statusClass = "CONSULTANT_REJECTED";
          break;
        }
        break;
      case "ADMIN_MODIFY_RESPONSE_PENDING":
        label = "بانتظار اعتماد التعديل";
        statusClass = "DEDUCTED";
        break;
      case "REFUND":
        label = "إسترداد نقاط";
        statusClass = "COMPLETED";
        break;
      case "DEDUCTED":
        label = "خصم نقاط";
        statusClass = "FAILED";
        break;
      case "HELD":
        label = "معلق";
        statusClass = "HELD";
        break;
      case "active":
        break;
      case "EXPIRED":
        label = "منتهية";
        break;
      case "completed":
      case "COMPLETED":
        label = "مكتمل";
        break;
      case "TEST_NOT_READY":
        if (
          props.testType === TestTypes.LAB_TEST &&
          props.tableId === TABLE_ID.FIELD_EXAMINATION
        ) {
          label = "بانتظار رفع النتائج";
          statusClass = "FIELD_EMPTY_TESTS";
          break;
        }
        label = "بإنتظار المعالجة";
        statusClass = "EMPTY_TESTS";
        break;
      case "appointment":
        label = "بانتظار تحديد الموعد";
        break;
      case "payment":
        label = "بانتظار الدفع";
        break;
      case "waitingAccept":
        label = "بانتظار القبول";
        break;
      case "LAB_ASSIGNED":
      case "PENDING_APPOINTMENT":
        if (
          props.testType === TestTypes.FIELD_TEST &&
          props.tableId === TABLE_ID.ADMIN_TECHNICIAN_REQUESTS
        ) {
          label = "تحت الإجراء";
          break;
        }
        if (
          props.testType === TestTypes.LAB_TEST &&
          props.tableId === TABLE_ID.ADMIN_TECHNICIAN_REQUESTS
        ) {
          label = "بإنتظار رفع النتائج";
          break;
        }
        if (
          props.testType === TestTypes.FIELD_TEST &&
          (props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
            props.dataId === DATA_ID.LAB_REQUEST_DETAILS)
        ) {
          label = "تحت الإجراء";
          break;
        }
        if (
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS
        ) {
          label = "تحت الإجراء";
          break;
        }
        if (props.tableId === TABLE_ID.DRILLING_LICENSES_REQUEST) {
          label = "تحت إجراءات الفحص";
          break;
        }
        if (
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
          props.dataId === DATA_ID.ADMIN_LAB_REQUESTS ||
          props.tableId === TABLE_ID.TECH_REQUESTS
        ) {
          label = "تحت الإجراء";
          break;
        }
        if (
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS ||
          props.dataId === DATA_ID.CON_REQUESTS
        ) {
          label = "تحت إجراءات الفحص";
          break;
        }

        if (
          props.appointment &&
          props.testType === TestTypes.FIELD_TEST &&
          (props.tableId === TABLE_ID.REQUEST ||
            props.tableId === TABLE_ID.ADMIN_REQUESTS ||
            props.dataId === DATA_ID.CON_REQUESTS)
        ) {
          label = "تحت إجراءات الفحص";
          break;
        }
        break;

      case "CREATED":
      case "STARTED":
        if (
          props.tableId === TABLE_ID.TEST_STATUS ||
          props.tableId === TABLE_ID.SUB_TEST_STATUS ||
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS
        ) {
          label = "تحت الإجراء";
          statusClass = "CREATED";
          break;
        }
        if (props.tableId === TABLE_ID.DRILLING_LICENSES_REQUEST) {
          label = "تحت إجراءات الفحص";
          break;
        }
        if (
          props.tableId === TABLE_ID.LAB_NO_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_NO_ASSIGN_REQUEST_DETAILS
        ) {
          label = "بانتظار الإسناد";
          statusClass = "LAB_CREATED";
          break;
        }
        if (!props.result && props.tableId === TABLE_ID.FIELD_EXAMINATION) {
          label = "بإنتظار رفع النتائج";
          statusClass = `TECH_${status}`;
          break;
        }
        if (
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS ||
          props.dataId === DATA_ID.CON_REQUESTS
        ) {
          label = "بانتظار قبول المختبر";
          statusClass = `REQUEST_${status}`;
          break;
        }

        break;

      case "SUCCEED":
        if (props.tableId === TABLE_ID.SUB_TEST_STATUS && !props.result) {
          label = "غير ناجح";
          statusClass = "FAILED";
          break;
        }
        if (props.tableId === TABLE_ID.SUB_TEST_STATUS && props.result) {
          label = "ناجح";
          statusClass = "SUCCEED";
          break;
        }
        if (props.tableId === TABLE_ID.TEST_STATUS) {
          label = "ناجح";
          break;
        }

      case "FAILED":
        if (props.tableId === TABLE_ID.SUB_TEST_STATUS && !props.result) {
          label = "غير ناجح";
          statusClass = "FAILED";
          break;
        }
        if (props.tableId === TABLE_ID.SUB_TEST_STATUS && props.result) {
          label = "ناجح";
          statusClass = "SUCCEED";
          break;
        }
        if (props.tableId === TABLE_ID.TEST_STATUS) {
          label = "غير ناجح";
          break;
        }

      case "SUCCEED":
      case "FAILED":
        if (props.tableId === TABLE_ID.DRILLING_LICENSES_REQUEST) {
          label = "مكتمل";
          break;
        }
        if (
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS
        ) {
          label = "مكتمل";
          statusClass = "COMPLETED";
          break;
        }
        if (
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.TECH_REQUESTS
        ) {
          label = "مكتمل";
          statusClass = `FIELD_EMPTY_TESTS_PSUEDO`;
          break;
        }
        if (
          props.dataId === DATA_ID.CON_REQUESTS ||
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS
        ) {
          label = "مكتمل";
          statusClass = `COMPLETED`;
          break;
        }
        break;
      case true:
        if (
          props.tableId === TABLE_ID.ADMIN_LICENSES ||
          props.dataId === DATA_ID.ADMIN_LICENSES
        ) {
          label = "اصدرت";
          statusClass = "EXPORTED_LICENSE";
          break;
        }
        if (
          props.tableId === TABLE_ID.TEST_STATUS ||
          props.tableId === TABLE_ID.SUB_TEST_STATUS
        ) {
          label = "ناجح";
          break;
        }
        if (
          props.tableId === TABLE_ID.ADMIN_USERS ||
          props.dataId === DATA_ID.ADMIN_USERS_DETAILS
        ) {
          label = "نشط";
          break;
        }
        break;
      case "LAB":
        if (
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.LAB_NO_ASSIGNED_REQUESTS
        ) {
          label = "لا يوجد";
          break;
        }
        break;
      case "FIELD":
        if (
          (props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
            props.dataId === DATA_ID.LAB_REQUEST_DETAILS) &&
          !props.scheduledAppointment
        ) {
          label = "بانتظار تحديد موعد";
          break;
        } else {
          label = (
            <DisplayRequestAppointmentDate
              scheduledAppointment={props.scheduledAppointment}
              appointmentDate={props.appointmentDate}
            />
          );
          break;
        }
        break;
      case false:
        if (
          props.tableId === TABLE_ID.ADMIN_LICENSES ||
          props.dataId === DATA_ID.ADMIN_LICENSES
        ) {
          label = "لم تصدر";
          statusClass = "NOT_EXPORTED_LICENSE";
          break;
        }
        if (
          props.tableId === TABLE_ID.TEST_STATUS ||
          props.tableId === TABLE_ID.SUB_TEST_STATUS
        ) {
          label = "غير ناجح";
          break;
        }
        if (
          props.tableId === TABLE_ID.ADMIN_USERS ||
          props.dataId === DATA_ID.ADMIN_USERS_DETAILS
        ) {
          label = "غير نشط";
          statusClass = "EMPTY_TESTS";
          break;
        }
        if (
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS
        ) {
          label = "مكتمل";
          statusClass = "LAB_FAILED";
          break;
        }
        break;
      case "TEST_STARTED":
        if (
          props.testType === TestTypes.FIELD_TEST &&
          (props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
            props.tableId === TABLE_ID.TECH_REQUESTS ||
            props.tableId === TABLE_ID.ADMIN_TECHNICIAN_REQUESTS)
        ) {
          label = "تحت الإجراء";
          statusClass = `TECH_${status}`;
          break;
        }
        if (
          // props.testType === TestTypes.FIELD_TEST &&
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS
        ) {
          label = "تحت الإجراء";
          statusClass = `TECH_${status}`;
          break;
        }
        if (
          props.dataId === DATA_ID.CON_REQUESTS ||
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS
        ) {
          label = "تحت إجراءات الفحص";
          statusClass = `TECH_${status}`;
          break;
        }

        break;

      case "DELIVERING":
        if (
          props.testType === TestTypes.FIELD_TEST &&
          (props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
            props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS)
        ) {
          label = "لا يوجد";
          statusClass = `FIELD_EXAMINATION-${status}`;
          break;
        }
        if (
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS
        ) {
          label = "جاري التوصيل";
          break;
        }

        if (
          props.testType === TestTypes.LAB_TEST &&
          (props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
            props.tableId === TABLE_ID.TECH_REQUESTS)
        ) {
          label = "بانتظار الجمع";
          break;
        }
        if (
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.TECH_REQUESTS
        ) {
          label = "بانتظار الجمع";
          break;
        }
        break;
      case "received":
        label = "تم الاستلام";
        break;
      case "undefined-date":
        label = "غير محدد";
        break;
      case "done":
        label = "مكتمل";
        break;
      case "DONE":
        if (props.tableId === TABLE_ID.TECH_REQUESTS) {
          label = "مكتمل";
          statusClass = `FIELD_EMPTY_TESTS_PSUEDO`;
          break;
        }
        break;
      case "USED":
      case "IMPORTED":
        label = "سارية";
        break;
      case "waitingCollect":
        label = "بانتظار الجمع";
        break;
      case "DELIVERED":
        label = "تم الاستلام";
        break;
      case "COLLECTED":
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          label = "قيد التسليم";
          break;
        }
        if (
          props.tableId === TABLE_ID.CENTER_REQUESTS ||
          props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
        ) {
          label = "جاري التسليم";
          statusClass = "COLLECTED_CENTER";
          break;
        }
        break;
      case "waitingConfirmation":
        label = "بانتظار التأكيد";
        break;
      case "not-found":
        label = "لا يوجد";
        break;
      case "not-found-date":
        label = "لا يوجد";
        break;
      case "ASSIGNED":
        if (props.tableId === TABLE_ID.TECH_REQUESTS) {
          label = "تحت الإجراء";
          break;
        }
        label = "ASSIGNED";
        break;
      case "active":
        label = "سارية";
        break;
      case "active":
        label = "سارية";
        break;
      case "RECIVED_BY_DC":
        if (props.tableId === TABLE_ID.CENTER_REQUESTS) {
          label = "قيد التسليم";
          break;
        }
        if (props.dataId === DATA_ID.CENTER_REQUEST_DETAILS) {
          label = "قيد التسليم";
          break;
        }
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          label = "مكتمل";
          statusClass = "TECH_COMPLETED";
          break;
        }
        break;
      case "RECIVED_TO_DC":
        if (props.tableId === TABLE_ID.CENTER_REQUESTS) {
          label = "قيد التسليم";
          break;
        }
        break;
      case "DELIVERED_TO_DC":
        if (
          props.tableId === TABLE_ID.CENTER_REQUESTS ||
          props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
        ) {
          label = "جاري التسليم";
          statusClass = "COLLECTED_CENTER";
          break;
        }
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          label = "بانتظار التأكيد";
          break;
        }
        // label = "جاري الجمع";
        break;
      case "DELIVERED_TO_LAB":
        if (
          props.tableId === TABLE_ID.CENTER_REQUESTS ||
          props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
        ) {
          label = "بانتظار التأكيد";
          break;
        }
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          label = "مكتمل";
          statusClass = "TECH_COMPLETED";
          break;
        }
        break;
      case "RECEIVED_BY_LAB":
        if (
          props.tableId === TABLE_ID.CENTER_REQUESTS ||
          props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
        ) {
          label = "مكتمل";
          statusClass = "CENTER_COMPLETED";
          break;
        }
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          label = "مكتمل";
          statusClass = "TECH_COMPLETED";
          break;
        }
        break;
      case defaultEmptyValue:
      case null:
      case undefined:
        if (props.tableId === TABLE_ID.SUB_TEST_STATUS) {
          label = "بإنتظار المعالجة";
          statusClass = "EMPTY_TESTS";
          break;
        }
        if (
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS ||
          props.dataId === DATA_ID.CON_REQUESTS
        ) {
          label = "تحت إجراءات الفحص";
          break;
        }
        if (
          props.tableId === TABLE_ID.CENTER_REQUESTS ||
          props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
        ) {
          label = "جاري الجمع";
          statusClass = "EMPTY_CENTER";
          break;
        }
        if (
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.TECH_REQUESTS
        ) {
          label = "بانتظار الجمع";
          statusClass = "EMPTY_TECH";
          break;
        }
        if (
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS
        ) {
          label = "لا يوجد";
          statusClass = "EMPTY_LAB_ASSIGNED";
          break;
        }
        break;

      default:
        label = defaultEmptyValue;
        break;
    }

    if (
      props.tableId === TABLE_ID.REQUEST ||
      props.tableId === TABLE_ID.ADMIN_REQUESTS
    ) {
      if (props.extraStatus === "CREATED") {
        label = "بانتظار الجمع";
      }
    }
    if (props.extraStatus) {
      if (props.dataId === DATA_ID.TECH_REQUEST_DETAILS) {
        if (props.extraStatus === "COLLECTED") {
          label = "قيد التسليم";
          statusClass = props.extraStatus;
        }
        if (props.extraStatus === "RECIVED_BY_DC") {
          label = "مكتمل";
          statusClass = props.extraStatus;
        }
      }

      if (props.dataId === DATA_ID.CENTER_REQUEST_DETAILS) {
        if (props.extraStatus === "DELIVERING") {
          label = "قيد التسليم";
          statusClass = props.extraStatus;
        }
        if (props.extraStatus === "RECIVED_BY_DC") {
          label = "قيد التسليم";
          statusClass = props.extraStatus;
        }
        if (props.extraStatus === "DELIVERED_TO_LAB") {
          label = "بانتظار التأكيد";
          statusClass = props.extraStatus;
        }
      }

      if (
        props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
        props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS
      ) {
        if (props.extraStatus === "RECEIVED_BY_LAB") {
          label = "تم الاستلام";
          statusClass = props.extraStatus;
        }
      }

      if (props.tableId === TABLE_ID.FIELD_EXAMINATION) {
        if (props.extraStatus === "PENDING_APPOINTMENT") {
          label = "بانتظار رفع النتائج";
          statusClass = `FIELD_EXAMINATION-${props.extraStatus}`;
        }
      }

      // if (props.tableId === TABLE_ID.TEST_STATUS) {
      //   if (props.extraStatus === "FAILED") {
      //     label = "غير ناجح";
      //     statusClass = props.extraStatus;
      //   }
      //   if (props.extraStatus === "SUCCEED") {
      //     label = "ناجح";
      //     statusClass = props.extraStatus;
      //   }
      // }
    }

    return { label, statusClass };
  };

  const renderTooltip = () => {
    let tooltipStatus = "";
    let tooltipIconName = "tooltip-blue";
    switch (status) {
      case "ADMIN_MODIFY_RESPONSE_PENDING":
        tooltipIconName = "tooltip-orange";
        tooltipStatus = status;
        break;
      case "PENDING_ADMIN":
        tooltipIconName = "tooltip-blue";
        tooltipStatus = status;
        break;
      case "OUTDATED":
        tooltipIconName = "tooltip-brown";
        tooltipStatus = status;
        break;
      case "CANCELED":
        tooltipIconName = "tooltip-brown";
        tooltipStatus = status;
        break;
      case "ASSIGNED":
        if (props.tableId === TABLE_ID.TECH_REQUESTS) {
          tooltipIconName = "tooltip-green-light";
          tooltipStatus = status;
          break;
        }
        break;
      case "LAB_ASSIGNED":
      case "PENDING_APPOINTMENT":
        if (
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.TECH_REQUESTS
        ) {
          tooltipIconName = "tooltip-green-light";
          tooltipStatus = status;
          break;
        }
        break;
      case "appointment":
        tooltipIconName = status;
        tooltipStatus = status;
        break;
      case "DELIVERING":
        if (
          props.testType === TestTypes.FIELD_TEST &&
          (props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
            props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS)
        ) {
          tooltipIconName = "tooltip-black";
          tooltipStatus = status;
          break;
        }
        if (
          props.testType === TestTypes.LAB_TEST &&
          (props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
            props.tableId === TABLE_ID.TECH_REQUESTS)
        ) {
          break;
        }
        tooltipIconName = "tooltip-orange";
        tooltipStatus = status;
        break;
      case "TEST_STARTED":
        if (
          //
          props.testType === TestTypes.FIELD_TEST &&
          (props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
            props.tableId === TABLE_ID.TECH_REQUESTS)
        ) {
          tooltipIconName = "tooltip-green-light";
          tooltipStatus = status;
          break;
        }
        break;
      case "DELIVERED_TO_LAB":
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          break;
        }
        tooltipIconName = "tooltip-blue-dark";
        tooltipStatus = status;
        break;
      case "wait_for_result":
        if (props.tableId === TABLE_ID.FIELD_EXAMINATION) {
          tooltipIconName = "tooltip-black";
          tooltipStatus = status;
          break;
        }
        break;
      case "DELIVERED_TO_DC":
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          tooltipIconName = "tooltip-blue-dark";
          tooltipStatus = status;
          break;
        }
        tooltipIconName = "tooltip-blue-light";
        tooltipStatus = status;
        break;
      case "LAB":
        if (
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.LAB_NO_ASSIGNED_REQUESTS
        ) {
          tooltipIconName = "tooltip-black";
          tooltipStatus = status;
          break;
        }
        break;
      case "FIELD":
        if (
          (props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
            props.dataId === DATA_ID.LAB_REQUEST_DETAILS) &&
          !props.scheduledAppointment
        ) {
          tooltipIconName = "tooltip-black";
          tooltipStatus = status;
          break;
        }
        break;
      case "COLLECTED":
        if (
          props.tableId === TABLE_ID.CENTER_REQUESTS ||
          props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
        ) {
          tooltipIconName = "tooltip-blue-light";
          tooltipStatus = status;
          break;
        }
        break;
      case "PENDING":
        if (
          props.tableId === TABLE_ID.ADMIN_EDIT_REQUESTS ||
          props.tableId === TABLE_ID.LAB_EDIT_REQUESTS ||
          props.dataId === DATA_ID.ADMIN_EDIT_REQUEST
        ) {
          tooltipIconName = "tooltip-blue-light";
          tooltipStatus = status;
          break;
        }
        break;

      case "undefined-date":
        tooltipIconName = status;
        tooltipStatus = status;
        break;
      case "not-found":
        tooltipIconName = status;
        tooltipStatus = status;
        break;
      case "not-found-date":
        tooltipIconName = status;
        tooltipStatus = status;
        break;
      case "delivery-progress":
        tooltipIconName = status;
        tooltipStatus = status;
        break;
      case "CREATED":
        if (!props.result && props.tableId === TABLE_ID.FIELD_EXAMINATION) {
          tooltipIconName = "tooltip-black";
          tooltipStatus = status;
          break;
        }
        break;

      case "HELD":
        tooltipIconName = "tooltip-black";
        tooltipStatus = status;
        break;
      case "TEST_NOT_READY":
        if (
          props.testType === TestTypes.LAB_TEST &&
          props.tableId === TABLE_ID.FIELD_EXAMINATION
        ) {
          tooltipIconName = "tooltip-black";
          tooltipStatus = status;
          break;
        }
        break;
      case defaultEmptyValue:
      case null:
      case undefined:
        if (
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.TECH_REQUESTS
        ) {
          tooltipIconName = "tooltip-orange";
          tooltipStatus = status;
          break;
        }
        if (props.tableId === TABLE_ID.SUB_TEST_STATUS) {
          break;
        }
        if (
          props.tableId === TABLE_ID.REQUEST ||
          props.tableId === TABLE_ID.ADMIN_REQUESTS ||
          props.dataId === DATA_ID.CON_REQUESTS
        ) {
          break;
        }
        if (
          props.tableId === TABLE_ID.TECH_REQUESTS ||
          props.dataId === DATA_ID.TECH_REQUEST_DETAILS
        ) {
          break;
        }
        if (
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS
        ) {
          tooltipIconName = "tooltip-black";
          tooltipStatus = status;
          break;
        }
        tooltipIconName = "tooltip-orange";
        tooltipStatus = status;
        break;
      default:
        tooltipStatus = defaultEmptyValue;
        break;
    }

    if (props.tableId === TABLE_ID.FIELD_EXAMINATION) {
      if (props.extraStatus === "PENDING_APPOINTMENT") {
        tooltipIconName = "tooltip-black";
        tooltipStatus = status;
      }
    }
    return { tooltipStatus, tooltipIconName };
  };

  const renderTooltipText = () => {
    let text = "لا يوجد";
    if (status === "OUTDATED") {
      text = "لعدم قبول أي مختبر بالوقت المحدد";
    }
    if (status === "CANCELED") {
      text = "تم الغاء الطلب من قبل المقاول";
    }
    if (status === "HELD") {
      text = "سيتم اعتماد خصم النقاط عند قبول الطلب من قبل المختبر";
    }
    if (status === "ADMIN_MODIFY_RESPONSE_PENDING") {
      text =
        "سيتم اعتماد النتائج المحدثة بعد قبول طلب التعديل من قبل مدير النظام";
    }
    if (status === "PENDING_ADMIN" || status === "PENDING") {
      if (
        props.tableId === TABLE_ID.ADMIN_EDIT_REQUESTS ||
        props.tableId === TABLE_ID.LAB_EDIT_REQUESTS ||
        props.dataId === DATA_ID.ADMIN_EDIT_REQUEST
      ) {
        text =
          "سيتم اعتماد النتائج المحدثة بعد قبول طلب التعديل من قبل مدير النظام";
      }
    }
    if (status === "appointment") {
      text = "ريجب التنسيق مع الاستشاري لحضور موعد الاختبار";
    }
    if (status === "DELIVERING") {
      if (
        props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
        props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS
      ) {
        text = "جاري إيصال العينة إلى المختبر";
      }
      if (
        props.testType === TestTypes.FIELD_TEST &&
        (props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS)
      ) {
        text = "لا يتطلب الفحص الحقلي جمع عينات من موقع الحفر";
      }
    } //////////
    if (status === "DELIVERED_TO_LAB") {
      if (
        props.tableId === TABLE_ID.CENTER_REQUESTS ||
        props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
      ) {
        text = "بانتظار تأكيد استلام العينة من قبل المختبر";
      }
    }
    if (status === "CREATED") {
      if (!props.result && props.tableId === TABLE_ID.FIELD_EXAMINATION) {
        text = "سيقوم المختبر برفع نتائج الفحص الحقلي";
      }
    }
    if (status === "TEST_NOT_READY") {
      if (
        props.testType === TestTypes.LAB_TEST &&
        props.tableId === TABLE_ID.FIELD_EXAMINATION
      ) {
        text = "سيقوم المختبر برفع النتائج بعد إتمام اختبارات الفحص الحقلي";
      }
    }
    if (status === "DELIVERED_TO_DC") {
      /////////////////////////////////////////////////
      if (
        props.tableId === TABLE_ID.CENTER_REQUESTS ||
        props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
      ) {
        text = "تم جمع العينة وسيتم توصيلها إلى مركز التوزيع قريبا";
      }
      if (
        props.tableId === TABLE_ID.TECH_REQUESTS ||
        props.dataId === DATA_ID.TECH_REQUEST_DETAILS
      ) {
        text = "بانتظار تأكيد استلام العينة من قبل مركز التوزيع";
      }
    }
    if (status === "COLLECTED") {
      if (
        props.tableId === TABLE_ID.CENTER_REQUESTS ||
        props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
      ) {
        text = "تم جمع العينة وسيتم توصيلها إلى مركز التوزيع قريبا";
      }
    }
    if (status === "TEST_STARTED") {
      if (
        props.testType === TestTypes.FIELD_TEST &&
        (props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
          props.tableId === TABLE_ID.TECH_REQUESTS)
      ) {
        text =
          "بانتظار مباشرة الموقع  بالوقت واليوم المحدد من قبل المقاول لمتابعة اجراء الاختبار";
      }
    }
    if (status === "LAB") {
      if (
        props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
        props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
        props.tableId === TABLE_ID.LAB_NO_ASSIGNED_REQUESTS
      ) {
        text = "لا يتطلب الفحص المعلمي تحديد موعد";
      }
    }
    if (status === "FIELD") {
      if (
        (props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS ||
          props.dataId === DATA_ID.LAB_REQUEST_DETAILS) &&
        !props.scheduledAppointment
      ) {
        text = "سيقوم المقاول بتحديد موعد الفحص عند جاهزية الموقع";
      }
    }
    if (status === "wait_for_result") {
      if (props.tableId === TABLE_ID.FIELD_EXAMINATION) {
        text = "سيقوم المختبر برفع نتائج الفحص الحقلي";
      }
    }
    if (props.extraStatus === "PENDING_APPOINTMENT") {
      if (props.tableId === TABLE_ID.FIELD_EXAMINATION) {
        text = "سيقوم المختبر برفع نتائج الفحص الحقلي";
      }
    }
    if (
      (status === "LAB_ASSIGNED" ||
        status === "PENDING_APPOINTMENT" ||
        status === "ASSIGNED") &&
      (props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
        props.tableId === TABLE_ID.TECH_REQUESTS)
    ) {
      text =
        "بانتظار مباشرة الموقع بالوقت واليوم المحدد من قبل المقاول لمتابعة اجراء الاختبار";
    }
    if (status === "undefined-date") {
      text = "سيقوم المقاول بتحديد موعد الفحص الحقلي عند جاهزية الموقع";
    }
    if (status === "not-found") {
      text =
        "بانتظار تأكيد تسليم العينة من قبل فني الرقابة إلي مركز التوزيع لتحديث التاريخ";
    }
    if (
      status === defaultEmptyValue ||
      status === null ||
      status === undefined
    ) {
      if (
        props.dataId === DATA_ID.TECH_REQUEST_DETAILS ||
        props.tableId === TABLE_ID.TECH_REQUESTS
      ) {
        text =
          " بانتظار جمع العينة بالوقت واليوم المحدد من قبل المقاول لمتابعة إجراءات الاختبار ";
      }
      if (
        props.tableId === TABLE_ID.CENTER_REQUESTS ||
        props.dataId === DATA_ID.CENTER_REQUEST_DETAILS
      ) {
        text = "جاري جمع العينة من قبل فني الرقابة";
      }
      if (
        props.dataId === DATA_ID.LAB_REQUEST_DETAILS ||
        props.tableId === TABLE_ID.LAB_ASSIGNED_REQUESTS
      ) {
        text = "لا يتطلب الفحص الحقلي جمع عينات من الموقع";
      }
    }
    return text;
  };

  const renderLabelData = renderLabel();
  const renderTooltipData = renderTooltip();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        className={`${classes["license-status"]} ${
          classes[`license-status--${renderLabelData.statusClass}`]
        }`}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          className={`${classes[`license-status__text`]} ${
            classes[`license-status__text--${renderLabelData.statusClass}`]
          }`}
        >
          {renderLabelData.label}
        </Typography>

        {status === renderTooltipData.tooltipStatus ? (
          <BaseTooltip
            type={renderTooltipData.tooltipIconName}
            text={renderTooltipText()}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default BaseStatus;
