import { TableCell, TableRow } from "@mui/material";
import { isContentReady } from "utils/Array/Arrays";
import { displayCell } from "../TableHead/TableHeader";

const TableSubData = (props) => {
  /* NEW TABLE HEADER OF SUB DATA */
  return (
    props.subData && (
      <>
        {/* NEW TABLE DATA OF SUB DATA [LOOP] */}
        {/* THIS WILL MODIFY LATER TO BE .subData to be generic for all sub data content */}
        {props.categoryElement[props.subData?.subDataListServerKey] &&
        isContentReady(
          props.categoryElement[props.subData?.subDataListServerKey]
        )
          ? props.categoryElement[props.subData?.subDataListServerKey].map(
              (subDataItem, idx) => {
                return (
                  <TableRow key={idx}>
                    {props.tableHeader.map((headerItem, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          sx={{
                            background: "base.white",
                            borderBottom: "1px solid borders.colorSoft2",
                            whiteSpace: "nowrap",
                            fontSize: "1.6rem",
                            color: "grayDark.color5",
                            display: displayCell(
                              headerItem,
                              subDataItem,
                              false
                            ),
                          }}
                        >
                          {props.renderTableCell(subDataItem, headerItem)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )
          : // <TableRow>
            //   <TableCell
            //     colSpan={"100%"}
            //     sx={{ fontSize: "1.4rem" }}
            //   >
            //     {props.subData.emptyLabel}
            //   </TableCell>
            // </TableRow>
            null}
      </>
    )
  );
};

export default TableSubData;
