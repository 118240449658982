/**
 *
 * @param {from} from is an integar that we add basic multiply operation to get the number of minutes/seocnds in milliseconds
 * @description The function takes an argument of time in minutes like (5, 6, 7) minutes and we count down and check
 *  if we reached 0 or not, if we reached 0 we clear the interval and return true otherwise we return false
 * @author Khaled
 */
export const countDownTimer = (from, display, callback) => {
  let duration = 60 * from;

  let timer = duration,
    minutes,
    seconds;

  const counter = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.textContent = `${minutes}:${seconds}`;

    if (--timer < 0) {
      clearInterval(counter);
      callback(true);
      return true;
    }
  }, 1000);

  return counter;
};
