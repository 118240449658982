import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const ConsultantDashboard = (props) => {
  const { t } = useTranslation();

  return <Outlet />;
};

export default ConsultantDashboard;
