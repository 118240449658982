import { useLocation, useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { AssignedRequestsDetail } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import { AlertTypes } from "constants/AlertTypes";
import { useGetInspectionForLabQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { Helmet } from "react-helmet";
import useAlert from "hooks/useAlert";
import { useEffect } from "react";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import FieldAssignedRequestTests from "./Field";
import { EditableStatusEnum } from "services/StaticLookup/EditableStatus";

const AssignedRequestsDetails = () => {
  const params = useParams();

  const { t } = useTranslation();

  const { state } = useLocation();

  const fromPageId = state?.fromPageId;

  const navigatedUserTimer = state?.navigatedUserTimer;

  useEffect(() => {
    if (navigatedUserTimer) {
      clearTimeout(navigatedUserTimer);
    }
  }, [navigatedUserTimer]);

  const {
    data: inspection,
    isFetching: isInspectionFetching,
    refetch,
  } = useGetInspectionForLabQuery(params.id);

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  // FIELD
  const {
    alert: raiseResultsAlert,
    showAlertHandler: showRaiseResultsAlertHandler,
    hideAlertHandler: hideRaiseResultsAlertHandler,
  } = useAlert();

  useEffect(() => {
    if (fromPageId === "assignLab") {
      refetch();
    }
    showAlertHandler({
      show: fromPageId === "assignLab",
      type: AlertTypes.SUCCESS,
    });
  }, []);

  const isRejected = inspection?.lastEditStatus === EditableStatusEnum.REJECTED;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة - رقم الطلب ${inspection?.id}`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <DashboardIntro
        title={t(`الطلب رقم ${inspection?.id}`, { ns: "dashboard" })}
        variant={"secondary"}
        action={
          <DashboardIntroAction>
            <BaseAlert
              mb={4}
              sx={{ mb: 2 }}
              destroy={hideAlertHandler}
              autoClose={3}
              show={alert.show}
              message={SuccessCodes.ASSIGNED_LAB_SUCCESSFULLY}
              type={alert.type}
              labelSx={{ fontWeight: "bold" }}
            />
            <BaseAlert
              sx={{ mb: 2 }}
              show={raiseResultsAlert.show}
              type={raiseResultsAlert.type}
              message={raiseResultsAlert.message}
              destroy={hideRaiseResultsAlertHandler}
              autoClose={5}
            />
          </DashboardIntroAction>
        }
        breadcrumbData={AssignedRequestsDetail({ id: inspection?.id })}
      />

      <BaseCard
        mt={2}
        py={"2rem"}
        px={"3rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        <FieldAssignedRequestTests
          inspection={inspection}
          isInspectionFetching={isInspectionFetching}
          showRaiseResultsAlertHandler={showRaiseResultsAlertHandler}
          isRejected={isRejected}
        />
      </BaseCard>
    </>
  );
};

export default AssignedRequestsDetails;
