import { TABLE_ID } from "constants/Table";
import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import { Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { enLabelSx } from "constants/Typography";

import { consultatioSieveMixDesignGradationLimits } from "services/StaticLookup/TableHeader/Consultant";

const DoubleLayerData = ({ subTests, request, asphalet }) => {
  const  consultantSieveMixDesignGradationLimits = [
    {
      serverKey: "testName",
      name: "Sieve Size",
      format: (cell, row) => {
        return (
          <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>
            {cell}
          </Typography>
        );
      },
    },
    {
      serverKey: "",
      name: "",
    },
    {
      serverKey: "",
      name: "",
    },
    {
      serverKey: "status",
      name: "",
      hasComponent: { comp: <BaseStatus /> },
    },
    {
      serverKey: "score",
      name: "",
      fallback: "غير محدد",
    },
    {
      serverKey: "",
      name: "",
    },
  ];

  return (
    <BaseTableCard
      tableHeader={asphalet? consultatioSieveMixDesignGradationLimits : consultantSieveMixDesignGradationLimits}
      content={subTests}
      injectProps={{
        tableId: TABLE_ID.SUB_TEST_STATUS,
        extraStatus: request?.status,
      }}
      tableSx={{
        td: {
          borderBottom: "0.1rem solid background.disabled",
        },
      }}
    />
  );
};

export default DoubleLayerData;
