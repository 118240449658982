import { FormControlLabel, Radio, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import classes from "./BaseRadio.module.scss";

const BaseRadio = (props) => {
  return (
    <Box sx={{ ...props.parentSx }}>
      <Box
        className={`${classes["base-radio"]} 
        ${classes[`base-radio--${props.inputHasError ? "invalid" : ""}`]}
        ${classes[`base-radio--${props.disabled ? "disabled" : ""}`]}`}
        sx={{ ...props.sx }}
      >
        <FormControlLabel
          value={props.value}
          disabled={props.disabled}
          control={
            <Radio
              checkedIcon={<CheckCircleIcon />}
              checked={props.checked}
              sx={{
                color: "primary.colorSoft6",
                "&.Mui-checked": {
                  color: "secondary.main",
                },
              }}
            />
          }
          label={
            <Typography
              className={classes["base-radio__label"]}
              sx={{ fontSize: "1.4rem", ...props.labelSx }}
            >
              {props.label}
            </Typography>
          }
        />
      </Box>

      {props.helperText && (
        <Typography sx={{ mt: 0.3 }}>{props.helperText}</Typography>
      )}
    </Box>
  );
};

export default BaseRadio;
