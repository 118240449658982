import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { walletActions } from "store/wallet-slice";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const CustomPackageForm = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();

  const { paymentCustomPoints } = watch();

  const [isPaymentCustomPointsValid, setIsPaymentCustomPointsValid] =
    useState(true);

  const { selectedPlan } = useSelector((store) => store.wallet);

  useEffect(() => {
    if (isPaymentCustomPointsValid) {
      props.onChangeCustomPointsHandler(paymentCustomPoints);
    }
  }, [paymentCustomPoints, isPaymentCustomPointsValid]);

  const { t } = useTranslation(["wallet", "auth", "dashboard"]);

  const onSubmitHandler = (data, event) => {
    const vat = selectedPlan.price * props.vatPercentage;

    event.preventDefault();
    props
      .getCheckoutId({ id: selectedPlan.id, price: selectedPlan.price, vat })
      .unwrap()
      .then((responseData) => {
        dispatch(walletActions.setCheckoutId(responseData.id));
        dispatch(walletActions.showPaymentFormHandler());
      });
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const onChangeCustomPointsHandler = (value) => {
    let isValid = true;
    if (value.length > 9) {
      setValue("paymentCustomPoints", paymentCustomPoints.slice(0, 9));
      isValid = false;
    }

    if (/[^0-9]/gi.test(value)) {
      setValue("paymentCustomPoints", value.replace(/[^0-9]/gi, ""));
      isValid = false;
    }

    setIsPaymentCustomPointsValid(isValid);
  };

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      style={{ marginRight: "5rem" }}
    >
      <BaseInput
        inputContainerStyles={{ mb: 1 }}
        sx={{ width: "35rem" }}
        min={"1"}
        htmlFor="paymentCustomPoints"
        placeholder={" ادخل عدد النقاط"}
        name="paymentCustomPoints"
        register={{
          ...register("paymentCustomPoints", {
            onChange: (e) => onChangeCustomPointsHandler(e.target.value),
            required: {
              value: true,
              message: `${t("field")} ${t("required")}`,
            },
          }),
        }}
        errors={errors}
      />
      <Typography
        sx={{ fontSize: "1.2rem", mt: errors.paymentCustomPoints ? 3 : 0 }}
      >
        يرجى إدخال عدد نقاط صحيح مثال : 1000
      </Typography>
      <button className="hidden" type="submit" ref={props.submitBtnRef}>
        التالي
      </button>
    </form>
  );
};

export default CustomPackageForm;
