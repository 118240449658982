import RequestDataLab from "./Lab/RequestDataLab";
import RequestDataField from "./Field/RequestDataField";

const RequestData = ({ inspectionTestsData, ...props }) => {
  return props.isLab ? (
    <RequestDataLab inspectionTestsData={inspectionTestsData} {...props} />
  ) : (
    <RequestDataField {...props} />
  );
};

export default RequestData;
