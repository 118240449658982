import { Box, Stack, Typography } from "@mui/material";
import MultipleLicensesModal from "components/AllModalContent/MultipleLicensesModal/MultipleLicensesModal";
import BaseModal from "core-ui/BaseModal/BaseModal";
import useShowBaseModal from "hooks/useShowBaseModal";
import { publicAssetsPath } from "utils/Paths/Paths";

const MultipleLicensesDataItem = (props) => {
  const { show, onOpen, onHide } = useShowBaseModal();

  return (
    <>
      <BaseModal
        show={show}
        py={"4rem"}
        px={"4rem"}
        onHide={onHide}
        closeIcon
        {...(props.requestId && {
          title: `رخص الحفر لطلب الفحص رقم ${props.requestId}`,
        })}
      >
        <MultipleLicensesModal onHide={onHide} licenses={props.data} />
      </BaseModal>

      <Box className={"request-data-details__item"}>
        <Typography className={"request-data-details__label"}>
          رخص الحفر
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={0.5}
          onClick={onOpen}
          sx={{ cursor: "pointer" }}
        >
          <Typography className={"request-data-details__value"}>
            استعراض الرخص
          </Typography>
          <img
            src={publicAssetsPath("icons/popout-svgrepo.svg")}
            width="auto"
            alt="popout"
          />
        </Stack>
      </Box>
    </>
  );
};

export default MultipleLicensesDataItem;
