import { Box, Stack, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { createPortal } from "react-dom";
import classes from "./BaseModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { _void } from "utils/Objects/Objects";
import { publicAssetsPath } from "utils/Paths/Paths";

const Modal = (props) => {
  return (
    props.show && (
      <Box className={classes.modal} sx={{ ...props.mainContainerSx }}>
        <BaseCard
          px={"0rem"}
          py={"0rem"}
          sx={{ ...props.containerSx, minHeight: "30rem" }}
        >
          {(props.title || props.closeIcon) && (
            <>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                className={classes["modal__header"]}
                px={"2rem"}
                py={"2rem"}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                  {props.titleIconName && (
                    <img
                      src={publicAssetsPath(`icons/${props.titleIconName}.svg`)}
                      width="auto"
                      alt=""
                    />
                  )}
                  <Typography className={classes["modal__title"]}>
                    {props.title || ""}
                  </Typography>
                </Box>

                {props.closeIcon && (
                  <CloseIcon
                    sx={{ width: "2rem", height: "2rem" }}
                    onClick={props.isLoading ? _void : props.onHide}
                    className={classes["modal__close-icon"]}
                  />
                )}
              </Stack>
              <hr />
            </>
          )}
          <Box
            px={props.px || "0rem"}
            py={props.py || "0rem"}
            sx={{ ...props.modalContentWrapperSx }}
          >
            {props.children}
          </Box>
        </BaseCard>
      </Box>
    )
  );
};
const Backdrop = (props) => {
  return (
    props.show && (
      <Box
        className={`${classes.backdrop} ${
          classes[`backdrop--${props.blurry ? "blurry" : undefined}`]
        }`}
        onClick={props.isLoading ? _void : props.onHide}
      ></Box>
    )
  );
};

const BaseModal = (props) => {
  return (
    <>
      {createPortal(
        <Modal
          px={props.px}
          py={props.py}
          show={props.show}
          mainContainerSx={props.mainContainerSx}
          containerSx={props.containerSx}
          closeIcon={props.closeIcon}
          title={props.title}
          onHide={props.onHide}
          modalContentWrapperSx={props.modalContentWrapperSx}
          titleIconName={props.titleIconName}
          isLoading={props.isLoading}
        >
          {props.children}
        </Modal>,
        document.getElementById("modal-overlay")
      )}
      {createPortal(
        <Backdrop
          show={props.show}
          blurry={props.blurry}
          onHide={props.onHide}
          isLoading={props.isLoading}
        />,
        document.getElementById("backdrop-overlay")
      )}
    </>
  );
};

export default BaseModal;
