export const TestTypes = {
  FIELD_TEST: "FIELD",
  LAB_TEST: "LAB",
};

export const samplestatus ={
  RECEIVED_BY_LAB:"RECEIVED_BY_LAB",
  DELIVERED_TO_LAB:"DELIVERED_TO_LAB",
};

