import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { useEffect, useState } from "react";
import { AlertTypes } from "constants/AlertTypes";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import PointsCard from "components/WalletPointsCard/WalletPointsCard";
import PaymentForm from "components/Wallet/PaymentForm/PaymentForm";
import Grid from "@mui/material/Grid";
import PackagesContent from "components/Package/PackagesContent/PackagesContent";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./custom.scss";
import { useDispatch, useSelector } from "react-redux";
import PaymentSummary from "components/Wallet/PaymentSummary/PaymentSummary";
import { walletActions } from "store/wallet-slice";
import { useCheckPaymentStatusMutation } from "modules/Contractor/apis/payment-apis/payment-apis";
import { useGetFullContractorDataQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { UsersTypesEnum } from "constants/UsersTypes";
import { clearPackageAction } from "store/wallet-slice/wallet-thunk-actions";
import { getLocalStorage } from "services/Storage";

import paymentCustomizations from "./paymentCustomizations";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { useGetPackagesQuery } from "modules/Contractor/apis/inspection-apis/inspection-api";
import { baseURL, isProduction } from "utils/Env/Env";

const Wallet = (props) => {
  const { t } = useTranslation(["wallet"]);

  const { userType } = useSelector((store) => store.auth);

  const { data: fullContractorData, isLoading } = useGetFullContractorDataQuery(
    undefined,
    { skip: userType !== UsersTypesEnum.CONTRACTOR }
  );

  const [messageOpen, setMessageOpen] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedPlan, showPaymentForm, checkoutId } = useSelector(
    (store) => store.wallet
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: packagesData } = useGetPackagesQuery();

  const [checkPaymentStatus, checkPaymentStatusResponse] =
    useCheckPaymentStatusMutation();

  const location = useLocation();

  const resetSelectedPlan = () => {
    dispatch(clearPackageAction());
    dispatch(walletActions.hidePaymentFormHandler());
    dispatch(walletActions.unCheckCustomPointsHandler());
  };

  useEffect(() => {
    dispatch(walletActions.setCheckoutId(null));
  }, [location]);

  useEffect(() => {
    if (showPaymentForm && checkoutId) {
      const script = document.createElement("script");
      const jQueryScript = document.createElement("script");
      const customScript = document.createElement("script");

      script.id = "main_script_id";
      jQueryScript.id = "jquery_script_id";
      customScript.id = "custom_script_id";

      jQueryScript.src = `https://code.jquery.com/jquery.js`;
      script.src = isProduction
        ? `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`
        : `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;

      script.async = true;
      customScript.async = true;

      customScript.innerHTML = paymentCustomizations;

      document.body.appendChild(jQueryScript);
      document.body.appendChild(script);
      document.body.appendChild(customScript);

      return () => {
        const isExistsInBody = (id) =>
          document.body.contains(document.getElementById(id));

        if (isExistsInBody("main_script_id")) {
          document.body.removeChild(script);
        }
        if (isExistsInBody("jquery_script_id")) {
          document.body.removeChild(jQueryScript);
        }
        if (isExistsInBody("custom_script_id")) {
          document.body.removeChild(customScript);
        }
      };
    }
  }, [checkoutId, showPaymentForm]);

  useEffect(() => {
    if (location.pathname.includes("resourcePath")) {
      const selectedPackage = getLocalStorage("selectedPackage");
      checkPaymentStatus({
        packageId: selectedPackage.id,
        checkOutId: searchParams.get("id"),
        points: selectedPackage.points,
      })
        .then(({ data }) => {
          if (data?.paymentstatus === "SUCCEED") {
            navigate(`/invoice-details/${data.walletPrePaymentId}`, {
              state: {
                status: data.paymentstatus,
                points: selectedPackage.points,
              },
            });
          } else if (data?.paymentstatus === "FAILED") {
            onCheckPaymentStatusError();
          }
        })
        .catch((err) => {
          onCheckPaymentStatusError();
        });
    }
  }, [location.pathname]);

  const onCheckPaymentStatusError = () => {
    props.indexShowAlertHandler({
      show: true,
      type: AlertTypes.ERROR,
      message: ErrorCodes.FAILED_PAYMENT_TRANSACTION_GENERIC_ERROR,
    });
  };

  const shopperResultUrl = `${baseURL}/wallet/resourcePath=/v1/checkouts/${checkoutId}/payment`;
  // const shopperResultUrl = `http://localhost:3000/wallet/resourcePath=/v1/checkouts/${checkoutId}/payment`;

  const isWalletDisabled = !isLoading && !fullContractorData?.isProfileComplete;

  //👇 resourcePath in the location pathname ensures that the transaction happened but still waiting for a BE response
  // until that we display a BaseLoader
  if (
    location.pathname.includes("resourcePath") &&
    checkPaymentStatusResponse?.data?.paymentstatus !== "FAILED"
  )
    return <BaseLoader />;

  return (
    <>
      <BaseAlert show={isWalletDisabled} type={AlertTypes.ERROR} mt={2} mb={5}>
        <Box>
          <Typography
            sx={{ fontWeight: "500", fontSize: "1.6rem", marginBottom: 0.5 }}
          >
            عزيزي المقاول,
          </Typography>
          <Typography sx={{ fontSize: "1.6rem" }}>
            لإتمام عملية الدفع يرجى إكمال بيانات الشركة من خلال الانتقال إلى
            صفحة
            <Link to="/settings"> بيانات الحساب.</Link>
          </Typography>
        </Box>
      </BaseAlert>
      <BaseCardTitle mb={2} mt={7}>
        {t("wallet points")}
      </BaseCardTitle>
      <PointsCard
        walletPoints={fullContractorData?.wallet}
        isProfileCompleted={!isWalletDisabled}
        isLoading={isLoading}
      />
      <Grid
        container
        columnSpacing={{ sm: 5, md: 10, lg: 15, xl: 30 }}
        mt={5}
        sx={{
          filter: isWalletDisabled ? "grayscale(1)" : "",
        }}
      >
        <Grid item sm={6}>
          {showPaymentForm && checkoutId ? (
            <PaymentForm
              shopperResultUrl={shopperResultUrl}
              resetSelectedPlan={resetSelectedPlan}
              checkoutId={checkoutId}
              isProduction={isProduction}
              contractorData={fullContractorData}
            />
          ) : (
            <PackagesContent
              isWalletDisabled={isWalletDisabled}
              packagesData={packagesData}
            />
          )}
        </Grid>
        <Grid item sm={6}>
          <PaymentSummary
            selectedPlan={selectedPlan}
            setMessageOpen={setMessageOpen}
            packagesData={packagesData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Wallet;
