import React from "react";
import { Box } from "@mui/system";

const BaseIcon = (props) => {
  const fontSize = {
    xg: "3.6rem",
    lg: "3.2rem",
    md: "2.8rem",
    sm: "2.2rem",
    xs: "1.6rem",
  };
  const clonedIconElement = React.cloneElement(props.icon, {
    sx: { fontSize: fontSize[props.size] || "md" },
  });

  return <Box sx={{ color: props.color }}>{clonedIconElement}</Box>;
};

export default BaseIcon;
