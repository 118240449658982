export const identityType = [
  { id: "1", name: "هوية وطنية" },
  { id: "2", name: "حفيظة نفوس" },
  { id: "3", name: "إقامة" },
  { id: "4", name: "جواز سفر" },
  { id: "5", name: "سجل تجاري" },
  { id: "6", name: "رقم مرجع المنشأة" },
  { id: "7", name: "هوية مواطن خليجي" },
  { id: "8", name: "رقم الحدود" },
  { id: "9", name: "زيارة عمل" },
];

export const consultantIdentityType = [
  { id: "0", name: "هوية وطنية" },
  { id: "1", name: "إقامة" },
];
