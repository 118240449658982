import { Breadcrumbs, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import classes from "./BaseBreadCrumb.module.scss";
import { useTranslation } from "react-i18next";

const BaseBreadCrumb = (props) => {
  const { t } = useTranslation(["common", "dashboard"]);
  return (
    <Breadcrumbs
      separator={
        <ChevronLeftIcon
          fontSize="medium"
          sx={{ color: "primary.colorSoft10", borderWidth: "1rem" }}
        />
      }
      aria-label="breadcrumb"
    >
      {props.data.map((link, idx, data) => {
        let lastItem = idx === data.length - 1;
        return lastItem ? (
          <Typography
            key={idx}
            color={"grayDark.color1"}
            sx={{ fontSize: "1.4rem", color: "grayDark.color1" }}
          >
            {t(link.label, { ns: link?.ns })}
          </Typography>
        ) : (
          <Link
            key={idx}
            style={{ color: "grayDark.color1", fontSize: "1.4rem" }}
            className={classes["base-breadcrumb__link"]}
            to={link.to}
          >
            {t(link.label, { ns: link?.ns })}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BaseBreadCrumb;
