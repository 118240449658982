import { useTheme } from "@emotion/react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import EmptyList from "core-ui/EmpyList/EmptyList";

const EmptyTable = (props) => {
  const { palette } = useTheme();

  return (
    <TableBody
      component={props.component}
      sx={{ backgroundColor: `${palette.background.colorSoft2}`, ...props.sx }}
    >
      <TableRow component={props.component}>
        <TableCell colSpan={"100%"} component={props.component}>
          <EmptyList
            title={props?.emptyData?.title || ""}
            desc={props?.emptyData?.desc || ""}
            label={props?.emptyData?.link || ""}
            img={props?.emptyData?.img || ""}
            to={props?.emptyData?.to || ""}
            linkStyles={{
              color: `${palette.primary.colorSoft5}`,
              fontSize: "1.6rem",
              fontWeight: "500",
            }}
            extrInfo={props?.emptyData?.extrInfo || ""}
            onClick={props?.emptyData?.onClick || ""}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default EmptyTable;
