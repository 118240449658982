import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formattedNumber } from "utils/Numbers/Numbers";
import { useTheme } from "@emotion/react";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseCard from "core-ui/BaseCard/BaseCard";
import EmptyList from "core-ui/EmpyList/EmptyList";
import { publicAssetsPath } from "utils/Paths/Paths";
import classes from "./PaymentSummary.module.scss";
import { calcTax, calcVat } from "utils/Tax/Tax";

function PaymentSummary(props) {
  const { t } = useTranslation(["wallet", "payment"]);

  const { palette } = useTheme();

  const vatPercentage = props?.packagesData?.vatPercentage * 100;

  const totalCostBeforeVAT = +props.selectedPlan?.price;
  const vatRate = vatPercentage;
  const vatValue = calcVat(totalCostBeforeVAT, vatRate);
  const totalWithTax = calcTax(Number(totalCostBeforeVAT), Number(vatValue));
  const textSX = {
    fontSize: "1.6rem",
  };

  const flexClasses = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const alignedText = {
    width: "40%",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowWrap: "anywhere",
      }}
    >
      <BaseCardTitle mb={2}>{t("payment request summary")}</BaseCardTitle>
      <Typography sx={{ fontSize: "1.6rem", mb: 3 }}>
        {"نحيطكم علما بأن لا يمكن استرداد رصيد النقاط من المحفظة"}
      </Typography>
      <BaseCard
        className={classes["payment-summary-card"]}
        px={"3rem"}
        py={"3rem"}
        variant={props.selectedPlan ? "primary" : "secondary"}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {props.selectedPlan ? (
          <>
            <Box sx={{ ...flexClasses, mb: 2 }}>
              <Typography sx={textSX}>{t("number of points")} </Typography>
              <Box sx={{ ...flexClasses, ...alignedText }}>
                <Typography sx={{ ...textSX, fontSize: "2.0rem", mr: 1 }}>
                  {props.selectedPlan?.points
                    ? formattedNumber(+props.selectedPlan.points)
                    : 0}
                </Typography>
                <Typography sx={{ ...textSX }}>نقطة </Typography>
              </Box>
            </Box>

            <Box sx={{ ...flexClasses, mb: 2 }}>
              <Typography sx={textSX}>{t("package value")}</Typography>
              <Box sx={{ ...flexClasses, ...alignedText }}>
                <Typography sx={{ ...textSX, fontSize: "2.0rem", mr: 1 }}>
                  {props.selectedPlan?.price
                    ? formattedNumber(+props.selectedPlan.price)
                    : 0}
                </Typography>
                <Typography sx={{ ...textSX }}>ريال سعودي </Typography>
              </Box>
            </Box>

            <Box sx={{ ...flexClasses, mb: 2 }}>
              <Box sx={{ ...flexClasses }}>
                <Typography sx={textSX}>{t("ضريبة القيمة المضافة")}</Typography>
                <Typography
                  sx={{ ...textSX, ml: 1 }}
                >{`(${vatPercentage}%) `}</Typography>
              </Box>

              <Box sx={{ ...flexClasses, ...alignedText }}>
                <Typography sx={{ ...textSX, fontSize: "2.0rem", mr: 1 }}>
                  {props.selectedPlan?.price ? formattedNumber(vatValue) : 0}
                </Typography>
                <Typography sx={{ ...textSX }}>ريال سعودي </Typography>
              </Box>
            </Box>

            <Box sx={{ ...flexClasses, marginTop: "auto", flexWrap: "wrap" }}>
              <Typography
                sx={{ ...textSX, fontWeight: "bold", fontSize: "2.0rem" }}
              >
                {t("total cost")}
              </Typography>
              <Box sx={{ ...flexClasses, flexWrap: "wrap" }}>
                <Typography
                  sx={{
                    ...textSX,
                    fontWeight: "bold",
                    fontSize: "2.6rem",
                    mr: 1,
                    color: palette.secondary.main,
                  }}
                >
                  {!props.selectedPlan.price
                    ? 0
                    : formattedNumber(+totalWithTax)}
                </Typography>
                <Typography
                  sx={{
                    ...textSX,
                    fontWeight: "bold",
                    fontSize: "2.0rem",
                    color: palette.secondary.main,
                  }}
                >
                  ريال سعودي
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <EmptyList
            title={"لم يتم اختيار باقة"}
            desc={"قم باختيار احدى الباقات المناسبة"}
            img={publicAssetsPath(`icons/empty-payment-summary.svg`)}
          />
        )}
      </BaseCard>
    </Box>
  );
}

export default PaymentSummary;
