import { Box } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useForm } from "react-hook-form";
import PatternValidationChecker from "../../../PatternValidationChecker/PatternValidationChecker";
import { useUpdatePasswordMutation } from "modules/Auth/apis/reset-password-api";
import { useNavigate, useParams } from "react-router-dom";
import useCatchUserType from "hooks/useCatchUserType";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { Helmet } from "react-helmet";

const ResetPasswordForm = () => {
  const [areAllCasesValid, setAreAllCasesValid] = useState("");

  const { t } = useTranslation(["auth"]);

  const navigate = useNavigate();

  const { alert, showAlertHandler } = useAlert();

  const { userRoleFromURL } = useCatchUserType();

  const params = useParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm({ mode: "onChange" });

  const passwordValue = watch("password");

  const [updatePassword, updatePasswordResponse] = useUpdatePasswordMutation();

  const onSubmit = (event) => {
    if (areAllCasesValid) {
      updatePassword({
        token: params.token,
        password: passwordValue,
      })
        .unwrap()
        .then((_) => {
          showAlertHandler({
            show: true,
            message: SuccessCodes.SUCCESS_FORGOT_PASSWORD,
            type: AlertTypes.SUCCESS,
          });
          setTimeout(() => {
            navigate(`/auth/${userRoleFromURL}/login`);
          }, 2000);
        })
        .catch((err) => {
          showAlertHandler({
            show: true,
            message: err.data.errorCode,
            type: AlertTypes.WARNING,
          });
        });
    }

    // submit values
  };

  const getAllResetPasswordValidationCases = (areCasesValid) => {
    setAreAllCasesValid(areCasesValid);
  };

  const isSubmitButtonDisabled =
    updatePasswordResponse.isLoading || updatePasswordResponse.isSuccess;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إعادة تعيين كلمة المرور`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <BaseCard px={"3rem"} py={"4rem"}>
        <BaseAlert
          show={alert.show}
          type={alert.type}
          message={alert.message}
        />
        <Box px={"6rem"} py={"8rem"}>
          <FormTitle title={t("reset password")} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <WrapperContentGrid>
              <BaseInput
                htmlFor="password"
                type="password"
                label={t("password")}
                maxLength={15}
                name="password"
                register={{
                  ...register("password", {
                    required: {
                      value: true,
                      message: `${t("field")} ${t("password")} ${t(
                        "required"
                      )}`,
                    },
                    minLength: {
                      value: 8,
                      message: ` ${t("need 8 chars")}`,
                    },
                  }),
                }}
                errors={errors}
              />
              <PatternValidationChecker
                password={passwordValue}
                isSubmitted={isSubmitted}
                getValidationCases={getAllResetPasswordValidationCases}
              />
              <BaseInput
                htmlFor="repassword"
                label={t("repeat password")}
                name={"repassword"}
                type="password"
                register={{
                  ...register("repassword", {
                    validate: (rePasswordValue) => {
                      if (passwordValue !== rePasswordValue) {
                        return `${t("field")} ${t("repeat password")} ${t(
                          "not match"
                        )}`;
                      }
                    },
                  }),
                }}
                errors={errors}
              />
            </WrapperContentGrid>
            <Box sx={{ marginTop: 5, textAlign: "center" }}>
              <BaseButton
                type="submit"
                disabled={isSubmitButtonDisabled}
                variant={isSubmitButtonDisabled ? "disabled" : ""}
              >
                {t("reset")}
              </BaseButton>
            </Box>
          </form>
        </Box>
      </BaseCard>
    </>
  );
};

export default ResetPasswordForm;
