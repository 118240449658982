import { Box } from "@mui/material";
import { ReactComponent as MasterCardLogo } from "assets/images/payment/mastercard.svg";
import { ReactComponent as VisaLogo } from "assets/images/payment/visa.svg";
import classes from "./PaymentMethods.module.scss";
import { publicAssetsPath } from "utils/Paths/Paths";

const PaymentMethods = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div className={classes["logo-wrapper"]}>
        <MasterCardLogo />
      </div>
      <div className={classes["logo-wrapper"]}>
        <img
          src={publicAssetsPath("images/payment/mada-sm.png")}
          alt="mada"
          width={50}
        />
      </div>
      <div className={classes["logo-wrapper"]}>
        <VisaLogo />
      </div>
    </Box>
  );
};

export default PaymentMethods;
