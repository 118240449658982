/**
 *
 * @param {Array<string>} tabsList - Its an array of strings which describes your tabs labels
 * @returns {Array} - It returns a list of compatible tab object to be rendered {value: 1, label: 'tab1'}
 */
export const renderTabs = (tabsList) => {
  return tabsList.map((tabItem, idx) => ({
    value: idx + 1,
    label: tabItem,
  }));
};
