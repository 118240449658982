import { Box, Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseModal from "core-ui/BaseModal/BaseModal";
import { publicAssetsPath } from "utils/Paths/Paths";

const SampleConfirmModal = ({
  showSamplesConfirm,
  onLeaveSamplesCardHandler,
  inspection,
  checkedSamples,
  handelConfirmDelivery,
  isLoading,
  t,
}) => (
  <BaseModal
    show={showSamplesConfirm}
    modalContentWrapperSx={{
      paddingTop: "2rem",
    }}
    onHide={onLeaveSamplesCardHandler}
  >
    <Box px={"2rem"} sx={{ display: "flex", mb: 1 }}>
      <img
        src={publicAssetsPath("icons/exclamationMark.svg")}
        style={{ width: "4rem" }}
      />

      <Typography
        sx={{
          mt: 2,
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: "grayDark.color",
          ml: 2,
          mb: 1.5,
        }}
      >
        {`تأكيد تسليم العينة للطلب رقم #${inspection?.id}`}
      </Typography>
    </Box>
    <hr />
    <Typography
      sx={{
        color: "grayDark.color",
        fontSize: "1.6rem",
        lineHeight: "3rem",
        px: "4rem",
        mb: 6,
        mt: 3,
      }}
    >
      سيتم تسليم هذه العينات للمختبر
      <span
        style={{
          fontWeight: "bold",
          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      >
        {checkedSamples.join(", ")}
      </span>
      لا يمكن تعديل هذه البيانات بعد الضغط على زر التأكيد.
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "flex-end",
        flexWrap: "wrap",
        px: "4rem",
        mb: 1,
      }}
    >
      <BaseButton
        sx={{
          minWidth: "12.2rem",
          mr: 1,
          color: "text.tags.blueSoft",
        }}
        variant={"transparent"}
        onClick={onLeaveSamplesCardHandler}
        disabled={isLoading}
      >
        {t("إلغاء")}
      </BaseButton>
      <BaseButton
        sx={{ minWidth: "12.2rem" }}
        variant={"secondary"}
        onClick={handelConfirmDelivery}
        isLoading={isLoading}
      >
        {t("تأكيد")}
      </BaseButton>
    </Box>
  </BaseModal>
);

export default SampleConfirmModal;
