import { TestTypes } from "constants/TestTypes";
import inspectionApi from "../../../Inspection/apis";

const inspectionTechnicianApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    // NEW
    getAppointmentDetailsForTechnician: builder.query({
      query: (inspectionId) => ({
        url: `/api/contractor/appointments/v2/technician/${inspectionId}`,
      }),
    }),

    // NEW
    getSamplesForTechnician: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/samples/v2/technician/details/${inspectionId}`,
      }),
      providesTags: ["Request", "Samples"],
    }),
    getTechnicianRequests: builder.query({
      query: ({
        page = 0,
        pageSize = 10,
        sortId = "id,desc",
        testType = TestTypes.LAB_TEST,
      }) => ({
        url: `/api/inspection/inspections/technicianAssignedRequests?testType=${testType}&page=${page}&size=${pageSize}&sort=${sortId}`,
      }),
      providesTags: ["Request"],
    }),
    getTechnicianInspection: builder.query({
      query: (request_id) => ({
        url: `/api/inspection/inspections/v2/technician/${request_id}`,
      }),
      providesTags: ["Request"],
    }),
    getInspectionForTechnician: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/v2/technician/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
    postCollectedSamplesData: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/samples/collect",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Request"],
    }),
    updateProctorData: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/inspectionTests/updateProctor",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Proctor"],
    }),
    confirmExaminationRecordForm: builder.mutation({
      query: ({ body, inspectionId }) => ({
        url: `/api/inspection/inspections/v2/FIELD/${inspectionId}/confirm`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Request", "Proctor", "Lab-Inspection-Tests"],
    }),
    deliverSamplesToDistributionCenter: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/samples/deliverToDistributionCenter",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Request", "Samples"],
    }),
    saveSampleAttachment: builder.mutation({
      query: ({ body, uid }) => ({
        url: `/api/inspection/samples/saveAttachment?sampleId=${uid}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Request"],
    }),
    saveRecordFormAttachment: builder.mutation({
      query: ({ body, appointmentId }) => ({
        url: `/api/inspection/inspectionTests/saveAttachment?appointmentId=${appointmentId}`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body,
      }),
      invalidatesTags: ["Request"],
    }),

    getHistoryLogs: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspections/historyLog/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "History"],
    }),

    getTechnicianAttachment: builder.query({
      query: (attachmentId) => ({
        url: `/api/inspection/attachments/${attachmentId}`,
      }),
      // providesTags: ["Request"],
    }),
    getAsphaltGradationTablesTech: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/aspeltGradationLimits/technician/inspectionLayer/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Lab-Aspelt-Summary", "Lab-Inspection-Tests"],
    }),
    // 2. getInspectionTestsData - NEW
    getTestsDataForTechnician: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspectionTests/v2/technician/${inspectionId}`,
      }),
      // providesTags: ["Request"],
    }),
  }),
});
export const {
  useGetTestsDataForTechnicianQuery,
  useGetSamplesForTechnicianQuery,
  useGetAppointmentDetailsForTechnicianQuery,
  useGetInspectionForTechnicianQuery,
  useGetTechnicianRequestsQuery,
  useGetTechnicianInspectionQuery,
  usePostCollectedSamplesDataMutation,
  useDeliverSamplesToDistributionCenterMutation,
  useSaveSampleAttachmentMutation,
  useSaveRecordFormAttachmentMutation,
  useGetHistoryLogsQuery,
  useUpdateProctorDataMutation,
  useConfirmExaminationRecordFormMutation,
  useGetTechnicianAttachmentQuery,
  useLazyGetTechnicianAttachmentQuery,
  useGetAsphaltGradationTablesTechQuery,
} = inspectionTechnicianApi;

export default inspectionTechnicianApi;
