import { Box, FormHelperText } from "@mui/material";

const SmartSearchError = (props) => {
  return (
    props.inputHasError && (
      <Box>
        <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
          {props.inputHasError.message}
        </FormHelperText>
      </Box>
    )
  );
};

export default SmartSearchError;
