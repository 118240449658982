import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useGetLicenseDetailsQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import {
  useGetCertificatePreviewMutation,
  useValidateCertificateMutation,
  useIssueCertificateMutation,
  useViewCertificateMutation,
} from "modules/Admin/apis/inspection-apis/inspection-api";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import DrillingLicensesData from "../../../../components/DataDetails/DrillingLicensesData/DrillingLicensesData";
import CertificatePreview from "../../../../components/Certificate/CertificatePreview/CertificatePreview";
import { Helmet } from "react-helmet";
import { drillingLicensesdetails } from "services/StaticLookup/Breadcrumb";
import DrillingSites from "modules/Admin/components/DataDetails/DrillingSites/DrillingSites";
import { renderTabs } from "utils/Tabs/Tabs";
import { AdminDrillingLicensesRequestTabs } from "services/StaticLookup/Tabs";
import DrillingLicensesRequests from "modules/Admin/components/DataDetails/DrillingLicensesRequests/DrillingLicensesRequests";
import { DATA_ID } from "constants/Details";
import BaseModal from "core-ui/BaseModal/BaseModal";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { base64ToBlob } from "utils/ApiHelpers";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";

const DrillingLicensesDetails = (props) => {
  const { t } = useTranslation(["dashboard"]);
  const [certificatePreviewModal, setCertificatePreviewModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [certificatePreviewData, setCertificatePreviewData] = useState();

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const params = useParams();
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  //prettier-ignore
  const { data: licenseData, isFetching , isSuccess, refetch} = useGetLicenseDetailsQuery(params.id);

  const [getCertificatePreview] = useGetCertificatePreviewMutation();

  const [validateCertificatePreview] = useValidateCertificateMutation();

  const [issueCertificate, issueCertificateResponse] =
    useIssueCertificateMutation();

  const [viewCertificate, viewCertificateResponse] =
    useViewCertificateMutation();

  useEffect(() => {
    validateCertificatePreview({
      licenseNumber: licenseData?.licenseNumber,
      licenseYear: licenseData?.licenseYear,
    })
      .unwrap()
      .then((responseData) => {
        setButtonDisabled(!responseData?.issueCertificate);
      });
  }, [licenseData]);

  const openPDFHandler = () => {
    if (licenseData?.certificateIssued)
      viewCertificate({
        licenseNumber: licenseData?.licenseNumber,
        licenseYear: licenseData?.licenseYear,
      })
        .unwrap()
        .then((responseData) => {
          var blob = base64ToBlob(responseData?.base64);
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl);
        })
        .catch((err) => {
          showAlertHandler({
            show: true,
            message: ErrorCodes.NO_ATTACHMENT_EXIST_FOR_THIS_LICENSE,
            type: AlertTypes.ERROR,
          });
        });
  };

  const ExportCertificateButton = (props) => {
    return licenseData?.certificateIssued ? (
      <BaseButton
        sx={{ minWidth: "17.3rem" }}
        onClick={openPDFHandler}
        isLoading={viewCertificateResponse.isLoading}
      >
        {t("view certificate", { ns: "dashboard" })}
      </BaseButton>
    ) : (
      <BaseButton
        variant={buttonDisabled ? "disabled" : ""}
        disabled={buttonDisabled}
        sx={{ minWidth: "17.3rem" }}
        onClick={onClickHandler}
      >
        {t("IssuetheCertificate", { ns: "dashboard" })}
      </BaseButton>
    );
  };

  const handleChange = (event, newValue) => {
    setTabsActiveIndex(newValue);
  };

  const onClickHandler = () => {
    getCertificatePreview({
      licenseNumber: licenseData?.licenseNumber,
      licenseYear: licenseData?.licenseYear,
    })
      .unwrap()
      .then((responseData) => {
        setCertificatePreviewData(responseData);
        setCertificatePreviewModal(true);
      });
  };

  const onLeaveModalHandler = () => setCertificatePreviewModal(false);

  const submitIssueCertificate = () => {
    issueCertificate({
      licenseNumber: licenseData?.licenseNumber,
      licenseYear: licenseData?.licenseYear,
    })
      .unwrap()
      .then((responseData) => {
        setCertificatePreviewModal(false);
        showAlertHandler({
          show: true,
          message: "CERTIFICATE_ISSUED_SUCCESSFULLY",
          type: AlertTypes.SUCCESS,
        });
        refetch();
        return responseData;
      })
      .catch((err) => {
        setCertificatePreviewModal(false);
        showAlertHandler({
          show: true,
          message: "UNKNOWN_TRY_AGAIN_ERROR_CODE",
          type: AlertTypes.ERROR,
        });
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`رخص الحفر - رقم الرخصة ${licenseData?.licenseNumber}`}</title>
        <link rel="canonical" href="/drilling-licenses" />
      </Helmet>

      <BaseAlert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        mt={2}
        mb={2}
        icon={false}
        destroy={hideAlertHandler}
        autoClose={5}
      />

      <BaseModal
        show={certificatePreviewModal}
        px={matches ? "1rem" : "4rem"}
        py={matches ? "2rem" : "6rem"}
        onHide={onLeaveModalHandler}
        title={"إصدار شهادة إتمام الفحص"}
        mainContainerSx={{
          height: "90%",
          width: "130rem",
          top: matches ? "53%" : "50%",
          overflow: matches ? "scroll" : "initial",
        }}
      >
        <CertificatePreview
          certificatePreviewData={certificatePreviewData}
          onLeaveModalHandler={onLeaveModalHandler}
          issueCertificate={submitIssueCertificate}
          isLoading={issueCertificateResponse.isLoading}
        />
      </BaseModal>

      <DashboardIntro
        title={`${t("license number", { ns: "dashboard" })} ${
          licenseData?.licenseNumber || ""
        }`}
        description={t("drilling licenses page description", {
          ns: "dashboard",
        })}
        action={isFetching ? <BaseLoader /> : <ExportCertificateButton />}
        breadcrumbData={drillingLicensesdetails({
          licenseNumber: licenseData?.licenseNumber,
          licenseYear: licenseData?.licenseYear,
        })}
      />

      <BaseCard mt={6} py={"1rem"} px={"1rem"}>
        {isFetching ? (
          <BaseLoader />
        ) : (
          <>
            <Tabs
              value={tabsActiveIndex}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              className="tabs"
            >
              {renderTabs(AdminDrillingLicensesRequestTabs).map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  sx={{
                    background:
                      tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                    fontSize: "1.6rem",
                  }}
                />
              ))}
            </Tabs>
            {tabsActiveIndex === 1 && isSuccess && (
              <Box>
                <DrillingLicensesData
                  data={licenseData}
                  dataId={DATA_ID.ADMIN_LICENSES}
                />
                <DrillingSites
                  onClickHandler={onClickHandler}
                  location={[licenseData]}
                />
              </Box>
            )}
            {tabsActiveIndex === 2 && (
              <Box>
                <DrillingLicensesRequests data={licenseData} />
              </Box>
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default DrillingLicensesDetails;
