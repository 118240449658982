import React, { Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sidemenuActions } from "store/sidemenu-slice/sidemenu-slice";
import SidemenuNavLink from "./SidemenuNavItem/SidemenuNavLink";
import { hideSubmenuChildrenListLinks, slideDown } from "utils/DOM/DOM";
import useSetActiveSidemenuLink from "hooks/useSetActiveSidemenuLink";
import { List } from "@mui/material";

const CustomNavLinks = (props) => {
  const { forMobile } = props;

  const dispatch = useDispatch();

  const { activeSidemenuLinkIndex } = useSelector((store) => store.sidemenu);

  const selectItemHandler = useCallback(
    (index, listItem) => {
      dispatch(sidemenuActions.onChangeSidemenuLinks({ index }));
      if (listItem) {
        openSubChildrenList(listItem, index);
      }
    },
    [dispatch]
  );

  const openSubChildrenList = async (listItem, index) => {
    const { type, id } = listItem;

    if (type === "parent") {
      const thisParentChildrenList = Array.from(
        document.querySelectorAll(`[data-sub-list='sub-list-${id}']`)
      );

      const areAllChildrenHidden = await hideSubmenuChildrenListLinks();

      if (areAllChildrenHidden) {
        if (thisParentChildrenList.length !== 0) {
          thisParentChildrenList.forEach((child, idx) => {
            slideDown(child);
          });
        }
      }
    }
  };

  // HOOK
  // 👇 we added hard coded /settings because its out of navData array AND why its out of navData array ?
  // because UX team decided to put this /settings route just in mobile sidemenu so we had to make it fixed like /home
  useSetActiveSidemenuLink(
    [...props.navData, { to: "/settings" }],
    selectItemHandler
  );

  const isActive = (index) => {
    return index === activeSidemenuLinkIndex;
  };

  const hasOneRoute = props.navData?.length === 1;

  return (
    <>
      {props.navData.map((navItem, parentIdx) => {
        const mainItemId = parentIdx + 1;
        return (
          <Fragment key={navItem.label}>
            <SidemenuNavLink
              id={mainItemId}
              index={parentIdx}
              navItem={navItem}
              isForMobile={forMobile}
              selectItemHandler={selectItemHandler}
              isActive={isActive}
              type="parent"
              forceActiveClass={hasOneRoute}
            />
            {navItem.children && (
              <List data-sub-list={`sub-list-${mainItemId}`}>
                {navItem.children.map((childNavItem, idx) => {
                  return (
                    <SidemenuNavLink
                      key={childNavItem.label}
                      id={mainItemId}
                      index={`${parentIdx}.${idx + 1}`}
                      type="child"
                      navItem={childNavItem}
                      isForMobile={forMobile}
                      selectItemHandler={selectItemHandler}
                      isActive={isActive}
                      forceActiveClass={hasOneRoute}
                    />
                  );
                })}
              </List>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default CustomNavLinks;
