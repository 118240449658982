import contractorApi from "modules/Contractor/apis";
import { queryBuilder } from "utils/ApiHelpers";

const consultationRequestsApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    getConsultantRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/requests?${q}`,
        };
      },
    }),
    getConsultantLicenses: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/licenses?${q}`,
        };
      },
    }),
    getConsultationRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/consultationrequests?${q}`,
        };
      },
      providesTags: ["Consultant-Consultation-Requests"],
    }),
    getConsultationRequestDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/consultant/consultationrequests/${id}`,
        };
      },
    }),
    getContractorCompanies: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/consultant/contractorCompanies?${q}`,
        };
      },
    }),
    getConsultationRequestLicense: builder.mutation({
      query: (body) => ({
        url: `/api/consultant/getVaildConsultationRequestLicense`,
        method: "POST",
        body: body,
      }),
    }),
    newConsultantRequest: builder.mutation({
      query: (body) => ({
        url: `/api/consultant/newConsultantRequest`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Consultant-Consultation-Requests"],
    }),
  }),
});

export const {
  useGetConsultantRequestsQuery,
  useLazyGetConsultantRequestsQuery,
  useGetConsultantLicensesQuery,
  useLazyGetConsultantLicensesQuery,
  useGetConsultationRequestsQuery,
  useGetConsultationRequestDetailsQuery,
  useLazyGetContractorCompaniesQuery,
  useGetConsultationRequestLicenseMutation,
  useNewConsultantRequestMutation,
} = consultationRequestsApi;

export default consultationRequestsApi;
