import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Box, List, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import classes from "./SideMenu.module.scss";
import { RESPONSIVE_BREAKPOINT, SIDEBAR_WIDTH } from "constants/Layout";
import { useSelector } from "react-redux";
import {
  getSubListsHeightOnMount,
  hideSubmenuChildrenListLinks,
} from "utils/DOM/DOM";
import useLogout from "hooks/useLogout";
import CustomNavLinks from "components/CustomNavLinks/CustomNavLinks";

import { getNavData } from "utils/Sidemenu/Sidemenu";

const sidebarSx = {
  width: SIDEBAR_WIDTH,
  position: "fixed",
  insetInlineStart: "inline-start",
  minHeight: "100vh",
  padding: "3.5rem 1.6rem",
  display: "flex",
  flexDirection: "column",
};

const SideMenu = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down(RESPONSIVE_BREAKPOINT));

  const { userType, userRole } = useSelector((store) => store.auth);

  const mobSidebarSx = matches
    ? {
        ...sidebarSx,
        transform: `translateX(-${SIDEBAR_WIDTH})`,
      }
    : {};

  const { t } = useTranslation(["common", "home"]);

  const { activeSidemenuLinkIndex } = useSelector((store) => store.sidemenu);

  const { logout } = useLogout();

  const logoutHandler = () => {
    logout();
  };

  const isActive = (index) => {
    return index === activeSidemenuLinkIndex;
  };

  useEffect(() => {
    getSubListsHeightOnMount(hideSubmenuChildrenListLinks);
  }, []);

  const navData = getNavData(userRole, userType);

  return (
    <Box className={classes["sidemenu"]} sx={{ ...sidebarSx, ...mobSidebarSx }}>
      <Box mb={16} textAlign={"center"}>
        <img
          src={publicAssetsPath(
            userType ? `images/logos/${userType}-white.svg` : ""
          )}
          alt="logo"
          width="200"
          height="auto"
        />
      </Box>
      <List className={classes["sidemenu__list"]} aria-label="sidemenu-list">
        {/* NAV LINKS */}
        <CustomNavLinks navData={navData} isMobile={false} />
        {/* NAV LINKS */}
      </List>
      <Box className={classes["sidemenu__logout"]}>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <LogoutIcon />
          <Link
            onClick={logoutHandler}
            className={classes["sidemenu__logout-link"]}
          >
            {t("logout", { ns: "common" })}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SideMenu;
