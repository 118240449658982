import { TestTypes } from "constants/TestTypes";
import { ScheduleAppointment } from "services/ScheduleAppointment";
import { baseCalendarDateFormatter } from "utils/Date/Date";

const RaiseNewRequestService = {
  isAppointmentTimeValid(data) {
    const stage3Data = data?.[`stage-3-formData`];

    const currentDate = new Date();

    const selectionDate = new Date(baseCalendarDateFormatter(stage3Data.date));

    const isLab = data?.testType === TestTypes.LAB_TEST;

    const scheduleAppointment = new ScheduleAppointment(selectionDate, {
      isLab: isLab,
      isField: !isLab,
    });
    const availableRangeOfAppointmentHours = isLab
      ? scheduleAppointment.getLabTestTimeRange
      : scheduleAppointment.getFieldTestTimeRange;

    //if the appointment is next year
    if (currentDate.getFullYear() < selectionDate.getFullYear()) return true;

    //if the appointment is next month
    if (currentDate.getMonth() < selectionDate.getMonth()) return true;

    // if the appointment is within the same year and month of today then check day date
    if (
      currentDate.getFullYear() === selectionDate.getFullYear() &&
      currentDate.getMonth() === selectionDate.getMonth()
    ) {
      //if it is in a future date
      if (currentDate.getDate() < selectionDate.getDate()) {
        return true;
      } else {
        //if it is in the same day

        //if it is in a future hour
        if (
          currentDate.getHours() + availableRangeOfAppointmentHours <
          stage3Data.time.$H
        )
          return true;

        //if it is in a future minute
        if (
          currentDate.getHours() + availableRangeOfAppointmentHours ===
            stage3Data.time.$H &&
          (currentDate.getMinutes() < stage3Data.time.$m ||
            currentDate.getMinutes() === data?.selectedTime.getMinutes())
        )
          return true;
      }
    }

    return false;
  },
  getTotalCostForTest(data) {
    const labTotalSampleCostValue = (data?.layersData || [])
      .slice(0, 1)
      .reduce((acc, cur) => {
        return acc + cur?.sampleCollectionCost;
      }, 0);

    const labTotalTestsCost = (data?.layersData || [])
      .slice(0, 1)
      .reduce((acc, cur) => {
        return acc + (cur?.combinedLabTestCost + labTotalSampleCostValue);
      }, 0);

    const fieldTotalTestsCost = data?.device?.price || 0;

    return { labTotalSampleCostValue, labTotalTestsCost, fieldTotalTestsCost };
  },
};

export default RaiseNewRequestService;
