import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "./DashboardDrillingLicensesData.module.scss";
import { formatPhoneNumber } from "utils/Numbers/Numbers";

const DrillingLicensesData = (props) => {
  return !props.data ? (
    <BaseLoader />
  ) : (
    <Box className={classes["drilling-data"]} mt={6} ml={4}>
      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          {props.title || "الرخصة"}
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              رقم الرخصة
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.licenseYear}/{props.data.licenseNumber}
            </Typography>
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              مقدم الخدمة
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.serviceProviderName}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              شركة المقاولات
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorCompany}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              النوع
            </Typography>
            <BaseTypeTag type={props.data.typeRefId} />
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              تاريخ الانتهاء
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.expiryDate}
            </Typography>
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              نوع الحفر{" "}
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.drillingType || "لايوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              نوع الخدمة{" "}
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.serviceName || "لايوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              حالة الشهادة
            </Typography>
            <BaseStatus
              status={props.data.certificateIssued}
              dataId={props.dataId}
            />
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />

      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          معلومات شركة المقاولات
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              اسم شركة المقاولات
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorCompany || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              البريد الالكتروني
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorEmail || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              رقم الجوال
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {formatPhoneNumber(props.data.contractorMobile) || "لا يوجد"}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />
      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          بيانات الحفر
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الطول
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.licenceLength || "لا يوجد"} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              العرض
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.boundaryWidth} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              العمق
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.boundaryDepth} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الحجم
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.volumNo}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>

      <hr />

      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          معلومات الاستشاري
        </Typography>
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الاسم
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.consultantName || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              رقم الجوال
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {formatPhoneNumber(props.data.consultantMobile) || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              البريد الالكتروني
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.consultantEmail || "لا يوجد"}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />
    </Box>
  );
};

export default DrillingLicensesData;
