import { TestTypes } from "constants/TestTypes";

export const requestCheckupTimelineStages = {
  [TestTypes.LAB_TEST]: [
    {
      id: 1,
      name: "إضافة الرخص",
      title: "طلب فحص: تفاصيل الطلب",
      subTitle: "يرجى إضافة رخص الحفر، وتعبئة البيانات التالية.",
    },
    {
      id: 2,
      name: "تفاصيل الطلب ",
      title: "طلب فحص: تفاصيل الطلب",
      subTitle: "قم بإدخال البيانات اللازمة",
    },
    {
      id: 3,
      name: "الاختبارات",
      title: "طلب فحص: الاختبارات",
      subTitle: "قائمة الاختبارات اللازمة للحصول على شهادة اتمام الفحص",
    },
    {
      id: 4,
      name: "تحديد الموعد",
      title: "طلب فحص: تحديد الموعد",
      subTitle: "يرجى تحديد الموعد المناسب للفحص",
    },
    {
      id: 5,
      name: "ملخص الطلب",
      title: "طلب فحص: ملخص الطلب",
      subTitle:
        "قم بمراجعة ملخص الطلب والتأكد من توفر نقاط كافية لإتمام العملية",
    },
  ],
  [TestTypes.FIELD_TEST]: [
    {
      id: 1,
      name: "تحديد الرخصة",
      title: "طلب فحص: تحديد الرخصة",
      subTitle: "يرجى تحديد الرخصة وإدخال معلومات الاستشاري",
    },
    {
      id: 2,
      name: "تفاصيل الطلب ",
      title: "طلب فحص: تفاصيل الطلب",
      subTitle: "قم بإدخال البيانات اللازمة",
    },
    {
      id: 3,
      name: "الاختبارات",
      title: "طلب فحص: الاختبارات",
      subTitle: "قائمة الاختبارات اللازمة للحصول على شهادة اتمام الفحص",
    },
    {
      id: 4,
      name: "تحديد الموعد",
      title: "طلب فحص: تحديد الموعد",
      subTitle: "يرجى تحديد الموعد المناسب للفحص",
    },
    {
      id: 5,
      name: "ملخص الطلب",
      title: "طلب فحص: ملخص الطلب",
      subTitle:
        "قم بمراجعة ملخص الطلب والتأكد من توفر نقاط كافية لإتمام العملية",
    },
  ],
};
