import { isNumber } from "../Numbers/Numbers";

/**
 * @description Calculate VAT based on a given value and VAT rate.
 * @param {number} value - The original value before VAT.
 * @param {number} vatRate - The VAT rate in percentage. E.g., 15 for 15%.
 * @returns {number} The VAT amount.
 */
export const calcVat = (value, vatRate = 15) => {
  if (!isNumber(value)) return 0;
  return ((vatRate * value) / 100).toFixed(2);
};

/**
 * @description Calculate the total value after adding a certain tax amount.
 * @param {number} original - The original value.
 * @param {number} tax - The tax amount to be added.
 * @returns {number} The total value after adding the tax.
 */
export const calcTax = (original, tax) => {
  if (!isNumber(original) || !isNumber(tax)) return 0;
  return (parseFloat(original) + parseFloat(tax)).toFixed(2);
};

/**
 * @description Calculate the price without VAT.
 * @param {number} price - The original price with VAT.
 * @param {number} vatRate - The VAT rate in percentage. E.g., 15 for 15%.
 * @returns {number} The price without VAT.
 */
export const getPriceWithoutVAT = (price, vatRate = 15) => {
  if (!isNumber(price)) return 0;
  return ((100 * price) / (100 + vatRate)).toFixed(2);
};

/**
 * @description Calculate the VAT excluded from the price.
 * @param {number} priceWithoutVAT - The price without VAT.
 * @param {number} vatRate - The VAT rate in percentage. E.g., 15 for 15%.
 * @returns {number} The VAT amount.
 */
export const getVATExcluded = (priceWithoutVAT, vatRate = 15) => {
  if (!isNumber(priceWithoutVAT)) return 0;
  return ((priceWithoutVAT * vatRate) / 100).toFixed(2);
};
