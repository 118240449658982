import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BaseMUITooltip = (props) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "20px",
              },
          },
        },
      }}
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      marginTop: "1rem",
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #6E6E6E",
      fontSize: 11,
    },
  }));

  return <LightTooltip {...props}>{props.children}</LightTooltip>;
};

export default BaseMUITooltip;
