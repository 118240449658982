import { Box, Typography } from "@mui/material";
import classes from "./BaseUserTypeTag.module.scss";
import { defaultEmptyValue } from "constants/Empty";

const BaseUserTypeTag = ({ type, role }) => {
  const renderLabel = () => {
    let label = "لا يوجد";
    let stylesVariantions = "";

    switch (role) {
      case "TECHNICIAN":
        label = "فني رقابة";
        stylesVariantions = "blue";
        break;
      case "SYSADMIN":
        if (type === "RESAA") {
          label = "فني التشغيل";
          stylesVariantions = "green";
        }
        break;
      case "LAB_TECHNICIAN":
      case "SYSOPS":
        if (type === "LABORATORY") {
          label = "فني المختبر";
          stylesVariantions = "navy";
        }
        if (type === "CENTER") {
          label = "مسؤول مركز التوزيع";
          stylesVariantions = "orange";
        }
        break;
      default:
        label = defaultEmptyValue;
        break;
    }

    return { label, stylesVariantions };
  };
  return (
    <Box
      py={"0.3rem"}
      className={`${classes["users-type"]} ${
        classes[`users-type--${renderLabel()?.stylesVariantions}`]
      }`}
    >
      <Typography className={classes["users-type__text"]}>
        {renderLabel()?.label}
      </Typography>
    </Box>
  );
};

export default BaseUserTypeTag;
