import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "services/Api";
import { REACT_APP_INSPECTOR_BASE_URL } from "utils/Env/Env";

const inspectionApi = createApi({
  reducerPath: "inspectionApi",
  baseQuery: axiosBaseQuery({
    baseUrl: REACT_APP_INSPECTOR_BASE_URL,
  }),
  endpoints: () => ({}),
});

export default inspectionApi;
