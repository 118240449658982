import React, { useEffect, useMemo } from "react";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { Box } from "@mui/material";

const LayerTest = (props) => {
  const { raiseNewRequestData, errors, control, id, editableLayersTemplates } =
    props;

  return (
    <>
      <Box>
        <BaseDropdown
          control={control}
          name={`station-${id}-layerTest`}
          errors={errors}
          required={{ message: "حقل مطلوب" }}
          labelStyles={{
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: 500,
          }}
          placeholder="حدد نوع اختبار مواد الأعمال المدنية "
          data={
            editableLayersTemplates ||
            raiseNewRequestData?.fetchedFieldLayersTemplates ||
            []
          }
          emptyListPlaceholder="يرجى تحديد نوع الجهاز أولا"
        />
      </Box>
    </>
  );
};

export default LayerTest;
