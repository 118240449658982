import { useState } from "react";

const useShowBaseModal = () => {
  const [show, setShow] = useState(false);

  const onOpenModalHandler = () => {
    setShow(true);
  };

  const onLeavePageHandler = () => {
    setShow(false);
  };

  return { show: show, onOpen: onOpenModalHandler, onHide: onLeavePageHandler };
};

export default useShowBaseModal;
