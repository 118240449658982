import { Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";

const DisplayDateAndTime = (props) => {
  if (!props.createdAt) return defaultEmptyValue;

  const timeLabel = displayFormattedTimeHHMM(props.createdAt);
  return (
    <Typography
      component={"span"}
      sx={{ fontSize: "1.6rem" }}
    >{`${formatDateYYYYMMDD(props.createdAt)} - ${timeLabel}`}</Typography>
  );
};

export default DisplayDateAndTime;
