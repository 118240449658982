import { createLogConfig, filterList, findTechnicianByStatus } from "../utils";

import { serverKeysEnum, technicianStatus } from "../utils/enums";
import HistoryRender from "../HistoryRender/HistoryRender";
import { historyLabCycle } from "./historyLabCycle";
import { historyLabDeassiningCycle } from "./historyLabDeassiningCycle";

function HistoryLab({
  rawData,
  inspection,
  sortedCycleItems,
  hasDeassignItems,
  reAssignmentCycleItems,
  hasReassignedTechnicians,
}) {
  const _props = {
    rawData,
    sortedCycleItems,
    hasDeassignItems,
    reAssignmentCycleItems,
    hasReassignedTechnicians,
    inspection,
  };

  function createCycleLogItems() {
    let cycle = [];

    // createdAt
    const createdAtConfig = createLogConfig({
      label: "",
      value: "تم إنشاء الطلب – بانتظار قبول المختبر",
      date: rawData[serverKeysEnum.CREATED_AT],
      isBackendValueExists: rawData[serverKeysEnum.CREATED_AT],
    });

    cycle.push(createdAtConfig);

    // prettier-ignore
    const doneTechnician = findTechnicianByStatus(rawData[serverKeysEnum.ASSIGNMENT_HISTORY_LIST], technicianStatus.DONE);

    // THE WHOLE CYCLE
    sortedCycleItems.forEach((cycleItem, cycleItemIndex) => {
      const prevItem = sortedCycleItems[cycleItemIndex - 1];
      //prettier-ignore
      const isFirstIteration = cycleItemIndex === 0;
      //prettier-ignore
      const isFirstLabAssigned = rawData[serverKeysEnum.ASSIGNED_LAB_NAME];
      //prettier-ignore
      const firstAssignedTechnician = reAssignmentCycleItems[0];
      //prettier-ignore
      const isLabNotNewlyAssigned = !prevItem?.labAssignedTime;
      //prettier-ignore
      const firstTechnician = sortedCycleItems.findIndex(
        tech => 
        tech?.status === technicianStatus.ASSIGNED ||
        tech?.status === technicianStatus.REASSIGNED ||
        tech?.status === technicianStatus.DONE
      );
      // prettier-ignore
      const isNotFirstAssignedTechnician = firstTechnician !== cycleItemIndex;

      // THIS CASE IS THE INITIAL CASE IF WE THERE IS NO DE-ASSIGN ACTIVITES
      if (isFirstIteration) {
        // prettier-ignore
        const assignedOrDoneTechnician = firstAssignedTechnician || doneTechnician;

        if (!hasDeassignItems && isFirstLabAssigned) {
          const assignedLabConfig = createLogConfig({
            label: "تم قبول الطلب من قبل المختبر: ",
            value: rawData[serverKeysEnum.ASSIGNED_LAB_NAME],
            date: rawData[serverKeysEnum.LAB_ASSIGNED_AT],
            isBackendValueExists: rawData[serverKeysEnum.LAB_ASSIGNED_AT],
          });
          const assignedTechnicianConfig = createLogConfig({
            label: "تم إسناد الطلب إلى فني الرقابة: ",
            value: assignedOrDoneTechnician.technicianName || "",
            date: assignedOrDoneTechnician.technicianAssignedAt,
            isBackendValueExists: rawData[serverKeysEnum.LAB_ASSIGNED_AT],
          });
          cycle.push(assignedLabConfig, assignedTechnicianConfig);
        }
      }

      // NO DE-ASSIGNING
      if (!hasDeassignItems) {
        // THIS CASE IF ADMIN REASSIGNED THE REQUEST TO ANOTHER TECHNICIAN
        if (isNotFirstAssignedTechnician && isLabNotNewlyAssigned) {
          const assignedTechnicianConfig = createLogConfig({
            label: "تم إعادة إسناد الطلب الى فني الرقابة: ",
            value: `${cycleItem.technicianName} من قبل: ${cycleItem.assignedBy}`,
            date: cycleItem.technicianAssignedAt,
            isBackendValueExists: cycleItem.technicianName,
          });

          cycle.push(assignedTechnicianConfig);
        }
        historyLabCycle(_props, cycle, cycleItem, cycleItemIndex);
      }

      // DE-ASSIGNING
      if (hasDeassignItems) {
        historyLabDeassiningCycle(_props, cycle, cycleItem, cycleItemIndex);
        historyLabCycle(_props, cycle, cycleItem, cycleItemIndex);
      }
    });

    // submittedAt
    const submittedAtConfig = createLogConfig({
      label: "",
      value: `تم رفع نتيجة الاختبار: ${inspection?.name}`,
      date: rawData[serverKeysEnum.LABORATORY_SCORE_AT],
      isBackendValueExists: rawData[serverKeysEnum.LABORATORY_SCORE_AT],
    });

    cycle.push(submittedAtConfig);

    // completedAt
    const completedAtConfig = createLogConfig({
      label: "",
      value: "تم اكتمال الطلب",
      date: rawData[serverKeysEnum.COMPLETED_AT],
      isBackendValueExists: rawData[serverKeysEnum.COMPLETED_AT],
    });

    cycle.push(completedAtConfig);

    return cycle;
  }

  const cycle = createCycleLogItems();

  return <HistoryRender logs={filterList(cycle)} />;
}

export default HistoryLab;
