import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useCatchUserType from "hooks/useCatchUserType";
import { availableTo } from "utils/Auth/Auth";
import { UsersTypesEnum } from "constants/UsersTypes";
import { AlertTypes } from "constants/AlertTypes";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { ValidEmailPattern } from "constants/Forms";
import "./LoginForm.scss";
import useLogin from "hooks/useLogin";

const LoginForm = (props) => {
  const { t } = useTranslation(["auth"]);
  const { userRoleFromURL } = useCatchUserType();

  const { loginHandler, loginResponse, customError } = useLogin();

  useEffect(() => {
    props.getErrorResponse({
      show: loginResponse.isError || customError?.show,
      message: loginResponse?.error?.data.errorCode || customError?.message,
      type: AlertTypes.WARNING,
    });
  }, [loginResponse.isError, customError]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    loginHandler(data);
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
    // submit values
  };

  return (
    <Box className="login-form">
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        <WrapperContentGrid gap={2}>
          <BaseInput
            type="email"
            htmlFor="email"
            label={t("auth:email")}
            name="username"
            register={{
              ...register("username", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("auth:email")} ${t("required")}`,
                },
                pattern: {
                  value: ValidEmailPattern,
                  message: `${t("Invalid email address")}`,
                },
              }),
            }}
            errors={errors}
          />
          <BaseInput
            id="password"
            htmlFor="password"
            label={t("password")}
            name="password"
            type="password"
            register={{
              ...register("password", {
                required: {
                  value: true,
                  message: `${t("الحقل مطلوب")} `,
                  // message: `${t("Please check the data entered")} `,
                },
              }),
            }}
            errors={errors}
            resetPassword
          />
        </WrapperContentGrid>
        <Box sx={{ marginTop: 4, textAlign: "center" }}>
          {loginResponse.isLoading ? (
            <BaseLoader />
          ) : (
            <BaseButton
              disabled={loginResponse.isLoading}
              type="submit"
              size={"md"}
            >
              {t("login")}
            </BaseButton>
          )}
        </Box>
        {availableTo(
          userRoleFromURL,
          [UsersTypesEnum.CONTRACTOR, UsersTypesEnum.CONSULTANT],
          <Box py={2} px={"2vw"} sx={{ textAlign: "center" }}>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "5px" }}
            >
              <Typography sx={{ fontSize: "1.4rem" }}>
                {t(" ليس لديك حساب؟ ")}
              </Typography>
              <Link
                to={`/auth/${userRoleFromURL}/register`}
                className="creat-account-link"
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.4rem",
                    color: "#20766c",
                  }}
                >
                  {t("إنشاء حساب")}
                </Typography>
              </Link>
            </div>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default LoginForm;
