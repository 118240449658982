import { Box, Typography } from "@mui/material";
import classes from "./TableLoader.module.scss";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";

const TableLoader = () => {
  return (
    <Box className={classes["table-loader"]}>
      <Box className={classes["table-loader__wrapper"]}>
        <BaseLoader size={"sm"} />
        <Typography className={classes["table-loader__text"]}>
          جاري التحميل...
        </Typography>
      </Box>
      <Box className={classes["table-loader__overlay"]}></Box>
    </Box>
  );
};

export default TableLoader;
