import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

const LazyHeader = React.lazy(() =>
  import("../LandingPage/component/Header/Header")
);
const LazyFooter = React.lazy(() =>
  import("../LandingPage/component/Footer/Footer")
);

const Home = () => {
  return (
    <Fragment>
      <LazyHeader />
      <Outlet />
      <LazyFooter />
    </Fragment>
  );
};

export default Home;
