import { Box, TableCell, TableRow } from "@mui/material";
import classes from "../BaseTable.module.scss";
import { _void } from "utils/Objects/Objects";

import WithDetails from "../Utilities/components/WithDetails/WithDetails";
import { Fragment } from "react";

const RowBaseTable = (props) => {
  return (
    <TableRow key={props.element.id} sx={props.rowSx}>
      {props.tableHeader.map((headerItem, idx) => {
        return (
          <Fragment key={idx}>
            {props.children({
              headerItem: headerItem,
              element: props.element,
              idx,
            })}
          </Fragment>
        );
      })}
      {props.withAction && (
        <TableCell
          className={classes["base-table__details-link"]}
          sx={{
            background: "base.white",
            whiteSpace: "nowrap",
            "&:hover": {
              color: "primary.colorSoft3",
            },
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={
              props.withAction.action
                ? props.withAction.action.bind(null, props.element)
                : _void
            }
            style={{
              color: "grayDark.color1",
            }}
          >
            {props.withAction.comp}
          </Box>
        </TableCell>
      )}
      {props.withDetails && (
        <WithDetails
          navigateWithDataHandler={props.navigateWithDataHandler}
          element={props.element}
        />
      )}
    </TableRow>
  );
};

export default RowBaseTable;
