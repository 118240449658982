import { Box, Typography, Stack, useMediaQuery } from "@mui/material";
import BaseBreadCrumb from "core-ui/BaseBreadCrumb/BaseBreadCrumb";
import DashboardUserInfo from "../DashboardUserInfo/DashboardUserInfo";
import classes from "./DashboardIntro.module.scss";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import { useSelector } from "react-redux";
import { UsersTypesEnum } from "constants/UsersTypes";
import { useGetFullContractorDataQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WarningModal from "modules/Contractor/components/MissingProfileWarningModal/WarningModal";
import { useNavigate } from "react-router-dom";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";

const DashboardIntro = (props) => {
  const navigate = useNavigate();
  const { palette, breakpoints } = useTheme();
  const matchesLg = useMediaQuery(breakpoints.down("lg"));

  const { userType, userRole } = useSelector((store) => store.auth);

  const { data: contractorData, isLoading } = useGetFullContractorDataQuery(
    undefined,
    { skip: userType !== UsersTypesEnum.CONTRACTOR }
  );

  const { pathname, state } = useLocation();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (
      sysopsContractorRoleChecker(userType, userRole) &&
      contractorData &&
      !contractorData?.isProfileComplete
    ) {
      if ((pathname === "/home" && state?.fromAuth) || pathname === "/wallet") {
        setShowConfirm(true);
      }
      if (pathname === "/home") {
        showAlertHandler({
          show: true,
          message: null,
          type: AlertTypes.ERROR,
        });
      }
    }
  }, [contractorData]);

  const onLeavePageHandler = (message) => {
    setShowConfirm(false);
  };

  const stylesVariantions = {
    ...(props.variant === "primary" && {
      color: `${palette.base.black}`,
    }),
    ...(props.variant === "secondary" && {
      color: `${palette.primary.colorSoft10}`,
    }),
  };
  return (
    <section>
      <WarningModal
        showConfirm={showConfirm}
        onLeaveHandler={onLeavePageHandler}
        navigate={navigate}
      />

      <Box className={classes["dashboard-intro__block"]}>
        <Box>
          {props.breadcrumbData && props.breadcrumbData.length > 0 && (
            <BaseBreadCrumb data={props.breadcrumbData} />
          )}
        </Box>
        <DashboardUserInfo
          contractorData={contractorData}
          isLoading={isLoading}
        />
      </Box>

      {props?.withAlertHandler && props?.withAlertHandler?.comp}
      <BaseAlert
        mt={"2rem"}
        show={alert.show}
        type={alert.type}
        message={alert.message}
        destroy={alert.message ? hideAlertHandler : false}
      >
        <span>
          {!alert.message && (
            <span>
              <Typography
                sx={{
                  fontSize: "inherit !important",
                  fontWeight: "bold",
                }}
              >
                عزيزي المقاول,
              </Typography>
              لتتمكن من الاستفادة من خدمات المنصة الرجاء إكمال بيانات الشركة من
              خلال الانتقال إلى صفحة
              <Link to="/settings"> بيانات الحساب.</Link>
            </span>
          )}
        </span>
      </BaseAlert>
      {props.warning && <Box mt={5}>{props.warning}</Box>}
      <Box
        mt={
          props.mt
            ? props.mt
            : props?.withAlertHandler || alert.show
            ? "3rem"
            : "5rem"
        }
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={matchesLg ? "wrap" : ""}
        gap={3}
      >
        <Box sx={props?.descriptionSx}>
          {props.title && (
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: "bold",
                marginBottom: "1.8rem",
                ...stylesVariantions,
              }}
            >
              {props.title}
            </Typography>
          )}

          {(props.description || props.to) && (
            <Stack
              direction={"row"}
              spacing={1}
              display={"flex"}
              alignItems={"center"}
              sx={{ mt: -1 }}
            >
              <Typography sx={{ color: "grayDark.color1", fontSize: "1.6rem" }}>
                {props.description && props.description}
                {props.to && (
                  <Link
                    style={{
                      marginRight: "0.5rem",
                      color: `${palette.primary.main}`,
                      fontSize: "1.6rem",
                      fontWeight: "bold",
                    }}
                    to={props.to}
                  >
                    {props.label}
                  </Link>
                )}
              </Typography>
            </Stack>
          )}
        </Box>
        {props.action}
      </Box>
    </section>
  );
};

export default DashboardIntro;
DashboardIntro.defaultProps = {
  variant: "primary",
};
