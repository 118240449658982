import { useLogoutMutation } from "modules/Auth/apis/login-api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeUserToken } from "services/Storage";
import { logoutAction } from "store/auth-slice/auth-thunk-actions";

const useLogout = (actionsToDispatch) => {
  const [logout] = useLogoutMutation();

  const dispatch = useDispatch();

  const { userType } = useSelector((store) => store.auth);

  const navigate = useNavigate();

  const _logout = () => {
    logout()
      .unwrap()
      .then(() => {
        if (Array.isArray(actionsToDispatch)) {
          for (let action of actionsToDispatch) {
            dispatch(action());
          }
        }
        dispatch(logoutAction());
        removeUserToken();
      })
      .then(() => {
        navigate(`/auth/${userType}/login`);
      })
      .catch((error) => console.error("error: ", error));
  };

  return { logout: _logout };
};

export default useLogout;
