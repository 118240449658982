import AuthFormIntro from "components/Forms/_Utilites/AuthFormIntro/AuthFormIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import CenteredWrapper from "core-ui/CenteredWrapper/CenteredWrapper";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useCatchUserType from "hooks/useCatchUserType";
import { Helmet } from "react-helmet";
import useAlert from "hooks/useAlert";
import { UsersRolesEnum } from "constants/UsersTypes";

const Register = (props) => {
  const { alert, showAlertHandler } = useAlert();
  const { userRoleFromURL } = useCatchUserType();
  const isConsultant = userRoleFromURL === "consultant";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إنشاء حساب`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <CenteredWrapper width={isConsultant ? "90rem" : "60rem"}>
        <AuthFormIntro />
        <BaseCard py={"2rem"} px={"2rem"}>
          <BaseAlert
            show={alert.show}
            type={alert.type}
            message={alert.message}
          />

          <Box paddingX={"9rem"} paddingY={"2rem"}>
            <Outlet context={showAlertHandler} />
          </Box>
        </BaseCard>
      </CenteredWrapper>
    </>
  );
};

export default Register;
