import React, { useState } from "react";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { BasePDF } from "../BasePDF";
import { pdf } from "@react-pdf/renderer";

const PreviwePDF = ({ children, ...props }) => {
  const { t } = useTranslation();
  const handlePrintClick = async () => {
    const blobPdf = await pdf(
      <BasePDF {...props}>{children}</BasePDF>
    ).toBlob();
    const url = URL.createObjectURL(blobPdf);
    window.open(url, "_blank");
  };

  return (
    <BaseButton
      disabled={props.disabled}
      variant={props.disabled ? "disabled" : ""}
      onClick={handlePrintClick}
    >
      {t("print")}
    </BaseButton>
  );
};

export default PreviwePDF;
