import contractorApi from "../../../Contractor/apis";
import { queryBuilder } from "utils/ApiHelpers";

const companiesApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: ({
        page = 0,
        pageSize = 10,
        sortId = "id,desc",
        nameQuery = "",
      }) => {
        return {
          url: `/api/contractor/contractors?page=${page}&size=${pageSize}&sort=${sortId}&searchTerm=${nameQuery}`,
        };
      },
    }),
    getCompany: builder.query({
      query: (id) => {
        return {
          url: `/api/contractor/contractors/${id}`,
        };
      },
    }),
    getCompanyInvoices: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/contractor/walletPrepayments/successful?${q}`,
        };
      },
    }),
    getCompanyPayments: builder.query({
      query: ({ query }) => {
        const q = queryBuilder(query);
        return {
          url: `/api/contractor/walletTransactions/contractorTransactions?${q}`,
        };
      },
    }),
    getInvoiceDetails: builder.query({
      query: (id) => ({
        url: `/api/contractor/walletPrepayments/${id}`,
      }),
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useLazyGetCompanyQuery,
  useGetCompanyQuery,
  useGetCompanyInvoicesQuery,
  useLazyGetCompanyInvoicesQuery,
  useLazyGetCompanyPaymentsQuery,
  useGetInvoiceDetailsQuery,
} = companiesApi;

export default companiesApi;
