import { Box, useMediaQuery } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Checkbox } from "@mui/material";
import { publicAssetsPath } from "utils/Paths/Paths";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseTable from "core-ui/BaseTable/BaseTable";
import { CertificatePreviewTests, CertificatePreviewFieldTests } from "services/StaticLookup/TableHeader/TableHeader";
import { TABLE_ID } from "constants/Table";
import { useTheme } from "@emotion/react";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

const CertificatePreview = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation(["dashboard"]);
  const [checked, setChecked] = useState(false);

  return (
    <>
      {/* header */}
      <Typography sx={{ fontSize: "1.6rem" }} color={"grayDark.color1"}>
        يمكنك معاينة الشهادة قبل إصدارها
      </Typography>
      {/* pdf body */}
      <Box
        py={matches ? "1rem" : "4rem"}
        px={matches ? "1rem" : "8rem"}
        mt={matches ? "1rem" : 5}
        mb={matches ? "" : 5}
        sx={{
          backgroundColor: "#1F766C0D",
          height: "500px",
          overflow: "scroll",
        }}
      >
        <Box
          px="3rem"
          py="3rem"
          sx={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <Typography>
            <img
              src={publicAssetsPath("images/LandingLogo.svg")}
              alt="logo"
              width="100rem"
              height="48rem"
            />
          </Typography>
          <Typography
            fontSize={18}
            py={"4rem"}
            sx={{
              color: "#1F766C",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            شهادة إتمام الفحص
          </Typography>
          <Box className={"request-data-details"} mt={4} ml={4} mr={4} mb={4}>
            <Box className={"request-data-details__wrapper"}>
              <Typography
                fontSize={16}
                mb={3}
                sx={{
                  color: "#1F766C",
                  fontWeight: "bold",
                }}
              >
                بيانات الشهادة
              </Typography>
              <WrapperContentGrid columnsWidth={24} gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography
                    sx={{ fontSize: "1.6rem" }}
                    color={"grayDark.color1"}
                    mb={1}
                  >
                    مقدم الخدمة
                  </Typography>
                  <Typography
                    sx={{ color: "grayDark.color" }}
                    className={"request-data-details__label"}
                  >
                    {props.certificatePreviewData?.serviceProvider ||
                      "لا يوجد "}{" "}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography
                    sx={{ fontSize: "1.6rem" }}
                    color={"grayDark.color1"}
                    mb={1}
                  >
                    رقم الرخصة
                  </Typography>
                  <Typography
                    sx={{ color: "grayDark.color" }}
                    className={"request-data-details__label"}
                  >
                    {props?.certificatePreviewData?.licenseYear}/
                    {props?.certificatePreviewData?.licenseNumber}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>
          </Box>
          <Box className={"request-data-details"} mt={4} ml={4} mr={4} mb={4}>
            <Box className={"request-data-details__wrapper"}>
              <Typography
                fontSize={16}
                mb={3}
                sx={{
                  color: "#1F766C",
                  fontWeight: "bold",
                }}
              >
                بيانات شركة المقاولات
              </Typography>
              <WrapperContentGrid columnsWidth={24} gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography
                    sx={{ fontSize: "1.6rem" }}
                    color={"grayDark.color1"}
                    mb={1}
                  >
                    اسم شركة المقاولات
                  </Typography>
                  <Typography
                    sx={{ color: "grayDark.color" }}
                    className={"request-data-details__label"}
                  >
                    {props.certificatePreviewData?.contractorName || "لا يوجد "}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography
                    sx={{ fontSize: "1.6rem" }}
                    color={"grayDark.color1"}
                    mb={1}
                  >
                    رقم السجل التجاري
                  </Typography>
                  <Typography
                    sx={{ color: "grayDark.color" }}
                    className={"request-data-details__label"}
                  >
                    {props.certificatePreviewData?.contractorCrNumber ||
                      "لا يوجد "}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>
          </Box>

          <Box className={"request-data-details"} mt={4} ml={4} mr={4}>
            <Box className={"request-data-details__wrapper"}>
              <Typography
                fontSize={16}
                mb={1}
                sx={{
                  color: "#1F766C",
                  fontWeight: "bold",
                }}
              >
                الاختبارات المعملية
              </Typography>
              <BaseTableIndex
                tableHeader={CertificatePreviewTests}
                injectProps={{ tableId: TABLE_ID.CERTIFICATE_TESTS }}
                content={props.certificatePreviewData?.passedLabLayers}
                isLoading={props.isLoading}
                tableSx={{
                  border: {
                    theaderFirstOfType: "0.5rem 0 0 0rem",
                    theaderLastOfType: "0 0.5rem 0rem 0rem",
                    tableCell: "0.1rem solid",
                    color: "background.disabled",
                  },
                }}
                emptyData={{
                  title: "لا يوجد اختبارات",
                  desc: "ستجد هنا جميع الاختبارات المتعلقة بالرخصة",
                  img: publicAssetsPath("images/documents2.svg"),
                }}
              />
            </Box>
          </Box>
          <Box className={"request-data-details"} mt={4} ml={4} mr={4}>
            <Box className={"request-data-details__wrapper"}>
              <Typography
                fontSize={16}
                mb={1}
                sx={{
                  color: "#1F766C",
                  fontWeight: "bold",
                }}
              >
                الاختبارات الحقلية
              </Typography>
              <BaseTableIndex
                tableHeader={CertificatePreviewFieldTests}
                injectProps={{ tableId: TABLE_ID.CERTIFICATE_TESTS }}
                content={props.certificatePreviewData?.passedFieldStations}
                isLoading={props.isLoading}
                tableSx={{
                  border: {
                    theaderFirstOfType: "0.5rem 0 0 0rem",
                    theaderLastOfType: "0 0.5rem 0rem 0rem",
                    tableCell: "0.1rem solid",
                    color: "background.disabled",
                  },
                }}
                emptyData={{
                  title: "لا يوجد اختبارات",
                  desc: "ستجد هنا جميع الاختبارات المتعلقة بالرخصة",
                  img: publicAssetsPath("images/documents2.svg"),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* footer */}
      <Box
        mb={3}
        mt={matches ? "1rem" : ""}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <Checkbox
          sx={{
            color: "primary.colorSoft6",
            "&.Mui-checked": {
              color: "secondary.main",
            },
          }}
          checked={checked}
          onChange={(event) => setChecked(!checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          sx={{
            fontSize: "1.6rem",
            color: "grayDark.color1",
          }}
        >
          أوافق على إغلاق طلبات الرخصة حيث لن يتمكن المقاول من رفع طلب فحص جديد
          بعد إصدار الشهادة
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "flex-end",
          flexDirection: matches ? "column" : "row",
          gap: matches ? "1rem" : "",
        }}
      >
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          onClick={() => props.issueCertificate()}
          disabled={!checked}
          variant={!checked ? "disabled" : ""}
          isLoading={props.isLoading}
        >
          {t("تأكيد إصدار الشهادة")}
        </BaseButton>
        <BaseButton
          sx={{
            minWidth: "12.2rem",
          }}
          variant={"secondary"}
          onClick={props.onLeaveModalHandler}
          isLoading={props.isLoading}
        >
          {t("إلغاء")}
        </BaseButton>
      </Box>
    </>
  );
};

export default CertificatePreview;
