import { UsersTypesEnum } from "constants/UsersTypes";
import contractorDashboardRoutes from "modules/Contractor/routes/DashboardRoutes";
import distributionCenterRoutes from "modules/DistributionCenter/routes/DashboardRoutes";
import labDashboardRoutes from "modules/Laboratory/routes/DashboardRoutes";
import technicianDashboardRoutes from "modules/Technician/routes/DashboardRoutes";
import { useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { getLocalStorage, getUserType } from "services/Storage";
import {
  getUserTypeByPath,
  isRouteFor,
  requiredAuthenticatedRoutes,
} from "utils/Paths/Paths";
import { UsersRolesEnum } from "constants/UsersTypes";
import consultantDashboardRoutes from "modules/Consultant/routes/DashboardRoutes";

const useCatchUserType = () => {
  const navigate = useNavigate();

  const path = useResolvedPath();

  const userRoleFromURL = getUserTypeByPath(path.pathname);

  const { isAuth, user } = useSelector((store) => store.auth);

  const isAuthRoute = isRouteFor(path.pathname, "auth");

  const requiredAuthPaths = useMemo(() => {
    return requiredAuthenticatedRoutes(
      [
        contractorDashboardRoutes,
        labDashboardRoutes,
        technicianDashboardRoutes,
        distributionCenterRoutes,
        consultantDashboardRoutes,
      ],
      isAuth
    );
  }, [isAuth]);

  useEffect(() => {
    const cachedUserType = getUserType();
    const backlink = getLocalStorage("backlink");

    if (!isAuth) {
      // IF CURRENT ROUTE NOT AUTH ROUTE OR PUBLIC ROUTE
      if (!requiredAuthPaths.includes(path.pathname)) {
        return;
      } else {
        if (!backlink) {
          if (cachedUserType) {
            navigate(`/auth/${cachedUserType}/login`);
          } else {
            navigate(`/auth/${UsersTypesEnum.DEFAULT}/login`);
          }
        }
      }
    } else {
      if (isAuthRoute && user?.user_role === UsersRolesEnum.ADMIN) {
        navigate("/drilling-licenses");
      } else if (isAuthRoute) {
        navigate("/home");
      }
    }
  }, [
    userRoleFromURL,
    isAuth,
    requiredAuthPaths,
    UsersTypesEnum,
    UsersRolesEnum,
    isAuthRoute,
  ]);

  return {
    userRoleFromURL,
    isAuthRoute,
    serverUserRole: user?.user_role?.toLowerCase(),
  };
};

export default useCatchUserType;
