/**
 *
 * @param {number} number - The number to be formatted
 * @returns {number} - It returns a formatted number
 */
export const formattedNumber = (number) => number?.toLocaleString("en-US");

/**
 *
 * @param {string} phone - It takes a phone number as a string
 * @returns {string} - It replaces +966 with 0 from the input string
 */
export const formatPhoneNumber = (phone) => phone?.replace("+966", "0");

/**
 *
 * @param {number} number
 * @returns {boolean} - It returns true if the input is a valid number
 */
export const isNumber = (number) => typeof number === "number";

/**
 *
 * @param {string} value
 * @returns - It returns null if the input value is undefined, null or an empty string otherwise it returns the value as a number
 */
export const valueChecker = (v) => {
  return v === undefined || v === null || v === "" ? null : +v;
};

// NEED TEST
export const isNotValidServerKey = (serverKey) =>
  Array.isArray(serverKey)
    ? serverKey.length === 0
    : serverKey === null || serverKey === undefined;

    export const validateIdNumberField = (nationalId) => {
      let sudiId = /^[1][0-9]{9}$/;
      let iqamaNumber = /^[2][0-9]{9}$/;
      if (!sudiId.test(nationalId) && !iqamaNumber.test(nationalId)) {
        return `فضلا تأكد من صحة المعلومات المدخلة`;
      }
    };

