import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSidemenu: false,
  activeSidemenuLinkIndex: 0,
};

const sidemenuSlice = createSlice({
  name: "sidemenu",
  initialState,
  reducers: {
    showSidemenu: (state) => {
      state.showSidemenu = !state.showSidemenu;
    },
    hideSidemenu: (state) => {
      state.showSidemenu = false;
    },
    onChangeSidemenuLinks: (state, action) => {
      state.activeSidemenuLinkIndex = action.payload.index;
    },
  },
});

export const sidemenuActions = sidemenuSlice.actions;

export default sidemenuSlice.reducer;
