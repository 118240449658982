import inspectionApi from "../../../Inspection/apis";

const inspectionLabApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getDistributionCenterReuqests: builder.query({
      query: ({ page = 0, pageSize = 10, sortId = "id,desc" }) => ({
        url: `/api/inspection/inspectionLayers/distributionCenterRequests?page=${page}&size=${pageSize}&sort=${sortId}`,
      }),
      providesTags: ["Request"],
    }),
    confirmRecivingTheSamples: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/samples/receivedByDistributionCenter",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Request"],
    }),
    confirmDeliveringTheSamples: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/samples/deliveredToLab",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Request"],
    }),
    getInspectionForCenter: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/v2/center/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
    getSamplesForCenter: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/samples/v2/center/details/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
  }),
});

export const {
  useGetInspectionForCenterQuery,
  useGetSamplesForCenterQuery,
  useGetDistributionCenterReuqestsQuery,
  useConfirmRecivingTheSamplesMutation,
  useConfirmDeliveringTheSamplesMutation,
} = inspectionLabApi;

export default inspectionLabApi;
