import React from "react";
import { Typography, Box } from "@mui/material";
import { publicAssetsPath } from "utils/Paths/Paths";

function BaseViewModalDataItem(props) {
  return (
    <Typography
      className={"request-data-details__value"}
      onClick={props.onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        cursor: "grab",
      }}
    >
      {props.value}
      <img src={publicAssetsPath("icons/popout-svgrepo.svg")} width="auto" />
    </Typography>
  );
}

export default BaseViewModalDataItem;
