import { useState } from "react";
import { Box } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import {
  assignedRequestTestsFeildSubmitted,
  assignedRequestTestsFeildSubmittedVS,
} from "services/StaticLookup/TableHeader/TableHeader"; // ⚠️
import FieldExaminationTestsActions from "./FieldExaminationTestsActions/FieldExaminationTestsActions";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import FieldExaminationPointsConfirmation from "components/TableUtilitiesComponents/TableDoubleLayerComponents/FieldExaminationPointsConfirmation";
import useGetVirtualStations from "hooks/useGetVirtualStations";

const FieldExaminationTestsContent = (props) => {
  const inspectionLayer = props.inspection?.inspectionLayersList?.[0];

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const { stations } = useGetStationsWithTestData(props?.inspection);

  const { hasVirtual, tableContent } = useGetVirtualStations(stations);

  const tableHeader = hasVirtual
    ? assignedRequestTestsFeildSubmittedVS("tech")
    : assignedRequestTestsFeildSubmitted("tech");

  const isFormFilled =
    props.appointmentReportData?.consultantPresent === true ||
    props.appointmentReportData?.consultantPresent === false
      ? true
      : false;

  const mainWrapperPadding = isFormFilled ? 2 : 5;

  return (
    <Box mt={5}>
      <BaseTableDoubleCard
        title={"الاختبارات"}
        tableHeader={tableHeader}
        injectProps={{
          tableId: TABLE_ID.FIELD_EXAMINATION,
          extraStatus: props.inspection?.status,
          displayScore: true,
          mySpanColumns,
          setMySpanColumns,
          myHiddenColumns,
          setMyHiddenColumns,
          hiddenColumnKeyFromList: "labScore",
          submittedFromLabAt: props.inspection?.submittedAt,
        }}
        content={tableContent || []}
        isLoading={props.isLoading}
        doubleLayer={() => ({
          visibility: isFormFilled,
          component: <FieldExaminationPointsConfirmation stations={stations} />,
        })}
      />
      <Box mt={12} mb={5} pl={mainWrapperPadding}>
        <FieldExaminationTestsActions
          isAppointmentReportDataFetching={
            props.isAppointmentReportDataFetching
          }
          appointmentId={props?.appointmentId}
          inspectionLayer={inspectionLayer}
          appointmentReportData={props?.appointmentReportData}
          isFormFilled={isFormFilled}
          inspection={props?.inspection}
        />
      </Box>
    </Box>
  );
};

export default FieldExaminationTestsContent;
