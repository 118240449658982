import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TestTypes } from "constants/TestTypes";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import LabAddCheckupTests from "./Lab/LabAddCheckupTests";
import FieldAddCheckupTests from "./Field/FieldAddCheckupTests";
import { useForm } from "react-hook-form";
import { UsersRolesEnum } from "constants/UsersTypes";
import { contractorActions } from "store/contractor-slice/contractor-slice";

const AddCheckupTests = (props) => {
  const { raiseNewRequestData } = useSelector((store) => store.contractor);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...raiseNewRequestData?.[`stage-${props.timeline.currentStage}-formData`],
    },
  });
  const formData = watch();

  const dispatch = useDispatch();

  const isAsphalt =
    raiseNewRequestData?.licenseLayer?.name?.includes("Asphalt");
  const isLab = raiseNewRequestData?.testType === TestTypes.LAB_TEST;
  const isField = raiseNewRequestData?.testType === TestTypes.FIELD_TEST;

  const testsTemplates = raiseNewRequestData.testsTemplates;

  const onSubmitHandler = (data, event) => {
    event.preventDefault();
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          [`stage-${props.timeline.currentStage}-formData`]: formData,
        },
      })
    );
    props.timeline.goNext();
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const sharedProps = {
    ...props,
    isAsphalt,
    raiseNewRequestData,
    testsTemplates,
  };

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
    >
      {isLab ? (
        <LabAddCheckupTests
          formData={formData}
          register={register}
          errors={errors}
          {...sharedProps}
        />
      ) : isField ? (
        <FieldAddCheckupTests {...sharedProps} />
      ) : (
        <BaseLoader />
      )}
    </form>
  );
};

export default AddCheckupTests;
