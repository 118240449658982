export const labAttachmentAvailableExtensions = [
  // "pdf",
  // "docx",
  // "doc",
  // "png",
  // "jpg",
  // "svg",
  // "word",
  // "images",
  // "dox",
  // "dot",
  // "docm",
  // "jpeg",
  // "doc",
  // "docx",
  "pdf",
  "jpg",
  "png",
  "svg",
];
export const displayAvailableExtensions = "PDF, images (JPG, PNG, SVG)";

export const sizes = ["Bytes", "KB", "MB", "GB"];

export const supportFormAvailableExtensions = [
  // files
  "pdf",
  "xls",
  "xlsx",
  "csv",
  "doc",
  "docx",
  // images
  "jpg",
  "png",
  // audio
  "mp3",
  // video
  "mp4",
];

export const displayAvailableExtensionsSupportForm =
  "pdf, xls, xlsx, csv, doc, docx, images (JPG, PNG), Audio (mp3), Video (mp4)";
