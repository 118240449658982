import { queryBuilder } from "utils/ApiHelpers";
import inspectionApi from "../../../Inspection/apis";

const labApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/inspection/inspectionLayers/reAssignableRequests?${q}`,
        };
      },
    }),
    deassignLab: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/inspections/labDeAssignment",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["History"],
    }),
  }),
});

export const { useLazyGetLabRequestsQuery, useDeassignLabMutation } = labApi;

export default labApi;
