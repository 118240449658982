import authApi from ".";

const registrationApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePassword: builder.mutation({
      query: (body) => ({
        url: `/api/user/register/updatePassword/`,
        method: "POST",
        body: body,
      }),
    }),
    signup: builder.mutation({
      query: (body) => ({
        url: "/api/user/auth/register/signup",
        method: "POST",
        body: body,
      }),
    }),
    validateOtp: builder.mutation({
      query: (body) => ({
        url: "/api/user/auth/register/validateOtp",
        method: "POST",
        body: body,
      }),
    }),
    completeRegistration: builder.mutation({
      query: (body) => ({
        url: "/api/user/register/completeRegistration",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useUpdatePasswordMutation,
  useValidateOtpMutation,
  useCompleteRegistrationMutation,
} = registrationApi;

export default registrationApi;
