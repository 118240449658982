import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { TABLE_DISPLAY_BY } from "constants/Table";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { labUsersManagement } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { TABLE_ID } from "constants/Table";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { useGetlabTechniciansQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";

const ViewUsers = () => {
  const { t } = useTranslation(["dashboard"]);

  const navigation = useNavigate();

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const { data, isFetching: isUsersDataFetching } = useGetlabTechniciansQuery(
    {
      page,
      pageSize,
    },
    { refetchOnMountOrArgChange: true }
  );

  const AddUserButtonAction = () => {
    return (
      <BaseButton
        sx={{ minWidth: "17.3rem" }}
        onClick={() => navigation("/users-management/add")}
      >
        {t("add user")}
      </BaseButton>
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين`}</title>
      </Helmet>

      <DashboardIntro
        title={t("users management")}
        description={t("تمكنك هذه الصفحة من إضافة وإستعراض معلومات المستخدمين")}
        action={<AddUserButtonAction />}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={labUsersManagement}
          content={data?.content || []}
          isLoading={isUsersDataFetching}
          emptyData={{
            title: t("No Users"),
            desc: t("No Users Desc"),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          injectProps={{
            tableId: TABLE_ID.ADMIN_USERS,
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/users-management/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "مستخدم",
            onChangePaginationDataHandler,
            totalCount: data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default ViewUsers;
