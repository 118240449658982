import React from "react";
import PreviwePDF from "core-ui/BasePDF/PreviwePDF/PreviwePDF";
import { BarcodeContent } from "./BarcodeContent/BarcodeContent";

const PrintBarcode = (props) => {
  const { data } = props;
  const headerProps = {
    title: "(barcodes) رموز العينات",
    subtitles: [
      {
        title: "بيانات الطلب",
        data: {
          "رقم الطلب": `${data.id}`,
          "مكان اخذ العينة": `${data.collectSampleDto?.location}`,
          "عدد العينات": `${data.collectSampleDto?.numberOfSamples}`,
        },
      },
    ],
    tableTitle: "(barcodes) رموز العينات",
    TableTitle1: "(barcode) رمز العينة",
    TableTitle2: "رقم العينة",
  };

  return (
    <PreviwePDF {...headerProps} toBeChunkedData={data.samples}>
      {(chunk) => <BarcodeContent barcodeChunks={chunk} />}
    </PreviwePDF>
  );
};

export default PrintBarcode;
