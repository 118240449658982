import React from "react";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseBarcode from "core-ui/BaseBarcode/BaseBarcode";
import { Box, Typography } from "@mui/material";
import PrintBarcode from "components/PrintBarcode/PrintBarcode";

const BarcodesModal = (props) => {
  const { samples = [] } = props;

  const renderBarcodes = () => {
    return samples.map((value, index) => {
      return (
        <Box
          key={index}
          sx={{
            justifyContent: "end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {props?.variant === "ADMIN" &&
            (value?.status === "DELIVERED_TO_LAB" ||
              value?.status === "RECEIVED_BY_LAB") && (
              <Typography
                sx={{
                  ml: 1,
                  fontWeight: 500,
                  fontSize: "1.3rem",
                  color: "primary.colorSoft4",
                }}
              >
                تم تسليمها للمختبر
              </Typography>
            )}
          <BaseBarcode
            key={index}
            value={value?.formattedCode}
            width={1}
            height={50}
          />
        </Box>
      );
    });
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          height: "50rem",
          overflowY: "scroll",
        }}
      >
        <WrapperContentGrid
          sx={{ marginBottom: "4rem", flexDirection: "row" }}
          columnsWidth={26}
          gap={4}
        >
          {renderBarcodes()}
        </WrapperContentGrid>
      </Box>
      {props.onPrint && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            mt: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
            }}
          >
            <PrintBarcode data={props.samplesInfo} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BarcodesModal;
