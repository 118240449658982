import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "bukrabold",
  src: `${process.env.PUBLIC_URL}/fonts/bukrabold.ttf`,
  format: "truetype",
});
Font.register({
  family: "bukraregular",
  src: `${process.env.PUBLIC_URL}/fontsbukraregular.ttf`,
  format: "truetype",
});

export const styles = StyleSheet.create({
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#EDEFF2",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 5,
    paddingBottom: 5,
    width: "100%",
  },
  barcodeIndex: {
    fontSize: 8,
    marginRight: 50,
  },
  barcode: {
    width: 200,
    height: 50,
    marginLeft: 20,
  },
});
