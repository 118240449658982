import { Box } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "components/Forms/_Utilites/FormTitle/FormTitle";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useForm } from "react-hook-form";
import PatternValidationChecker from "../../../PatternValidationChecker/PatternValidationChecker";
import { useNavigate, useParams } from "react-router-dom";
import { useCompleteRegistrationMutation } from "modules/Auth/apis/register-api";
import useAlert from "hooks/useAlert";
import useCatchUserType from "hooks/useCatchUserType";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";

const MagicRegisterForm = () => {
  const [areAllCasesValid, setAreAllCasesValid] = useState("");

  const { userRoleFromURL } = useCatchUserType();

  const navigate = useNavigate();

  const { t } = useTranslation(["auth"]);

  const { alert, showAlertHandler } = useAlert();

  const params = useParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm({ mode: "onChange" });

  const [completeRegistration, completeRegistrationResponse] =
    useCompleteRegistrationMutation();

  const passwordValue = watch("password");

  const onSubmit = (data, event) => {
    if (areAllCasesValid) {
      completeRegistration({
        token: params.token,
        userName: data.username,
        password: data.password,
      })
        .unwrap()
        .then((_) => {
          setTimeout(() => {
            navigate(`../../auth/${userRoleFromURL}/login`);
          }, 2000);
        })
        .catch((err) => {
          showAlertHandler({
            show: true,
            message: err.data.errorCode,
            type: AlertTypes.WARNING,
          });
        });
    }
  };

  const getAllResetPasswordValidationCases = (areCasesValid) => {
    setAreAllCasesValid(areCasesValid);
  };

  const isSubmitButtonDisabled =
    completeRegistrationResponse.isLoading ||
    completeRegistrationResponse.isSuccess;

  return (
    <BaseCard px={"2rem"} py={"2rem"}>
      <BaseAlert show={alert.show} type={alert.type} message={alert.message} />
      <Box px={"6rem"} py={"6rem"}>
        <FormTitle title={t("create account")} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <WrapperContentGrid>
            <BaseInput
              htmlFor="password"
              type="password"
              label={t("password")}
              maxLength={15}
              name="password"
              register={{
                ...register("password", {
                  required: {
                    value: true,
                    message: `${t("field")} ${t("password")} ${t("required")}`,
                  },
                  minLength: {
                    value: 8,
                    message: ` ${t("need 8 chars")}`,
                  },
                }),
              }}
              errors={errors}
            />
            <PatternValidationChecker
              password={passwordValue}
              isSubmitted={isSubmitted}
              getValidationCases={getAllResetPasswordValidationCases}
            />
            <BaseInput
              htmlFor="repassword"
              label={t("repeat password")}
              name={"repassword"}
              type="password"
              register={{
                ...register("repassword", {
                  validate: (rePasswordValue) => {
                    if (passwordValue !== rePasswordValue) {
                      return `${t("Password does not match")}`;
                    }
                  },
                }),
              }}
              errors={errors}
            />
          </WrapperContentGrid>
          <Box sx={{ marginTop: 5, textAlign: "center" }}>
            <BaseButton
              type="submit"
              disabled={isSubmitButtonDisabled}
              variant={isSubmitButtonDisabled ? "disabled" : ""}
            >
              {t("create account")}
            </BaseButton>
          </Box>
        </form>
      </Box>
    </BaseCard>
  );
};

export default MagicRegisterForm;
