import { useMediaQuery } from "@mui/material";
import { RESPONSIVE_BREAKPOINT } from "constants/Layout";
import { useTheme } from "@emotion/react";

const useBreakpoint = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down(RESPONSIVE_BREAKPOINT));

  return matches;
};

export default useBreakpoint;
