import React from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Radio, ListItem, Typography, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch } from "react-redux";
import { walletActions } from "store/wallet-slice";
import { formattedNumber } from "utils/Numbers/Numbers";
import { savePackageAction } from "store/wallet-slice/wallet-thunk-actions";

function Packge(props) {
  const dispatch = useDispatch();

  const handleChange = (data) => {
    dispatch(savePackageAction(data));
    dispatch(walletActions.unCheckCustomPointsHandler());
  };

  return (
    <BaseCard
      variant={props.checked ? "secondary" : "primary-transparent"}
      mb={2}
      sx={{
        minHeight: "7.6rem",
        width: "auto",
        display: "flex",
      }}
      px={"min(1.4rem, 2vw)"}
      py={"0rem"}
      borderRadius={3}
    >
      <ListItem
        value={props.data.points}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          flexWrap={"wrap"}
          spacing={0.5}
        >
          <Radio
            value={props.data.id}
            label={props.data.points}
            disabled={props.disabled}
            fontSize={"large"}
            checked={props.checked}
            checkedIcon={<CheckCircleIcon />}
            sx={{
              color: "primary.colorSoft6",
              "&.Mui-checked": {
                color: "secondary.main",
              },
            }}
            onChange={() => handleChange(props.data)}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "2.4rem",
              color: "grayDark.color1",
            }}
          >
            {formattedNumber(props.data.points)}
          </Typography>

          <Typography fontSize={"1.6rem"}>نقطة</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography sx={{ fontSize: "2.4rem", color: "grayDark.color1" }}>
            {formattedNumber(props.data?.price)}
          </Typography>
          <Typography fontSize={"1.6rem"}>ريال سعودي</Typography>
        </Stack>
      </ListItem>
    </BaseCard>
  );
}

export default Packge;
