import { Box } from "@mui/material";
import HistoryItem from "../HistoryItem/HistoryItem";

const HistoryRender = (props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
      {props.logs.map((log) => {
        return (
          <HistoryItem
            key={Math.random().toString()}
            date={log.date}
            label={log.label}
            value={log.value}
          />
        );
      })}
    </Box>
  );
};

export default HistoryRender;
