import React from "react";
import { RadioGroup, List } from "@mui/material";
import Package from "../Package";
import { useSelector } from "react-redux";
import { isContentReady } from "utils/Array/Arrays";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import CustomPackage from "../CustomPackage/CustomPackage";

const PackageList = (props) => {
  const { selectedPlan, defaultCustomPointsObject } = useSelector(
    (store) => store.wallet
  );

  return (
    <RadioGroup>
      <List>
        {isContentReady(props.plans) ? (
          props.plans.map((data, idx, orgArr) => {
            const checked = selectedPlan?.id === data.id;

            if (idx === orgArr.length - 1) {
              return (
                <CustomPackage
                  key={data.id}
                  submitBtnRef={props.submitBtnRef}
                  data={data}
                  checked={checked}
                  disabled={props.isWalletDisabled}
                  defaultCustomPointsObject={defaultCustomPointsObject}
                  getCheckoutId={props.getCheckoutId}
                  getCheckoutIdRespoonse={props.getCheckoutIdResponse}
                  vatPercentage={props.vatPercentage}
                />
              );
            } else {
              return (
                <Package
                  key={data.id}
                  data={data}
                  checked={checked}
                  disabled={props.isWalletDisabled}
                />
              );
            }
          })
        ) : (
          <BaseLoader />
        )}
      </List>
    </RadioGroup>
  );
};

export default PackageList;
