import { prepareStationServerData } from "utils/Station/Station";

export const compareTwoArraysBy = (key, arr1, arr2) => {
  let returnedArray = [];

  //prettier-ignore
  const currentTestsTemplatesIds = (arr1 || []).map(tt => tt[key]);
  //prettier-ignore
  const incomingTestsTemplatesIds = arr2.map(inctest => inctest[key]);

  incomingTestsTemplatesIds.forEach((inc) => {
    const item = arr2.find((res) => res[key] === inc);

    if (currentTestsTemplatesIds.includes(inc)) {
      returnedArray = (arr1 || []).filter((tt) => tt[key] !== inc);
    } else {
      returnedArray = (arr1 || returnedArray).concat(item).flat();
    }
  });

  return returnedArray;
};

export const selectPaymentPlan = (layersData, selectedPlan) => {
  const updatedLayersData = [...layersData];
  const updatedLayer = updatedLayersData.find(
    (layer) => layer.id === selectedPlan.id
  );
  const updatedLayerIndex = updatedLayersData.findIndex(
    (layer) => layer.id === selectedPlan.id
  );
  updatedLayersData[updatedLayerIndex] = {
    ...updatedLayer,
    selectedForPayment: selectedPlan.value || false,
    checked: !updatedLayersData[updatedLayerIndex].checked, // WE ADDED THIS TO FILTER JUST AVAILABLE CHUNKs TO BE PAID
  };

  return updatedLayersData;
};

export const getMinMaxData = (dataKey, formData, applySingle) => {
  const extractedData = filterMinMaxDataFromFormData(
    dataKey,
    formData,
    applySingle
  );
  return transformFilteredMinMaxDataToList(extractedData);
};

export const filterMinMaxDataFromFormData = (
  dataKey,
  formData,
  applySingle
) => {
  let inspectionSubTestTemplate = {};

  for (let key in formData) {
    if (key.includes(dataKey)) {
      const id = +key.split("-").at(-1);

      if (key.startsWith("min")) {
        inspectionSubTestTemplate[id] = {
          minPassScore: formData[key] ? +formData[key] : null,
          ...inspectionSubTestTemplate[id],
        };
        if (applySingle) {
          inspectionSubTestTemplate[id].singleScore = null;
        }
      }
      if (key.startsWith("max")) {
        inspectionSubTestTemplate[id] = {
          maxPassScore: formData[key] ? +formData[key] : null,
          ...inspectionSubTestTemplate[id],
        };
        if (applySingle) {
          inspectionSubTestTemplate[id].singleScore = null;
        }
      }
      if (applySingle && key.startsWith("single")) {
        inspectionSubTestTemplate[id] = {
          singleScore: formData[key] ? +formData[key] : null,
          minPassScore: null,
          maxPassScore: null,
          ...inspectionSubTestTemplate[id],
        };
      }
    }
  }
  return inspectionSubTestTemplate;
};

export const transformFilteredMinMaxDataToList = (filteredData) => {
  let transformedData = [];

  for (let key in filteredData) {
    transformedData.push({ id: +key, ...filteredData[key] });
  }

  return transformedData;
};

export const getLayerNumbers = (formData) => {
  return [
    ...new Set(
      Object.keys(formData)
        .filter(
          (name) => name.startsWith("station") && name.includes("layerNumber")
        )
        .map((station) => parseInt(formData[station]))
    ),
  ];
};

export const prepareTemplateLayers = (data) => {
  const stations = prepareStationServerData(data)

  let templateLayers = stations.map((station) => {
    let layerObject = {};

    layerObject.templateLayerId = station?.layerTest?.id;
    layerObject.selectedForPayment = true;
    layerObject.layerNumber = `${station?.layerNumber}`;
    layerObject.stations = [{
          from: station?.from,
          to: station?.to,
          distance: station?.length,
        }]

    return layerObject;
  });

  return templateLayers;
};
