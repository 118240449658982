import { Box, List, Typography } from "@mui/material";
import classes from "../BaseDropdown.module.scss";
import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { isContentReady } from "utils/Array/Arrays";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { _void } from "utils/Objects/Objects";
import { useEffect, useState } from "react";

const DropdownList = ({
  pickup,
  selectMenuStyles,
  disabled,
  open,
  isFetching,
  data,
  onChange,
  pickupData,
  value,
  setPickupData,
  setOpen,
  ignoreStackingContext,
  dropdownPosition,
  itemNameKey,
  emptyListPlaceholder,
  itemDirection,
}) => {
  const EmptyListComp = () => (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.6rem",
        }}
      >
        {emptyListPlaceholder ? emptyListPlaceholder : "لا يوجد بيانات"}
      </Typography>
    </Box>
  );
  const addPickupItemHandler = (pickupItem) => {
    if (pickup) {
      setPickupData((prevState) => ({
        allData: prevState.allData.concat(pickupItem),
        item: pickupItem,
      }));
      if (onChange) {
        onChange({
          allData: pickupData.allData.concat(pickupItem),
          item: pickupItem,
        });
      }
    } else {
      if (onChange) {
        onChange(pickupItem);
      }
    }
    setOpen(false);
  };

  const [dropdownPositionState, setDropdownPositionState] =
    useState(dropdownPosition);

  useEffect(() => {
    const windowScrollHandler = () => {
      setDropdownPositionState(null);
    };

    document.addEventListener("scroll", windowScrollHandler);

    return () => document.removeEventListener("scroll", windowScrollHandler);
  }, []);

  useEffect(() => {
    setDropdownPositionState(dropdownPosition);
  }, [dropdownPosition, open]);

  return (
    <List
      className={`${classes["base-dropdown__list"]} ${
        open ? classes[`base-dropdown__list--open`] : undefined
      } `}
      sx={{
        ...selectMenuStyles,
        ...(ignoreStackingContext &&
          dropdownPositionState && {
            transition: "none",
            position: `fixed !important`,
            top: `${dropdownPositionState?.y + 50}px !important`,
            right: `${dropdownPositionState?.x}px !important`,
            width: `${dropdownPositionState?.width}px !important`,
          }),
      }}
    >
      {!isFetching && isContentReady(data) ? (
        data.map((item) => {
          const selectedCase =
            pickupData.allData?.find((d) => d.id === item.id) ||
            value?.id === item.id;
          return (
            <li
              key={item.id}
              className={`${classes["base-dropdown__item"]} ${
                classes[
                  `base-dropdown__item--${
                    selectedCase ? "selected" : undefined
                  }`
                ]
              }`}
              onClick={
                selectedCase || disabled
                  ? _void
                  : addPickupItemHandler.bind(null, item)
              }
            >
              <span style={{ direction: itemDirection }}>
                {itemNameKey && item[itemNameKey]
                  ? item[itemNameKey]
                  : item.name}
              </span>
              {selectedCase && (
                <DoneIcon className={classes["base-dropdown__item-icon"]} />
              )}
            </li>
          );
        })
      ) : isFetching ? (
        <BaseLoader />
      ) : (
        <EmptyListComp />
      )}
    </List>
  );
};

export default DropdownList;
