import { Typography } from "@mui/material";
import { valueChecker } from "utils/Numbers/Numbers";

const DisplayScore = (props) => {
  const displayedValue = () => {
    if (props.containSubtests) {
      return "";
    }
    if (
      !valueChecker(props.score) &&
      typeof valueChecker(props.score) !== "number"
    ) {
      return "غير محدد";
    }

    return props.score;
  };
  return <Typography fontSize={"1.6rem"}>{displayedValue()}</Typography>;
};

export default DisplayScore;
