import { createSlice } from "@reduxjs/toolkit";
import { AlertTypes } from "constants/AlertTypes";

const initialState = {
  show: false,
  message: null,
  type: AlertTypes.SUCCESS,
};

const alertSlice = createSlice({
  name: "alert",
  initialState: initialState,
  reducers: {
    showAlert: (state, action) => {
      state.show = action.payload.show;
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    hideAlert: (state, action) => {
      state.show = initialState.show;
      state.type = initialState.type;
      state.message = initialState.message;
    },
  },
});

export const alertActions = alertSlice.actions;

export default alertSlice.reducer;
