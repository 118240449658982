import { Typography } from "@mui/material";
import { isNumber, valueChecker } from "utils/Numbers/Numbers";

const DisplayScore = (props) => {
  const displayedValue = () => {
    if (isNumber(props.singleScore)) {
      return props.singleScore;
    }
    if (isNumber(props.minPassScore) && isNumber(props.maxPassScore)) {
      return `${props.minPassScore} - ${props.maxPassScore}`;
    }
    if (isNumber(props.minPassScore) && !isNumber(props.maxPassScore)) {
      return `Min ${props.minPassScore}`;
    }
    if (!isNumber(props.minPassScore) && isNumber(props.maxPassScore)) {
      return `Max ${props.maxPassScore}`;
    }
  };
  return <Typography fontSize={"1.6rem"}>{displayedValue()}</Typography>;
};

export default DisplayScore;
