import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import FieldAttachment from "../../../../../../components/FieldAttachment/FieldAttachment";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import {
  assignedRequestTestsFeildSubmitted,
  assignedRequestTestsFeildSubmittedVS,
} from "services/StaticLookup/TableHeader/TableHeader";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import BaseVerticalTimeline from "core-ui/BaseVerticalTimeline/BaseVerticalTimeline";
import RaiseResults from "../../Lab/RaiseResults/RaiseResults";
import useVerticalTimeline from "hooks/useVerticalTimeline";
import { useGetAttachmentForLaboratoryQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";

const AssignedRequestTestsFeild = (props) => {
  const { hasVirtual, tableContent } = useGetVirtualStations(props.stations);

  const tableHeader = hasVirtual
    ? assignedRequestTestsFeildSubmittedVS("lab")
    : assignedRequestTestsFeildSubmitted("lab");

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const { data: attachment } = useGetAttachmentForLaboratoryQuery(
    props.inspection?.id,
    {
      skip:
        !props.inspection?.id ||
        !props.inspection?.isLabResultAttachmentSubmitted,
    }
  );

  const isDraftedNotSubmitted =
    props.inspection.isRequestInitialSubmissionDrafted &&
    !props.inspection.submittedAt;

  const { completeStage, reanimate, onHide, vTimelineRef } =
    useVerticalTimeline();

  useEffect(() => {
    if (vTimelineRef?.current) {
      if (props.inspection?.submittedAt) {
        completeStage(1);
      }
      if (attachment) {
        if (props.inspection?.submittedAt) {
          completeStage(1);
        } else {
          completeStage(2);
        }
      }
      if (isSubmittedWithAttachment) {
        onHide();
      }
    }
    if (!props.isStationsFetching) {
      reanimate();
    }
  }, [
    vTimelineRef,
    props.inspection?.submittedAt,
    attachment,
    props.isStationsFetching,
  ]);

  const isSubmittedWithAttachment = props.inspection?.submittedAt && attachment;

  if (props.isStationsFetching || props.isAttachmentFetching)
    return <BaseLoader />;

  return (
    <>
      <Box mt={5}>
        <BaseTableDoubleCard
          content={tableContent}
          tableHeader={tableHeader}
          injectProps={{
            mySpanColumns,
            setMySpanColumns,
            myHiddenColumns,
            setMyHiddenColumns,

            submittedFromLabAt: true,
          }}
        />
      </Box>
      <Box
        sx={{
          marginInlineStart: isSubmittedWithAttachment ? "" : "5rem",
        }}
      >
        <BaseVerticalTimeline sx={{ marginBlock: 5 }} ref={vTimelineRef}>
          {!isSubmittedWithAttachment && (
            <Box>
              <RaiseResults
                form={"field"}
                isSubmitted={props.inspection.submittedAt}
                isDraftedNotSubmitted={isDraftedNotSubmitted}
              />
            </Box>
          )}
          <Box>
            <FieldAttachment
              attachment={attachment}
              inspection={props.inspection}
              disabled={!props.inspection.submittedAt}
              showSaveAttachmentAlert={props.showSaveAttachmentAlert}
            />
          </Box>
        </BaseVerticalTimeline>
      </Box>
    </>
  );
};

export default AssignedRequestTestsFeild;
