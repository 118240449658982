export const SampleType = [
  { id: "1", name: "Subgrade" },
  { id: "2", name: "Subbase " },
  { id: "3", name: "ABC" },
  { id: "4", name: "Backfill" },
  { id: "5", name: "Asphalt Wearing Course" },
  { id: "6", name: "Asphalt Base Course" },
];

export const MaterialSource = [
  { id: "1", name: "ناتج الحفر (محسن)" },
  { id: "2", name: "توريد خارجي" },
];

export const PlaceOfTakeSample = [
  { id: "1", name: "موقع التخزين" },
  { id: "2", name: "جانب الحفرية" },
  { id: "3", name: "الحفرية" },
  { id: "4", name: "أخرى" },
];

export const WeatherConditions = [{ id: "1", name: "درجة حرارة " }];
