import { useEffect, useState } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import AddCheckupTests from "./AddCheckupTests";
import AddCheckupSummary from "./AddCheckupSummary";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useGetFullContractorDataQuery } from "modules/Contractor/apis/contractor-resource/contractor-resource";
import { useDispatch, useSelector } from "react-redux";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import useAlert from "hooks/useAlert";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import messages from "services/AlertMessages/messages";
import AddLicenseDetails from "./AddLicenseDetails";
import { checkIfWalletAvailable } from "utils/Wallet/Wallet";
import { _scrollToTop } from "utils/DOM/DOM";
import { TestTypes } from "constants/TestTypes";
import AddCheckupExamination from "./AddCheckupExamination";
import { stationInputsActions } from "store/staions-inputs/station-inputs";
import ContractorAppintmentBooking from "components/Alerts/ContractorAppointmentBooking/ContractorAppointmentBooking";
import AddCheckupSiteSpecs from "./AddCheckupSiteSpecs";
import RaiseNewRequestService from "./RaiseNewRequestService";

const RaiseNewRequest = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const isLab = raiseNewRequestData?.testType === TestTypes.LAB_TEST;

  const _licenseData = isLab
    ? raiseNewRequestData?.multipleLicenses?.at(-1)
    : raiseNewRequestData?.licenseData;

  const { data: contractorData } = useGetFullContractorDataQuery();

  let checkupValidationFnTimer;

  const [showConfirm, setShowConfirm] = useState(false);

  const onOpenConfirmModalHandler = () => setShowConfirm(true);
  const onLeavePageHandler = () => setShowConfirm(false);

  const isWalletAvailable = () => {
    const { labTotalTestsCost, fieldTotalTestsCost } =
      RaiseNewRequestService.getTotalCostForTest(raiseNewRequestData);

    return checkIfWalletAvailable(
      raiseNewRequestData?.testType,
      isLab ? labTotalTestsCost : fieldTotalTestsCost,
      (raiseNewRequestData?.layersData || []).slice(0, 1),
      contractorData
    );
  };

  async function resetAndLeave() {
    onLeavePageHandler();
    dispatch(contractorActions.resetRaiseNewRequestData());
    dispatch(stationInputsActions.resetStations());
  }

  function navigateAfterPostNewInsepction(response) {
    if (checkupValidationFnTimer) clearTimeout(checkupValidationFnTimer);
    navigate(`/examination-requests/${response?.inspectionId}`);
  }

  const showProperAlertMessage = () => {
    let message = messages[alert.message];

    if (alert.message === ErrorCodes.CONTRACTOR_APPOINTMENT_BOOKING) {
      return (
        <ContractorAppintmentBooking
          hideAlertHandler={hideAlertHandler}
          goPrev={props.goPrev}
        />
      );
    }

    if (alert.message === ErrorCodes.CONTRACTOR_BALANCE) {
      message = (
        <Typography sx={{ display: "flex", fontSize: "1.3rem", gap: "5px" }}>
          <Typography fontSize={"inherit"}>{`${
            messages[alert.message]
          } من `}</Typography>
          <Link to={"/wallet"}>هنا</Link>
        </Typography>
      );
    }

    return <Typography component={"p"}>{message}</Typography>;
  };

  useEffect(() => {
    _scrollToTop();
  }, [props.currentStage]);

  useEffect(() => {
    if (!raiseNewRequestData?.testType) {
      resetAndLeave().then((_) => {
        navigate("/home/request-path");
      });
    }
  }, []);

  let currentScreen = null;

  if (props.currentStage === 0) {
    currentScreen = (
      <AddLicenseDetails
        timeline={{ ...props }}
        indexShowAlertHandler={showAlertHandler}
        indexHideAlertHandler={hideAlertHandler}
      />
    );
  } else if (props.currentStage === 1) {
    currentScreen = (
      <AddCheckupSiteSpecs
        timeline={{ ...props }}
        indexShowAlertHandler={showAlertHandler}
        indexHideAlertHandler={hideAlertHandler}
      />
    );
  } else if (props.currentStage === 2) {
    currentScreen = <AddCheckupTests timeline={{ ...props }} />;
  } else if (props.currentStage === 3) {
    currentScreen = <AddCheckupExamination timeline={{ ...props }} />;
  } else if (props.currentStage === 4) {
    currentScreen = (
      <AddCheckupSummary
        timeline={{ ...props }}
        _licenseData={_licenseData}
        isWalletAvailable={isWalletAvailable}
        resetAndLeave={resetAndLeave}
        indexShowAlertHandler={showAlertHandler}
        showConfirm={showConfirm}
        onOpenConfirmModalHandler={onOpenConfirmModalHandler}
        onLeavePageHandler={onLeavePageHandler}
        navigateAfterPostNewInsepction={navigateAfterPostNewInsepction}
      />
    );
  }

  return (
    <BaseCard
      mt={6}
      py={"auto"}
      px={"2.5rem"}
      sx={{
        minHeight: "70vh",
        position: "relative",
        paddingTop: "4.6rem",
        paddingBottom: { sm: "10rem", xs: "17rem" },
      }}
    >
      <BaseAlert
        mb={4}
        show={alert?.show}
        type={alert?.type}
        destroy={hideAlertHandler}
      >
        {showProperAlertMessage()}
      </BaseAlert>

      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {currentScreen}
      </Box>
    </BaseCard>
  );
};

export default RaiseNewRequest;
