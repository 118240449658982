import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import ExaminationDataField from "modules/Contractor/components/DataDetails/ExaminationData/Field/ExaminationDataField/ExaminationDataField";
import React from "react";
import { useParams } from "react-router-dom";

function FieldExaminationRequests({
  dataId,
  requestDetails,
  labtestappointment,
  isFetching,
  canReschedule,
}) {
  const params = useParams();
  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData({ id: params.id });

  return (
    <ExaminationDataField
      title={"تفاصيل الطلب"}
      request={requestDetails}
      dataId={dataId}
      stations={stations}
      labtestappointment={labtestappointment}
      isFetching={isFetching}
      canReschedule={canReschedule}
      isStationsFetching={isStationsFetching}
    />
  );
}

export default FieldExaminationRequests;
