import { Typography } from "@mui/material";

const BaseCardTitle = (props) => {
  const getFontSize = () => {
    let fontSize = "1.8rem";
    if (props?.sx?.fontSize || props?.fontSize) {
      fontSize = props?.sx?.fontSize || props?.fontSize;
      return fontSize;
    } else if (props.sm) {
      fontSize = "1.4rem";
    }
    return fontSize;
  };
  return (
    <Typography
      {...props}
      sx={{
        color: "grayDark.color",
        fontSize: getFontSize(),
        fontWeight: "bold",
      }}
    >
      {props.children}
    </Typography>
  );
};

export default BaseCardTitle;
