import { Text, View, Image } from "@react-pdf/renderer";
import { textToBase64Barcode } from "utils/Strings/Strings";
import { styles } from "./style";
export const BarcodeContent = ({ barcodeChunks }) => {
  const barcodeChunk = barcodeChunks.map((format) => ({
    ...format,
    barcodeSrc: textToBase64Barcode(format.formattedCode),
  }));

  return (
    <>
      {barcodeChunk.map((item, index) => (
        <View key={index} style={styles.tableRow}>
          <Image src={item.barcodeSrc} style={styles.barcode} />
          <View style={styles.barcodeIndex}>
            <Text>{item.formattedCode}</Text>
          </View>
        </View>
      ))}
    </>
  );
};
