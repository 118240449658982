import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  licenseData: null,
  layersData: [],
  testsTemplatesData: [],
  raiseNewRequestData: {},
  selectedLicense: null,
};

const consultantSlice = createSlice({
  name: "consultant",
  initialState,
  reducers: {
    saveRaiseNewRequestData: (state, action) => {
      let raiseNewRequestDataTemp = { ...state.raiseNewRequestData };

      raiseNewRequestDataTemp = Object.assign(
        { ...raiseNewRequestDataTemp },
        action.payload.data
      );
      state.raiseNewRequestData = raiseNewRequestDataTemp;
    },
    resetRaiseNewRequestData: (state, action) => {
      state.raiseNewRequestData = initialState.raiseNewRequestData;
    },
  },
});

export const consultantActions = consultantSlice.actions;

export default consultantSlice.reducer;
