import { useLocation, useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ExaminationRequests } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { useState } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { renderTabs } from "utils/Tabs/Tabs";
import { FieldExaminationRequests } from "services/StaticLookup/Tabs";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import { AlertTypes } from "constants/AlertTypes";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import FieldExaminationTestsContent from "modules/Technician/components/FieldExaminationTestsContent/FieldExaminationTestsContent";
import RequestData from "modules/Technician/components/DataDetails/RequestData";
import {
  useGetAppointmentDetailsForTechnicianQuery,
  useGetTechnicianInspectionQuery,
} from "modules/Technician/apis/inspection-apis/inspection-api";
import { DATA_ID } from "constants/Details";
import { useGetAppointmentReportQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";

const FieldExaminationRequestDetails = () => {
  const params = useParams();

  const { t } = useTranslation();

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const { palette } = useTheme();

  const { state } = useLocation();

  useEffect(() => {
    if (state?.activeTab) {
      setTabsActiveIndex(state?.activeTab);
    }
  }, []);

  const handleChange = (_, newValue) => {
    setTabsActiveIndex(newValue);
  };

  const {
    data: inspection,
    isFetching,
    isSuccess: isInspectionSuccess,
  } = useGetTechnicianInspectionQuery(params.id);

  const { data: appointmentData } = useGetAppointmentDetailsForTechnicianQuery(
    inspection?.id,
    { skip: !inspection?.id }
  );

  const {
    data: appointmentReportData,
    isFetching: isAppointmentReportDataFetching,
  } = useGetAppointmentReportQuery(appointmentData?.id, {
    skip: !appointmentData?.id,
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>

      <DashboardIntro
        title={t(`طلب رقم #${inspection?.id || ""}`, {
          ns: "dashboard",
        })}
        description={t(
          "تستعرض هذه الصفحة تفاصيل الفحص الحقلي ومعلومات التواصل للأطراف المعنية"
        )}
        action={
          <DashboardIntroAction>
            {state?.fromPageId === "newInspection" && (
              <BaseAlert
                type={AlertTypes.SUCCESS}
                color={palette.base.successBorder}
                message={"تم إرسال الطلب بنجاح"}
                autoClose={3}
                labelSx={{ fontWeight: "bold" }}
              />
            )}
          </DashboardIntroAction>
        }
        breadcrumbData={ExaminationRequests({
          id: inspection?.id || "",
        })}
      />

      <BaseCard
        mt={6}
        py={"2rem"}
        px={"2rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        <Tabs
          value={tabsActiveIndex}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
          className="tabs"
        >
          {renderTabs(FieldExaminationRequests).map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{
                background: tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                fontSize: "1.6rem",
              }}
            />
          ))}
        </Tabs>
        {tabsActiveIndex === 1 && (
          <RequestData
            inspection={inspection}
            isInspectionSuccess={isInspectionSuccess}
            dataId={DATA_ID.TECH_REQUEST_DETAILS}
          />
        )}

        {tabsActiveIndex === 2 && (
          <FieldExaminationTestsContent
            inspection={inspection}
            isAppointmentReportDataFetching={isAppointmentReportDataFetching}
            appointmentReportData={appointmentReportData}
            appointmentId={appointmentData?.id}
            isLoading={isFetching}
          />
        )}
      </BaseCard>
    </>
  );
};

export default FieldExaminationRequestDetails;
