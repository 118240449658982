import { Box, FormHelperText, RadioGroup } from "@mui/material";
import BaseFancyRadio from "./BaseFancyRadio";

const BaseFancyRadioGroup = (props) => {
  const inputHasError = props.errors ? props.errors[props.name] : false;

  return (
    <Box>
      <RadioGroup
        sx={{ width: "100%", display: "flex", columnGap: 4, ...props.sx }}
        row
        name="accept"
        onChange={props.onChange}
        value={props.value || props.data?.value}
      >
        {props.data.map((dataItme) => {
          return (
            <BaseFancyRadio
              key={dataItme.id}
              dataItme={dataItme}
              value={props.value}
              disabled={props.isDisabled}
              lableSx={props.lableSx}
            />
          );
        })}
      </RadioGroup>
      {inputHasError && (
        <Box>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {inputHasError.message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
};

export default BaseFancyRadioGroup;
