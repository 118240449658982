import { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import VerifyOtpForm from "components/Forms/VerifyOtpForm/VerifyOtpForm";
import { countDownTimer } from "services/Timers";
import { OTP } from "constants/Forms";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { VerifyIDs } from "constants/Auth";
import { useContractorRegisterMutation } from "modules/Contractor/apis/auth-apis/register-api";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import useCatchUserType from "hooks/useCatchUserType";
import { useResendOTPMutation } from "modules/Auth/apis/consultant-auth-apis/register-api";

const resendOtpBtnSx = {
  backgroundColor: "none",
  color: "primary.main",
  textDecoration: "underline",
  fontWeight: "bold",
  padding: "0",
  fontSize: "1.4rem",
  "&:hover": {
    background: "none",
  },
  "&:disabled": {
    background: "none",
    textDecoration: "none",
  },
};

const OtpHandler = (props) => {
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const { palette } = useTheme();
  const { state: params } = useLocation();
  // const { verifyId } = params;
  const verifyId = params?.verifyId;
  // const [resendOtp, setResendOtp] = useState(false);

  const { userRoleFromURL } = useCatchUserType();
  const isConsultant = userRoleFromURL === "consultant";

  const [registerApi] = useContractorRegisterMutation();
  const [resendOtpConsultant] = useResendOTPMutation();

  const otpTimerRef = useRef();
  let resendOtpTimer;

  // this user effect will run only one time when the component mount
  useEffect(() => {
    resendOtpTimer = countDownTimer(
      OTP.OTP_TIMER_DURATION,
      otpTimerRef.current,
      setIsOtpExpired
    );

    return () => {
      clearInterval(resendOtpTimer);
    };
  }, []);

  useEffect(() => {
    // THIS USE EFFECT IF OTP EXPIRED/ DO AN ACTION
    if (isOtpExpired) {
      props.isOtpResent(false);
    }
  }, [isOtpExpired]);

  const resendOtpHandler = () => {
    // setResendOtp(true);
    const constHandlerResend = isConsultant ? resendOtpConsultant : registerApi;
    constHandlerResend(props.registerData)
      .unwrap()
      .then((_) => {
        props.isOtpResent(true);
        setIsOtpExpired(false);
        resendOtpTimer = countDownTimer(
          OTP.OTP_TIMER_DURATION,
          otpTimerRef.current,
          setIsOtpExpired
        );

        props.alertData.showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_REGISTER,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        props.alertData.showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.WARNING,
        });
      });
  };
  const isForRegister = verifyId === VerifyIDs.REGISTER;

  const userMobile = props.validateResponse?.userMobileNumber;
  const otpHandlerMobileTitle = isForRegister
    ? ` إلى الرقم المنتهي بـ ${userMobile?.slice(userMobile?.length - 4)}`
    : `إلى الرقم المنتهي بـ xxxx`;

  const userEmail = props.registerData?.email;
  const otpHandlerEmailTitle = isForRegister
    ? `إلى البريد الإلكتروني ${userEmail}`
    : `إلى البريد الإلكتروني xxxx`;

  return (
    <>
      <Typography
        mb={1.5}
        sx={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          color: palette.grayDark.color,
        }}
      >
        تم إرسال رمز التحقق{" "}
        {isConsultant ? otpHandlerEmailTitle : otpHandlerMobileTitle}
      </Typography>
      <Box display={"flex"} alignItems={"center"} gap={1.5}>
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: "500",
            color: palette.grayDark.color,
          }}
        >
          الوقت المتبقي
        </Typography>
        <Typography
          color={"primary.main"}
          fontWeight={"bold"}
          sx={{ fontSize: "1.4rem" }}
          ref={otpTimerRef}
        >
          00:00
        </Typography>

        <Button
          // variant="secondary"
          size="sm"
          sx={resendOtpBtnSx}
          disabled={!isOtpExpired}
          onClick={resendOtpHandler}
        >
          إعادة إرسال
        </Button>
      </Box>
      <VerifyOtpForm
        isConsultant={isConsultant}
        isOtpExpired={isOtpExpired}
        isForRegister={isForRegister}
        registerData={props.registerData}
      />
    </>
  );
};

export default OtpHandler;
