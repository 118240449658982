import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";

const BaseDataWrapper = (props) => {
  const { palette } = useTheme();

  return (
    <Box className={"request-data-details"} mt={props?.mt || 4}>
      <Box className={"request-data-details__wrapper"} mb={5}>
        <Typography
          className={"request-data-details__title"}
          sx={{
            ...props.titleStyles,
            marginBottom: `${props.titleStyles?.marginBottom} !important`,
          }}
        >
          {props.title}
        </Typography>
        {props.description && (
          <Typography
            color={palette.grayDark.color1}
            fontSize={"1.6rem"}
            mt={-1.5}
            mb={4}
            sx={props.descriptionStyles}
          >
            {props.description}
          </Typography>
        )}
        <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
          {props.children}
        </WrapperContentGrid>
      </Box>
    </Box>
  );
};

export default BaseDataWrapper;
