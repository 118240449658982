import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";

const DisplayResultTest = (props) => {
  const results = props.inspectionPointDtos?.map((result) => result.score);
  if (props.displayScore) {
    return props.inspectionPointDtos.length > 0 ? (
      <Typography sx={{ fontSize: "1.6rem" }}>{results.join(", ")}</Typography>
    ) : (
      <BaseStatus
        status={"TEST_NOT_READY"}
        tableId={props.tableId}
        testType={props.testType}
      />
    );
  }

  return (
    <Box>
      <BaseStatus
        status={props.result}
        tableId={props.tableId}
        testType={props.testType}
      />
    </Box>
  );
};

export default DisplayResultTest;
