import BaseTable from "core-ui/BaseTable/BaseTable";
import React from "react";
import { licenseDetailsLocationsURL } from "services/StaticLookup/TableHeader/TableHeader";
import { Box } from "@mui/material";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";

function DrillingSites(props) {
  return (
    <Box mt={5} px={"3.2rem"}>
      <BaseTableIndex
        title={"مواقع الحفر"}
        tableHeader={licenseDetailsLocationsURL}
        content={props.location || []}
      />
    </Box>
  );
}

export default DrillingSites;
