import React from "react";
import PreviwePDF from "core-ui/BasePDF/PreviwePDF/PreviwePDF";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import TransactionContent from "components/PrintTransaction/TransactionContent/TransactionContent";
import { TransactionTypes } from "constants/TransactionTypes";
import { Text, View } from "@react-pdf/renderer";
import { formattedNumber } from "utils/Numbers/Numbers";
import { TestTypes } from "constants/TestTypes";

const PrintTransaction = (props, { chunk }) => {
  const { data } = props;

  const testTypeLabel = {
    FIELD: "حقلي",
    LAB: "معملي",
  };

  const processType =
    data?.status === TransactionTypes.REFUND ? "إسترداد نقاط" : "خصم نقاط";

  const headerProps = {
    title: "ملخص العملية",
    subtitles: [
      {
        title: "التفاصيل",
        data: {
          "اسم شركة المقاولات": `${data?.contractorName}`,
          "رقم العملية": `${data?.id}`,
          "نوع العملية": processType,
          التاريخ: `${formatDateYYYYMMDD(data?.transactionTime)}`,
          "رقم الطلب": `${data?.inspectionLayer}`,
          "نوع الفحص": `${testTypeLabel[data?.testtype]}`,
          "اختبار مواد الأعمال المدنية": `${data?.inspectionLayerName}`,
        },
      },
    ],
    tableTitle: "ملخص الطلب",
    TableTitle2: "الوصف",
    tableTitle1Margin: 30,
    tableTitle2Margin: 360,
    ...props,
  };

  const totalCost = data?.deductionDetailsDTO?.combinedFieldTestCost;

  const toBeChunkedData =
    data?.deductionDetailsDTO && data?.deductionDetailsDTO.length !== 0
      ? data?.deductionDetailsDTO
      : [{}];

  const OneUseLabContent = () => {
    return (
      <>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            borderColor: "#EDEFF2",
            padding: 10,
            fontSize: 8,
          }}
        >
          <View
            style={{
              fontSize: 8,
              textAlign: "left",
              marginLeft: "31px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text style={{ marginRight: "2px" }}>نقطة</Text>
            <Text>{formattedNumber(data?.combinedLabTestCost)}</Text>
          </View>
          <View
            style={{
              textAlign: "right",
              marginRight: "10px",
            }}
          >
            <Text>تكلفة الاختبارات المعملية</Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            borderTopWidth: 1,
            borderColor: "#EDEFF2",
            padding: 10,
            fontSize: 8,
          }}
        >
          <View
            style={{
              fontSize: 8,
              textAlign: "left",
              marginLeft: "31px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text style={{ marginRight: "2px" }}>نقطة</Text>
            <Text>{formattedNumber(data?.combinedSampleCollectionCost)}</Text>
          </View>
          <View
            style={{
              textAlign: "right",
              marginRight: "10px",
            }}
          >
            <Text>تكلفة جمع العينات</Text>
          </View>
        </View>
      </>
    );
  };

  const OneUseContent = ({ withAddition = 0 }) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          // borderTopWidth: 1,
          borderColor: "#EDEFF2",
          padding: 10,
          fontSize: 8,
        }}
      >
        <View
          style={{
            fontSize: 8,
            textAlign: "left",
            marginLeft: "31px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={{ marginRight: "2px" }}>نقطة</Text>
          <Text>
            {formattedNumber(totalCost + withAddition || data?.deductedPoints)}
          </Text>
        </View>
        <View
          style={{
            textAlign: "right",
            marginRight: "10px",
          }}
        >
          <Text>التكلفة الكلية</Text>
        </View>
      </View>
    );
  };

  const OneUseRefundContent = () => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          // borderTopWidth: 1,
          borderColor: "#EDEFF2",
          padding: 10,
          fontSize: 8,
        }}
      >
        <View
          style={{
            fontSize: 8,
            textAlign: "left",
            marginLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={{ marginRight: "2px" }}>نقطة</Text>
          <Text>{formattedNumber(data?.deductedPoints)}</Text>
        </View>
        <View
          style={{
            textAlign: "right",
            marginRight: "10px",
          }}
        >
          <Text>إجمالي القيمة المستردة</Text>
        </View>
      </View>
    );
  };

  const isLab = data?.testtype === TestTypes.LAB_TEST;
  const isDeducted = data?.status === TransactionTypes.DEDUCTED;

  let withAdditionData =
    isLab && isDeducted ? data?.combinedSampleCollectionCost : 0;

  return (
    <PreviwePDF {...headerProps} toBeChunkedData={toBeChunkedData}>
      {(chunk, pageIndex, chunks) => {
        const isLastPage = pageIndex === chunks.length - 1;
        return (
          <>
            {!isDeducted && <OneUseRefundContent />}
            {isDeducted && <TransactionContent transactionData={chunk} />}
            {isLastPage && isDeducted && isLab && <OneUseLabContent />}
            {isLastPage && isDeducted && (
              <OneUseContent withAddition={withAdditionData} />
            )}
          </>
        );
      }}
    </PreviwePDF>
  );
};

export default PrintTransaction;
