import React, { Fragment } from "react";
import { CssBaseline } from "@mui/material";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import dynamicTheme from "./services/Theme";
import styleCache from "./services/StyleCache";
import { useSelector } from "react-redux";
import BaseLayout from "./core-ui/Layouts/BaseLayout/BaseLayout";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Suspense } from "react";
import useCheckTokenExpiration from "hooks/useCheckTokenExpiration";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const LazyMobHeader = React.lazy(() =>
  import("core-ui/Layouts/MobHeader/MobHeader")
);
const LazyMobSideMenu = React.lazy(() =>
  import("core-ui/Layouts/MobSideMenu/MobSideMenu")
);

function App() {
  const { theme } = useSelector((store) => store.theme);
  const { isAuth } = useSelector((store) => store.auth);

  useCheckTokenExpiration();

  return (
    <ErrorBoundary>
      <CssVarsProvider theme={dynamicTheme(theme)}>
        <CssBaseline />
        <CacheProvider value={styleCache}>
          <Suspense fallback={<BaseLoader />}>
            {isAuth && (
              <Fragment>
                <LazyMobHeader />
                <LazyMobSideMenu />
              </Fragment>
            )}
            <BaseLayout />
          </Suspense>
        </CacheProvider>
      </CssVarsProvider>
    </ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
