export const LicenseType = [
  { id: "1", name: "عادية" },
  { id: "2", name: "طارئة" },
];

export const DrillingType = [
  { id: "1", name: "حفر مفتوح" },
  { id: "2", name: "ثقب افقي" },
  { id: "3", name: "حفر مفتوح + ثقب أفقي" },
  { id: "4", name: "سفلتة اولية" },
  { id: "5", name: "فحص تربة" },
  { id: "6", name: "كشط و سفلته" },
  { id: "7", name: "انشاء محطات" },
  { id: "8", name: "كشف خدمات" },
  { id: "9", name: "انشاء نفق" },
  { id: "10", name: "انشاء كوبري" },
  { id: "11", name: "انشاء جسر مشاة" },
  { id: "12", name: "اعمال ترابية وسفلته" },
  { id: "13", name: "صيانة" },
  { id: "14", name: "ارصفة" },
  { id: "15", name: "حفر لترحيل كيابل المترو" },
  { id: "16", name: "تركيب بلاط وبردورات" },
  { id: "17", name: "استحداث فتحة دوران" },
  { id: "18", name: "صيانة جسور" },
  { id: "19", name: "طفايات حريق" },
  { id: "20", name: "انشاء جسر" },
  { id: "21", name: "لوحات دعائية" },
  { id: "22", name: "لوحات إلكترونية" },
  { id: "23", name: "تاهيل وتطوير" },
  { id: "24", name: "حفر ابار" },
  { id: "25", name: "لوحات ارشادية" },
  { id: "26", name: "اصلاح ومعالجة" },
];

export const ServiceName = [
  { id: "14", name: "سفلتة" },
  { id: "34", name: "دراسات تربة" },
];

export const ServiceProvderName = [{ id: "0", name: "أمانة مدينة الرياض" }];

export const licenseTestTypes = [
  { id: "1", name: "معملي", value: "LAB" },
  {
    id: "2",
    name: "حقلي",
    value: "FIELD",
  },
];
