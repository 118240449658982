import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { TestsPrices } from "services/StaticLookup/TableHeader/TableHeader";

const TestsPricesModal = (props) => {
  return (
    <>
      <Box>
        <BaseTableIndex
          tableHeader={TestsPrices}
          content={props.tableContent}
          isLoading={props.isLoading}
          tableSx={{
            thLastOfType: {
              textAlign: "end",
            },
            td: {
              borderBottom: "0.1rem solid background.disabled",
            },
            tdLastOfType: {
              textAlign: "end",
            },
          }}
        />
      </Box>
    </>
  );
};

export default TestsPricesModal;
