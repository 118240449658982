import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropdownActions } from "store/dropdown-slice/dropdown-slice";

const useBaseDropdown = (mainData, config) => {
  const dropdownRef = useRef();

  const dropdownId = useRef();

  const dropdown = useSelector((store) => store.dropdown);

  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (dropdownRef.current) {
      timer = setTimeout(() => {
        dropdownId.current = dropdownRef.current.dataset.id;
      }, 500);
    }
    return () => timer && clearTimeout(timer);
  }, [dropdownRef.current]);

  const onCachedValueChange = (value) => {
    const newValueKey = mainData.pickup ? "pickupData" : "value";
    dispatch(
      dropdownActions.onChangeValue({
        id: dropdownId.current,
        data: {
          ...mainData,
          [newValueKey]: value,
        },
      })
    );
  };

  return {
    dropdownRef,
    dropdownId: dropdownId.current,
    onCachedValueChange,
    initialMainDropdownData: mainData,
    cachedData: dropdown.f_dropdowns[dropdownId.current],
  };
};

export default useBaseDropdown;
