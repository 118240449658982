import RegisterFormCont from "components/Forms/RegisterForm/RegisterFormCont/RegisterFormCont";
import RegisterFormMain from "components/Forms/RegisterForm/RegisterFormMain/RegisterFormMain";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Register from "modules/Auth/pages/Register/Register";

const constractorAuthRoutes = {
  path: "contractor",
  children: [
    {
      path: "register",
      element: <ProtectedRoute component={<Register />} />,
      children: [
        {
          index: true,
          element: <ProtectedRoute component={<RegisterFormMain />} />,
        },
        {
          path: "cont",
          element: <ProtectedRoute component={<RegisterFormCont />} />,
        },
      ],
    },
  ],
};


export { constractorAuthRoutes };
