import JsBarcode from "jsbarcode";

/**
 *
 * @param {string} string - The string that should be filtered from any special characters expect these - _
 * @returns {string} - it removes every special characters like [!@#$%^&*()+= ], but (- _) are not considered as special characters
 */
export const noSpecialCharsString = (string) =>
  string.replace(/[^0-9a-zA-Z-_]/g, "");

export const textToBase64Barcode = (code) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, code, { format: "CODE39" });
  return canvas.toDataURL("image/png");
};
