import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "./ResubmitNewAppointment.module.scss";
import LicenseDataFallbackComp from "../../../../../components/Dashboard/DashboardDrillingLicensesData/LicenseDataFallbackComp";
import {
  baseCalendarDateFormatter,
  displayFormattedTimeHHMM,
  displayFormattedTimeHHMMwithoutPMAM,
} from "utils/Date/Date";

const ResubmitNewAppointment = (props) => {
  const date = new Date(props?.data.time);
  const selectionTimeDate = new Date(date);
  selectionTimeDate.setMinutes(selectionTimeDate.getMinutes() + 30);

  if (!props.data?.time) {
    return (
      <Box>
        <LicenseDataFallbackComp title="يرجى تحديد التاريخ والوقت لعرض تفاصيل الموعد" />
      </Box>
    );
  }

  if (props.data) {
    return (
      <Box className={classes["drilling-data"]} mt={4}>
        <Box className={classes["drilling-data__wrapper"]}>
          <WrapperContentGrid columnsWidth={props.columns || 24} gap={1}>
            <Box className={classes["drilling-data__item"]}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography className={classes["drilling-data__label"]}>
                  التاريخ:
                </Typography>
                <Typography
                  className={classes["drilling-data__value"]}
                  marginLeft={1}
                >
                  {`${baseCalendarDateFormatter(
                    props?.data.date,
                    "dddd, YYYY/MM/DD"
                  )}`}
                </Typography>
              </Box>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography className={classes["drilling-data__label"]}>
                  الوقت:
                </Typography>
                <Typography
                  className={classes["drilling-data__value"]}
                  marginLeft={1}
                >
                  {displayFormattedTimeHHMMwithoutPMAM(props?.data.time)} -{" "}
                  {`${displayFormattedTimeHHMM(selectionTimeDate)}`}
                </Typography>
              </Box>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes["drilling-data__label"]}>
                  مدة الفحص:
                </Typography>
                <Typography
                  className={classes["drilling-data__value"]}
                  marginLeft={1}
                >
                  30 دقيقة
                </Typography>
              </Box>
            </Box>
          </WrapperContentGrid>
        </Box>
      </Box>
    );
  }
};

export default ResubmitNewAppointment;
