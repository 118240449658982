import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "services/Api";
import { REACT_APP_ADMIN_BASE_URL } from "utils/Env/Env";

// RTK Query
const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: axiosBaseQuery({
    baseUrl: REACT_APP_ADMIN_BASE_URL,
  }),
  endpoints: (builder) => ({}),
});

export default adminApi;
