import { queryBuilder } from "utils/ApiHelpers";
import contractorApi from "..";

const contractorPaymentApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    getCheckoutId: builder.mutation({
      query: (body) => ({
        url: `/api/contractor/payment/processPayment`,
        method: "POST",
        body,
      }),
    }),
    checkPaymentStatus: builder.mutation({
      query: (body) => ({
        url: `/api/contractor/payment/paymentConfirmation`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Wallet"],
    }),
    getInvoices: builder.query({
      query: ({ page = 0, pageSize = 10, sortId = "id,desc" }) => ({
        url: `/api/contractor/walletPrepayments/invoices?page=${page}&size=${pageSize}&sort=${sortId}`,
      }),
      invalidatesTags: ["Wallet"],
    }),
    getInvoiceDetails: builder.query({
      query: (id) => ({
        url: `/api/contractor/walletPrepayments/${id}`,
      }),
      invalidatesTags: ["Wallet"],
    }),
    getPayments: builder.query({
      query: ({ id, query }) => {
        const q = queryBuilder(query);
        return {
          url: `/api/contractor/walletTransactions/contractorTransactions?${q}`,
        };
      },
    }),
    getPayment: builder.query({
      query: (id) => {
        return {
          url: `/api/contractor/walletTransactions/${id}`,
        };
      },
    }),
    getInvoice: builder.query({
      query: (walletPrepaymentId) => {
        return {
          url: `/api/contractor/walletPrepayments/${walletPrepaymentId}/receipt`,
        };
      },
    }),
  }),
});

export const {
  useGetCheckoutIdMutation,
  useCheckPaymentStatusMutation,
  useGetInvoicesQuery,
  useGetInvoiceDetailsQuery,
  useLazyGetPaymentsQuery,
  useGetPaymentQuery,
  useLazyGetInvoiceQuery,
  useGetInvoiceQuery,
} = contractorPaymentApi;

export default contractorPaymentApi;
