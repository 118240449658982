import {
  LICENSE_YEAR_DATE_RANGE,
  LICENSE_YEAR_DATE_FROM,
} from "constants/Forms";
import { getHijriYearAsIntegar } from "utils/Date/Date";

export const licenseYearsData = function () {
  let years = [];
  let fromYear = getHijriYearAsIntegar() - LICENSE_YEAR_DATE_RANGE;
  let toYear = getHijriYearAsIntegar() + LICENSE_YEAR_DATE_FROM;

  for (let year = fromYear; year <= toYear; year++) {
    years.push({ id: `${year}`, name: `${year}` });
  }

  return years;
};
