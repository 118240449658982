import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "bukrabold",
  src: `${process.env.PUBLIC_URL}/fonts/bukrabold.ttf`,
  format: "truetype",
});
Font.register({
  family: "bukraregular",
  src: `${process.env.PUBLIC_URL}/fonts/bukraregular.ttf`,
  format: "truetype",
});

export const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#4A4949",
    fontFamily: "bukraregular",
    fontSize: 6,
  },
  footerContent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: 8,
    borderTop: 1,
    borderColor: "#EDEFF2",
  },
});
