import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import EditRequestField from "modules/Laboratory/components/DataDetails/EditRequest/EditRequestField";
import React from "react";

function FieldEditRequestDetails({ data }) {
  // stations handlers
  const { stations, isFetching: isStationsFetching } =
    useGetStationsWithTestData({ id: data?.inspectionId });

  return <EditRequestField data={data} stations={stations} />;
}

export default FieldEditRequestDetails;
