import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collectedSampleData: null,
  acceptSampleDelivery: false,
  requestDetails: null,
};

const technicianSlice = createSlice({
  name: "technician",
  initialState,
  reducers: {
    saveCollectedSampleData: (state, action) => {
      state.collectedSampleData = action.payload.collectedSampleData;
    },
    acceptSampleDelivery: (state, action) => {
      state.acceptSampleDelivery = true;
    },
    resetCollectedSampleData: (state, action) => {
      state.collectedSampleData = null;
    },
    saveRequestDetails: (state, action) => {
      state.requestDetails = action.payload.requestDetails;
    },
  },
});

export const technicianActions = technicianSlice.actions;

export default technicianSlice.reducer;
