import { Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import { renderLabAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { TestTypes } from "constants/TestTypes";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";

const DisplayTimeRangeForLab = (props) => {
  if (!props.appointmentDate || !props.scheduledAppointment)
    return defaultEmptyValue;

  const isField = props?.testType === TestTypes.FIELD_TEST;

  const timeLabel = renderLabAppointmentTimeLabel(props.scheduledAppointment);

  return (
    <>
      {isField ? (
        <Typography
          component={"span"}
          sx={{ fontSize: "1.6rem" }}
        >{`${formatDateYYYYMMDD(
          props.appointmentDate
        )} - ${timeLabel}`}</Typography>
      ) : (
        <BaseStatus
          scheduledAppointment={props.scheduledAppointment}
          appointmentDate={props.appointmentDate}
          status={props.testType}
          tableId={props.tableId}
        />
      )}
    </>
  );
};

export default DisplayTimeRangeForLab;
