import { queryBuilder } from "utils/ApiHelpers";
import inspectionApi from "../../../Inspection/apis";

const editApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getEditRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/inspection/inspectionEdits/list?${q}`,
        };
      },
    }),
    getEditRequestsDetails: builder.query({
      query: (id) => ({
        url: `/api/inspection/inspectionEdits/${id}/details`,
      }),
      providesTags: ["Edit-Request"],
    }),
    approveEditRequest: builder.mutation({
      query: (inspectionEditId) => ({
        url: `/api/inspection/inspectionEdits/inspectionEdit/accept/${inspectionEditId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Edit-Request"],
    }),
    rejectEditRequest: builder.mutation({
      query: (payload) => ({
        url: `/api/inspection/inspectionEdits/inspectionEdit/reject`,
        method: "PUT",
        body: payload.body,
      }),
      invalidatesTags: ["Edit-Request"],
    }),
  }),
});

export const {
  useGetEditRequestsQuery,
  useLazyGetEditRequestsQuery,
  useGetEditRequestsDetailsQuery,
  useLazyGetEditRequestsDetailsQuery,
  useApproveEditRequestMutation,
  useRejectEditRequestMutation,
} = editApi;

export default editApi;
