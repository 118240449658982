import NotFound from "modules/Public/pages/NotFound/NotFound";
import SupportPage from "modules/Public/pages/Support";
import { deepCopy } from "utils/Objects/Objects";
import { noSpecialCharsString } from "utils/Strings/Strings";

export const DEFAULT_HOME_PAGE_ROUTE = "/home";

export const sharedRoutesBetweenPublicAndAuth = [
  {
    path: "support",
    element: <SupportPage />,
  },
];

export const notFoundRoute = { path: "*", element: <NotFound /> };

export const combineRoutes = (mainRoutesList, routes = []) => {
  return [...mainRoutesList, ...routes];
};

export const combineRoutesAsChildren = (mainRoute, routes = []) => {
  routes.forEach((route) => {
    mainRoute.children.push(route);
  });

  return [mainRoute];
};

/**
 *
 * @param {*} mainRoutes this is the routes that we want to exclude some paths from it.
 * @param {*} excludes this is a list that contains all paths names that we want to exclude from mainRoutes
 * @description this is a clouser function and depends on recurssion and IIFE | the outer function makes a deep copy of the mainRoutes object
 * because mainRoutes has a lot of deep objects and arrays that's why we needed deep copy of it, the inner function(exclude) takes 1 argument
 * which is the mainRoutes and the default value is the deep copied main routes from the outer function, I've set the _mainRoutes equals to mainRoutesCp
 * because we needed exclude function to start first iteration with the same mainRoutesCp object and then it will go through recurssion to splice all excluded
 * paths from the mainRoutesCp
 * @returns a path object that contains all paths and children needed to serve some user
 * @author Khaled
 */
export const excludeRoutesFromAnotherRoutes = (mainRoutes, excludes = []) => {
  const mainRoutesCp = JSON.parse(JSON.stringify(mainRoutes));

  return (function exclude(_mainRoutes = mainRoutesCp) {
    if (!_mainRoutes.children) return _mainRoutes;

    for (let [index, route] of _mainRoutes.children.entries()) {
      if (!route.path) continue;

      let found = false;

      let cleanRoutePath = noSpecialCharsString(route.path);

      if (excludes.includes(cleanRoutePath)) {
        found = true;
        _mainRoutes.children.splice(index, 1);
      }
      if (!found && route.children) {
        exclude(_mainRoutes.children[index]);
      }
    }

    return mainRoutesCp;
  })();
};
