import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  f_dropdowns: {},
};

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: initialState,
  reducers: {
    onChangeValue: (state, action) => {
      state.f_dropdowns[action.payload.id] = {
        ...state.f_dropdowns[action.payload.id],
        ...action.payload.data,
      };
    },
    resetDropdown: (state, action) => {
      if (Array.isArray(action.payload.id)) {
        action.payload.id.forEach((id) => {
          state.f_dropdowns[id] = {};
        });
      }
      state.f_dropdowns[action.payload.id] = {};
    },
  },
});

export const dropdownActions = dropdownSlice.actions;

export default dropdownSlice.reducer;
