import React, { Fragment, useLayoutEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import TableSubData from "../TableSubData/TableSubData";
import { isContentReady } from "utils/Array/Arrays";

const CategorizedTableBody = (props) => {
  const { palette } = useTheme();

  const isTableContentEmpty = !isContentReady(props.content);
  const [categorizedContent, setCategorizedContent] = useState({});

  useLayoutEffect(() => {
    if (!isTableContentEmpty) {
      let categorizedData = {};
      for (let i = 0; i < props.content.length; i++) {
        let item = props.content[i];
        let key = item.category;
        if (!(key in categorizedData)) {
          categorizedData[key] = [];
        }
        categorizedData[key] = [...categorizedData[key], item];
      }
      setCategorizedContent({
        categories: Object.keys(categorizedData),
        data: categorizedData,
      });
    }
  }, []);

  const InjectedComponent = (rowProps) =>
    React.cloneElement(props.injectCompBetweenRows, {
      ...rowProps,
      ...props.injectProps,
      insertProps: {
        props: { ...props.insertedProps },
        insertNewPropsHandler: props.insertNewPropsHandler,
      },
    });

  return (
    <TableBody>
      {categorizedContent?.categories &&
        isContentReady(categorizedContent?.categories) &&
        categorizedContent?.categories?.map((category, idx) => {
          return (
            <Fragment key={category}>
              <TableRow
                sx={{
                  background: `${palette.background.colorSoft2}`,
                }}
              >
                <TableCell
                  colSpan={"100%"}
                  sx={{
                    fontSize: "1.6rem",
                    fontWeight: "bold",
                  }}
                >
                  {category}
                </TableCell>
              </TableRow>
              {categorizedContent.data[category].map((categoryElement, idx) => {
                return (
                  // <Fragment key={categoryElement.id}>
                  <Fragment key={idx}>
                    <TableSubData
                      categoryElement={categoryElement}
                      subData={props.subData}
                      tableHeader={props.tableHeader}
                      renderTableCell={props.renderTableCell}
                    />
                    {!props.subData && (
                      <TableRow>
                        {props.tableHeader.map((headerItem, idx) => {
                          return props.children({
                            categoryElement,
                            headerItem,
                            idx,
                          });
                        })}
                      </TableRow>
                    )}
                    {props.injectCompBetweenRows && (
                      <TableRow>
                        <TableCell colSpan={"100%"}>
                          {InjectedComponent(categoryElement)}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
    </TableBody>
  );
};

export default CategorizedTableBody;
