import useCatchUserType from "hooks/useCatchUserType";
import { useEffect } from "react";
import { UsersRolesEnum } from "constants/UsersTypes";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/**
 *
 * IN THIS COMPONENT WE DO BASIC MIDDLEWARE VALIDATION TO CHECK IF THE USER IS AUTH OR NOT AND PREVENT HIM TO GO TO NOT ALLOWED ROUTE
 * FOR MORE [ADVANCED] VALIDATION ON useCatchUserType HOOK
 */
const ProtectedRoute = (props) => {
  const { isAuth, userType, user } = useSelector((store) => store.auth);
  const { userRoleFromURL, isAuthRoute } = useCatchUserType();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      if (!isAuthRoute) {
        return navigate(`/auth/${userRoleFromURL}/login`);
      }
    } else {
      if (isAuthRoute && user?.user_role === UsersRolesEnum.ADMIN) {
        navigate("/drilling-licenses");
      } else if (isAuthRoute) {
        return navigate(`/home`, {
          state: {
            fromAuth: true,
          },
        });
      }
    }
  }, [isAuth, userType, userRoleFromURL]);

  return props.component;
};

export default ProtectedRoute;
