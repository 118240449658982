import { useGeStationNumberQuery } from "modules/Laboratory/apis/inspection-apis/inspection-api";

const useGetStationsWithTestData = (inspection) => {
  const {
    data: stations,
    isFetching,
    isLoading,
  } = useGeStationNumberQuery(
    { id: inspection?.id, isCustomResponse: inspection?.isCustomResponse },
    { skip: !inspection?.id }
  );

  // MAKE A CUSTOM HOOK TO GET STATIONS WITH TEST NAME
  const stationsWithTestName = stations?.map((station) => {
    const stationTests = station?.tests?.[0];
    if (!inspection?.isCustomResponse) {
      return {
        ...station,
        testStandard: stationTests?.testStandard,
        inspectionTestId: stationTests?.inspectionTestId,
        scoresList: stationTests?.scoresList,
        testStatus: stationTests?.testStatus,
      };
    }
    return station;
  });

  return { stations: stationsWithTestName, isFetching };
};

export default useGetStationsWithTestData;
