import { UsersRolesEnum, UsersTypesEnum } from "constants/UsersTypes";
import consultantDashboardRoutes from "modules/Consultant/routes/DashboardRoutes";
import contractorDashboardRoutes from "modules/Contractor/routes/DashboardRoutes";
import distributionCenterRoutes from "modules/DistributionCenter/routes/DashboardRoutes";
import labDashboardRoutes from "modules/Laboratory/routes/DashboardRoutes";

/**
 *
 * @param {string} userType
 * @param {string} userRole
 * @returns {Array<routes> | undefined} - It returns an array of dashboard routes of matched user type(contractor, laboratory, center) and the user role is SYSOPS
 */
export const sysopsRoleRoutes = (userType, userRole) => {
  if (userType === UsersTypesEnum.CONTRACTOR) {
    if (userRole === UsersRolesEnum.SYSOPS) {
      return contractorDashboardRoutes;
    }
  }

  if (userType === UsersTypesEnum.CONSULTANT) {
    if (userRole === UsersRolesEnum.SYSOPS) {
      return consultantDashboardRoutes;
    }
  }

  if (userType === UsersTypesEnum.LABORATORY) {
    if (userRole === UsersRolesEnum.SYSOPS) {
      return labDashboardRoutes;
    }
  }
  if (userType === UsersTypesEnum.CENTER) {
    if (userRole === UsersRolesEnum.SYSOPS) {
      return distributionCenterRoutes;
    }
  }
};
