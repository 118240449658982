import React, { useEffect, useState } from "react";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Radio, ListItem, Typography, Stack, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch } from "react-redux";
import { walletActions } from "store/wallet-slice";
import { formattedNumber } from "utils/Numbers/Numbers";
import CustomPackageForm from "./CustomPackageForm/CustomPackageForm";
import { savePackageAction } from "store/wallet-slice/wallet-thunk-actions";

const CustomPackage = (props) => {
  const dispatch = useDispatch();

  const [paymentCustomPoints, setPaymentCustomPoints] = useState("0");

  useEffect(() => {
    if (!props.checked) {
      setPaymentCustomPoints("0");
    }
  }, [props.checked]);

  const onChangeCustomPointsHandler = (value) => {
    setPaymentCustomPoints(value);
    if (props.checked) {
      dispatch(
        savePackageAction({ ...props.data, points: +value, price: +value })
      );
    }
  };

  const onCheckHandler = (_) => {
    dispatch(walletActions.checkCustomPointsHandler());
    // 👇 this reset the selected plans to be initial state
    if (props.data) {
      dispatch(savePackageAction({ ...props.data, points: 0, price: 0 }));
    }
  };

  return (
    <BaseCard
      variant={props.checked ? "secondary" : "primary-transparent"}
      mb={2}
      sx={{
        minHeight: "7.6rem",
        width: "auto",
      }}
      px={"min(1.4rem, 2vw)"}
      py={"0rem"}
      borderRadius={3}
    >
      <ListItem value={props.data?.points || 0}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          flexWrap={"wrap"}
          spacing={0.5}
          width={"100%"}
        >
          <Radio
            value={props.data?.id || 4}
            label={props.data?.points || 0}
            fontSize={"large"}
            checked={props.checked}
            disabled={props.disabled}
            checkedIcon={<CheckCircleIcon />}
            sx={{
              color: "primary.colorSoft6",
              "&.Mui-checked": {
                color: "secondary.main",
              },
            }}
            onChange={() => onCheckHandler(props.data)}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "2.4rem",
              color: "grayDark.color13",
            }}
          >
            حدد عدد النقاط
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginLeft: "auto !important",
            }}
          >
            <Typography sx={{ fontSize: "2.4rem", overflowWrap: "anywhere" }}>
              {formattedNumber(+paymentCustomPoints || 0)}
            </Typography>
            <Typography fontSize={"1.6rem"}>ريال سعودي</Typography>
          </Box>
        </Stack>
      </ListItem>
      {props.checked && (
        <CustomPackageForm
          vatPercentage={props.vatPercentage}
          submitBtnRef={props.submitBtnRef}
          onChangeCustomPointsHandler={onChangeCustomPointsHandler}
          getCheckoutId={props.getCheckoutId}
          getCheckoutIdResponse={props.getCheckoutIdResponse}
        />
      )}
    </BaseCard>
  );
};

export default CustomPackage;
