import { Box, Stack, Typography } from "@mui/material";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { TestTypes } from "constants/TestTypes";
import BaseCard from "core-ui/BaseCard/BaseCard";
import RequestPathItem from "modules/Contractor/components/RequestPathItem/RequestPathItem";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GeneralInstructionsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { contractorActions } from "store/contractor-slice/contractor-slice";

let navigateTimer;

const RequestPath = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const onClickHandler = (value) => {
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          testType: value,
          // date: null, time: ""
        },
      })
    );
    navigateTimer = setTimeout(() => {
      navigate("/home/general-instructions", { state: location.state });
    }, 100);
  };

  useEffect(() => {
    dispatch(contractorActions.resetRaiseNewRequestData());

    return () => {
      if (navigateTimer) {
        clearTimeout(navigateTimer);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("raise a request")}</title>
        <link rel="canonical" href="/home/request-path" />
      </Helmet>
      <DashboardIntro
        title={t("raise a request", { ns: "dashboard" })}
        description={t("request path description", { ns: "dashboard" })}
        // breadcrumbData={GeneralInstructionsBreadcrumb(t)}
      />
      <BaseCard mt={6} py={"5rem"} px={"5rem"}>
        <Typography
          fontWeight={"700"}
          mb={"2.5rem"}
          sx={{ fontSize: "1.6rem" }}
          color={"grayDark.color8"}
        >
          يرجى تحديد نوع مسار الفحص
        </Typography>
        <Stack direction={"row"} gap={3} flexWrap={"wrap"}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <RequestPathItem
              publicImageUrl={"images/landingPage/lab-portal.svg"}
              description={
                "يمكنك رفع طلبات الفحص المعملية، كما يمكنك رفع طلب واحد لأكثر من رخصة حفر"
              }
              buttonText={"طلب فحص معملي"}
              onClick={onClickHandler.bind(null, TestTypes.LAB_TEST)}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <RequestPathItem
              publicImageUrl={"images/landingPage/contractor-portal.svg"}
              description={
                "يمكنك رفع طلبات الفحص الحقلية، كما يمكنك تحديد عدد النقاط و الاختبارات المرتبطة بها"
              }
              buttonText={"طلب فحص حقلي"}
              onClick={onClickHandler.bind(null, TestTypes.FIELD_TEST)}
            />
          </Box>
        </Stack>
      </BaseCard>
    </>
  );
};

export default RequestPath;
