import { Box } from "@mui/material";

const CenteredWrapper = (props) => {
  return (
    <Box
      {...props}
      width={`min(${props?.width || "58rem"}, 100%)`}
      margin={`min(${props?.margin || "1rem"}, 2.5vw) auto`}
    >
      {props.children}
    </Box>
  );
};

export default CenteredWrapper;

CenteredWrapper.defaultProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "100%",
  paddingInline: "1rem",
};
