export const isProduction = process.env.REACT_APP_NODE_ENV === "production";
export const isDevelopment = process.env.REACT_APP_NODE_ENV === "development";
export const isUAT = process.env.REACT_APP_NODE_ENV === "uat";

export const baseURL = isProduction
  ? "https://fohosat.resaa.sa"
  : isUAT
  ? "https://uat-fuhosat.tamkeen.live"
  : "https://dev-fuhosat.tamkeen.live";

export const REACT_APP_AUTH_BASE_URL = baseURL;
export const REACT_APP_ADMIN_BASE_URL = baseURL;
export const REACT_APP_CONTRACTOR_BASE_URL = baseURL;
export const REACT_APP_INSPECTOR_BASE_URL = baseURL;

// and example for a flag where a feature can only be visible in dev/uat
// the flag can then be imported to be used with the flagged feature
export const FLAGS = {
  EXAMPLE_FLAG: isDevelopment || isUAT,
};
