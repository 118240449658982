import { Box, Typography } from "@mui/material";
import StationsDataModal from "components/AllModalContent/StationsDataModal/StationsDataModal";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import { defaultEmptyValue } from "constants/Empty";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import { useGetAppointmentDetailsForTechnicianQuery } from "modules/Technician/apis/inspection-apis/inspection-api";
import { useState } from "react";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { renderAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { publicAssetsPath } from "utils/Paths/Paths";

const FieldTechnicianRequestDetails = (props) => {
  const { stations } = useGetStationsWithTestData(props.inspection);

  const { data: appointmentData } = useGetAppointmentDetailsForTechnicianQuery(
    props.inspection.id
  );

  const [showStation, setShowStation] = useState(false);

  const onLeavePageHandler = () => setShowStation(false);

  const timeLabel = renderAppointmentTimeLabel(appointmentData?.scheduledTime);

  const singleLicense = props?.inspection?.licenses?.[0];

  return (
    <Box>
      <BaseModal
        show={showStation}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <StationsDataModal onHide={onLeavePageHandler} stations={stations} />
      </BaseModal>
      <Box className={"request-data-details"} mt={4}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid columnsWidth={props.columns || 24} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection.id || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اختبار مواد الأعمال المدنية{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موعد الفحص
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                {`${formatDateYYYYMMDD(
                  appointmentData?.scheduledDate
                )} - ${timeLabel}`}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الحالة
              </Typography>
              <BaseStatus
                status={props.inspection.status}
                testType={props.inspection.testType}
                dataId={props.dataId}
              />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الموقع
              </Typography>
              <LocationField location={singleLicense.locationUrl} />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نقاط الـ Stations
              </Typography>
              <Typography
                className={"request-data-details__value"}
                onClick={() => setShowStation(true)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  cursor: "grab",
                }}
              >
                نقاط الـ Stations
                <img
                  src={publicAssetsPath("icons/popout-svgrepo.svg")}
                  width="auto"
                />
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>

        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات الاستشاري
          </Typography>
          <WrapperContentGrid columnsWidth={26} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection.licenses[0]?.consultantName ||
                  defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الجوال{" "}
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{ direction: "rtl", mr: 4 }}
              >
                {formatPhoneNumber(
                  props.inspection?.licenses[0]?.consultantMobile
                ) || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection.licenses[0]?.consultantEmail ||
                  defaultEmptyValue}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>

        <hr />

        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات المختبر{" "}
          </Typography>
          <WrapperContentGrid columnsWidth={26} gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection.labName || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موقع المختبر
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{
                  fontWeight: "bold !important",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {props.inspection.labLocation ? (
                  <>
                    <a
                      href={props.inspection.labLocation}
                      target="_blank"
                      className="request-data-details__link"
                      rel="noreferrer"
                    >
                      الموقع
                    </a>
                    <img
                      src={publicAssetsPath("icons/popout-svgrepo.svg")}
                      width="auto"
                      alt="location"
                    />
                  </>
                ) : (
                  defaultEmptyValue
                )}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default FieldTechnicianRequestDetails;
