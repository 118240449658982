import useAlert from "hooks/useAlert";
import { Outlet } from "react-router-dom";

const ExaminationRequests = () => {
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  return (
    <Outlet
      context={{
        globalAlert: alert,
        onShowGlobalAlert: showAlertHandler,
        onHideGlobalAlert: hideAlertHandler,
      }}
    />
  );
};

export default ExaminationRequests;
