import { SETUP_V_TIMELINE_DURATION } from "constants/Timeline";
import { useEffect, useState } from "react";
import { useCallback, useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";

const initialState = { activeStageIndex: 0, lastAction: null, isHidden: false };

const useVerticalTimeline = () => {
  const vTimelineRef = useRef();
  const location = useLocation();
  const [animate, setAnimate] = useState(false);

  const reanimate = () => {
    let timer;
    if (timer) clearTimeout(timer);
    setAnimate(true);
    timer = setTimeout(() => {
      setAnimate(false);
    }, 200);
  };

  const reducerFn = (state = initialState, action) => {
    if (action.type === "NEXT") {
      return {
        ...state,
        activeStageIndex: state.activeStageIndex + 1,
        lastAction: "NEXT",
      };
    }

    if (action.type === "PREV") {
      return {
        ...state,
        activeStageIndex: state.activeStageIndex - 1,
        lastAction: "PREV",
      };
    }

    if (action.type === "DONE_STAGE") {
      return {
        ...state,
        activeStageIndex: action.payload.stage || 0,
        lastAction: "DONE_STAGE",
      };
    }

    if (action.type === "DONE_SPECIFIC_STAGE") {
      return {
        ...state,
        activeStageIndex: action.payload.stage,
        lastAction: "DONE_SPECIFIC_STAGE",
      };
    }

    if (action.type === "RESET") {
      return { ...initialState };
    }

    if (action.type === "HIDE") {
      return { ...state, isHidden: true };
    }

    if (action.type === "SHOW") {
      return { ...state, isHidden: false };
    }

    return state;
  };

  const [vTimelineData, dispatch] = useReducer(reducerFn, initialState);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (vTimelineRef.current) {
        //prettier-ignore
        const timelineDashedLine = vTimelineRef.current.previousElementSibling;

        //prettier-ignore
        const lastElementBoundaries =  Array.from(vTimelineRef.current?.children).at(-1)?.getBoundingClientRect();
        //prettier-ignore
        const timelineHeight = vTimelineRef.current.getBoundingClientRect().height;

        if (lastElementBoundaries) {
          //prettier-ignore
          timelineDashedLine.style.height = `${timelineHeight - lastElementBoundaries.height}px`;
        }
        /////////////////////////////////////////////////////////////
        let stageDoneIndex = vTimelineData.activeStageIndex - 1;

        // add active to the next stage
        vTimelineRef.current?.children[stageDoneIndex + 1]?.classList?.add(
          "timeline-stage-active"
        );

        if (vTimelineData.lastAction === "DONE_SPECIFIC_STAGE") return;

        Array.from(vTimelineRef.current?.children).forEach((child, idx) => {
          // remove all classes from the timeline children
          //prettier-ignore
          child?.classList?.remove("timeline-stage-active","timeline-stage-active");

          if (idx <= stageDoneIndex) {
            // add done stage class on finished stage
            child?.classList?.add("timeline-stage-done");
          } else {
            child?.classList?.remove("timeline-stage-done");
          }
        });
      }
    }, SETUP_V_TIMELINE_DURATION);

    return () => {
      clearTimeout(timer);
    };
  }, [
    vTimelineData.activeStageIndex,
    vTimelineData.lastAction,
    vTimelineRef.current,
    location,
    animate,
  ]);

  const goNext = useCallback(() => {
    if (
      vTimelineData.activeStageIndex <=
      vTimelineRef.current?.children?.length - 1
    ) {
      dispatch({ type: "NEXT" });
    }
  }, [vTimelineData.activeStageIndex, vTimelineRef.current?.children]);

  const goPrev = useCallback(() => {
    if (vTimelineData.activeStageIndex) {
      dispatch({ type: "PREV" });
    }
  }, [vTimelineData.activeStageIndex, vTimelineRef.current?.children]);

  const completeStage = (stage, hideOnFinish = true) => {
    const _stage = Math.min(stage, vTimelineRef.current?.children.length);

    setTimeout(
      () =>
        dispatch({
          type: "DONE_STAGE",
          payload: {
            stage: _stage,
          },
        }),
      SETUP_V_TIMELINE_DURATION
    );
    if (vTimelineRef.current && hideOnFinish) {
      if (stage >= Array.from(vTimelineRef.current?.children).length) {
        vTimelineRef.current.classList?.add("hide");
        dispatch({ type: "HIDE" });
      } else {
        vTimelineRef.current.classList?.remove("hide");
      }
    }
  };

  const completeSpecificStage = (stage) => {
    if (stage > vTimelineRef?.current?.length) return;

    Array.from(vTimelineRef.current?.children)?.[stage - 1]?.classList?.add(
      "timeline-stage-done"
    );

    dispatch({
      type: "DONE_SPECIFIC_STAGE",
      payload: {
        stage,
      },
    });
  };

  const onHide = () => {
    vTimelineRef.current?.classList?.add("hide");
    dispatch({ type: "HIDE" });
  };

  const onShow = () => {
    vTimelineRef.current.classList?.remove("hide");
    dispatch({ type: "SHOW" });
  };

  const onReset = () => {
    dispatch({ type: "RESET" });
    onShow();
  };

  return {
    vTimelineRef,
    goNext,
    goPrev,
    onHide,
    onShow,
    completeStage,
    reanimate,
    onReset,
    completeSpecificStage,
    isHidden: vTimelineData.isHidden,
  };
};

export default useVerticalTimeline;
