import {
  filterList,
  findTechnicianByStatusIfExists,
  findTechnicianByStatus,
  createLogConfig,
} from "../utils";

import { serverKeysEnum, technicianStatus } from "../utils/enums";
import HistoryRender from "../HistoryRender/HistoryRender";
import { historyFieldCycle } from "./HistoryFieldCycle";
import { historyFieldDeassigningCycle } from "./historyFieldDeassigningCycle";

function HistoryField({
  rawData,
  sortedCycleItems,
  hasDeassignItems,
  reAssignmentCycleItems,
  hasReassignedTechnicians,
}) {
  const _props = {
    rawData,
    sortedCycleItems,
    hasDeassignItems,
    reAssignmentCycleItems,
    hasReassignedTechnicians,
  };

  function createCycleLogItems() {
    let cycle = [];

    // createdAt
    let createdAtConfig = createLogConfig({
      label: "",
      value: "تم إنشاء الطلب – بانتظار قبول المختبر",
      date: rawData[serverKeysEnum.CREATED_AT],
      isBackendValueExists: rawData[serverKeysEnum.CREATED_AT],
    });
    cycle.push(createdAtConfig);

    // THE WHOLE CYCLE
    // prettier-ignore
    sortedCycleItems.forEach((cycleItem, cycleItemIndex) => {
      const prevItem = sortedCycleItems[cycleItemIndex - 1];
      // prettier-ignore
      const isFirstIteration = cycleItemIndex === 0;
      // prettier-ignore
      const doneTechnician = findTechnicianByStatus(rawData[serverKeysEnum.ASSIGNMENT_HISTORY_LIST], technicianStatus.DONE);
      // prettier-ignore
      const firstAssignedTechnician = reAssignmentCycleItems[0];
      // prettier-ignore
      const isLabNotNewlyAssigned = !prevItem?.labAssignedTime;
      //prettier-ignore
      const firstTechnician = sortedCycleItems.findIndex(
        tech => 
        tech?.status === technicianStatus.ASSIGNED ||
        tech?.status === technicianStatus.REASSIGNED ||
        tech?.status === technicianStatus.DONE
      );
      
      // prettier-ignore
      const isNotFirstAssignedTechnician = firstTechnician !== cycleItemIndex;
      // THIS CASE IS THE INITIAL CASE IF WE THERE IS NO DE-ASSIGN ACTIVITES
      if (isFirstIteration) {
        //prettier-ignore
        const assignedOrDoneTechnician = firstAssignedTechnician || doneTechnician;
        if (!hasDeassignItems && rawData[serverKeysEnum.ASSIGNED_LAB_NAME]) {
          const assignedLabConfig = createLogConfig({
            label: "تم قبول الطلب من قبل المختبر: ",
            value: rawData[serverKeysEnum.ASSIGNED_LAB_NAME],
            date: rawData[serverKeysEnum.LAB_ASSIGNED_AT],
            isBackendValueExists: rawData[serverKeysEnum.ASSIGNED_LAB_NAME],
          });
          const assignedTechnicianConfig = createLogConfig({
            label: "تم إسناد الطلب إلى فني الرقابة: ",
            value: assignedOrDoneTechnician.technicianName || "",
            date: assignedOrDoneTechnician.technicianAssignedAt,
            isBackendValueExists: rawData[serverKeysEnum.ASSIGNED_LAB_NAME],
          });
          cycle.push(assignedLabConfig, assignedTechnicianConfig);
        }
      }

      // THIS CASE IF ADMIN REASSIGNED THE REQUEST TO ANOTHER TECHNICIAN
      if (!hasDeassignItems) {
        if (isNotFirstAssignedTechnician && isLabNotNewlyAssigned) {
          const assignedTechnicianConfig = createLogConfig({
            label: "تم إعادة إسناد الطلب الى فني الرقابة: ",
            value: `${cycleItem.technicianName} من قبل: ${cycleItem.assignedBy}`,
            date: cycleItem.technicianAssignedAt,
            isBackendValueExists: cycleItem.technicianName,
          });

          cycle.push(assignedTechnicianConfig);
        }

        historyFieldCycle(_props, cycle, cycleItem, cycleItemIndex);
      }

      // DE ASSIGNING
      if (hasDeassignItems) {
        //prettier-ignore
        historyFieldDeassigningCycle(_props, cycle, cycleItem, cycleItemIndex, );
        //prettier-ignore
        historyFieldCycle(_props, cycle, cycleItem, cycleItemIndex);
      }
    });

    // inspectionSubTestHistoryList - this getting the latest assigned technician
    // prettier-ignore
    const technician = findTechnicianByStatusIfExists(reAssignmentCycleItems, "last");

    [...rawData[serverKeysEnum.INSPECTION_SUB_TEST_HISTORY_LIST]]
      .sort((a, b) => 1)
      .forEach((subData) => {
        if (subData.technicianConfirmationAt) {
          const subInspectionSubTestHistoryListConfig = createLogConfig({
            label: "تم تأكيد النتيجة من قبل فني الرقابة: ",
            value: technician && technician.technicianName,
            date: subData.technicianConfirmationAt,
            isBackendValueExists:
              rawData[serverKeysEnum.INSPECTION_SUB_TEST_HISTORY_LIST],
          });
          cycle.push(subInspectionSubTestHistoryListConfig);
        }
      });

    // completedAt
    const completedAtConfig = createLogConfig({
      label: "",
      value: "تم اكتمال الطلب",
      date: rawData[serverKeysEnum.COMPLETED_AT],
      isBackendValueExists: rawData[serverKeysEnum.COMPLETED_AT],
    });

    cycle.push(completedAtConfig);

    return cycle;
  }

  const cycle = createCycleLogItems();

  return <HistoryRender logs={filterList(cycle)} />;
}

export default HistoryField;
