import { useState } from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseInput from "core-ui/BaseInput/BaseInput";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { licenseTestTypes } from "services/StaticLookup/LicenseDetails";
import classes from "./RequestsSearch.module.scss";
import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import { useTheme } from "@emotion/react";

const RequestsSearch = (props) => {
  const theme = useTheme();

  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const [searchTerm, setSearchTerm] = useState("");
  const [requestType, setRequestType] = useState("");

  const onChangesSearchTerm = (value) => {
    setSearchTerm(value);
    if (props.setSearchTerm) {
      props.setSearchTerm(value);
    }
  };
  const onChangeRequestType = (value) => {
    setRequestType(value);
    if (props.setRequestType) {
      props.setRequestType(value.value);
    }
  };

  const onRestore = () => {
    setSearchTerm("");
    setRequestType(null);
    if (props.setSearchTerm && props.setRequestType) {
      props.setSearchTerm("");
      props.setRequestType("");
    }
  };
  return (
    <BaseCard py={"5rem"} px={"5rem"} mt={6}>
      <Grid
        container
        sx={{ justifyContent: "space-between", alignItems: "center", gap: 5 }}
      >
        <Grid item md={lgDown ? 12 : 10}>
          <Grid container spacing={6}>
            <Grid item md={lgDown ? 6 : 8} sx={{ width: "100%" }}>
              <BaseInput
                buttonContainerStyles={{ marginBottom: 0 }}
                htmlFor="searchTerm"
                label={"البحث"}
                type={"text"}
                placeholder={props.placeholder}
                name="searchTerm"
                icon={"search"}
                value={searchTerm}
                onChange={onChangesSearchTerm}
              />
            </Grid>
            <Grid item md={lgDown ? 6 : 4} sx={{ width: "100%" }}>
              <BaseDropdown
                name={"requestTypes"}
                value={requestType}
                onChange={onChangeRequestType}
                label={"نوع الفحص"}
                selectMenuStyles={{
                  height: "auto !important",
                }}
                labelStyles={{
                  color: "grayDark.color",
                  fontWeight: 500,
                }}
                placeholder="حدد نوع الفحص"
                data={licenseTestTypes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ marginLeft: lgDown ? "0" : "auto" }}>
          <button
            className={classes["restore-btn"]}
            onClick={onRestore}
            disabled={!searchTerm && !requestType}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <RestoreIcon className={classes["restore-btn__img"]} />
              <Typography
                sx={{ fontSize: "1.4rem", fontWeight: "bold" }}
                className={classes["restore-btn__text"]}
              >
                إعادة تعيين
              </Typography>
            </Stack>
          </button>
        </Grid>
      </Grid>
    </BaseCard>
  );
};

export default RequestsSearch;
