import React from "react";
import DashboardLayout from "core-ui/Layouts/DashboardLayout/DashboardLayout";
import { Outlet } from "react-router-dom";

const DashboardPage = () => {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default DashboardPage;
