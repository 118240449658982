import { useMemo } from 'react';
import { queryBuilder } from 'utils/ApiHelpers';

// Custom hook for creating a query string
export const useSearchQuery = (parameters) => {
  const queryString = useMemo(() => {
    const params = Object.entries(parameters).reduce((acc, [key, value]) => {
      // Only add parameter if it has a truthy value
      if (value) acc[key] = value;
      return acc;
    }, {});

    const query = queryBuilder(params);
    return query ? `?${query}` : "";
  }, [parameters]);

  return queryString;
};
