import React from "react";
import { Controller } from "react-hook-form";
import BaseFileInput from "./BaseFileInput";
import { _void } from "utils/Objects/Objects";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import messages from "services/AlertMessages/messages";

const BaseUploadFile = (props) => {
  const maxSize = {
    size: props.maxSize?.size || 10,
    unit: props.maxSize?.unit || "MB",
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        ...props.rules,
        validate(v) {
          if (v?.error) {
            if (v.error === ErrorCodes.WRONG_FILE_EXTENSION) {
              return messages[v.error];
            }
            if (v.error === ErrorCodes.FILE_TOO_LARGE) {
              return messages[v.error];
            }
          }
        },
      }}
      render={({ field: { onChange, value, name, ref } }) => {
        return (
          <BaseFileInput
            name={name}
            onChange={onChange}
            value={value}
            disabled={props.disabled}
            title={props.title}
            description={props.description}
            availableExtensions={props.availableExtensions}
            displayAvailableExtensions={props.displayAvailableExtensions}
            onSubmit={props.onSubmit}
            isSubmitting={props.isSubmitting}
            readOnly={props.readOnly}
            errors={props.errors}
            uploadedFile={props.uploadedFile}
            maximumFiles={props.maximumFiles}
            maxSize={maxSize}
          />
        );
      }}
    />
  );
};

export default BaseUploadFile;
