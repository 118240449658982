import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { ConsultationRequestsDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { DATA_ID } from "constants/Details";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import { useGetConsultationRequestDetailsQuery } from "modules/Consultant/apis/consultation-requests-apis/consultation-requests-apis";

const ConsultationRequestsDetails = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { data: requestsDetailsData, isFetching } =
    useGetConsultationRequestDetailsQuery(params.id);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلب الإسناد رقم ${requestsDetailsData?.requestNumber}`}</title>
      </Helmet>
      <DashboardIntro
        title={t(
          `طلب الإسناد رقم ${requestsDetailsData?.requestNumber || ""}`,
          {
            ns: "dashboard",
          }
        )}
        breadcrumbData={ConsultationRequestsDetailsBreadcrumb({
          id: requestsDetailsData?.requestNumber || "",
        })}
      />
      <BaseCard
        py={"2rem"}
        px={"5rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        {isFetching ? (
          <BaseLoader />
        ) : (
          <Box className={"request-data-details"} mt={4}>
            <Box className={"request-data-details__wrapper"} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات الطلب
              </Typography>

              <WrapperContentGrid columnsWidth={24} gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الطلب
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.requestNumber}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    اسم شركة المقاولات
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.contractorCompanyName}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    تاريخ رفع الطلب
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatDateYYYYMMDD(
                      requestsDetailsData?.requestCreationDate
                    )}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    حالة الطلب
                  </Typography>
                  <BaseStatus
                    status={requestsDetailsData?.status}
                    dataId={DATA_ID?.CONSULTANT_CONSULTATION_REQUEST}
                  />
                </Box>
                {requestsDetailsData?.requestProcessingDate && (
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      تاريخ معالجة الطلب
                    </Typography>
                    <Typography className={"request-data-details__value"}>
                      {formatDateYYYYMMDD(
                        requestsDetailsData?.requestProcessingDate
                      )}
                    </Typography>
                  </Box>
                )}
              </WrapperContentGrid>
            </Box>

            <hr />
            {requestsDetailsData?.rejectionReason && (
              <Box className={"request-data-details__wrapper"} mt={5} mb={5}>
                <Typography className={"request-data-details__title"}>
                  سبب الرفض
                </Typography>
                <Typography
                  sx={{ fontSize: "1.6rem" }}
                  color={"grayDark.color1"}
                  mb={3}
                >
                  {requestsDetailsData?.rejectionReason}
                </Typography>
                <hr />
              </Box>
            )}
            <Box className={"request-data-details__wrapper"} mt={5} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات الرخصة
              </Typography>

              <WrapperContentGrid columnsWidth={24} gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الرخصة
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {`${requestsDetailsData?.licenseYear}/${requestsDetailsData?.licenseNumber}`}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    مقدم الخدمة
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.serviceProviderName}
                  </Typography>
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    النوع
                  </Typography>
                  <BaseTypeTag type={requestsDetailsData?.licenseTypeRefId} />
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    تاريخ الانتهاء
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatDateYYYYMMDD(requestsDetailsData?.licenseExpiryDate)}
                  </Typography>
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    نوع الحفر{" "}
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.licenseDrillingType || ""}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    نوع الخدمة{" "}
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.licenseServiceName || ""}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>
          </Box>
        )}
      </BaseCard>
    </>
  );
};

export default ConsultationRequestsDetails;
