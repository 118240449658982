import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTranslation } from "react-i18next";
import TableTitle from "components/Dashboard/TableTitle";
import { newRequests } from "services/StaticLookup/TableHeader/TableHeader";
import { useTheme } from "@emotion/react";
import { publicAssetsPath } from "utils/Paths/Paths";
import {
  useGetAssignedRequestsQuery,
  useGetNoAssignedRequestsQuery,
  useGetTestPricesQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { TABLE_ID, TABLE_LIMIT_PAGE_SIZE } from "constants/Table";
import { Helmet } from "react-helmet";
import { useMemo, useState } from "react";
import DisplayTimeRangeForLab from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayTimeRangeForLab/DisplayTimeRangeForLab";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { Typography } from "@mui/material";
import BaseModal from "core-ui/BaseModal/BaseModal";
import TestsPricesModal from "components/AllModalContent/TestsPricesModal/TestsPricesModal";


const DashboardIndex = (props) => {
  const { t } = useTranslation(["dashboard"]);
  const { palette } = useTheme();
  const [showModal, setShowModal] = useState(false);

  const {
    data: noAssignedRequestsData,

    isFetching,
  } = useGetNoAssignedRequestsQuery({
    page: 0,
    pageSize: TABLE_LIMIT_PAGE_SIZE,
  });

  const {
    data: labAssignedRequestsData,
    isFetching: isLabAssignedRequestDataFetching,
  } = useGetAssignedRequestsQuery({
    page: 0,
    pageSize: TABLE_LIMIT_PAGE_SIZE,
  });

  const onLeavePageHandler = () => {
    setShowModal(false);
  };

  const { data: getTestPrice } = useGetTestPricesQuery();
  const assignedRequests = useMemo(
    () => [
      { serverKey: "id", name: "request_number" },
      {
        serverKey: "status",
        hasComponent: {
          comp: <BaseStatus />,
        },
        name: "order_status",
      },
      {
        serverKey: ["samplestatus", "testType"],
        hasComponent: {
          customProps: ["status", "testType"],
          comp: <BaseStatus />,
        },
        name: "samples_status",
      },

      {
        serverKey: "testType",
        name: "request_type",
        hasComponent: { customProps: "type", comp: <BaseTypeTag /> },
      },
      {
        serverKey: "labTechName",
        name: "lab_tech_name",
      },
      {
        serverKey: "scheduledAppointment",
        name: "Examinationـdate",
        hasComponent: {
          comp: <DisplayTimeRangeForLab />,
        },
      },
    ],
    []
  );

  const IndexPageDescription = () => (
    <Typography component="span" color={"grayDark.color1"} fontSize={"1.6rem"}>
      {t("lab desc")}{" "}
      <Typography
        component="span"
        color={"primary.main"}
        fontSize={"1.6rem"}
        className="request-data-details__link"
        onClick={() => setShowModal(true)}
      >
        جدول أسعار الإختبارات{" "}
        <img src={publicAssetsPath("icons/popout-svgrepo.svg")} width="auto" />
      </Typography>
    </Typography>
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الرئيسية`}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <BaseModal
        show={showModal}
        py={"3rem"}
        px={"2rem"}
        onHide={onLeavePageHandler}
        title="قائمة أسعار الاختبارات المعملية والحقلية"
        closeIcon
      >
        <TestsPricesModal isLoading={false} tableContent={getTestPrice} />
      </BaseModal>

      <DashboardIntro
        title={t("home page")}
        description={<IndexPageDescription />}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("New_Requests")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={"/new-requests"}
        />
        <BaseTableIndex
          tableHeader={newRequests}
          content={noAssignedRequestsData?.content || []}
          isLoading={isFetching}
          injectProps={{ tableId: TABLE_ID.LAB_NO_ASSIGNED_REQUESTS }}
          emptyData={{
            title: t("NoNewRequest"),
            desc: t("No New Request Desc"),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/new-requests/${id}`,
          }}
        />
      </BaseCard>

      {/* second section examination_requests */}
      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <TableTitle
          title={t("Assigned_Requests")}
          view={t("view all")}
          linkStyles={{
            fontWeight: "bold",
            color: `${palette.text.tags.blueSoft}`,
            fontSize: "1.6rem",
          }}
          to={"/assigned-requests"}
        />
        <BaseTableIndex
          tableHeader={assignedRequests}
          content={labAssignedRequestsData?.content || []}
          isLoading={isLabAssignedRequestDataFetching}
          injectProps={{ tableId: TABLE_ID.LAB_ASSIGNED_REQUESTS }}
          emptyData={{
            title: t("No requests"),
            desc: t("LabNoRequest"),
            img: publicAssetsPath("images/documents3.svg"),
          }}
          withDetails={{
            transferData: true,
            paramsKey: "id",
            to: (id) => `/assigned-requests/${id}`,
          }}
        />
      </BaseCard>
    </>
  );
};

export default DashboardIndex;
