import { createLogConfig, serverKeysEnum } from "../utils";

export const historyFieldDeassigningCycle = (
  _props,
  cycle,
  cycleItem,
  cycleItemIndex
) => {
  const prevItem = _props.sortedCycleItems[cycleItemIndex - 1];
  const isLabNotNewlyAssigned = !prevItem?.labAssignedTime;

  if (cycleItem.key === `${serverKeysEnum.DE_ASSIGNMENT_HISTORIES}-assign`) {
    const assignedLabConfig = createLogConfig({
      label: "تم قبول الطلب من قبل المختبر: ",
      value: `${cycleItem.labName}`,
      date: cycleItem.labAssignedTime,
      isBackendValueExists:
        _props.rawData[serverKeysEnum.DE_ASSIGNMENT_HISTORIES],
    });
    cycle.push(assignedLabConfig);
  }
  if (cycleItem.key === serverKeysEnum.ASSIGNMENT_HISTORY_LIST) {
    const isPrevItemDeAssigned =
      prevItem.key === `${serverKeysEnum.DE_ASSIGNMENT_HISTORIES}-deassign`;
    // IF PREV ITEM IS DE ASSIGNED
    if (isPrevItemDeAssigned) {
      const assignedLabConfig = createLogConfig({
        label: "تم قبول الطلب من قبل المختبر: ",
        value: _props.rawData[serverKeysEnum.ASSIGNED_LAB_NAME],
        date: _props.rawData[serverKeysEnum.LAB_ASSIGNED_AT],
        isBackendValueExists:
          _props.rawData[serverKeysEnum.ASSIGNMENT_HISTORY_LIST],
      });
      cycle.push(assignedLabConfig);
    }

    let assignedTechnicianConfigObject = {};
    // IF PREV ITEM IS RE ASSIGNED TECNICIAN
    if (isLabNotNewlyAssigned) {
      // THIS IS A REPEATED ASSIGNING PROCESS [BECAUSE THE PREV ONE WAS ALREADY ASSIGNED]
      assignedTechnicianConfigObject = createLogConfig({
        label: "تم إعادة إسناد الطلب الى فني الرقابة: ",
        value: `${cycleItem.technicianName} من قبل: ${cycleItem.assignedBy}`,
        date: cycleItem.technicianAssignedAt,
        isBackendValueExists:
          _props.rawData[serverKeysEnum.ASSIGNMENT_HISTORY_LIST],
      });
    } else {
      // THIS IS A NEW ASSIGNING PROCESS
      assignedTechnicianConfigObject = createLogConfig({
        label: "تم إسناد الطلب إلى فني الرقابة: ",
        value: cycleItem.technicianName,
        date: cycleItem.technicianAssignedAt,
        isBackendValueExists:
          _props.rawData[serverKeysEnum.ASSIGNMENT_HISTORY_LIST],
      });
    }
    cycle.push(assignedTechnicianConfigObject);
  }
  if (cycleItem.key === `${serverKeysEnum.DE_ASSIGNMENT_HISTORIES}-deassign`) {
    const deassignLabConfig = createLogConfig({
      label: "",
      value: `تم إعادة الطلب لنظام التوزيع من قبل: ${cycleItem.assignedBy}`,
      date: cycleItem.labDeAssignedTime,
      isBackendValueExists:
        _props.rawData[serverKeysEnum.DE_ASSIGNMENT_HISTORIES],
    });
    cycle.push(deassignLabConfig);
  }
};
