/**
 * @description This function triggers on mount in Sidemenu component to hide every submenu on mount
 *  It also gets called on every click on links to make hide any not related submenu
 * @returns {Promise}
 */
export const hideSubmenuChildrenListLinks = () => {
  let timer;
  if (timer) clearTimeout(timer);
  const subChildrenMenu = Array.from(
    document.querySelectorAll("[data-sub-list^='sub-list']")
  );

  return new Promise((resolve) => {
    timer = setTimeout(() => {
      subChildrenMenu.forEach((child) => {
        slideUp(child);
      });
      resolve(true);
    }, 0);
  });
};

// this function used in SideMenu component at useEffect hook
// and its main job to get the height of every sub menu before they get hidden
// as this function takes a call back function as an argument which it takes
// hideSubmenuChildrenListLinks fn that's main job to hide all sub menus on mount
export const getSubListsHeightOnMount = (callback) => {
  const subChildrenMenu = Array.from(
    document.querySelectorAll("[data-sub-list^='sub-list']")
  );

  subChildrenMenu.forEach((child) => {
    child.dataset.height = child.offsetHeight + 8 + "px";
  });

  callback();
};

/**
 *
 * @param {string} id - This id is the id of the link clicked
 * @description This function takes the id of submenu and add slideDown styles on it to toggle its visibility state to be displayed
 * @returns {void}
 */
export const showSubmenuChildrenListLinksIfActive = (id) => {
  const thisParentChildrenList = Array.from(
    document.querySelectorAll(`[data-sub-list='sub-list-${id}']`)
  );

  thisParentChildrenList.forEach((child) => {
    slideDown(child);
  });
};

export const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.height = 0;
  target.style.margin = 0;
  target.style.padding = 0;
  target.style.overflow = "hidden";
  target.style.visibility = "hidden";
};

export const slideDown = (target, duration = 500) => {
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.height = target.dataset.height;
  target.style.margin = "unset";
  target.style.padding = "unset";
  target.style.overflow = "hidden";
  target.style.visibility = "visible";
};

export const stopOnWheel = (e) => e.target.blur();

export const scrollToNonInputElement = (errors) => {
  const firstErrorElementName = checkForInputTypeError(errors);

  if (firstErrorElementName) {
    firstErrorElementName.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};

let checkForInputTypeError = (errors) => {
  let isFirstErrorOnScreenIsInput = false;
  if (Array.from(document.querySelectorAll("[name]")).length > 0) {
    const allNamedElements = Array.from(document.querySelectorAll("[name]"));

    for (let input of allNamedElements) {
      if (errors[input.getAttribute("name")]) {
        return input;
      }
    }
  }
  return isFirstErrorOnScreenIsInput;
};

export const _scrollToTop = () =>
  window.scrollTo({ top: 0, behavior: "smooth" });

// NEED TEST
export const onProctorFieldKeyDown = (e) => {
  if (e.which === 38 || e.which === 40) {
    e.preventDefault();
  }
};
