import {
  createLogConfig,
  findTechnicianByStatusIfExists,
  serverKeysEnum,
} from "../utils";

export const historyFieldCycle = (_props, cycle, cycleItem, cycleItemIndex) => {
  // prettier-ignore
  const prevTechnician = findTechnicianByStatusIfExists(_props.sortedCycleItems.slice(0, cycleItemIndex), "last");

  if (cycleItem.key === serverKeysEnum.INSPECTION_SUB_TEST_HISTORY_LIST) {
    [..._props.rawData[cycleItem.key]]
      .sort(() => 1)
      .forEach((subData) => {
        if (subData.scoreTime) {
          const inspectionSubTestHistoryListConfig = createLogConfig({
            label: "تم رفع نتيجة الاختبار: ",
            value: `${subData.name} من قبل المختبر: ${
              _props.rawData[serverKeysEnum.ASSIGNED_LAB_NAME]
            }`,
            date: subData.scoreTime,
            isBackendValueExists:
              _props.rawData[serverKeysEnum.ASSIGNED_LAB_NAME],
          });

          cycle.push(inspectionSubTestHistoryListConfig);
        }
      });
  }
  if (cycleItem.key === serverKeysEnum.TECHNICIAN_ATTACHED_AT) {
    const technicianAttachedAtConfig = createLogConfig({
      label: "تم إرفاق صور النتائج الحقلية من قبل فني الرقابة: ",
      value: prevTechnician?.technicianName,
      date: _props.rawData[serverKeysEnum.TECHNICIAN_ATTACHED_AT],
      isBackendValueExists:
        _props.rawData[serverKeysEnum.TECHNICIAN_ATTACHED_AT],
    });

    cycle.push(technicianAttachedAtConfig);
  }
};
