import { createLogConfig, serverKeysEnum } from "../utils";

export const historyLabCycle = (_props, cycle, cycleItem) => {
  if (cycleItem.key === serverKeysEnum.COLLECTED_AT) {
    const collectedAtConfig = createLogConfig({
      label: "",
      value: "تم جمع العينة من الموقع",
      date: cycleItem._date,
      isBackendValueExists: _props.rawData[serverKeysEnum.COLLECTED_AT],
    });
    cycle.push(collectedAtConfig);
  }
  if (cycleItem.key === serverKeysEnum.DIST_CENTER_DELIVERED_AT) {
    const distCenterDeliveredAtConfig = createLogConfig({
      label: "",
      value: "تم تسليم العينة إلى مركز التوزيع",
      date: cycleItem._date,
      isBackendValueExists:
        _props.rawData[serverKeysEnum.DIST_CENTER_DELIVERED_AT],
    });

    cycle.push(distCenterDeliveredAtConfig);
  }
  if (cycleItem.key === serverKeysEnum.DIST_CENTER_RECEIVED_AT) {
    const distCenterReceivedAtConfig = createLogConfig({
      label: "تم استلام العينة من قبل مركز التوزيع:  ",
      value: _props.rawData[serverKeysEnum.DIST_CENTER_USERNAME],
      date: cycleItem._date,
      isBackendValueExists:
        _props.rawData[serverKeysEnum.DIST_CENTER_RECEIVED_AT],
    });
    cycle.push(distCenterReceivedAtConfig);
  }
  if (cycleItem.key === serverKeysEnum.LABORATORY_DELIVERED_AT) {
    const laboratoryNameConfig = createLogConfig({
      label: "تم تسليم العينة إلى المختبر: ",
      value: _props.rawData[serverKeysEnum.LABORATORY_NAME],
      date: cycleItem._date,
      isBackendValueExists:
        _props.rawData[serverKeysEnum.LABORATORY_DELIVERED_AT],
    });

    cycle.push(laboratoryNameConfig);
  }
  if (cycleItem.key === serverKeysEnum.LABORATORY_RECEIVED_AT) {
    const laboratoryReceivedAtConfig = createLogConfig({
      label: "تم استلام العينة من قبل المختبر: ",
      value: _props.rawData[serverKeysEnum.LABORATORY_NAME],
      date: _props.rawData[serverKeysEnum.LABORATORY_RECEIVED_AT],
      isBackendValueExists:
        _props.rawData[serverKeysEnum.LABORATORY_RECEIVED_AT],
    });

    cycle.push(laboratoryReceivedAtConfig);
  }
};
