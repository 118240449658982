import { queryBuilder } from "utils/ApiHelpers";
import inspectionApi from "../../../Inspection/apis";

const inspectionAdminApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getCertificatePreview: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/inspectionCertificates/preview",
        method: "POST",
        body: body,
      }),
    }),
    validateCertificate: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/inspectionCertificates/validate",
        method: "POST",
        body: body,
      }),
    }),
    issueCertificate: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/inspectionCertificates/issue",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["License-Details"],
    }),
    viewCertificate: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/inspectionCertificates/view",
        method: "POST",
        body: body,
      }),
    }),
    getAsphaltGradationLimit: builder.query({
      query: (inspection_layer_id) => ({
        url: `/api/inspection/aspeltGradationLimits/admin/inspectionLayer/${inspection_layer_id}`,
      }),
    }),
    // NEW
    getInspectionForAdmin: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspections/v2/admin/${inspectionLayerId}`,
      }),
      providesTags: ["Request"],
    }),
    getTestsForAdmin: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionTests/v2/admin/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Lab-Inspection-Tests"],
    }),
    getSubTestsForAdmin: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionSubTests/v2/admin/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Lab-Inspection-Sub-Tests"],
    }),
    // NEW
    getSamplesForAdmin: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/samples/v2/admin/details/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Samples"],
    }),
    getRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/inspection/inspectionLayers?${q}`,
        };
      },
    }),
  }),
});

export const {
  useGetInspectionForAdminQuery,
  useGetSamplesForAdminQuery,
  useLazyGetRequestsQuery,
  useGetTestsForAdminQuery,
  useGetSubTestsForAdminQuery,
  useGetCertificatePreviewMutation,
  useValidateCertificateMutation,
  useIssueCertificateMutation,
  useViewCertificateMutation,
  useGetAsphaltGradationLimitQuery,
} = inspectionAdminApi;

export default inspectionAdminApi;
