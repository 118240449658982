import { Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import { publicAssetsPath } from "utils/Paths/Paths";

const LocationField = (props) => {
  const { location, testType } = props;

  return (
    <>
      <Typography
        className={"request-data-details__value"}
        sx={{
          fontWeight: "bold !important",
          display: "flex",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        {props.location && testType === "LAB"? (
          defaultEmptyValue
        ) : (
          <>
            {location ? (
              <>
                <a
                  href={location}
                  target="_blank"
                  className="request-data-details__link"
                >
                  الموقع
                </a>
                <img
                  src={publicAssetsPath("icons/popout-svgrepo.svg")}
                  width="auto"
                />
              </>
            ) : (
              defaultEmptyValue
            )}
          </>
        )}
      </Typography>
    </>
  );
};

export default LocationField;
