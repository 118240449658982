import { Typography } from "@mui/material";
import DashboardLayout from "core-ui/Layouts/DashboardLayout/DashboardLayout";
import { Outlet } from "react-router-dom";

const Dashboard = (props) => {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default Dashboard;
