import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import ExaminationDataField from "modules/Consultant/components/ExaminationData/Field/ExaminationDataField";
import React from "react";
import { useParams } from "react-router-dom";

function FieldExaminationRequests({
  requestDetails,
  dataId,
  labTestAppointment,
}) {
  const params = useParams();
  const { stations } = useGetStationsWithTestData({ id: params.id });

  return (
    <ExaminationDataField
      request={requestDetails}
      dataId={dataId}
      stations={stations}
      labTestAppointment={labTestAppointment}
    />
  );
}

export default FieldExaminationRequests;
