import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EditRequestsDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { Box, Typography } from "@mui/material";
import {
  useGetEditRequestsDetailsQuery,
  useApproveEditRequestMutation,
  useRejectEditRequestMutation,
} from "modules/Admin/apis/inspection-apis/edit-requests";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import BaseConfimationModal from "core-ui/BaseConfimationModal/BaseConfimationModal";
import RejectionModal from "../../../../../components/AllModalContent/RejectionModal/RejectionModal";
import { _void } from "utils/Objects/Objects";
import FieldEditRequestDetails from "./Field";
import LabEditRequestDetails from "./Lab";
import { TestTypes } from "constants/TestTypes";

const EditRequestDetails = () => {
  const { t } = useTranslation(["dashboard"]);
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  // media size

  // main data handlers
  const params = useParams();

  const { data: editRequestsDetails, isFetching } =
    useGetEditRequestsDetailsQuery(params?.id, {
      skip: !params?.id,
    });

  const isLab = editRequestsDetails?.testtype === TestTypes.LAB_TEST;

  // actions (accept/reject) handler
  const [approveEditRequest, approveEditRequestResponse] =
    useApproveEditRequestMutation();
  const [rejectEditRequest, rejectEditRequestResponse] =
    useRejectEditRequestMutation();

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const confirmAceept = () => {
    approveEditRequest(editRequestsDetails?.id)
      .unwrap()
      .then(() => {
        setShowAcceptModal(false);
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_APPROVE_EDIT_REQUEST,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        setShowAcceptModal(false);
        showAlertHandler({
          show: true,
          message: ErrorCodes.FAILURE_APPROVE_EDIT_REQUEST,
          type: AlertTypes.ERROR,
        });
      });
  };
  const confirmReject = (rejectReason, reset) => {
    rejectEditRequest({
      body: {
        inspectionEditId: editRequestsDetails?.id,
        rejectReason,
      },
    })
      .unwrap()
      .then(() => {
        setShowRejectionModal(false);
        reset();
      })
      .catch((err) => {
        setShowRejectionModal(false);
        showAlertHandler({
          show: true,
          message: ErrorCodes.FAILURE_APPROVE_EDIT_REQUEST,
          type: AlertTypes.ERROR,
        });
      });
  };

  const DashboardIntroAction = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <BaseAlert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          destroy={hideAlertHandler}
          autoClose={10}
        />
        {editRequestsDetails?.status === "PENDING" && (
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}
          >
            <BaseButton
              onClick={() => setShowRejectionModal(true)}
              sx={{
                minWidth: "12.2rem",
              }}
              variant="secondary"
              isLoading={rejectEditRequestResponse.isLoading}
              disabled={approveEditRequestResponse.isLoading || isFetching}
            >
              {t("reject")}
            </BaseButton>
            <BaseButton
              sx={{
                minWidth: "12.2rem",
              }}
              onClick={() => setShowAcceptModal(true)}
              isLoading={approveEditRequestResponse.isLoading}
              disabled={rejectEditRequestResponse.isLoading || isFetching}
            >
              {t("accept")}
            </BaseButton>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {/* Rejection Modal */}
      <RejectionModal
        show={showRejectionModal}
        closeModal={() => setShowRejectionModal(false)}
        confirmReject={confirmReject}
        isLoading={rejectEditRequestResponse.isLoading || isFetching}
      />

      {/* Accept Modal */}
      <BaseConfimationModal
        title="تأكيد قبول طلب التعديل"
        onConfirm={confirmAceept}
        onHide={() => setShowAcceptModal(false)}
        showModal={showAcceptModal}
        isLoading={approveEditRequestResponse.isLoading || isFetching}
      >
        يرجى العلم أنه سيتم قبول طلب تعديل النتائج لطلب الفحص رقم{" "}
        <span style={{ fontWeight: 700 }}>{editRequestsDetails?.id}</span>، هل
        أنت متأكد من قيامك بذلك؟
      </BaseConfimationModal>

      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${t("Edit Requests")} - ${editRequestsDetails?.id}`}</title>
        </Helmet>

        <DashboardIntro
          title={`طلب تعديل رقم ${editRequestsDetails?.id || ""}`}
          isLoading={isFetching}
          description="يمكنك من خلال هذه الصفحة الاطلاع على تفاصيل طلب التعديل، كما يمكنك أيضا قبول طلب التعديل أو رفضه."
          action={<DashboardIntroAction />}
          breadcrumbData={
            !editRequestsDetails
              ? _void
              : EditRequestsDetailsBreadcrumb(editRequestsDetails)
          }
        />

        <BaseCard py="3rem" px="5rem" mt={2} minHeight="75%">
          {isFetching ? (
            <BaseLoader />
          ) : (
            <>
              {editRequestsDetails?.adminDecisionReasoning && (
                <BaseAlert
                  mb={5}
                  show={true}
                  labelSx={{ fontWeight: "bold", color: "black" }}
                  type={AlertTypes.WARNING}
                  icon={false}
                >
                  <Typography
                    sx={{
                      fontSize: "inherit !important",
                      fontWeight: "bold",
                      marginBottom: "1rem",
                    }}
                  >
                    سبب رفض طلب تحديث النتائج:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "normal",
                    }}
                  >
                    {editRequestsDetails?.adminDecisionReasoning}
                  </Typography>
                </BaseAlert>
              )}
              {isLab ? (
                <LabEditRequestDetails
                  data={editRequestsDetails}
                  isFetching={isFetching}
                />
              ) : (
                <FieldEditRequestDetails
                  data={editRequestsDetails}
                  isFetching={isFetching}
                />
              )}
            </>
          )}
        </BaseCard>
      </>
    </>
  );
};

export default EditRequestDetails;
