import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";

const StationsDataModal = (props) => {
  const stations = props.stations;

  const renderStationElements = () => {
    if (!stations || stations?.length === 0)
      return (
        <Typography
          mt={2.5}
          sx={{
            fontSize: "1.6rem",
            color: "grayDark.color",
          }}
        >
          لا يوجد بيانات
        </Typography>
      );
    return stations
      ?.filter((station) => !station.isVirtual)
      ?.map((item, idx) => {
        return (
          <Box
            key={idx}
            sx={{
              display: "flex",
              gap: "1rem",
              mt: 2,
            }}
          >
            {/* Station number box */}
            <Box
              px={1.8}
              py={1}
              mr={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: `transparent linear-gradient(180deg,#347a7c59
                 200%,#52b6a459 100%) 0% 0% no-repeat padding-box`,
                width: "3.6rem",
                borderRadius: "0.5rem",
                height: "3.7rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  fontWeight: 500,
                  color: "grayDark.color",
                }}
              >
                {`${idx + 1}`}
              </Typography>
            </Box>

            <Typography
              mt={0.7}
              sx={{
                fontSize: "1.6rem",
                fontWeight: 500,
                color: "grayDark.color",
              }}
            >
              {item.from}م - {item.to}م
            </Typography>
          </Box>
        );
      });
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "grayDark.color",
            }}
          >
            نقاط الـ Stations
          </Typography>
          <CloseIcon
            onClick={props.onHide}
            fontSize="large"
            sx={{
              color: "grayDark.color",
              fontWeight: "bold",
            }}
          />
        </Box>
        <hr />
        <WrapperContentGrid columnsWidth={21} gap={2}>
          {renderStationElements()}
        </WrapperContentGrid>
      </Box>
    </>
  );
};

export default StationsDataModal;
