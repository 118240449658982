import React, { useMemo } from "react";
import { Button, useTheme } from "@mui/material";
import classes from "./BaseButton.module.scss";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";

const BaseButton = React.forwardRef(({ isLoading, ...props }, ref) => {
  const { palette } = useTheme();

  const _variant =
    isLoading || props.disabled || props.variant === "disabled"
      ? "disabled"
      : props.variant || "";

  const getVariantSx = (variant) => {
    let variantSx = {
      backgroundColor: palette.primary.main,
      color: palette.base.white,
    };
    switch (variant) {
      case "disabled":
        variantSx = {
          backgroundColor: palette.background.disabled,
          color: `${palette.grayDark.color12} !important`,
          fontWeight: "bold",
          border: `1px solid ${palette.borders.disabled}`,
        };
        break;
      case "secondary":
        variantSx = {
          backgroundColor: palette.base.white,
          color: palette.base.black,
          border: `1px solid ${palette.primary.main}`,
        };
        break;
      case "transparent":
        variantSx = {
          color: palette.text.tags.blueSoft,
        };
        break;
      case "white":
        variantSx = {
          background: palette.base.white,
          color: palette.base.black,
        };
        break;
    }
    return variantSx;
  };
  const defaultButtonSx = useMemo(
    () => ({
      ...props.sx,
      minWidth: props?.sx?.minWidth || "20rem",
      fontSize: props.sx?.fontSize || "1.6rem",
      color: `${palette.base.white}`,
      transition: "all 0.5s ease",
      ...getVariantSx(_variant),
    }),
    [_variant]
  );

  return (
    <Button
      ref={ref}
      {...props}
      size={props.size}
      sx={{ ...defaultButtonSx, ...props.sx }}
      disabled={isLoading || props.disabled}
      className={`${classes["base-btn"]} ${classes[`base-btn--${_variant}`]}`}
    >
      {isLoading ? <BaseLoader size="sm" /> : props.children}
    </Button>
  );
});

export default BaseButton;

BaseButton.defaultProps = {
  variant: "primary",
  type: "button",
  sx: {
    minWidth: "20rem",
    fontSize: "1.6rem",
  },
};
